<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-form class="ruleForm" :model="ruleForm" :rules="rules" ref="ruleForm">
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            {{ pageTitleName }}
          </h3>
        </div>
        <div class="card-body">
          <el-row :gutter="24">
            <el-col :span="24">
              <el-form-item label="管理原因" prop="name">
                <el-input v-model="ruleForm.name" placeholder="请输入管理原因"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="个人分类" prop="personCategoryId">
                <treeSelect
                  v-if="personCategory != null && personCategory.length > 0"
                  :key="random"
                  :options="personCategory"
                  ref="refType"
                  :value="ruleForm.personCategoryId"
                  @getValue="getPerson"
                ></treeSelect>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="寝室分类" prop="dormitoryCategoryId">
                <treeSelect
                  v-if="dormitoryCategory != null && dormitoryCategory.length > 0"
                  :key="random"
                  :options="dormitoryCategory"
                  ref="refType"
                  :value="ruleForm.dormitoryCategoryId"
                  @getValue="getDorm"
                ></treeSelect>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="班级分类" prop="classCategoryId">
                <treeSelect
                  v-if="classCategory != null && classCategory.length > 0"
                  :key="random"
                  :options="classCategory"
                  ref="refType"
                  :value="ruleForm.classCategoryId"
                  @getValue="getClass"
                ></treeSelect>
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="关联班级">
                <el-switch v-model="ruleForm.relatedClass" class="formControl"></el-switch>
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="关联寝室">
                <el-switch v-model="ruleForm.relatedDormitory" class="formControl"></el-switch>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="教师角色">
                <el-select class="formControl" v-model="ruleForm.roleConfigs" multiple collapse-tags placeholder="请选择角色（可多选）">
                  <el-option v-for="item in roles" :key="item.roleId" :label="item.name" :value="item.roleId"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="学生会角色">
                <el-select class="formControl" v-model="ruleForm.associationConfigs" multiple collapse-tags placeholder="请选择角色（可多选）">
                  <el-option v-for="item in studentRoles" :key="item.associationId" :label="item.name" :value="item.associationId"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="优先级" prop="priority">
                <el-input @change="onPriorityChange()" v-model="ruleForm.priority" placeholder="请输入优先级"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="关联累计">
                <el-switch v-model="ruleForm.accumulate" class="formControl"></el-switch>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="提醒分值">
                <el-input v-model="ruleForm.warnScore" placeholder="提醒分值" :disabled="!regNull(ruleForm.reasonId)"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="上限分值">
                <el-input v-model="ruleForm.maxScore" placeholder="上限分值" :disabled="!regNull(ruleForm.reasonId)"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="备注" prop="description">
                <el-input
                  class="formControl"
                  type="textarea"
                  :autosize="{ minRows: 5, maxRows: 10 }"
                  show-word-limit
                  placeholder="请输入备注"
                  maxlength="500"
                  v-model="ruleForm.description"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-card>
    </el-form>

    <!--选择模式录入-->
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          选择模式录入
        </h3>
        <div class="card-tools">
          <el-button type="text" class="btn btn-success btn-xs" @click="dialogVisible = true">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#fax-circle-plus-alt"></use>
            </svg>
            新增
          </el-button>
        </div>
      </div>
      <div class="card-body">
        <div class="callout callout-warning">
          <div class="tipCont">1、该规则的分数是在设定的分数中选择，不能自定义录入</div>
          <div class="tipCont">2、在用户选择时按从小到大排序；默认值有且只有一个，如果未设置默认值，在保存时系统会把最小分数设置为默认值；</div>
          <div class="tipCont">3、红色星号为默认值</div>
        </div>
        <el-table :data="arrFractionForSel" stripe class="elTableData">
          <el-table-column label="序号" width="120">
            <template slot-scope="scope">
              {{ scope.$index + 1 }}
              <svg class="icon color-danger" aria-hidden="true" v-if="scope.row.isDefault">
                <use xlink:href="#fax-star-alt"></use>
              </svg>
            </template>
          </el-table-column>
          <el-table-column prop="score" label="个人分数" class="elColumn" v-if="!regNull(ruleForm.personCategoryId)"></el-table-column>
          <el-table-column
            prop="relatedScore"
            label="集体分数"
            class="elColumn"
            v-if="!regNull(ruleForm.dormitoryCategoryId) || !regNull(ruleForm.classCategoryId)"
          ></el-table-column>
          <el-table-column label="操作" class="elColumn" width="100">
            <template slot-scope="scope">
              <el-button size="mini" type="danger" @click="onRemoveSel(scope.row.deployId)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>
    <div v-if="configs.length > 0">
      <comLiteracyConfig :configList="configs" :defaultConfig="defaultConfigs" ref="comLiteracyConfig" @change="handleTypesChange" :key="random"></comLiteracyConfig>
    </div>
    <!-- <div v-if="configsClass.length>0">
      <comLiteracyConfig :configList='configsClass' :defaultConfig='defaultConfigs' ref="comLiteracyConfig2"
        :title="'班级评价类型'" @change="handleTypesChange" :key="random">
      </comLiteracyConfig>
    </div> -->

    <!-- 弹出框 -->
    <el-dialog title="新增分数" :visible.sync="dialogVisible" width="30%" :before-close="handleClose" :close-on-click-modal="false">
      <div class="card-body">
        <el-form class="ruleForm" ref="ruleDialog" :model="ruleDialog" :rules="rulesDialog">
          <el-form-item label="个人分数" v-if="!regNull(ruleForm.personCategoryId)">
            <el-input v-model="ruleDialog.score" placeholder="请输入个人分数" class="formControl"></el-input>
          </el-form-item>
          <el-form-item label="集体分数" v-if="!regNull(ruleForm.dormitoryCategoryId) || !regNull(ruleForm.classCategoryId)">
            <el-input v-model="ruleDialog.relatedScore" placeholder="请输入集体分数" class="formControl"></el-input>
          </el-form-item>
          <!--          <el-form-item label="是否设置为默认">
            <el-switch v-model="ruleDialog.isDefault" class="formControl"></el-switch>
          </el-form-item> -->
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="onCreateFraction">确 定</el-button>
      </span>
    </el-dialog>

    <div class="row mt-3">
      <div class="col-12">
        <el-button icon="el-icon-back" @click="onBack">返回</el-button>
        <el-button icon="el-icon-refresh" @click="onReset()">重置</el-button>
        <el-button type="primary" icon="el-icon-circle-check" @click="onSave()">提交</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import treeSelect from '@/components/TreeSelect.vue';
import comLiteracyConfig from '@/components/Admin/LiteracyConfig.vue';
import { element } from 'screenfull';
export default {
  components: {
    treeSelect,
    comLiteracyConfig
  },
  data() {
    return {
      // 加载动画
      fullscreenLoading: false,
      //综合类型
      configs: [],
      //班级评价类型
      configsClass: [],
      defaultConfigs: [],
      // 必填项
      ruleForm: {
        reasonId: null, //ID
        name: null, // 名称
        score: 0,
        priority: null, //优先级
        description: null, //描述
        configs: [],
        // 教师角色
        roleConfigs: [],
        // 学生角色
        associationConfigs: [],
        reasonType: 2, // 2：管理原因
        inputScoreMode: '', // 分数类型
        reasonDeploys: [], // 分数集合
        accumulate: false,
        personCategoryId: null,
        dormitoryCategoryId: null,
        classCategoryId: null
      },
      // 验证
      rules: {
        name: [
          {
            required: true,
            message: '原因不能为空',
            trigger: 'change'
          },
          {
            max: 64,
            message: '原因不能超过64个字符',
            trigger: 'change'
          }
        ],
        score: [
          {
            required: true,
            message: '请输入分值',
            trigger: 'change'
          }
        ],
        priority: [
          {
            validator: (rule, value, callback) => {
              let that = this;
              var reg = /^-?[1-9]\d*$/;
              if (value != 0 && !reg.exec(value)) {
                callback(new Error('优先级只能输入整数！'));
              } else {
                callback();
              }
            },
            trigger: 'change'
          }
        ]
      },
      ruleFormReset: null,
      // 所属类型
      moralityTree: [],
      random: null,
      // 管理原因对象 1：班级 2：班级个人 3：寝室 4：寝室个人
      currentType: 1,
      // 是根据对象显示页面名称
      pageTitleName: '',
      // 教师角色
      roles: [],
      // 学生角色
      studentRoles: [],
      isFractionType: '',
      // 分数录入方式
      optFraction: [
        {
          Value: '1',
          Text: '按选择模式录入'
        },
        {
          Value: '4',
          Text: '自定义录入'
        }
      ],
      // 录入方式:选择模式
      arrFractionForSel: [],
      arrFractionForSelReset: null,
      // 录入方式:按次数累加计算
      arrFractionForGrand: [],
      arrFractionForGrandReset: null,
      // 按数量计算
      itemFractionForNum: {
        score: '', //分数
        unit: '' // 单位
      },
      itemFractionForNumReset: null,

      // 弹出框
      dialogVisible: false,
      // 弹出框 自定义录入分数
      ruleDialog: {
        score: '', //分数
        isDefault: false,
        relatedScore: null
      },
      personCategory: [],
      dormitoryCategory: [],
      classCategory: [],
      ruleDialogReset: null,
      // 弹出框验证
      rulesDialog: {
        number: [
          {
            required: true,
            message: '请输入数量',
            trigger: 'change'
          },
          {
            validator: (rule, value, callback) => {
              let that = this;
              var reg = /^[0-9]*$/;
              if (value != 0 && !reg.exec(value)) {
                callback(new Error('数量只能输入正整数！'));
              } else {
                callback();
              }
            },
            trigger: 'change'
          }
        ],
        score: [
          {
            required: true,
            message: '请输入个人分值',
            trigger: 'change'
          },
          {
            validator: (rule, value, callback) => {
              let that = this;
              var reg = /^(\-|\+)?\d+(\.\d{1,2})?$/;
              if (value != 0 && !reg.exec(value)) {
                callback(new Error('分数只能输入带1-2位小数的正数或负数！'));
              } else {
                callback();
              }
            },
            trigger: 'change'
          }
        ],
        relatedScore: [
          {
            required: true,
            message: '请输入班级分值',
            trigger: 'change'
          },
          {
            validator: (rule, value, callback) => {
              let that = this;
              var reg = /^(\-|\+)?\d+(\.\d{1,2})?$/;
              if (value != 0 && !reg.exec(value)) {
                callback(new Error('分数只能输入带1-2位小数的正数或负数！'));
              } else {
                callback();
              }
            },
            trigger: 'change'
          }
        ]
      },
      reasonsClass: [],
      reasonsDormitory: []
    };
  },
  methods: {
    onChange() {
      let that = this;
    },
    /**
     * @description 所属类型选择事件
     * @param {Array} 类型ID
     * @return {void}
     */
    getPerson(_val) {
      let that = this;
      that.ruleForm.personCategoryId = _val;
    },
    getDorm(_val) {
      let that = this;
      that.ruleForm.dormitoryCategoryId = _val;
    },
    getClass(_val) {
      let that = this;
      that.ruleForm.classCategoryId = _val;
    },
    onChangeinputScoreMode() {
      let that = this;
      that.ruleForm.accumulate = false;
      that.arrFractionForSel = [];
    },
    /**
     * @description 数据递归
     * @param {Array} data 需要递归的原数据
     * @return {Array} 返回递归后的数据
     */
    getTreeData(data) {
      // 循环遍历json数据
      for (var i = 0; i < data.length; i++) {
        if (data[i].children.length < 1) {
          // children若为空数组，则将children设为undefined
          data[i].children = undefined;
        } else {
          // children若不为空数组，则继续 递归调用 本方法
          this.getTreeData(data[i].children);
        }
      }
      return data;
    },
    handleTypesChange(objItem, objValID) {
      let that = this;
      if (!regNull(objItem)) {
        if (regNull(that.ruleForm.configs)) {
          that.ruleForm.configs.push(objItem);
        } else {
          let tpInfo = that.ruleForm.configs.find((item) => item.configId == objItem.configId);
          if (regNull(tpInfo)) {
            that.ruleForm.configs.push(objItem);
          } else {
            that.ruleForm.configs.forEach((item) => {
              if (item.configId == objItem.configId) {
                item.score = objItem.score;
              }
            });
          }
        }
      } else if (!regNull(objValID)) {
        that.ruleForm.configs = that.ruleForm.configs.filter((item) => item.configId != objValID);
      }
    },
    /**
     * @description 删除【选择模式录入】的分数
     * @param {String} _valId 数据ID
     * @return {void}
     */
    onRemoveSel(_valId) {
      let that = this;

      let tempArr = that.arrFractionForSel.filter((val) => val.deployId != _valId);
      that.arrFractionForSel = tempArr;
    },

    /**
     * @description 弹出框提交按钮
     * @return {void}
     */
    onCreateFraction() {
      let that = this;
      that.$refs['ruleDialog'].validate((valid) => {
        if (valid) {
          // 按选择模式录入
          // 判断是否存在同分值
          if (
            that.arrFractionForSel.find((val) => setTwoDecimal(val.score) == setTwoDecimal(that.ruleDialog.score)) == undefined ||
            that.arrFractionForSel.find((val) => setTwoDecimal(val.relatedScore) == setTwoDecimal(that.ruleDialog.relatedScore)) == undefined
          ) {
            if (that.ruleDialog.isDefault) {
              for (var item of that.arrFractionForSel) {
                item.isDefault = false;
              }
            }
            that.arrFractionForSel.push({
              deployId: timeTransform(new Date(), 'YYYYMMDD-HHmmss-SSS'), //临时ID
              score: that.ruleDialog.score ? that.ruleDialog.score : 0,
              relatedScore: that.ruleDialog.relatedScore ? that.ruleDialog.relatedScore : 0,
              isDefault: that.ruleDialog.isDefault,
              number: 0,
              unit: null
            });
          } else {
            warning(that, '已存在同分值的分数！');
          }
          // 关闭弹出框
          that.handleClose();
        }
      });
    },

    /**
     * @description 返回按钮
     * @return {void}
     */
    onBack() {
      let that = this;
      back(that);
    },

    /**
     * @description 优先级
     * @return {void}
     */
    onPriorityChange() {
      let that = this;
      let temp = setInt(that.ruleForm.priority);
      that.ruleForm.priority = temp;
    },

    /**
     * @description 关闭弹出框
     * @return {void}
     */
    handleClose() {
      let that = this;
      that.dialogVisible = false;
      that.$refs['ruleDialog'].resetFields();
      that.ruleDialog = JSON.parse(JSON.stringify(that.ruleDialogReset));
    },
    /**
     * @description 重置按钮
     * @return {void}
     */
    onReset() {
      let that = this;
      that.ruleForm = JSON.parse(JSON.stringify(that.ruleFormReset));
      that.ruleDialog = JSON.parse(JSON.stringify(that.ruleDialogReset));
      // if (that.currentType == 1 || that.currentType == 3) {
      //   that.$refs.comLiteracyConfig2.onReset();
      // }
      // if (that.currentType == 2 || that.currentType == 4) {
      that.$refs.comLiteracyConfig.onReset();
      // }
      if (regNull(that.ruleForm.reasonId)) {
        that.itemFractionForNum = JSON.parse(JSON.stringify(that.itemFractionForNumReset));
        that.arrFractionForSel = [];
        that.arrFractionForGrand = [];
        that.$refs.refType.clearHandle();
      } else {
        that.arrFractionForGrand = JSON.parse(JSON.stringify(that.arrFractionForGrandReset));
        that.arrFractionForSel = JSON.parse(JSON.stringify(that.arrFractionForSelReset));
      }
    },

    /**
     * @description 提交按钮
     * @return {void}
     */
    onSave() {
      let that = this;
      // 设置优先级，默认值为0
      that.ruleForm.priority = Number(that.ruleForm.priority);
      // 获取教师角色
      let temproleConfigs = [];
      if (!regNullArray(that.ruleForm.roleConfigs)) {
        for (let it of that.ruleForm.roleConfigs) {
          temproleConfigs.push({
            roleId: it
          });
        }
      }
      // 获取学生角色
      let tempReasonStudentRoles = [];
      let studentRole = JSON.parse(JSON.stringify(that.ruleForm.associationConfigs));
      if (!regNullArray(that.ruleForm.associationConfigs)) {
        tempReasonStudentRoles = studentRole.map((item) => {
          return {
            associationId: item
          };
        });
      }
      if (!regNullArray(that.ruleForm.configs)) {
        that.ruleForm.configs.forEach((element) => {
          element.coefficient = element.score;
        });
      }
      // // 表单验证
      that.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          // 同步 教师角色和
          let params = JSON.parse(JSON.stringify(that.ruleForm));
          params.associationConfigs = tempReasonStudentRoles;
          params.roleConfigs = temproleConfigs;
          if (regNull(that.ruleForm.personCategoryId) && regNull(that.ruleForm.dormitoryCategoryId) && !regNull(that.ruleForm.classCategoryId)) {
            params.classOnly = true;
          } else {
            params.classOnly = false;
          }
          if (regNull(that.ruleForm.personCategoryId) && !regNull(that.ruleForm.dormitoryCategoryId) && regNull(that.ruleForm.classCategoryId)) {
            params.dormitoryOnly = true;
          } else {
            params.dormitoryOnly = false;
          }
          params.scores = that.arrFractionForSel;
          params.scores.forEach((item) => {
            if (regNull(item.score)) {
              item.score = 0;
            }
            if (regNull(item.relatedScore)) {
              item.relatedScore = 0;
            }
          });
          // 判断是新增 或 编辑
          if (regNull(that.ruleForm.reasonId)) {
            // 新增
            that.fullscreenLoading = true;

            ajaxPost(that, '/api/admin/moralreason', params, function (res) {
              that.fullscreenLoading = false;
              back(that);
            });
          } else {
            // 编辑
            that.fullscreenLoading = true;
            ajaxPut(that, '/api/admin/moralreason/' + that.ruleForm.reasonId, params, function (res) {
              that.fullscreenLoading = false;
              back(that);
            });
          }
        }
      });
    }
  },
  created() {
    let that = this;
    let _TypeID = that.$route.params.Type;
    let _DataID = that.$route.params.Id;
    that.ruleDialogReset = JSON.parse(JSON.stringify(that.ruleDialog));
    that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
    that.itemFractionForNumReset = JSON.parse(JSON.stringify(that.itemFractionForNum));
    //判断是否有传值过来
    let _data = {
      categoryType: 1
    };
    that.fullscreenLoading = true;
    ajaxGet(
      that,
      '/api/admin/moralcategory/treelist',
      {
        categoryType: 1
      },
      function (r1) {
        ajaxGet(
          that,
          '/api/admin/moralcategory/treelist',
          {
            categoryType: 2
          },
          function (r2) {
            ajaxGet(
              that,
              '/api/admin/moralcategory/treelist',
              {
                categoryType: 3
              },
              function (r3) {
                ajaxGet(that, '/api/admin/GrowthConfig', null, function (resConfig) {
                  if (r1) {
                    that.classCategory = renameCategoryIdToId(r1);
                  }
                  if (r2) {
                    that.dormitoryCategory = renameCategoryIdToId(r2);
                  }
                  if (r3) {
                    that.personCategory = renameCategoryIdToId(r3);
                  }
                  console.log(that.personCategory);
                  for (var item of resConfig) {
                    item.isSelect = false;
                  }
                  that.configs = JSON.parse(
                    JSON.stringify(
                      resConfig.filter((val) => {
                        return val.configType == 1;
                      })
                    )
                  );
                  that.fullscreenLoading = false;
                  onGetReasonType(that);
                  let pageTypeName = '';
                  if (regNull(_DataID)) {
                    pageTypeName = '新增';
                  } else {
                    pageTypeName = '编辑';
                    that.ruleForm.reasonId = _DataID;
                    onGetInfo(that);
                  }
                });
              }
            );
          }
        );
      }
    );
  }
};

/**
 * @description 根据ID获取管理原因数据
 * @param {Object} that
 * @return {void}
 */
function onGetInfo(that) {
  // 通过对象类型，获取接口地址
  that.fullscreenLoading = true;
  // 获取数据
  ajaxGet(that, '/api/admin/moralreason/' + that.ruleForm.reasonId, null, function (resData) {
    var temp = JSON.parse(JSON.stringify(resData));
    that.fullscreenLoading = false;
    that.ruleForm = resData;
    // Int转换String
    // 教师角色
    that.ruleForm.roleConfigs = [];
    if (temp.roleConfigs != null && temp.roleConfigs.length > 0) {
      for (var it of temp.roleConfigs) {
        that.ruleForm.roleConfigs.push(it.roleId);
      }
    }
    // 学生角色
    that.ruleForm.associationConfigs = [];
    if (temp.associationConfigs != null && temp.associationConfigs.length > 0) {
      for (var it of temp.associationConfigs) {
        that.ruleForm.associationConfigs.push(it.associationId);
      }
    }
    // 是根据录入的方式 进行绑定敏据
    that.arrFractionForSel = resData.scores;
    that.arrFractionForSelReset = JSON.parse(JSON.stringify(that.arrFractionForSel));
    resData.configs.forEach((ele) => {
      ele.score = ele.coefficient;
    });
    // console.log(that.ruleForm.classCategoryId)
    that.ruleForm.configs = resData.configs;
    that.defaultConfigs = resData.configs;
    var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
    that.random += random;
    try {
      that.$refs['ruleForm'].resetFields();
    } catch {}
  });
}
function renameCategoryIdToId(categories) {
  return categories.map((category) => {
    const { categoryId, children, ...rest } = category;
    const newCategory = { id: categoryId, ...rest };
    if (children && Array.isArray(children)) {
      newCategory.children = renameCategoryIdToId(children);
    }
    return newCategory;
  });
}
/**
 * @description 获取所属类型，教师角色，学生角色
 * @param {Object} that
 * @return {void}
 */
function onGetReasonType(that) {
  that.fullscreenLoading = true;
  // 获取教师角色
  ajaxGet(that, '/api/admin/sysRole', null, function (resSysRole) {
    // 获取学生角色
    ajaxGet(that, '/api/admin/association', null, function (resStudentRole) {
      that.roles = resSysRole;
      that.studentRoles = resStudentRole;
      if (regNull(that.ruleForm.reasonId)) {
        that.ruleForm.roleConfigs = [];
        that.ruleForm.associationConfigs = [];
        that.roles.forEach((item) => {
          that.ruleForm.roleConfigs.push(item.roleId);
        });
        that.studentRoles.forEach((item) => {
          that.ruleForm.associationConfigs.push(item.associationId);
        });
      }
      that.fullscreenLoading = false;
    });
  });
}
</script>

<style scoped="scoped">
.spTipCont {
  font-size: 12px;
  color: #767676;
  position: absolute;
  top: 13px;
  left: 120px;
}

.tipCont {
  font-size: 14px;
}
</style>
<style>
.tab-content .el-checkbox {
  margin-bottom: 0;
}
</style>
