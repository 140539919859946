<!--
  学生信息详情
-->
<template>
  <div class="SubWebPageStudent" v-loading.fullscreen.lock="fullscreenLoading">
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          基础信息
        </h3>
        <div class="card-tools">
          <ul class="nav">
            <li class="nav-item">
              <el-button type="warning" size="mini" icon="el-icon-edit-outline" @click="onEdit()">编辑个人信息</el-button>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body pb-5">
        <el-row :gutter="24">
          <el-col :span="6" class="text-center">
            <img class="imgAvatar" :src="imageUrl" :alt="arrInfo.name" />
          </el-col>
          <el-col :span="18" class="position-relative">
            <div class="infoList clearfix">
              <div class="spName">
                {{ arrInfo.name }}
                <span class="spNullData" v-if="onIsShow(arrInfo.name)">暂无数据</span>
              </div>
              <div class="spGender">
                <svg class="icon maleColor" aria-hidden="true" v-if="isGender">
                  <use xlink:href="#fax-mars-circle-alt"></use>
                </svg>
                <svg class="icon femaleColor" aria-hidden="true" v-else>
                  <use xlink:href="#fax-mars-circle-alt"></use>
                </svg>
              </div>
              <div class="spLabelList bg-info" v-if="arrInfo.isPoor">困难生</div>
              <div class="spLabelList bg-danger" v-if="!onIsShow(arrInfo.punishLevel)">{{ arrInfo.punishLevel }}</div>
            </div>
            <div class="infoList clearfix">
              <span class="spFonts">
                {{ onAge(arrInfo.birthday) }}
                <span class="spNullData" v-if="onIsShow(arrInfo.birthday)">暂无出生年月</span>
              </span>
              <span class="spFonts">
                {{ arrInfo.nation }}
                <span class="spNullData" v-if="onIsShow(arrInfo.nation)">暂无民族信息</span>
              </span>
              <span class="spFonts">
                {{ arrInfo.political }}
                <span class="spNullData" v-if="onIsShow(arrInfo.political)">暂无政治面貌</span>
              </span>
              <span class="spFonts" v-if="positionVal != ''">
                {{ onPosition(positionVal) }}
              </span>
            </div>
            <div class="infoList clearfix" v-if="arrInfo.stars != null && arrInfo.stars.length != 0">
              <span class="spMedal" v-for="(item, index) in arrInfo.stars.slice(0, 5)" :key="index">
                <img :src="onGetImg(item.icon)" :alt="item.star" width="32" />
              </span>
            </div>
            <div class="infoList clearfix">
              <span class="spFonts">
                <svg class="icon faxSize" aria-hidden="true">
                  <use xlink:href="#fax-font-number-o"></use>
                </svg>
                {{ arrInfo.studentNo }}
                <span class="spNullData" v-if="onIsShow(arrInfo.studentNo)">暂无学号</span>
              </span>
              <span class="spFonts">
                <svg class="icon faxSize" aria-hidden="true">
                  <use xlink:href="#fax-font-ban-o"></use>
                </svg>
                {{ arrInfo.class }}
                <span class="spNullData" v-if="onIsShow(arrInfo.class)">暂无班级信息</span>
                &nbsp;/&nbsp;
                {{ arrInfo.college }}
                <span class="spNullData" v-if="onIsShow(arrInfo.college)">暂无学部信息</span>
              </span>
            </div>
            <div class="infoList clearfix">
              <span class="spFonts">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#fax-phone-o"></use>
                </svg>
                {{ arrInfo.mobile }}
                <span class="spNullData" v-if="onIsShow(arrInfo.mobile)">暂无联系电话</span>
              </span>
              <span class="spFonts">
                <svg class="icon faxSize" aria-hidden="true">
                  <use xlink:href="#fax-font-ji-o"></use>
                </svg>
                {{ arrInfo.homeAddress }}
                <span class="spNullData" v-if="onIsShow(arrInfo.homeAddress)">暂无家庭地址</span>
              </span>
              <span class="spFonts">
                <i class="el-icon-office-building"></i>
                {{ arrInfo.dormitory }}
                <span class="spNullData" v-if="onIsShow(arrInfo.dormitory)">暂无寝室信息</span>
              </span>
            </div>
            <div class="infoList clearfix hidden">
              <span class="spFonts">
                <i class="el-icon-user" style="width: 20px"></i>
                <span v-for="(p, index) in arrInfo.studentRoles" :key="index">
                  <span v-if="index > 0">，</span>
                  {{ p.name }}
                </span>
              </span>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-card>

    <!--家庭情况-->
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          家庭情况
        </h3>
      </div>
      <div class="card-body">
        <el-row :gutter="24" v-if="arrInfo.families != null && arrInfo.families.length != 0">
          <el-col :span="6" v-for="(item, index) in arrInfo.families" :key="index">
            <div class="familyList">
              <div class="emergencyCont" v-if="item.isEmer">紧急联系人</div>
              <div class="divTopCont">
                <div class="appellativeCont VerticalMid">{{ item.title }}</div>
                <div class="memberName">{{ item.name }}</div>
                <div class="memberPhone">{{ item.telephone }}</div>
                <div class="memberPosition">
                  <span class="spFont">{{ onIsShow(item.position) ? '暂无职位信息' : item.position }}</span>
                </div>
              </div>
              <div class="divBottomCont">
                <span class="spFont">{{ onIsShow(item.company) ? '暂无工作单位信息' : item.company }}</span>
              </div>
            </div>
          </el-col>
        </el-row>
        <span class="spNullData" v-else>暂无数据</span>
      </div>
    </el-card>

    <!--寝室记录-->
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          当前寝室
        </h3>
      </div>
      <div class="card-body">
        <el-row :gutter="24" v-if="arrInfo.dormCheck != null && arrInfo.dormCheck.length != 0">
          <el-col :span="6" v-for="(p, index) in arrInfo.dormCheck" :key="index" style="padding: 10px">
            <div class="dormAddrCont">{{ p.building }} , {{ p.floor }}层 , {{ p.room }}室 , {{ p.bed }}号床</div>
            <div class="dormTimeCont">
              {{ p.checkinDate | dateformat('YYYY-MM-DD') }} -
              <span v-if="p.checkoutDate != null">{{ p.checkoutDate | dateformat('YYYY-MM-DD') }}</span>
              <span v-else>至今</span>
            </div>
          </el-col>
        </el-row>
        <span class="spNullData" v-else>暂无数据</span>
      </div>
    </el-card>

    <!--兴趣特长-->
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          兴趣特长
        </h3>
      </div>
      <div class="card-body">
        <el-row :gutter="24">
          <el-col :span="24" class="hobbiesCont">
            <span class="spTitle">兴趣</span>
            {{ arrInfo.hobby }}
            <span class="spNullData" v-if="arrInfo.hobby == ''">暂无数据</span>
          </el-col>
          <el-col :span="24" class="hobbiesCont">
            <span class="spTitle">特长</span>
            {{ arrInfo.speciality }}
            <span class="spNullData" v-if="arrInfo.speciality == ''">暂无数据</span>
          </el-col>
        </el-row>
      </div>
    </el-card>

    <!--其他相关信息-->
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          其他相关信息
        </h3>
      </div>
      <div class="card-body">
        <el-row :gutter="24">
          <el-col :span="8" class="otherInfo">
            <span class="spTitle">入学年份：</span>
            <span class="spFonts">
              {{ arrInfo.entryYear | fieldZeroValue }}
              <span class="spNullData" v-if="onIsShow(arrInfo.entryYear)">暂无数据</span>
            </span>
          </el-col>
          <el-col :span="8" class="otherInfo">
            <span class="spTitle">学籍状态：</span>
            <span class="spFonts">
              {{ arrInfo.statusString }}
              <span class="spNullData" v-if="onIsShow(arrInfo.statusString)">暂无数据</span>
            </span>
          </el-col>
          <el-col :span="8" class="otherInfo">
            <span class="spTitle">身份证号：</span>
            <span class="spFonts">
              {{ arrInfo.idCardNo }}
              <span class="spNullData" v-if="onIsShow(arrInfo.idCardNo)">暂无数据</span>
            </span>
          </el-col>
          <el-col :span="8" class="otherInfo">
            <span class="spTitle">生源地：</span>
            <span class="spFonts">
              {{ arrInfo.sourcePlace }}
              <span class="spNullData" v-if="onIsShow(arrInfo.sourcePlace)">暂无数据</span>
            </span>
          </el-col>

          <el-col :span="8" class="otherInfo">
            <span class="spTitle">邮箱：</span>
            <span class="spFonts">
              {{ arrInfo.email }}
              <span class="spNullData" v-if="onIsShow(arrInfo.email)">暂无数据</span>
            </span>
          </el-col>
          <el-col :span="8" class="otherInfo">
            <span class="spTitle">QQ：</span>
            <span class="spFonts">
              {{ arrInfo.qq }}
              <span class="spNullData" v-if="onIsShow(arrInfo.qq)">暂无数据</span>
            </span>
          </el-col>
          <el-col :span="24" class="otherInfo">
            <span class="spTitle">家庭地址：</span>
            <span class="spFonts">
              {{ arrInfo.homeAddress }}
              <span class="spNullData" v-if="onIsShow(arrInfo.homeAddress)">暂无数据</span>
            </span>
          </el-col>
        </el-row>
      </div>
    </el-card>

    <!--教育经历-->
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          教育经历
        </h3>
      </div>
      <div class="card-body">
        <el-row :gutter="24" v-if="arrInfo.educations != null && arrInfo.educations.length != 0">
          <el-col :span="6" v-for="(item, index) in arrInfo.educations" :key="index">
            <div class="schoolInfo">
              {{ item.school }}
              <span class="spTime">{{ item.startDate | filterDate }}&nbsp;-&nbsp;{{ item.endDate | filterDate }}</span>
            </div>
            <div class="schoolLevel">
              <span class="spFont">{{ item.title }}</span>
            </div>
          </el-col>
        </el-row>
        <span class="spNullData" v-else>暂无数据</span>
      </div>
    </el-card>
  </div>
</template>

<script>
import '@/assets/css/Student.css';

export default {
  data() {
    return {
      fullscreenLoading: false,
      arrInfo: [],
      studentId: null,

      imageUrl: '',
      isGender: true,

      // 班干部职务
      positionVal: ''
    };
  },
  methods: {
    /**
     * @description 编辑个人信息
     */
    onEdit() {
      this.$router.push({
        path: '/Student/Myself/BaseInfo/Edit'
      });
    },
    onPosition(_val) {
      return _val.substring(0, _val.lastIndexOf('、'));
    },
    onAvatorUrl(_data) {
      let that = this;
      let tempUrl = '';
      if (_data.photo == null || typeof _data.photo == 'undefined' || _data.photo == '') {
        if (that.onGender(_data.gender)) {
          // 男
          tempUrl = require('@/assets/image/defaultAvatar_Male.jpg');
        } else {
          tempUrl = require('@/assets/image/defaultAvatar_Female.jpg');
          // 女
        }
      } else {
        tempUrl = that.$ServerUrl() + _data.photo;
      }
      return tempUrl;
    },
    /**
     * @description 是否有数据
     * @param {Object} _data
     */
    onIsShow(_data) {
      return regNull(_data);
    },
    // 返回
    onBack() {
      let that = this;
      back(that);
    },
    onGetObtainData(that) {
      that.fullscreenLoading = true;
      ajaxGet(that, '/api/student/student', null, function (resData) {
        that.arrInfo = resData; //JSON.parse(JSON.stringify());

        if (resData.cadres != null && resData.cadres.length > 0) {
          for (let item of resData.cadres) {
            that.positionVal += item.position + '（' + item.remark + '）' + '、';
          }
        }
        that.imageUrl = that.onAvatorUrl(resData);
        that.isGender = that.onGender(resData.gender);
        that.fullscreenLoading = false;
      });
    },
    //性别判断，男生:true  女生:false
    onGender(obj) {
      if (obj == '男' || obj.toUpperCase() == 'MALE') {
        return true;
      } else {
        return false;
      }
    },
    // 计算年龄
    onAge(obj) {
      let that = this;
      if (!that.onIsShow(obj)) {
        let oldTime = obj; //出生日间
        let nowTime = new Date(); // 当前时间
        let countTime = nowTime.getTime() - new Date(oldTime).getTime(); // 计算
        let arr = Math.floor(countTime / (24 * 3600 * 1000) / 365);
        return arr + '岁';
      }
      return null;
    },
    /**
     * @description 图片实际地址
     * @param {Object} _valSrc 图片地址
     */
    onGetImg(_valSrc) {
      return this.$ServerUrl() + _valSrc;
    }
  },
  created() {
    let that = this;
    that.onGetObtainData(that);
  },
  filters: {
    // 过滤空值
    fieldZeroValue(obj) {
      if (obj == null || typeof obj == 'undefined' || obj == '' || obj == 0) {
        return '';
      }
      return obj;
    },
    // 过滤时间
    filterDate(_data) {
      return dateFormat(_data, '.', 'YYYY-MM');
    }
  }
};
</script>

<style scoped="scoped">
.schoolInfo {
  color: #4d5669;
}

.schoolInfo .spTime {
  color: #aeaebe;
  font-size: 14px;
  display: block;
}

.schoolLevel {
  padding-top: 5px;
}

.schoolLevel .spFont {
  font-size: 14px;
  color: #aeaebe;
  border: 1px solid #dfdfdf;
  border-radius: 30px;
  padding: 3px 30px;
}

.hobbiesCont {
  padding-bottom: 15px;
  color: #465167;
}

.hobbiesCont .spTitle {
  color: #aeaebe;
  border: 1px solid #e0e0e0;
  padding: 2px 25px;
  border-radius: 30px;
  font-size: 14px;
  margin-right: 15px;
}

.otherInfo {
  padding-bottom: 15px;
  padding-left: 15px;
}

.otherInfo .spTitle {
  color: #3b465d;
}

.otherInfo .spFonts {
  color: #a8a8a8;
}

.titleCont {
  position: relative;
  padding-left: 20px;
  color: #323e56;
  letter-spacing: 2px;
}

.titleCont .spIcon {
  position: absolute;
  top: 0;
  left: 0;
  width: 5px;
  height: 20px;
  border-radius: 5px;
  background-image: linear-gradient(#4545ff, #2d8eff);
}

.imgAvatar {
  width: 180px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.QRCodeBox {
  position: absolute;
  top: 0;
  right: 30px;
  bottom: 0;
}

.QRCodeBox img {
  width: 150px;
}

.infoList {
  padding-bottom: 17px;
  display: flex;
}

.infoList .spName {
  font-size: 32px;
  color: #212e48;
  padding-right: 30px;
  height: 40px;
}

.infoList .spGender {
  font-size: 32px;
  padding-right: 30px;
  height: 40px;
}

.infoList .spLabelList {
  border-radius: 5px;
  font-weight: normal;
  color: #ffffff;
  height: 30px;
  margin-right: 10px;
  padding: 0px 10px;
  line-height: 30px;
  margin-top: 8px;
  font-size: 14px;
}

.infoList .spFonts {
  color: #3b475d;
  padding-right: 30px;
  padding-left: 30px;
  border-right: 1px solid #d6d6d6;
}

.infoList .spFonts:first-child {
  padding-left: 0;
}

.infoList .spFonts:last-child {
  border-right: none;
}

.infoList .spMedal {
  padding-right: 15px;
}

.infoList .spFonts .faxSize {
  font-size: 18px;
}

.infoList .spFonts .icon {
  color: #b1b1b1;
}

.familyList {
  border: 2px solid #f0f0f0;
  border-radius: 5px;
  padding: 5px;
  position: relative;
  overflow: hidden;
}

.familyList .divTopCont {
  padding-left: 100px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.familyList .divTopCont .appellativeCont {
  background-color: #069df7;
  color: #ffffff;
  width: 82px;
  height: 82px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  position: absolute;
  padding: 5px;
  top: 15px;
  left: 15px;
}

.familyList .divTopCont .memberName {
  font-size: 18px;
  color: #303c54;
  font-weight: bold;
}

.familyList .divTopCont .memberPhone {
  padding-top: 5px;
  padding-bottom: 5px;
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 1px;
  color: #313d55;
}

.familyList .divTopCont .memberPosition .spFont {
  background-color: #7582a5;
  color: #ffffff;
  font-size: 14px;
  padding: 3px 10px;
}

.familyList .divBottomCont {
  border-top: 1px solid #f8f8f8;
  padding-top: 5px;
}

.familyList .divBottomCont .spFont {
  background-color: #f9f9f9;
  color: #3e4960;
  display: block;
  text-align: center;
  padding: 5px;
  letter-spacing: 1px;
}

.familyList .emergencyCont {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #e7f2ff;
  color: #2296ec;
  font-size: 14px;
  padding: 5px 10px;
  border-radius: 0px 5px 0px 5px;
}

.dormAddrCont {
  font-size: 16px;
  color: #38435a;
}

.dormTimeCont {
  font-size: 14px;
  color: #c7c7d1;
}

.spNullData {
  color: #dddddd;
}
</style>
