<!--
  寝室管理 - 寝室管理
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <!-- 搜索 -->
    <el-card class="card">
      <div slot="header" class="card-header">
        <h3 class="card-title">
          <ul class="nav nav-pills navTabsCont">
            <li class="nav-item" v-if="isPower.dormitory_index == true">
              <router-link to="/Admin/DormManage/Index" class="nav-link active">寝室信息</router-link>
            </li>
            <li class="nav-item" v-if="isPower.dormitory_preview == true">
              <router-link to="/Admin/DormManage/Building" class="nav-link">寝室视图</router-link>
            </li>
          </ul>
        </h3>
      </div>
      <div class="card-body">
        <el-row :gutter="24">
          <el-col :span="6" class="mb-3">
            <div class="selTitleBox">
              <span class="spTitle">寝室楼</span>
            </div>
            <div class="selBodyBox">
              <el-select v-model="searchForm.buildingId" placeholder="请选择寝室楼" filterable style="width: 100%" clearable @change="handleChangeBuilding">
                <el-option v-for="(item, index) in searchForm.optBuilding" :key="index" :label="item.name" :value="item.buildingId"></el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="6" class="mb-3">
            <div class="selTitleBox">
              <span class="spTitle">楼层</span>
            </div>
            <div class="selBodyBox">
              <el-select v-model="searchForm.floorId" placeholder="请选择楼层" filterable style="width: 100%" clearable @change="handleChangeFloor">
                <el-option v-for="(item, index) in searchForm.optFloor" :key="index" :label="item.name" :value="item.floorId"></el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="6" class="mb-3">
            <div class="selTitleBox">
              <span class="spTitle">房间</span>
            </div>
            <div class="selBodyBox">
              <el-select v-model="searchForm.roomId" placeholder="请选择房间" filterable style="width: 100%" clearable @change="handleChangeRoom">
                <el-option v-for="(item, index) in searchForm.optRoom" :key="index" :label="item.name" :value="item.roomId"></el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="6" class="mb-3">
            <div class="selTitleBox">
              <span class="spTitle">学生</span>
            </div>
            <div class="selBodyBox">
              <el-input v-model="searchForm.Student" placeholder="请输入学号/姓名"></el-input>
            </div>
          </el-col>
          <el-col :span="6" class="mb-3">
            <div class="selTitleBox">
              <span class="spTitle">性别</span>
            </div>
            <div class="selBodyBox">
              <el-select v-model="searchForm.genderId" placeholder="请选择性别" filterable style="width: 100%" clearable @change="handleChangeGender">
                <el-option v-for="(item, index) in searchForm.optGender" :key="index" :label="item.name" :value="item.genderId"></el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="6" class="mb-3" v-if="!TeacherInfo.isTeacher">
            <div class="selTitleBox">
              <span class="spTitle">年级</span>
            </div>
            <div class="selBodyBox">
              <el-select v-model="searchForm.collegeId" placeholder="请选择年级" filterable style="width: 100%" clearable @change="handleChangeCollege">
                <el-option v-for="(item, index) in searchForm.optCollege" :key="index" :label="item.name" :value="item.collegeId"></el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="6" class="mb-3">
            <div class="selTitleBox">
              <span class="spTitle">班级</span>
            </div>
            <div class="selBodyBox">
              <el-select v-model="searchForm.classId" placeholder="请选择班级" filterable style="width: 100%" :clearable="!TeacherInfo.isTeacher" @change="handleChangeClass">
                <el-option v-for="(item, index) in classOptions" :key="index" :label="item.alias" :value="item.classId"></el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="6" class="mb-3">
            <div class="selTitleBox">
              <span class="spTitle">入住信息</span>
            </div>
            <div class="selBodyBox">
              <el-select v-model="searchForm.isCheckIn" placeholder="请选择是否已入住" filterable style="width: 100%" clearable @change="handleChangeIsCheckIn">
                <el-option v-for="(item, index) in searchForm.optCheckIn" :key="index" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="card-footer text-center">
        <el-button type="primary" class="mr-2" @click="onSearch()">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#fax-search"></use>
          </svg>
          查询
        </el-button>
        <el-button class="ml-2" @click="onReset()">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#fax-redo-alt"></use>
          </svg>
          重置
        </el-button>
      </div>
    </el-card>

    <!-- 列表-->
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title">寝室信息</h3>
        <div class="card-tools">
          <ul class="nav nav-pills ml-auto navToolsCont dormTools">
            <li class="nav-item">
              <comImport
                @onImportSuccess="onImportSuccess"
                :template="'/templates/寝室入住模版.xlsx'"
                :path="uploadInfoApi"
                :btnIcon="'el-icon-upload2'"
                :title="'批量入住'"
                :btnName="'批量入住'"
                ref="conImportInfoFile"
                v-if="isPower.dormitory_importcheckin == true"
              ></comImport>
            </li>
            <li class="nav-item" style="margin-right: 4px">
              <comImport
                @onImportSuccess="onImportSuccess"
                :template="'/templates/寝室退住模版.xlsx'"
                :path="uploadInfoApi2"
                :btnIcon="'el-icon-right'"
                :title="'批量退住'"
                :btnName="'批量退住'"
                ref="conImportInfoFile"
                v-if="isPower.dormitory_importcheckout == true"
              ></comImport>
              <!-- <el-button v-if="isPower.dormitory_importcheckout == true" type="danger" icon="el-icon-right">批量退住</el-button> -->
            </li>
            <li class="nav-item">
              <el-button v-if="isPower.dormitory_export == true" @click="onExportData()" icon="el-icon-download" style="background-color: #17a2b8; color: white">导出</el-button>
            </li>
            <li class="nav-item">
              <el-button v-if="isPower.dormitory_export_lender == true" @click="onExportLender()" icon="el-icon-download" style="background-color: #17a2b8; color: white">
                导出寝室长
              </el-button>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body p-0" v-if="itemData != null && itemData.length != 0">
        <el-table :data="itemData" stripe class="elTableData">
          <el-table-column type="index" label="序号" class="elColumn" width="80" :index="indexMethod"></el-table-column>
          <el-table-column prop="building" label="楼名" class="elColumn" min-width="100" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="floor" label="楼层" class="elColumn" min-width="80"></el-table-column>
          <el-table-column prop="room" label="房号" class="elColumn" min-width="80"></el-table-column>
          <el-table-column prop="bed" label="床位" class="elColumn" min-width="80"></el-table-column>
          <el-table-column prop="studentNo" label="学号" class="elColumn" min-width="120"></el-table-column>
          <el-table-column prop="name" label="姓名" class="elColumn" min-width="140" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="gender" label="性别" class="elColumn" min-width="80"></el-table-column>
          <el-table-column prop="college" label="年级" class="elColumn" min-width="160" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="class" label="班级" class="elColumn" min-width="160" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column label="寝室长" class="elColumn" min-width="100">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.leader" v-if="scope.row.studentNo != null && scope.row.studentNo != ''" @change="onLeader($event, scope.row.bedId)"></el-switch>
            </template>
          </el-table-column>
          <el-table-column label="操作" class="elColumn" width="120" fixed="right">
            <template slot-scope="scope">
              <span v-if="scope.row.studentNo != null && scope.row.studentNo != ''">
                <el-button v-if="isPower.dormitory_checkout == true" type="danger" slot="reference" size="mini" @click="onDropOut(scope.row.bedId)">退住</el-button>
              </span>
              <span v-else>
                <el-button v-if="isPower.dormitory_checkin == true" type="success" size="mini" @click="onOpenCheckIn(scope.row)">入住</el-button>
              </span>
            </template>
          </el-table-column>
        </el-table>
        <comPage :paging="page" :pageBtnList="pageBtnList" @pagingClick="pagingClick" @pageBtnClick="pageBtnClick"></comPage>
      </div>

      <div class="card-body" v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>

    <!--入住-->
    <el-dialog title="入住" :visible.sync="dialogVisibleForCheckIn" width="31s%" :before-close="handleClose" :close-on-click-modal="false">
      <div class="card-body">
        <el-form :model="ruleCheckInForm" ref="ruleCheckInForm" :rules="rulesCheckIn" label-width="120px">
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="寝室信息">
                <div>
                  <b class="color-danger">{{ ruleCheckInForm.building }}</b>
                  （楼）
                  <b class="color-danger">{{ ruleCheckInForm.floor }}</b>
                  （层）
                  <b class="color-danger">{{ ruleCheckInForm.room }}</b>
                  （房间）
                  <b class="color-danger">{{ ruleCheckInForm.bed }}</b>
                  （床位）
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="班级" prop="classId">
                <el-select v-model="ruleCheckInForm.classId" clearable filterable placeholder="请选择班级" style="width: 100%" @change="onGetStudent">
                  <el-option-group v-for="group in ruleCheckInForm.optClass" :key="group.label" :label="group.label">
                    <el-option v-for="item in group.options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                  </el-option-group>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="学生" prop="studentId">
                <el-select v-model="ruleCheckInForm.studentId" filterable clearable placeholder="请选择学生" style="width: 100%">
                  <el-option v-for="item in ruleCheckInForm.optStudent" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="入住时间" prop="checkinDate">
                <el-date-picker style="width: 100%" v-model="ruleCheckInForm.checkinDate" type="datetime" placeholder="选择日期时间"></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="是否设为寝室长">
                <el-switch class="formControl" v-model="ruleCheckInForm.leader"></el-switch>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onResetForm('ruleCheckInForm')">取 消</el-button>
        <el-button type="primary" @click="onVerification('ruleCheckInForm', 'checkIn')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import '@/assets/css/Admin.css';
import comImport from '@/components/Import.vue';
import comPage from '@/components/Page.vue';
export default {
  name: '',
  components: {
    comImport,
    comPage
  },
  data() {
    return {
      // 加载动画
      fullscreenLoading: false,
      itemDataError: [],
      dialogTitle: '',
      dialogVisible: false,
      // 弹出框
      dialogVisibleForCheckIn: false,
      dialogVisibleForAdjustment: false,
      // 数据集合
      itemData: null,
      //分页查询接口返回的结果
      page: null,
      //分页左侧按钮集合
      pageBtnList: null,
      //分页数据
      paging: {
        // 总共页数
        pageLength: 0,
        // 每页请求的数据量
        Size: 10,
        //当前在第几页
        Index: 1
      },
      // 导入入住接口地址
      uploadInfoApi: '/api/admin/dormitory/checkin/import',
      //导入退住接口地址
      uploadInfoApi2: '/api/admin/dormitory/checkout/import',
      // 入住集合
      ruleCheckInForm: {
        building: '',
        floor: '',
        room: '',
        bed: '',
        bedId: '',
        studentId: '',
        checkinDate: new Date(),
        classId: '',
        optClass: {
          label: '',
          options: {
            label: '',
            value: ''
          }
        },
        optStudent: [],
        leader: false
      },
      ruleCheckInResetForm: null,
      rulesCheckIn: {
        studentId: [
          {
            required: true,
            message: '请选择入住学生',
            trigger: 'change'
          }
        ],
        classId: [
          {
            required: true,
            message: '请选择班级',
            trigger: 'change'
          }
        ],
        checkinDate: [
          {
            required: true,
            message: '请选择班级',
            trigger: 'change'
          }
        ]
      },
      classOptions: [],
      allClass: [], //全部班级信息
      // 搜索集合
      searchForm: {
        // 楼
        buildingId: '',
        optBuilding: [],
        // 层
        floorId: '',
        optFloor: [],
        // 室
        roomId: '',
        optRoom: [],
        // 学号/姓名
        Student: '',
        // 性别
        genderId: '',
        optGender: [],
        // 年级
        collegeId: '',
        optCollege: [],
        // 班级
        classId: '',
        optClass: [],
        // 专业
        majorId: '',
        optMajor: [],
        // 是否已入住
        isCheckIn: '',
        optCheckIn: [
          {
            value: 'free',
            label: '空闲'
          },
          {
            value: 'checkin',
            label: '已入住'
          }
        ],

        // 年级专业班级数据集
        arrTotalData: []
      },
      searchResetForm: null,
      isPower: {
        //增删改查 权限管理
        dormitory_checkin: false,
        dormitory_checkout: false,
        dormitory_importcheckin: false,
        dormitory_importcheckout: false,
        dormitory_export: false,
        dormitory_index: false,
        dormitory_preview: false
      },

      TeacherInfo: {} // 教师信息
    };
  },
  created() {
    let that = this;
    that.TeacherInfo = onGetNowUser(that);
    isPower(that, 'dormitory_index', 'dormitory_index');
    isPower(that, 'dormitory_preview', 'dormitory_preview');
    isPower(that, 'dormitory_checkin', 'dormitory_checkin');
    isPower(that, 'dormitory_checkout', 'dormitory_checkout');
    isPower(that, 'dormitory_importcheckin', 'dormitory_importcheckin');
    isPower(that, 'dormitory_importcheckout', 'dormitory_importcheckout');
    isPower(that, 'dormitory_export', 'dormitory_export');
    isPower(that, 'dormitory_export_lender', 'dormitory_export_lender');

    that.fullscreenLoading = true;
    that.allClass = that.TeacherInfo.isTeacher ? that.TeacherInfo.manageClass : that.TeacherInfo.allClass;

    if (that.TeacherInfo.isTeacher) {
      that.searchForm.classId = that.TeacherInfo.manageClass[0].classId;
      that.classOptions = that.TeacherInfo.manageClass;
    }
    that.ruleCheckInResetForm = JSON.parse(JSON.stringify(that.ruleCheckInForm));
    that.searchResetForm = JSON.parse(JSON.stringify(that.searchForm));
    onGetDataPage(that);
    that.fullscreenLoading = false;
  },
  methods: {
    /**
     * @description 列表序号索引
     * @param {Object} index
     */
    indexMethod(index) {
      return (this.paging.Index - 1) * this.paging.Size + index + 1;
    },

    filterBoolen(_value) {
      return Boolean(_value);
    },
    // 设置寝室长
    onLeader(_value, _dataID) {
      let that = this;
      that.fullscreenLoading = true;
      let _data = {
        bedId: _dataID,
        leader: Boolean(_value)
      };
      ajaxPut(that, '/api/admin/dormitory/leader/' + _dataID, _data, function (res) {
        that.fullscreenLoading = false;
        onGetDataPage(that);
      });
    },
    // 导出寝室长数据
    onExportLender() {
      let that = this,
        data = {
          BuildingId: that.searchForm.buildingId,
          FloorId: that.searchForm.floorId,
          RoomId: that.searchForm.roomId,
          Student: that.searchForm.Student,
          GenderId: that.searchForm.genderId,
          CollegeId: that.searchForm.collegeId,
          MajorId: that.searchForm.majorId,
          ClassId: that.searchForm.classId
        },
        fileName = '寝室长信息' + getTimeID() + '.xls';

      that.fullscreenLoading = true;
      ajaxExport(that, '/api/admin/dormitory/exportleader', data, fileName, function (res) {
        that.fullscreenLoading = false;
      });
    },
    // 导出数据
    onExportData() {
      let that = this,
        data = {
          BuildingId: that.searchForm.buildingId,
          FloorId: that.searchForm.floorId,
          RoomId: that.searchForm.roomId,
          Student: that.searchForm.Student,
          GenderId: that.searchForm.genderId,
          CollegeId: that.searchForm.collegeId,
          MajorId: that.searchForm.majorId,
          ClassId: that.searchForm.classId
        },
        fileName = '寝室信息' + getTimeID() + '.xls';

      that.fullscreenLoading = true;
      ajaxExport(that, '/api/admin/dormitory/export', data, fileName, function (res) {
        that.fullscreenLoading = false;
      });
    },
    // 重置
    onReset() {
      let that = this;
      that.searchForm = JSON.parse(JSON.stringify(that.searchResetForm));
      if (!that.TeacherInfo.isTeacher) {
        that.classOptions = [];
      }
      onGetDataPage(that);
    },
    // 专业下拉框选择值
    // handleChangeMajor(_dataID) {
    //   // let that = this;
    //   // that.searchForm.classId = null;
    //   // that.searchForm.optClass = JSON.parse(JSON.stringify([]));
    //   // for (let item of that.searchForm.arrTotalData) {
    //   //   if (item.majors.length != 0) {
    //   //     for (let major of item.majors) {
    //   //       if (major.majorId == _dataID) {
    //   //         that.searchForm.optClass = major.classes;
    //   //         return;
    //   //       }
    //   //     }
    //   //   }
    //   // }
    // },
    // 班级下拉框选择值
    handleChangeClass(_dataID) {
      this.searchForm.classId = _dataID;
    },
    // 年级下拉框选择值
    handleChangeCollege(_dataID) {
      let that = this;
      if (_dataID) {
        that.classOptions = that.allClass.filter((item) => item.collegeId === _dataID);
      } else {
        that.classOptions = [];
      }
      that.searchForm.optMajor = JSON.parse(JSON.stringify([]));
      that.searchForm.majorId = null;
      that.searchForm.classId = null;
      for (let item of that.searchForm.arrTotalData) {
        if (item.collegeId == _dataID) {
          that.searchForm.optMajor = item.majors;
          break;
        }
      }
    },
    // 性别下拉框选择值
    handleChangeGender(_dataID) {
      this.searchForm.genderId = _dataID;
    },
    // 搜索
    onSearch() {
      let that = this;
      that.paging.Index = 1;
      onGetDataPage(that);
    },

    // 寝室房间下拉框选择值
    handleChangeRoom(_dataID) {
      this.searchForm.roomId = _dataID;
    },
    // 楼层下拉框选择值
    handleChangeFloor(_dataID) {
      let that = this;
      that.searchForm.roomId = null;
      if (_dataID != '') {
        that.searchForm.floorId = _dataID;
        that.fullscreenLoading = true;
        ajaxGet(that, '/api/admin/room/floor/' + _dataID, null, function (resData) {
          that.searchForm.optRoom = resData.filter((item) => {
            if (item.enable) return item;
          });
          that.fullscreenLoading = false;
        });
      }
    },
    // 寝室楼下拉框选择值
    handleChangeBuilding(_dataID) {
      let that = this;
      that.searchForm.floorId = null;
      that.searchForm.roomId = null;
      if (_dataID != '') {
        that.searchForm.buildingId = _dataID;
        that.fullscreenLoading = true;
        ajaxGet(that, '/api/admin/floor/building/enable/' + _dataID, null, function (resData) {
          that.searchForm.optFloor = resData;
          that.fullscreenLoading = false;
        });
      }
    },
    // 是否已入住下拉选值
    handleChangeIsCheckIn(_dataID) {
      let that = this;
      that.isCheckIn = _dataID;
    },
    // 退住
    onDropOut(_dataID) {
      let that = this;
      confirmReturn(that, null, function (res) {
        if (res == true) {
          that.fullscreenLoading = true;
          ajaxPut(that, '/api/admin/dormitory/' + _dataID, null, function (res) {
            that.fullscreenLoading = false;
            onGetDataPage(that);
          });
        }
      });
    },
    // 关闭入住弹出框
    handleClose(done) {
      var that = this;
      done();
      that.onResetForm('ruleCheckInForm');
    },
    // 入住验证
    onVerification(_formType, _operatType) {
      let that = this;
      that.$refs[_formType].validate((valid) => {
        if (valid) {
          that.onSumbit(that);
        } else {
          return false;
        }
      });
    },
    // 确定入住
    onSumbit(that) {
      that.fullscreenLoading = true;
      that.ruleCheckInForm.checkinDate = timeTransform(that.ruleCheckInForm.checkinDate);
      ajaxPost(that, '/api/admin/dormitory', that.ruleCheckInForm, function (res) {
        that.fullscreenLoading = false;
        that.onResetForm('ruleCheckInForm');
        onGetDataPage(that);
      });
    },
    // 关闭验证
    onResetForm(_formType) {
      let that = this;
      that.$refs[_formType].resetFields();
      // 闭关弹出窗
      that.dialogVisibleForCheckIn = false;
    },
    // 打开入住弹出框
    onOpenCheckIn(_data) {
      let that = this;
      that.ruleCheckInForm = JSON.parse(JSON.stringify(that.ruleCheckInResetForm));
      that.dialogVisibleForCheckIn = true;
      that.ruleCheckInForm.building = _data.building;
      that.ruleCheckInForm.floor = _data.floor;
      that.ruleCheckInForm.room = _data.room;
      that.ruleCheckInForm.bed = _data.bed;
      that.ruleCheckInForm.bedId = _data.bedId;
      that.fullscreenLoading = true;
      ajaxGet(that, '/api/admin/sysuser/userfilternograduate', null, function (resData) {
        that.ruleCheckInForm.optClass = that.onDealWith(that, resData);
        that.fullscreenLoading = false;
      });
    },
    // 整理专业数据
    onDealWith(that, _data) {
      let arrTemp = [];
      for (let item of _data) {
        arrTemp.push({
          label: item.college,
          options: that.onDealWithClass(_data, item.college)
        });
      }
      return that.onArrUnique(arrTemp);
    },
    // 处理班级数据
    onDealWithClass(_data, _type) {
      let arrTemp = [];
      for (let item of _data) {
        if (item.college == _type) {
          arrTemp.push({
            value: item.classId,
            label: item.alias
          });
        }
      }
      return arrTemp;
    },
    // 去除重复性数据
    onArrUnique(arr) {
      const res = new Map();
      return arr.filter((arr) => !res.has(arr.label) && res.set(arr.label, 1));
    },
    // 根据班级ID,获取学生
    onGetStudent(_value) {
      let that = this;
      that.ruleCheckInForm.optStudent = [];
      that.ruleCheckInForm.studentId = '';
      that.fullscreenLoading = true;
      ajaxGet(that, '/api/admin/student/class/' + _value, null, function (resData) {
        for (let item of resData) {
          that.ruleCheckInForm.optStudent.push({
            value: item.studentId,
            label: '[' + item.studentNo + '] ' + item.name
          });
        }
        that.fullscreenLoading = false;
      });
    },
    //分页组件左侧的按钮事件，按顺序进行判断
    pageBtnClick(index) {},
    // 分页面按钮
    pagingClick(type, val) {
      let that = this;
      if (type == 1) {
        //更改每页条数触发的事件
        that.paging.Size = val;
        that.paging.Index = 1;
      } else {
        //更改当前页时触发的事件
        that.paging.Index = val;
      }
      onGetDataPage(that);
    },
    // 导入成功后执行
    onImportSuccess(param) {
      let that = this;
      onGetDataPage(that);
    }
  },
  watch: {
    $route() {
      let that = this;
      onGetDataPage(that);
    }
  },
  filters: {}
};

// 页面加载数据
function onGetDataPage(that) {
  paging(that, function (res) {
    that.fullscreenLoading = true;

    ajaxGet(that, '/api/admin/building/enable', null, function (resBuilding) {
      ajaxGet(that, '/api/admin/gender', null, function (resGender) {
        ajaxGet(that, '/api/admin/college/linkage', null, function (resLinkage) {
          that.searchForm.optGender = resGender;
          that.searchForm.optBuilding = resBuilding;
          that.searchForm.arrTotalData = resLinkage;
          that.searchForm.optCollege = resLinkage;
          let data = {
            PageNumer: that.paging.Index,
            PageSize: that.paging.Size,
            BuildingId: that.searchForm.buildingId,
            FloorId: that.searchForm.floorId,
            RoomId: that.searchForm.roomId,
            Student: that.searchForm.Student,
            GenderId: that.searchForm.genderId,
            CollegeId: that.searchForm.collegeId,
            ClassId: that.searchForm.classId,
            Status: that.searchForm.isCheckIn
          };
          ajaxGet(that, '/api/admin/dormitory/search', data, function (resData) {
            that.page = resData;
            that.itemData = resData.items;
            that.fullscreenLoading = false;
          });
        });
      });
    });
  });
}

function confirmReturn(that, msg, callback) {
  if (msg == null || typeof msg == 'undefined' || msg == '') {
    msg = '您确定要退住该床位学生吗？';
  }
  confirm(that, msg, callback);
}
</script>

<style scoped="scoped">
.navTabsCont .nav-link {
  font-size: 12px;
}

.itemCont {
  margin-top: 40px;
  color: #767676;
}

.selTitleBox {
  font-size: 14px;
}

.selTitleBox .spTitle {
  color: #303133;
}

.selBodyBox {
  padding-top: 5px;
}
</style>
<style>
.dormTools .importStyle {
  font-size: 12px;
  font-weight: normal;
  padding: 9px 15px;
}
</style>
