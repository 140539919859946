<!--
  编辑学生信息
-->
<template>
  <div class="SubWebPageStudent" v-loading.fullscreen.lock="fullscreenLoading" v-if="arrInfo!=null">

    <el-form :model="arrInfo" :rules="rules" ref="ruleForm" label-width="100px">
      <!-- 基础信息 -->
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            基础信息
          </h3>
        </div>
        <div class="card-body pb-5">
          <el-row :gutter="24">
            <!--照片-->
            <el-col :span="6" class="text-center divStuAva">
              <!--  <el-upload class="avatar-uploader imgAvatar" action="" :auto-upload="true" :show-file-list="false"
                :before-upload="beforeAvatarUpload" :http-request="uploadSectionFile">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                 <span class="spImgFont">更换照片</span>
              </el-upload> -->
              <div class="avatar-uploader imgAvatar">
                <img v-if="imageUrl" :src="imageUrl" class="avatar" style="margin: 0 auto;">
              </div>

              <div class="studentName">{{arrInfo.name}}</div>
              <div class="studentNo">{{arrInfo.studentNo}}</div>
            </el-col>
            <!--基础信息-->
            <el-col :span="18">
              <el-row :gutter="24">
                <el-col :span="8" class="otherInfo">
                  <el-form-item label="民族" prop="nation">
                    <el-select v-model="arrInfo.nation" filterable placeholder="请选择民族" style="width: 100%;"
                      @change="handleChangeNation">
                      <el-option v-for="item in optNation" :key="item.id" :label="item.name" :value="item.id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8" class="otherInfo">
                  <el-form-item label="身份证号" prop="idCardNo">
                    <el-input v-model="arrInfo.idCardNo" placeholder="请输入身份证号" class="formControl">
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8" class="otherInfo">
                  <el-form-item label="政治面貌" prop="political">
                    <el-select v-model="arrInfo.political" filterable placeholder="请输入政治面貌" style="width: 100%;"
                      @change="handleChangePolitical">
                      <el-option v-for="item in optPolitical" :key="item.id" :label="item.name" :value="item.id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="24" class="otherInfo">
                  <el-form-item label="籍贯" prop="originalPlace">
                    <el-input v-model="arrInfo.originalPlace" placeholder="请输入籍贯" class="formControl"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="24" class="otherInfo">
                  <el-form-item label="生源地" prop="sourcePlace">
                    <el-input v-model="arrInfo.sourcePlace" placeholder="请输入生源地" class="formControl"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="24" class="otherInfo">
                  <el-form-item label="家庭地址" prop="homeAddress">
                    <el-input v-model="arrInfo.homeAddress" placeholder="请输入家庭地址" class="formControl"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8" class="otherInfo">
                  <el-form-item label="邮箱" prop="email">
                    <el-input v-model="arrInfo.email" placeholder="请输入邮箱" class="formControl"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8" class="otherInfo">
                  <el-form-item label="QQ" prop="qq">
                    <el-input v-model="arrInfo.qq" placeholder="请输入QQ" class="formControl"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8" class="otherInfo">
                  <el-form-item label="手机号码" prop="mobile">
                    <el-input v-model="arrInfo.mobile" placeholder="请输入手机号码" class="formControl"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </div>
      </el-card>



      <!--家庭情况-->
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            家庭情况
          </h3>
          <div class="card-tools">
            <ul class="nav">
              <li class="nav-item">
                <el-button type="success" icon="el-icon-circle-plus-outline" @click="addFamily()">新增家庭信息</el-button>
              </li>
            </ul>
          </div>
        </div>
        <div class="card-body">
          <el-row :gutter="24" v-if="arrInfo.families.length!=0">
            <el-col :span="6" v-for="(item,index) in arrInfo.families" :key="index">
              <div class="familyList">
                <div class="emergencyCont2" @click="onEditMember(item.familyId)">
                  <i class="el-icon-edit"></i>
                </div>
                <div class="emergencyCont" @click="onRemoveMember(item.familyId)">
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#fax-times"></use>
                  </svg>
                </div>
                <div class="divTopCont">
                  <div class="appellativeCont VerticalMid">{{item.title}}</div>
                  <div class="memberName">{{item.name}}&nbsp;<span class="spLabel" v-if="item.isEmer">紧急联系人</span></div>
                  <div class="memberPhone">{{item.telephone}}</div>
                  <div class="memberPosition">
                    <span class="spFont">{{item.position | filterNullFont}}</span>
                  </div>
                </div>
                <div class="divBottomCont">
                  <span class="spFont">{{item.company | filterNullFont}}</span>
                </div>
              </div>
            </el-col>
          </el-row>
          <span class="spNullData" v-else>暂无数据</span>
        </div>
      </el-card>



      <!--教育经历-->
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            教育经历
          </h3>
          <div class="card-tools">
            <ul class="nav">
              <li class="nav-item">
                <el-button type="success" icon="el-icon-circle-plus-outline" @click="addEducation()">新增教育经历</el-button>
              </li>
            </ul>
          </div>
        </div>
        <div class="card-body">
          <el-row :gutter="24" v-if="arrInfo.educations.length!=0">
            <el-col :span="6" v-for="(item,index) in arrInfo.educations" :key="index">
              <div class="familyList educationList">
                <div class="emergencyCont2" @click="onEditEducation(item.educationId)">
                  <i class="el-icon-edit"></i>
                </div>
                <div class="emergencyCont" @click="onRemoveEducation(item.educationId)">
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#fax-times"></use>
                  </svg>
                </div>
                <div class="divTopCont">
                  <div class="memberName">{{item.school}}</div>
                  <div class="memberPosition">
                    <span class="spFont">{{item.title | filterNullFont}}</span>
                  </div>
                </div>
                <div class="divBottomCont">
                  <span class="spFont">{{item.startDate | filterDate}}&nbsp;-&nbsp;{{item.endDate | filterDate}}</span>
                </div>
              </div>
            </el-col>
          </el-row>
          <span class="spNullData" v-else>暂无数据</span>
        </div>
      </el-card>


      <!--兴趣特长-->
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            兴趣特长
          </h3>
        </div>
        <div class="card-body">
          <el-row :gutter="24">
            <el-col :span="24" class="hobbiesCont">
              <span class="spTitle">兴趣</span>
              <el-input class="txtInput" type="textarea" v-model="arrInfo.hobby" :autosize="{ minRows: 5}"
                maxlength="200" show-word-limit></el-input>
            </el-col>
            <el-col :span="24" class="hobbiesCont">
              <span class="spTitle">特长</span>
              <el-input class="txtInput" type="textarea" v-model="arrInfo.speciality" :autosize="{ minRows: 5}"
                maxlength="200" show-word-limit></el-input>
            </el-col>
          </el-row>
        </div>
      </el-card>


      <div class="row">
        <div class="col-12">
          <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
          <el-button icon="el-icon-refresh" @click="onReset()">重 置</el-button>
          <el-button type="primary" icon="el-icon-circle-check" @click="onVerification('ruleForm')">提交</el-button>
        </div>
      </div>

    </el-form>


    <el-dialog :title="titleFamily" :visible.sync="dialogVisibleFamily" width="30%" top="40px"
      :before-close="handleFamilyClose" :close-on-click-modal="false" :close-on-press-escape="false"
      :append-to-body="true" :fullscreen="false">
      <el-form :model="familyForm" :rules="familyRules" ref="familyForm" label-width="90px">
        <el-row>
          <el-col :span="24">
            <el-form-item label="成员称谓" prop="title">
              <el-input v-model="familyForm.title" placeholder="请输入成员称谓，例如：父亲/母亲"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="成员姓名" prop="name">
              <el-input v-model="familyForm.name" placeholder="请输入成员姓名"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="联系电话" prop="telephone">
              <el-input v-model="familyForm.telephone" placeholder="请输入联系电话"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="工作单位">
              <el-input v-model="familyForm.company" placeholder="请输入工作单位"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="职务">
              <el-input v-model="familyForm.position" placeholder="请输入职务"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="紧急联系人" prop="isEmer">
              <el-switch v-model="familyForm.isEmer" active-color="#409EFF" inactive-color="#C0CCDA">
              </el-switch>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onResetForm('familyForm')">取 消</el-button>
        <el-button @click="onFamilyReset()">重 置</el-button>
        <el-button type="primary" @click="onVerificationFamily()">确 定</el-button>
      </span>
    </el-dialog>



    <el-dialog :title="titleEducation" :visible.sync="dialogVisiblEducation" width="30%" :before-close="handleEducationClose"
      :close-on-click-modal="false" :close-on-press-escape="false" :append-to-body="true" :fullscreen="false">
      <el-form :model="educationForm" :rules="educationRules" ref="educationForm" label-width="90px">
        <el-row>
          <el-col :span="24">
            <el-form-item label="学校名称" prop="school">
              <el-input v-model="educationForm.school" placeholder="请输入学校名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="学位">
              <el-input v-model="educationForm.title" placeholder="请输入学位"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="日期范围" prop="date">
              <el-date-picker v-model="educationForm.date" type="daterange" range-separator="至" start-placeholder="开始日期"
                end-placeholder="结束日期" style="width: 100%;">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onResetForm('educationForm')">取 消</el-button>
        <el-button @click="onEducationReset()">重 置</el-button>
        <el-button type="primary" @click="onVerificationEducation()">确 定</el-button>
      </span>
    </el-dialog>




  </div>
</template>

<script>
  import '@/assets/css/Student.css';

  export default {
    components: {},
    data() {
      return {
        fullscreenLoading: false,
        dialogVisibleFamily: false,
        dialogVisiblEducation: false,
        titleFamily:'新增家庭成员',
        titleEducation:'新增教育经历',
        roles: null,
        studentRoles: [],
        // 数据
        arrInfo: null,
        //
        arrInfoReset: [],

        // 学生ID
        studentId: null,

        // 民族下拉数据
        optNation: [],
        // 正治面貌
        optPolitical: [],
        // 班级
        optClass: [],
        // 入学年份
        optEntryYear: [],
        // 新增
        optGender: [],

        // 验证
        rules: {
          name: [{
              required: true,
              message: '请输入学生姓名',
              trigger: 'change'
            },
            {
              min: 0,
              max: 256,
              message: '学生姓名不能超过256个字符',
              trigger: 'change'
            }
          ],
          studentNo: [{
              required: true,
              message: '请输入学生学号',
              trigger: 'change'
            },
            {
              min: 0,
              max: 32,
              message: '学生学号不能超过32个字符',
              trigger: 'change'
            }
          ],
          gender: [{
            required: true,
            message: '请选择性别',
            trigger: 'change'
          }],
          entryYear: [{
            required: true,
            message: '请选择入学年份',
            trigger: 'change'
          }],
          class: [{
            required: true,
            message: '请选择班级',
            trigger: 'change'
          }],
          idCardNo: [{
            validator: (rule, value, callback) => {
              let that = this;
              var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
              if (value == null) {
                callback();
              } else if (value != 0 && !reg.exec(value)) {
                callback(new Error("请输入有效身份证号！"));
              } else {
                callback();
              }
            },
            trigger: 'change'
          }],
          email: [{
            validator: (rule, value, callback) => {
              let that = this;
              var reg =
                /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
              if (value == null) {
                callback();
              } else if (value != 0 && !reg.exec(value)) {
                callback(new Error("请输入有效邮箱地址"));
              } else {
                callback();
              }
            },
            trigger: 'change'
          }],
          mobile: [{
            validator: checkPhone,
            trigger: 'change'
          }],
          short: [{
            validator: (rule, value, callback) => {
              let that = this;
              var reg =
                /^\d{6}$/;
              if (value == null) {
                callback();
              } else if (value != 0 && !reg.exec(value)) {
                callback(new Error("请输入有效短号"));
              } else {
                callback();
              }
            },
            trigger: 'change'
          }],
          qq: [{
            validator: (rule, value, callback) => {
              let that = this;
              var reg =
                /^[0-9]*$/;
              if (value == null) {
                callback();
              } else if (value != 0 && !reg.exec(value)) {
                callback(new Error("请输入有效QQ"));
              } else {
                callback();
              }
            },
            trigger: 'change'
          }],
        },


        // 家庭信息
        familyForm: {
          familyId: '',
          title: '',
          name: '',
          telephone: '',
          company: '',
          position: '',
          isEmer: false
        },
        familyFormReset: {},
        familyRules: {
          title: [{
              required: true,
              message: '请输入家庭成员称谓',
              trigger: 'change'
            },
            {
              min: 0,
              max: 20,
              message: '长度在 20 个字符内',
              trigger: 'change'
            },
          ],
          name: [{
              required: true,
              message: '请输入家庭成员姓名',
              trigger: 'change'
            },
            {
              min: 0,
              max: 20,
              message: '长度在 20 个字符内',
              trigger: 'change'
            },
          ],
          telephone: [{
              required: true,
              message: '请输入成员联系电话',
              trigger: 'change'
            },
            {
              validator: checkPhone,
              trigger: 'change'
            },
          ],
          company: [{
              required: true,
              message: '请输入成员工作单位',
              trigger: 'change'
            },
            {
              min: 0,
              max: 20,
              message: '长度在 20 个字符内',
              trigger: 'change'
            },
          ],
          position: [{
              required: true,
              message: '请输入成员工作职务',
              trigger: 'change'
            },
            {
              min: 0,
              max: 20,
              message: '长度在 20 个字符内',
              trigger: 'change'
            },
          ],
        },
        educationFormInit: null,
        familyFormInit: null,
        // 教育经历
        educationForm: {
          educationId: '',
          title: '', //学位
          school: '', //学校名称
          date: [],
        },
        educationFormReset: null,
        educationRules: {
          school: [{
              required: true,
              message: '请输入学校名称',
              trigger: 'change'
            },
            {
              min: 0,
              max: 20,
              message: '长度在 20 个字符内',
              trigger: 'change'
            },
          ],
          title: [{
              required: true,
              message: '请输入学位名称',
              trigger: 'change'
            },
            {
              min: 0,
              max: 20,
              message: '长度在 20 个字符内',
              trigger: 'change'
            },
          ],
          date: [{
            required: true,
            message: '请选择日期范围',
            trigger: 'change'
          }, ],
        },



        imageUrl: '',
        optStatus: [],

      };
    },
    methods: {
      // 编辑家庭成员信息
      onEditMember(_DataID) {
        let that = this;
        that.titleFamily='编辑家庭成员';
        let item = that.arrInfo.families.find(item => item.familyId == _DataID);
        that.familyForm = JSON.parse(JSON.stringify(item));
        that.familyFormReset = JSON.parse(JSON.stringify(that.familyForm));
        that.dialogVisibleFamily = true;
      },
      // 删除家庭成员信息
      onRemoveEducation(_DataID) {
        let that = this;
        ajaxDelete(that,'/api/student/student/studenteducations/'+_DataID,null,function(res){
          that.onGetObtainData(that);
        });
      },
      // 编辑教育经历信息
      onEditEducation(_DataID) {
        let that = this;
        that.titleEducation = '编辑教育经历';
        let item = that.arrInfo.educations.find(item => item.educationId == _DataID);
        item.date = [item.startDate, item.endDate];
        that.educationForm = JSON.parse(JSON.stringify(item));
        that.educationFormReset = JSON.parse(JSON.stringify(that.educationForm));
        that.dialogVisiblEducation = true;
      },
      // 验证提交教育经历
      onVerificationEducation() {
        let that = this;
        // 验证
        that.$refs['educationForm'].validate((valid) => {
          if (valid) {
            that.educationForm.startDate = timeTransform(that.educationForm.date[0]);
            that.educationForm.endDate = timeTransform(that.educationForm.date[1]);
            if (!regNull(that.educationForm.educationId)) { // 编辑
              that.fullscreenLoading = true;
              ajaxPut(that, '/api/student/student/studenteducations/' + that.educationForm.educationId, that.educationForm, res => {
                if (res.result) {
                  that.dialogVisiblEducation = false;
                  ajaxGet(that, '/api/student/student', null, function(resData) {
                    that.fullscreenLoading = false;
                    that.arrInfo = JSON.parse(JSON.stringify(resData));
                    that.arrInfoReset = JSON.parse(JSON.stringify(that.arrInfo));
                  })
                }
              });
            } else { // 新增
              that.fullscreenLoading = true;
              that.educationForm.studentId = that.arrInfo.studentId;
              ajaxPost(that, '/api/student/student/studenteducations', that.educationForm, res => {
                if (res.result) {
                  that.dialogVisiblEducation = false;
                  ajaxGet(that, '/api/student/student', null, function(resData) {
                    that.fullscreenLoading = false;
                    that.arrInfo = JSON.parse(JSON.stringify(resData));
                    that.arrInfoReset = JSON.parse(JSON.stringify(that.arrInfo));
                  })
                }
              });
            }
          } else {
            return false;
          }
        });
      },

      // 删除家庭成员信息
      onRemoveMember(_DataID) {
        let that = this;
        ajaxDelete(that,'/api/student/student/studentfamilys/'+_DataID,null,function(res){
          that.onGetObtainData(that);
        });
      },
      // 验证提交家庭成员信息
      onVerificationFamily() {
        let that = this;
        // 验证
        that.$refs['familyForm'].validate((valid) => {
          if (valid) {
            if(!regNull(that.familyForm.familyId)) { // 编辑
              that.fullscreenLoading = true;
              ajaxPut(that, '/api/student/student/studentfamilys/' + that.familyForm.familyId, that.familyForm, res => {
                if (res.result) {
                  that.dialogVisibleFamily = false;
                  ajaxGet(that, '/api/student/student', null, function(resData) {
                    that.fullscreenLoading = false;
                    that.arrInfo = JSON.parse(JSON.stringify(resData));
                    that.arrInfoReset = JSON.parse(JSON.stringify(that.arrInfo));
                  })
                }
              });
            } else { // 新增
              that.fullscreenLoading = true;
              that.familyForm.studentId = that.arrInfo.studentId;
              ajaxPost(that, '/api/student/student/studentfamilys', that.familyForm, res => {
                if (res.result) {
                  that.dialogVisibleFamily = false;
                  ajaxGet(that, '/api/student/student', null, function(resData) {
                    that.fullscreenLoading = false;
                    that.arrInfo = JSON.parse(JSON.stringify(resData));
                    that.arrInfoReset = JSON.parse(JSON.stringify(that.arrInfo));
                  })
                }
              });
            }
          } else {
            return false;
          }
        });
      },
      // 添加家庭成员
      addFamily() {
        let that = this;
        that.titleFamily = '新增家庭成员';
        that.familyForm = JSON.parse(JSON.stringify(that.familyFormInit));
        that.familyFormReset = JSON.parse(JSON.stringify(that.familyForm));
        that.$nextTick(() => {
          that.$refs["familyForm"].clearValidate();
        });
        that.dialogVisibleFamily = true;
      },
      // 添加教育经历
      addEducation() {
        let that = this;
        that.titleEducation = '新增教育经历';
        that.educationForm = JSON.parse(JSON.stringify(that.educationFormInit));
        that.educationFormReset = JSON.parse(JSON.stringify(that.educationForm));
        that.$nextTick(() => {
          that.$refs["educationForm"].clearValidate();
        });
        that.dialogVisiblEducation = true;
      },
      // 关闭弹出窗
      handleFamilyClose(done) {
        let that = this;
        done();
        that.onResetForm('familyForm');
      },
      // 关闭教育经历弹出窗
      handleEducationClose(done) {
        let that = this;
        done();
        that.onResetForm('educationForm');
      },
      // 关闭验证
      onResetForm(objForm) {
        let that = this;
        that.$refs[objForm].resetFields();
        // 闭关弹出窗
        that.dialogVisibleFamily = false;
        that.dialogVisiblEducation = false;
      },

      // 入学年份
      onGetEntryYear(that) {
        let nowYear = parseInt(new Date().getFullYear()) + 1;
        for (let i = nowYear; i > nowYear - 20; i--) {
          let arr = {
            value: i,
            label: i
          };
          that.optEntryYear.push(arr);
        }
      },
      // 获取学院
      onGetClass(that) {

      },
      // 获取专业数据
      onGetMajor(that, _data, _type) {
        let arrTemp = [];
        for (let item of _data) {
          let arr = [];
          if (item.major == _type) {
            arr = {
              value: item.classId,
              label: item.name,
            };
            arrTemp.push(arr);
          }
        }
        return arrTemp;
      },
      // 去除重复数据
      onArrUnique(arr) {
        const res = new Map();
        return arr.filter((arr) => !res.has(arr.label) && res.set(arr.label, 1));
      },

      // 民族
      onGetNation(that) {
        that.fullscreenLoading = true;
        ajaxGet(that, '/api/student/student/politics', null, function(resPolitics) {
          ajaxGet(that, '/api/student/student/nation', null, function(resNation) {
            that.optNation = resNation;
            that.optPolitical = resPolitics;
            that.fullscreenLoading = false;
          });
        });
      },
      // 是否有数据
      onIsShow(_data) {
        if (_data == null || typeof(_data) == "undefined" || _data == '' || _data == 0) {
          return true;
        }
        return false
      },
      // 性别
      onGender(_data) {
        return isGender(_data);
      },
      // 返回上一页
      onBack() {
        let that = this;
        back(that);
      },
      /**
       * @description 验证表单
       * @param {Object} objForm
       */
      onVerification(objForm) {
        let that = this;
        // 验证
        that.$refs[objForm].validate((valid) => {
          if (valid) {
            that.arrInfo.studentRoles = [];
            for (var it of that.studentRoles) {
              that.arrInfo.studentRoles.push({
                roleId: it,
              });
            }


            that.fullscreenLoading = true;
            if (that.arrInfo.birthday != '' && that.arrInfo.birthday != null) {
              that.arrInfo.birthday = timeTransform(that.arrInfo.birthday);
            }
            that.arrInfo.status = setInt(that.arrInfo.status);
            ajaxPut(that, '/api/student/student/' + that.arrInfo.studentId, that.arrInfo, function(resMessage) {
              if (resMessage.result) {
                that.onBack();
              } else {
                warning(that, resMessage.message);
              }
              that.fullscreenLoading = false;
            });



          } else {
            return false;
          }
        });
      },
      // 重置
      onReset() {
        let that = this;
        that.arrInfo = JSON.parse(JSON.stringify(that.arrInfoReset));
        try {
          that.$refs["arrInfo"].resetFields();
        } catch {}
      },
      // 重置家庭信息
      onFamilyReset() {
        let that = this;
        that.familyForm = JSON.parse(JSON.stringify(that.familyFormReset));
        try {
          that.$refs["familyForm"].resetFields();
        } catch {}
      },
      // 重置教育经历
      onEducationReset() {
        let that = this;
        that.educationForm = JSON.parse(JSON.stringify(that.educationFormReset));
        try {
          that.$refs["educationForm"].resetFields();
        } catch {}
      },

      // 获取数据
      onGetObtainData(that) {

        that.fullscreenLoading = true;


        that.familyFormInit = JSON.parse(JSON.stringify(that.familyForm));
        that.educationFormInit = JSON.parse(JSON.stringify(that.educationForm));
        ajaxGet(that, '/api/student/student', null, function(resData) {

          that.arrInfo = JSON.parse(JSON.stringify(resData));
          that.studentRoles = [];
          for (var it of that.arrInfo.studentRoles) {
            that.studentRoles.push(it.roleId);
          }
          // 判断入学年份
          if (that.arrInfo.entryYear == 0) {
            that.arrInfo.entryYear = '';
          }
          if (that.onIsShow(that.arrInfo.photo)) {
            if (that.onGender(that.arrInfo.gender)) {
              // 男
              that.imageUrl = require('@/assets/image/defaultAvatar_Male.jpg');
            } else {
              that.imageUrl = require('@/assets/image/defaultAvatar_Female.jpg');
              // 女
            }
          } else {
            that.imageUrl = that.$ServerUrl() + that.arrInfo.photo;
          }
          that.fullscreenLoading = false;
          that.arrInfoReset = JSON.parse(JSON.stringify(that.arrInfo));
        });




      },
      // 班级
      handleChangeClass(_data) {
        let that = this;
        that.arrInfo.classId = _data;
      },
      // 民族
      handleChangeNation(_data) {
        let that = this;
        that.arrInfo.nationId = _data;
      },
      // 政治面貌
      handleChangePolitical(_data) {
        let that = this;
        that.arrInfo.politicalId = _data;
      },
      //性别
      handleChangeGender(_data) {
        let that = this;
        that.arrInfo.genderId = _data;
      },

      // 提交数据
      onPostData(that) {},
      // 上传照片
      uploadSectionFile(param) {},
      beforeAvatarUpload(file) {},
      //返回上一层
      backHistory() {
        this.$router.go(-1);
      },
    },
    created() {
      //先进行下拉数据的创建
      let that = this;
      // 民族
      that.onGetNation(that);
      that.onGetObtainData(that);
    },
    filters: {
      // 过滤时间
      filterDate(_data) {
        return dateFormat(_data, '.', 'YYYY-MM');
      },
      filterNullFont(_data) {
        return regNull(_data) ? '暂无信息' : _data;
      }
    },
  };

  // 验证手机号码
  let checkPhone = (rule, value, callback) => {
    let that = this;
    var reg = /^1\d{10}$/;
    if (value == null) {
      callback();
    } else if (value != 0 && !reg.exec(value)) {
      callback(new Error("请输入有效的手机号码"));
    } else {
      callback();
    }
  }
</script>

<style scoped="scoped">
  .studentName {
    font-size: 32px;
    padding-top: 15px;
  }

  .studentNo {
    font-size: 16px;
    color: #767676;
  }

  .remarksCont {
    font-size: 14px;
    color: #B0A8BB;
  }

  .schoolInfo {
    color: #4D5669;
  }

  .schoolInfo .spTime {
    color: #AEAEBE;
    font-size: 14px;
  }

  .schoolLevel {
    padding-top: 5px;
  }

  .schoolLevel .spFont {
    font-size: 14px;
    color: #AEAEBE;
    border: 1px solid #DFDFDF;
    border-radius: 30px;
    padding: 3px 30px;
  }

  .hobbiesCont {
    padding-bottom: 15px;
    color: #465167;
  }

  .hobbiesCont .spTitle {
    color: #AEAEBE;
    border: 1px solid #E0E0E0;
    padding: 2px 25px;
    border-radius: 30px;
    font-size: 14px;
    margin-right: 15px;
  }

  .hobbiesCont .txtInput {
    width: 100%;
    margin-top: 15px;
    padding-left: 2px;
    padding-right: 2px;
    border: none;
  }



  .otherInfo {
    padding-bottom: 0px;
    padding-left: 0px;
  }

  .otherInfo .spTitle {
    color: #3B465D;
  }

  .otherInfo .spFonts {
    color: #A8A8A8;
  }

  .otherInfo .txtInput {
    border: none;

    padding-left: 10px;
    padding-right: 10px;
    color: #A8A8A8;
    letter-spacing: 1px;
  }

  .otherInfo .txtInput textarea {
    text-decoration: none !important;
  }

  .otherInfo .txtInput::-webkit-input-placeholder {
    color: #A8A8A8;
  }

  .otherInfo .txtInput::-moz-placeholder {
    color: #A8A8A8;
  }

  .otherInfo .txtInput:-ms-input-placeholder {
    color: #A8A8A8;
  }

  .titleCont {
    position: relative;
    padding-left: 20px;
    color: #323E56;
    letter-spacing: 2px;
  }

  .titleCont .spIcon {
    position: absolute;
    top: 0;
    left: 0;
    width: 5px;
    height: 20px;
    border-radius: 5px;
    background-image: linear-gradient(#4545FF, #2D8EFF);
  }

  .QRCodeBox {
    position: absolute;
    top: 0;
    right: 30px;
    bottom: 0;
  }

  .QRCodeBox img {
    width: 150px;
  }

  .infoList {
    padding-bottom: 17px;
  }

  .infoList .spName {
    font-size: 32px;
    color: #212E48;
    padding-right: 30px;
    height: 40px;
  }

  .infoList .spGender {
    font-size: 32px;
    padding-right: 30px;
    height: 40px;
  }

  .infoList .spLabel {
    height: 40px;
  }

  .infoList .spLabel label {
    position: absolute;
    top: 10px;
    background-color: #DD4B39;
    border: 1px solid #CF3421;
    border-radius: 5px;
    font-weight: normal;
    color: #FFFFFF;
    padding: 2px 10px;
    margin-bottom: 0;
  }

  .infoList .spFonts {
    color: #3B475D;
    padding-right: 30px;
    padding-left: 30px;
    border-right: 1px solid #D6D6D6;
  }

  .infoList .spFonts:first-child {
    padding-left: 0;
  }

  .infoList .spFonts:last-child {
    border-right: none;
  }

  .infoList .spMedal {
    padding-right: 15px;
  }

  .infoList .spFonts .faxSize {
    font-size: 18px;
  }

  .infoList .spFonts .icon {
    color: #B1B1B1;
  }

  .addSchoolInfo,
  .addFamilyList {
    border: 2px solid #F0F0F0;
    border-radius: 5px;
    padding: 5px;
    position: relative;
    height: 149px;
    color: #DDDDDD;
    font-size: 56px;
  }

  .addSchoolInfo {
    height: 50px;
    font-size: 32px;
  }
  .familyList .emergencyCont2 {
    position: absolute;
    top: 0;
    right: 34px;
    background-color: #E6A23C;
    color: #FFFFFF;
    font-size: 14px;
    padding: 5px 10px;
    border-radius: 0px 0px 0px 5px;
    cursor: pointer;
  }

  .familyList .emergencyCont2:hover {
    background-color: #dd9d3e;
  }
  .addSchoolInfo:hover,
  .addFamilyList:hover {
    cursor: pointer;
    color: #28A745;
  }

  .familyList {
    border: 2px solid #F0F0F0;
    border-radius: 5px;
    padding: 5px;
    position: relative;
    overflow: hidden;
  }

  .familyList .divTopCont {
    padding-left: 100px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .familyList .divTopCont .appellativeCont {
    background-color: #069DF7;
    color: #FFFFFF;
    width: 82px;
    height: 82px;
    border-radius: 5px;
    font-size: 22px;
    font-weight: bold;
    position: absolute;
    top: 15px;
    left: 15px;
  }

  .familyList .divTopCont .memberName {
    font-size: 18px;
    color: #303C54;
    font-weight: bold;
  }

  .familyList .divTopCont .memberName .spLabel {
    font-size: 12px;
    background-color: #E7F2FF;
    color: #2296EC;
    padding: 4px 10px;
    border-radius: 4px;
  }

  .familyList .divTopCont .memberPhone {
    padding-top: 5px;
    padding-bottom: 5px;
    font-family: Arial, Helvetica, sans-serif;
    letter-spacing: 1px;
    color: #313D55;
  }

  .familyList .divTopCont .memberPosition .spFont {
    background-color: #7582A5;
    color: #FFFFFF;
    font-size: 14px;
    padding: 3px 10px;
  }

  .familyList .divBottomCont {
    border-top: 1px solid #F8F8F8;
    padding-top: 5px;
  }

  .familyList .divBottomCont .spFont {
    background-color: #F9F9F9;
    color: #3E4960;
    display: block;
    text-align: center;
    padding: 5px;
    letter-spacing: 1px;
  }

  .familyList .emergencyCont {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #dc3545;
    color: #FFFFFF;
    font-size: 14px;
    padding: 5px 10px;
    border-radius: 0px 5px 0px 5px;
    cursor: pointer;
  }

  .familyList .emergencyCont:hover {
    background-color: #C82333;
  }

  .dormAddrCont {
    font-size: 16px;
    color: #38435A;
  }

  .dormTimeCont {
    font-size: 14px;
    color: #C7C7D1;
  }

  .divStuAva {
    padding-top: 30px;
  }

  .divStuAva .spImgFont {
    font-size: 12px;
    color: #767676;
    ;
  }

  .spNullData {
    color: #DDDDDD;
  }

  .ruleForm .el-form-item {
    margin-bottom: 0;
  }

  .educationList .divTopCont {
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
  }
</style>


<style>
  .divStuAva .avatar-uploader .el-upload {
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .divStuAva .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }

  .divStuAva .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 180px;
    text-align: center;
  }

  .divStuAva .avatar {
    width: 180px;
    display: block;
    border-radius: 5px;
  }

  .otherInfo .spFonts .selAddr .el-input__inner,
  .otherInfo .spFonts .selSingle .el-input__inner,
  .otherInfo .spFonts .txtDateInput .el-input__inner {
    height: 25px;
    line-height: 25px;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #DDDDDD;
    color: #A8A8A8;
    font-size: 16px;
  }

  .otherInfo .spFonts .txtDateInput .el-input__suffix,
  .otherInfo .spFonts .txtDateInput .el-input__prefix {
    top: -8px;
  }


  .otherInfo .spFonts .selSingle .el-input__suffix {
    top: 7px;
  }

  .otherInfo .spFonts .selSingle .el-input__suffix-inner {
    position: absolute;
    right: 0px;
    top: -12px;
  }


  .cascaderMajor .el-input__inner::-webkit-input-placeholder,
  .cascaderMajor .el-input__inner:-moz-placeholder,
  .cascaderMajor .el-input__inner::-moz-placeholder,
  .cascaderMajor .el-input__inner:-ms-input-placeholder {
    color: #606266;
  }
</style>
