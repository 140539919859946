<template>
  <div class="performancesummary">
    <p>学生在校表现汇总</p>
    <div class="performancesummary_list">
      <div class="performancesummary_list_item card">
        <div class="iconBox VerticalMid iconbox" style="background-color: #eaefff; color: #3364ff">
          <i nums-v-0e3c62fe="" class="iconfont">&#xe65e;</i>
        </div>
        <div class="numberBox">
          {{ (nums && nums.Activity) || 0 }}
          <span class="spUnit">次</span>
        </div>
        <div class="typeName">参与活动</div>
      </div>
      <div class="performancesummary_list_item card">
        <div class="iconBox VerticalMid iconbox" style="background-color: #e5fbfa; color: #17dccd">
          <i class="iconfont">&#xe65d;</i>
        </div>
        <div class="numberBox">
          {{ (nums && nums.Moral) || 0 }}
          <span class="spUnit">次</span>
        </div>
        <div class="typeName">行为规范</div>
      </div>
      <div class="performancesummary_list_item card">
        <div class="iconBox VerticalMid iconbox" style="background-color: #fff5f8; color: #ffa1c0">
          <i nums-v-0e3c62fe="" class="iconfont">&#xe62f;</i>
        </div>
        <div class="numberBox">
          {{ (nums && nums.Prize) || 0 }}
          <span class="spUnit">次</span>
        </div>
        <div class="typeName">学生奖励</div>
      </div>
      <div class="performancesummary_list_item card">
        <div class="iconBox VerticalMid iconbox" style="background-color: #ffeaf4; color: #ff3368">
          <i class="iconfont">&#xe63b;</i>
        </div>
        <div class="numberBox">
          {{ (nums && nums.Punish) || 0 }}
          <span class="spUnit">次</span>
        </div>
        <div class="typeName">处分记录</div>
      </div>
      <div class="performancesummary_list_item card">
        <div class="iconBox VerticalMid iconbox" style="background-color: #fff8eb; color: #ffbc20">
          <i nums-v-0e3c62fe="" class="iconfont">&#xe680;</i>
        </div>
        <div class="numberBox">
          {{ (nums && nums.SelfReport) || 0 }}
          <span class="spUnit">次</span>
        </div>
        <div class="typeName">上报审核</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    nums: {
      required: true
    }
  }
};
</script>

<style scoped lang="less">
.performancesummary {
  > p {
    letter-spacing: 1px;
    color: #212529;
    position: relative;
    font-size: 32px;
    text-align: center;
    margin-bottom: 30px;
  }
  .performancesummary_list {
    display: flex;
    column-gap: 24px;
    .card:hover {
      border-color: #3364ff;
    }
    .performancesummary_list_item {
      padding: 20px;
      width: 150px;
      height: 195px;

      .numberBox {
        color: #323d56;
        font-family: 'OswaldLight';
        font-size: 34px;
        letter-spacing: 1px;
        font-weight: bold;
        .spUnit {
          font-size: 18px;
          padding-left: 5px;
        }
      }
      .typeName {
        color: #acb2c6;
        letter-spacing: 1px;
        font-size: 16px;
      }
      .iconbox {
        width: 70px;
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        margin: 0 auto;
        text-align: center;
        font-size: 34px;
        line-height: 34px;
        i {
          font-size: 34px;
          line-height: 34px;
        }
      }
    }
  }
}
</style>
