<!--
  班干部事务 - 行为规范
-->
<template>
  <div class="SubWebPageStudent" v-loading.fullscreen.lock="fullscreenLoading">
    <!-- 搜索 -->
    <search :key="random" :SearchTitles="SearchTitles" :ColNumber="2" :SearchList="SearchList" ref="SearchList" @onSearch="onSearch" v-if="SearchList != null"></search>
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="padding-left: 0px">行为规范</h3>
      </div>
      <div class="card-body p-0" v-if="itemData != null && itemData.length != 0">
        <el-table :data="itemData" stripe class="elTableData" border>
          <el-table-column type="index" label="序号" class="elColumn" width="80" align="center"></el-table-column>
          <el-table-column prop="className" label="班级" width="160" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.related == true" style="color: #409eff; font-size: 16px">关联</span>
              <span v-if="scope.row.transformStatus == 2" style="color: #409eff">已落实</span>
              <span v-if="scope.row.transformStatus == 3" style="color: #409eff">无法落实</span>
              {{ scope.row.class ? scope.row.class : scope.row.className }}
            </template>
          </el-table-column>
          <el-table-column prop="room" label="寝室" width="120" align="center"></el-table-column>
          <el-table-column label="责任人" width="80" align="center" v-if="currentType != 2">
            <template slot-scope="scope">
              <div
                v-if="regNull(scope.row.student) && regNull(scope.row.responsible) && scope.row.isImplement"
                style="font-size: 24px; color: #60c560; cursor: pointer"
                @click="onImplement(scope.row)"
              >
                落实
              </div>
              <div v-else>
                {{ scope.row.student ? scope.row.student : scope.row.responsible }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="原因" min-width="300" :show-overflow-tooltip="true" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.appealStatus == 1" style="color: #409eff">申诉中</span>
              <span v-if="scope.row.appealStatus == 2" style="color: #67c23a">申诉成功</span>
              <span v-if="scope.row.appealStatus == 3" style="color: #f56c6c">申诉驳回</span>
              <span v-if="scope.row.appealStatus == 4" style="color: #909399">申诉过期</span>
              <span v-html="onGetReason(scope.row)"></span>
            </template>
          </el-table-column>
          <el-table-column prop="remark" label="备注" width="120" align="center"></el-table-column>
          <el-table-column prop="score" label="行为分数" width="120" align="center"></el-table-column>
          <el-table-column label="行为时间" width="150">
            <template slot-scope="scope">
              <span>{{ scope.row.logDate | dateformatMinute }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" :width="isClassCadreMorality ? 180 : 100" fixed="right" align="center">
            <template slot-scope="scope">
              <operationBtn :btnName="'查看'" @click.native="onDetail(scope.row.memberId, scope.row.logId)"></operationBtn>

              <!--
              <el-button
                size="small"
                style="margin-left: 10px"
                @click="onAppeal(scope.row)"
                v-if="isClassCadreMorality && scope.row.appealStatus != 1"
              >
                申述
              </el-button>
              -->
              <!--
              <operationBtn :btnName="'落实'"  :btnType="'warning'" v-if="scope.row.isImplement" @click.native="onImplement(scope.row)">
              </operationBtn>
              -->
            </template>
          </el-table-column>
        </el-table>
        <comPage :paging="page" :pageBtnList="pageBtnList" @pagingClick="pagingClick" @pageBtnClick="pageBtnClick"></comPage>
      </div>

      <div class="card-body" v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>

    <!-- 申诉 -->
    <el-dialog
      :title="'申诉'"
      :visible.sync="dialogVisibleAppeal"
      width="30%"
      :before-close="() => (dialogVisibleAppeal = false)"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :append-to-body="true"
      :fullscreen="false"
    >
      <el-form ref="ruleFormAppeal" :model="ruleFormAppeal" :rules="rulesAppeal">
        <el-form-item label="申诉理由" prop="appealReason">
          <el-input type="textarea" v-model="ruleFormAppeal.appealReason" :rows="5" placeholder="请输入申诉理由"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleAppeal = false">取 消</el-button>
        <el-button type="primary" @click="onUpdateAppeal">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import '@/assets/css/Student.css';
import comPage from '@/components/Page.vue';
import search from '@/components/search.vue';
import operationBtn from '@/components/operationBtn.vue';

export default {
  components: {
    search,
    operationBtn,
    comPage
  },
  data() {
    return {
      rulesAppeal: {
        appealReason: [{ max: 256, message: '申诉理由不能超过256个字符', trigger: 'change' }]
      },
      //
      ruleFormAppeal: {
        appealReason: null,
        memberId: null,
        logId: null
      },
      // 申述弹框
      dialogVisibleAppeal: false,
      // 动画
      fullscreenLoading: false,
      // 搜索
      SearchList: null,
      //列表数据
      itemData: [],
      //分页查询接口返回的结果
      page: null,
      //分页左侧按钮集合
      pageBtnList: null,
      //分页数据
      paging: {
        // 总共页数
        pageLength: 0,
        // 每页请求的数据量
        Size: 1,
        //当前在第几页
        Index: 1
      },
      random: 0, //刷新组件
      SearchTitles: [],
      currentType: null,
      tempStudent: null,
      optAppealStatus: [],
      optAll: [
        {
          Value: null,
          Title: '全部'
        },
        {
          Value: 'true',
          Title: '已落实'
        },
        {
          Value: 'false',
          Title: '未落实'
        }
      ],
      isClassCadreMorality: false,
      ruleFormAppealReset: null
    };
  },
  methods: {
    //申述
    onAppeal(obj) {
      let that = this;

      let show = compareDate(new Date(), new Date(obj.appealDeadline));
      if (show == true) {
        that.ruleFormAppeal = JSON.parse(JSON.stringify(that.ruleFormAppealReset));
        that.ruleFormAppeal.logId = obj.logId;
        that.$nextTick(() => {
          that.$refs.ruleFormAppeal.clearValidate();
        });
        that.dialogVisibleAppeal = true;
      } else {
        warning(that, '已超出申诉时效!');
      }
    },

    /**
     * @description 提交申诉
     */
    onUpdateAppeal() {
      let that = this;
      that.$refs['ruleFormAppeal'].validate((valid) => {
        if (valid) {
          let url = '';
          if (that.currentType == 1) {
            url = '/api/admin/moralclass/appeal';
          } else if (that.currentType == 2) {
            url = '/api/admin/moraldormitorylog/appeal';
          } else if (that.currentType == 3) {
            url = '/api/admin/moralperson/appeal';
          }
          that.fullscreenLoading = true;
          ajaxPut(that, url, that.ruleFormAppeal, (r) => {
            that.fullscreenLoading = false;
            that.dialogVisibleAppeal = false;
            Init(that);
          });
        } else {
          setTimeout(() => {
            var isError = document.getElementsByClassName('is-error');
            var el = isError[0].querySelector('input') || isError[0].querySelector('textarea') || isError[0].querySelector('select');
            if (el) {
              el.focus();
            }
          }, 1);
          return false;
        }
      });
    },
    onImplement(objItem) {
      let that = this;
      that.$router.push({
        path: '/Student/BehaviorRecord/ClassImplement/' + objItem.logId,
        query: {
          url: that.$router.history.current.fullPath
        }
      });
    },
    /**
     * @description 显示原因
     * @param {Object} objItem
     */
    onGetReason(objItem) {
      return objItem.reason + (regNull(delHtml(objItem.remark)) ? '' : '<span class="color-gray">（' + delHtml(objItem.remark) + '）</span>');
    },
    /**
     * @description 搜索
     * @param {Object} params
     */
    onSearch(params) {
      let that = this;
      let data = {};
      params.forEach((element) => {
        if (element.data) {
          data[element.zhi] = Join(element.data);
        }
      });
      data.PageNumer = 1;
      data['type'] = that.currentType;
      routerPath(that, '/Student/BehaviorRecord/List', data, null, 'PageSize');
    },

    /**
     * @description 详情
     * @param {Object} Id
     */
    onDetail(memberId, logId) {
      let that = this;
      that.$router.push({
        path: '/Student/BehaviorRecord/DetailsList/' + that.currentType + '/' + logId,
        query: {
          url: that.$router.history.current.fullPath
        }
      });
    },
    pageBtnClick(index) {
      //分页组件左侧的按钮事件，按顺序进行判断
    },
    // 分页面按钮
    pagingClick(type, val) {
      let that = this;
      let url = '/Student/BehaviorRecord/List?type=' + that.currentType;
      if (type == 1) {
        //更改每页条数触发的事件
        routerPath(that, url, 'PageSize', val, 'PageNumer', 'SemesterId', 'Reason', 'StudentInfo', 'LogDate', 'AppealStatus', 'All');
      } else {
        //更改当前页时触发的事件
        routerPath(that, url, 'PageNumer', val, 'PageSize', 'SemesterId', 'Reason', 'StudentInfo', 'LogDate', 'AppealStatus', 'All');
      }
    }
  },
  created() {
    let that = this;
    let tempStudent = JSON.parse(that.$GetKey(that.$cacheStudent));

    if (!regNull(tempStudent)) {
      that.isClassCadreMorality = tempStudent.isClassCadreMorality;
    }

    that.ruleFormAppealReset = JSON.parse(JSON.stringify(that.ruleFormAppeal));
    ajaxGet(that, '/api/dict/appealstatus', null, (r) => {
      if (!regNullArray(r)) {
        r.forEach((element) => {
          that.optAppealStatus.push({
            Value: element.Value,
            Title: element.Text
          });
        });
      }

      Init(that);
    });
  },
  watch: {
    $route() {
      let that = this;
      Init(that);
    }
  }
};

function Init(that) {
  that.currentType = that.$route.query.type;
  if (that.currentType == null || typeof that.currentType == 'undefined' || that.currentType == '') {
    that.currentType = 1;
  }
  if (regNull(JSON.parse(that.$GetKey('Student')))) {
    ajaxGet(that, '/api/student/student', null, function (resStudent) {
      that.tempStudent = resStudent;
    });
  } else {
    that.tempStudent = JSON.parse(that.$GetKey('Student'));
  }

  that.SearchTitles = [];

  if (!regNull(that.tempStudent)) {
    that.SearchTitles.push(
      {
        name: '班级',
        isCurrent: that.currentType == 1 ? true : false,
        url: '/Student/BehaviorRecord/List?type=1'
      },
      {
        name: '寝室',
        isCurrent: that.currentType == 2 ? true : false,
        url: '/Student/BehaviorRecord/List?type=2'
      },
      {
        name: '个人',
        isCurrent: that.currentType == 3 ? true : false,
        url: '/Student/BehaviorRecord/List?type=3'
      }
    );
  }

  onGetDataPage(that);
}

function onGetDataPage(that) {
  paging(
    that,
    function (res) {
      let apiUrl = null;
      if (that.currentType == 1) {
        apiUrl = '/api/student/classcadremoral/classsearch';
      } else if (that.currentType == 2) {
        apiUrl = '/api/student/classcadremoral/dormsearch';
      } else if (that.currentType == 3) {
        apiUrl = '/api/student/classcadremoral/personsearch';
      }

      that.fullscreenLoading = true;
      // 搜索 下拉学期
      ajaxGet(that, '/api/student/semester/student', null, function (resSemester) {
        that.fullscreenLoading = false;
        // 获得学期数据
        var SemesterId = res.SemesterId;
        let arrSemester = resSemester.map((item) => ({
          Title: item.name, //semester[item].name,
          Value: item.semesterId //semester[item].semesterId,
        }));
        // 设置学期默认值
        if (!regNullArray(resSemester)) {
          let tpSemester = resSemester.find((item) => item.isCurrent == true);
          if (regNull(SemesterId)) {
            if (regNull(tpSemester)) {
              SemesterId = resSemester[0].semesterId;
            } else {
              SemesterId = tpSemester.semesterId;
            }
          }
        }

        that.SearchList = [
          // 学期
          {
            type: 'select',
            Name: '学期',
            data: SemesterId,
            select: arrSemester, //选择的数据
            zhi: 'SemesterId' // 传值
            // isClear: false, //select是否可以清除
            // resetData: SemesterId,
          },
          {
            type: 'input',
            Name: '行为原因',
            data: res.Reason,
            holder: '请输入行为原因',
            zhi: 'Reason'
          },
          {
            type: 'time',
            Name: '行为时间',
            data: res.LogDate,
            zhi: 'LogDate'
          }, // 申诉状态
          {
            type: 'select',
            Name: '申诉状态',
            data: res.AppealStatus,
            select: that.optAppealStatus, //选择的数据
            zhi: 'AppealStatus', // 传值
            isClear: true //select是否可以清除
          }
        ];
        if (that.currentType == 1) {
          that.SearchList.push({
            type: 'select',
            Name: '是否落实',
            data: res.All,
            select: that.optAll, //选择的数据
            zhi: 'All', // 传值
            isClear: true //select是否可以清除
          });
        }
        if (that.currentType == 3) {
          that.SearchList.push({
            type: 'input',
            Name: '学生',
            data: res.StudentInfo,
            holder: '请输入学生姓名/学号',
            zhi: 'StudentInfo'
          });
        }

        var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
        that.random += random;

        var LogDateList = [];
        LogDateList = res.LogDate != null ? JSON.parse(JSON.stringify(res.LogDate)).split(',') : [];

        let data = {
          PageNumer: res.PageNumer,
          PageSize: res.PageSize,
          SemesterId: SemesterId,
          Reason: res.Reason,
          ClassId: that.tempStudent.classId,
          StudentInfo: res.StudentInfo,
          LogDateStart: LogDateList[0],
          LogDateEnd: LogDateList[1],
          AppealStatus: res.AppealStatus,
          All: res.All
        };
        ajaxGet(that, apiUrl, data, function (resData) {
          that.page = resData;
          that.itemData = resData.items;
          for (var it of that.itemData) {
            it.isImplement = true;
            // 是否转化，转化则已落实，未转化则未落实
            if (it.transformStatus == 2 || it.transformStatus == 3 || it.appealStatus == 1) {
              it.isImplement = false;
            }
            if (it.appealStatus == 1 || it.appealStatus == 2) {
              it.isImplement = false;
            }
            // 由班级个人关联过来的数据，不需要申诉、落实、删除、取消申诉
            if (it.related == true) {
              it.isImplement = false;
            }
          }
          that.fullscreenLoading = false;
        });
      });
    },
    'SemesterId',
    'Reason',
    'StudentInfo',
    'LogDate',
    'AppealStatus',
    'All'
  );
}
</script>

<style scoped="scoped">
.titleErr {
  font-size: 12px;
  color: #f6402c;
}
.paperclip {
  margin-right: 5px;
  color: #409eff;
}
.navTabsCont .nav-link {
  font-size: 12px;
}

.navToolsCont {
  margin-top: 1px;
}

/*重置样式*/
.table tbody td,
.table thead th {
  font-size: 0.875rem;
  /*14*/
}

.table tr td,
.table tr th {
  text-align: center;
}

.table tr th:nth-child(1),
.table tr th:nth-child(2),
.table tr td:nth-child(1),
.table tr td:nth-child(2) {
  text-align: left;
}
</style>
