<!--
  行为规范
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-form :model="ruleForm" ref="ruleForm" label-width="100px">
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            查看行为规范{{ currentTitle }}
          </h3>
        </div>
        <div class="card-body">
          <el-row :gutter="20">
            <el-col :span="12" class="colInfo">
              <label>管理原因</label>
              <div class="colFonts">{{ onNullData(ruleForm.reasonName) }}</div>
            </el-col>
            <el-col :lg="12" :md="8" :sm="12" class="colInfo">
              <label>分数</label>
              <div class="colFonts">{{ ruleForm.score }}</div>
            </el-col>
            <el-col :lg="12" :md="8" :sm="12" class="colInfo">
              <label>责任人</label>
              <div class="colFonts">{{ onNullData(ruleForm.student ? ruleForm.student : ruleForm.responsible) }}</div>
            </el-col>
            <el-col :lg="12" :md="8" :sm="12" class="colInfo">
              <label>班级</label>
              <div class="colFonts">{{ onNullData(ruleForm.class ? ruleForm.class : ruleForm.className) }}</div>
            </el-col>
            <el-col :lg="12" :md="8" :sm="12" class="colInfo">
              <label>寝室</label>
              <div class="colFonts">{{ onNullData(ruleForm.room) }}</div>
            </el-col>
            <el-col :lg="12" :md="8" :sm="12" class="colInfo" v-if="isPower.school_recordperson_details || isPower.class_recordperson_details">
              <label>记录人</label>
              <div class="colFonts">{{ ruleForm.createdBy }}</div>
            </el-col>
            <el-col :lg="12" :md="8" :sm="12" class="colInfo">
              <label>检查时间</label>
              <div class="colFonts">{{ ruleForm.logDate | dateformatMinute }}</div>
            </el-col>
            <el-col :span="24" class="colInfo">
              <label>备注</label>
              <div class="colFonts">{{ onNullData(ruleForm.remark) }}</div>
            </el-col>
          </el-row>
        </div>
      </el-card>

      <el-card class="box-card" v-if="ruleForm.appealStatus">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            申诉信息
          </h3>
        </div>
        <div class="card-body">
          <el-row :gutter="24">
            <el-col :span="12" class="colInfo">
              <label>申诉人</label>
              <div class="colFonts">{{ ruleForm.appealBy }}</div>
            </el-col>
            <el-col :span="12" class="colInfo">
              <label>申诉状态</label>
              <div v-if="ruleForm.appealStatus == 1" class="colFonts" style="color: #409eff">{{ ruleForm.appealStatusName }}</div>
              <div v-if="ruleForm.appealStatus == 2" class="colFonts" style="color: #67c23a">{{ ruleForm.appealStatusName }}</div>
              <div v-if="ruleForm.appealStatus == 3" class="colFonts" style="color: #f56c6c">{{ ruleForm.appealStatusName }}</div>
              <div v-if="ruleForm.appealStatus == 4" class="colFonts" style="color: #909399">{{ ruleForm.appealStatusName }}</div>
            </el-col>
            <el-col :span="12" class="colInfo">
              <label>申诉时间</label>
              <div class="colFonts">{{ ruleForm.appealDate | dateformatMinute }}</div>
            </el-col>
            <el-col :span="12" class="colInfo">
              <label>申诉理由</label>
              <div class="colFonts">{{ ruleForm.appealReason ? ruleForm.appealReason : '暂无数据' }}</div>
            </el-col>
          </el-row>

          <el-row :gutter="24" v-if="ruleForm.appealStatus == 2 || ruleForm.appealStatus == 3">
            <el-col :span="12" class="colInfo">
              <label>申诉处理人</label>
              <div class="colFonts">{{ ruleForm.approveBy }}</div>
            </el-col>
            <el-col :span="12" class="colInfo">
              <label>申诉处理时间</label>
              <div class="colFonts">{{ ruleForm.approveDate | dateformatMinute }}</div>
            </el-col>
            <el-col :span="12" class="colInfo">
              <label>申诉处理意见</label>
              <div class="colFonts">{{ ruleForm.approveReason ? ruleForm.approveReason : '暂无数据' }}</div>
            </el-col>
          </el-row>
        </div>
      </el-card>

      <el-card class="box-card" v-if="uploadFiles != null && uploadFiles.length != 0">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            行为图片
          </h3>
        </div>
        <div class="card-body">
          <el-carousel height="500px" :autoplay="!showViewer">
            <el-carousel-item v-for="(item, index) in uploadFiles" :key="index" class="VerticalMid">
              <img :src="item" @click="onPreview" />
            </el-carousel-item>
          </el-carousel>
        </div>
      </el-card>

      <!--      <el-card class="box-card" v-if="ruleForm.transform && !regNullArray(transData)">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            落实关联记录
          </h3>
        </div>
        <div class="card-body p-0">
          <el-table stripe :data="transData" class="elTableData">
            <el-table-column prop="reason" label="管理原因" min-width="200" :show-overflow-tooltip="true">
              <template slot-scope="scope">
                <span v-html="onGetReason(scope.row)"></span>
              </template>
            </el-table-column>
            <el-table-column prop="studentName" label="学生" v-if="currentType==1" min-width="160" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="class" label="班级" width="160" :show-overflow-tooltip="true">
              <template slot-scope="scope">
                <span>{{scope.row.class ? scope.row.class : scope.row.className}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="score" label="分数" width="80">
            </el-table-column>
            <el-table-column prop="logDate" label="检查时间" width="180">
              <template slot-scope="scope">
                <span>{{scope.row.logDate | dateformatMinute}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="createdBy" label="记录人" width="120" :show-overflow-tooltip="true">
            </el-table-column>
          </el-table>
        </div>
      </el-card> -->

      <!--      <el-card class="box-card" v-if="ruleForm.relatedLogId && !regNullArray(ruleForm.students)">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            关联的学生
          </h3>
        </div>
        <div class="card-body p-0">
          <el-table stripe :data="ruleForm.students" class="elTableData">
            <el-table-column prop="studentNo" label="学号"  min-width="160" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="name" label="姓名"  min-width="160" :show-overflow-tooltip="true">
            </el-table-column>
          </el-table>
        </div>
      </el-card> -->
    </el-form>

    <div class="row" style="margin-top: 10px">
      <div class="col-12">
        <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
      </div>
    </div>

    <!--配合走马灯效果放大图片组件-->
    <el-image-viewer v-if="showViewer" :on-close="closeViewer" :url-list="uploadFiles" />
  </div>
</template>

<script>
import '@/assets/css/Admin.css';
import ElImageViewer from 'element-ui/packages/image/src/image-viewer';
export default {
  components: {
    ElImageViewer
  },
  data() {
    return {
      showViewer: false,
      random: 0,
      currentTitle: '',
      fullscreenLoading: false,
      uploadFiles: [],
      ruleForm: {
        class: null,
        reasonName: null,
        logDate: null,
        remark: null,
        score: null,
        attachment: null,
        members: []
      },
      currentType: null,
      currentId: null,
      isPower: {
        school_recordperson_details: false,
        class_recordperson_details: false
      },
      transData: [],
      roleInfo: {}
    };
  },
  created() {
    let that = this;
    isPower(that, 'school_recordperson_details', 'school_recordperson_details');
    isPower(that, 'class_recordperson_details', 'class_recordperson_details');
    that.currentType = that.$route.params.type;
    that.currentId = that.$route.params.id;
    that.roleInfo = onGetNowRole(that);

    let detailUrl = '';
    if (that.currentType == 1) {
      that.currentTitle = '【班级】';
      detailUrl = '/api/admin/moralclass/' + that.currentId;
    } else if (that.currentType == 2) {
      that.currentTitle = '【寝室】';
      detailUrl = '/api/admin/moraldorm/' + that.currentId;
    } else if (that.currentType == 3) {
      that.currentTitle = '【个人】';
      detailUrl = '/api/admin/moralperson/' + that.currentId;
    }

    if (detailUrl != '') {
      console.log(that.roleInfo);
      detailUrl += '?roleId=' + that.roleInfo.roleId;

      console.log(detailUrl);
      that.fullscreenLoading = true;
      ajaxGet(that, detailUrl, null, function (r) {
        that.ruleForm = r;
        that.ruleForm.reasonName = r.reason;

        // 判断是否有落实转换
        // if (r.transform) {
        //   let tpiUrl = "";
        //   if (that.currentType == 1) {
        //     tpiUrl = "/api/admin/morallog/byclasslogid?classLogId=" + r.logId;
        //   } else if (that.currentType == 2) {
        //     tpiUrl = "/api/admin/moralclasslog/" + r.classLogId;
        //   } else if (that.currentType == 3) {
        //     tpiUrl = "/api/admin/moraldplog/bydormtorylogid?dormtoryLogId=" + r.logId;
        //   } else if (that.currentType == 4) {
        //     tpiUrl = "/api/admin/moraldormitorylog/" + r.dormtoryLogId;
        //   }
        //   ajaxGet(that, tpiUrl, null, function(resTrans) {
        //     if (that.currentType == 1 || that.currentType == 3) {
        //       that.transData = JSON.parse(JSON.stringify(resTrans));
        //     } else {
        //       that.transData.push(resTrans);
        //     }
        //   });
        // }

        that.uploadFiles = [];
        if (r.attachment != null && typeof r.attachment != 'undefined' && r.attachment != '') {
          var files = r.attachment.split('|');
          for (var i in files) {
            that.uploadFiles.push(that.$ServerUrl() + files[i]);
          }
          var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
          that.random += random;
        }
        that.fullscreenLoading = false;
        console.log(that.uploadFiles);
      });
    }
  },
  methods: {
    /**
     * @description 显示原因
     * @param {Object} objItem
     */
    onGetReason(objItem) {
      return objItem.reason + (regNull(delHtml(objItem.remark)) ? '' : '<span class="color-gray">（' + delHtml(objItem.remark) + '）</span>');
    },
    /**
     * @description 打开放大图片
     */
    onPreview() {
      this.showViewer = true;
    },
    /**
     * @description 关闭图片预览图
     */
    closeViewer() {
      this.showViewer = false;
    },
    /**
     * @description 返回上一页
     */
    onBack() {
      let that = this;
      back(that);
    },
    /**
     * @description 判断字段是否有数据
     * @param {Object} _val
     * @return {String} 没有数据返回‘暂无数据’
     */
    onNullData(_val) {
      return regNull(_val) ? '暂无数据' : _val;
    }
  }
};
</script>

<style scoped="scoped">
.stuInfo {
  position: relative;
  height: 100%;
  min-height: 80px;
  padding-left: 80px;
  margin: 15px;
}

.stuInfo .stuFraction {
  position: absolute;
  top: -5px;
  bottom: 0;
  right: -5px;
  font-family: 'OswaldLight';
  font-size: 26px;
  color: #28a745;
}

.stuInfo .stuBtnDown {
  position: absolute;
  bottom: 20px;
  right: 0;
}

.stuInfo .stuBtnDown .aView {
  margin-right: 5px;
  margin-left: 5px;
}

.stuInfo .stuBtnDown .aView .faxSize {
  font-size: 12px;
  color: #343a40;
}

.stuInfo .stuBtnDown .aView:hover .faxSize {
  color: #1666b3;
}

.stuInfo .stuImgCont {
  position: absolute;
  top: 0;
  left: 0;
  width: 70px;
  height: 70px;
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid #dddddd;
}

.stuInfo .stuImgCont img {
  width: 100%;
  padding-top: 10px;
}

.stuInfo .stuName {
  color: #212e48;
  font-size: 18px;
  font-weight: bold;
}

.stuInfo .stuRole {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 12px;
}

.stuRole .spStatus {
  font-size: 12px;
  color: #b1b1b1;
}

.stuInfo .stuOther {
  color: #767676;
  font-size: 12px;
}

.stuInfo .stuRemove {
  display: none;
}

.stuInfo:hover .stuRemove {
  display: block;
  position: absolute;
  top: 0rem;
  bottom: 0rem;
  right: 0rem;
  font-size: 22px;
  cursor: pointer;
}

.transferBox {
  margin: 0 auto;
}

label {
  margin-bottom: 0;
}

.inputGroupCont {
  padding-top: 15px;
}

.inputGroupCont .inputList {
  padding-bottom: 10px;
}

.tabListCont .divNavTitle {
}

.tabListCont .divNavTitle {
}

.tabListCont .divNavTitle .divTitleCont {
  padding: 10px 0px 10px 10px;
  color: #dee2e6;
  cursor: pointer;
  font-size: 18px;
}

.tabListCont .divNavTitle .divTitleCont .spNum {
  background-color: #dee2e6;
  font-family: 'OswaldLight';
  padding: 2px 5px;
  border-radius: 4px;
  margin-left: 5px;
  font-size: 14px;
  color: #ffffff;
}

.tabListCont .divNavTitle .divTitleAct {
  color: #3e3e3e;
}

.tabListCont .divNavTitle .divTitleAct .spNum {
  background-color: #17a2b8;
  color: #ffffff;
}

.tabListCont .divNavTitle .nav-link {
  font-size: 14px;
  color: #b1b1b1;
}

.tabListCont .divNavTitle .nav-link .spNum {
  font-family: 'OswaldLight';
  font-weight: normal;
  font-size: 14px;
  padding-left: 10px;
}

.tabListCont .divNavTitle .active {
  color: #007bff;
}

.divAlertCont {
  padding-left: 30px;
  padding-top: 10px;
  color: #9595a0;
}
</style>

<style>
.transferCont .el-transfer__buttons {
  width: 20%;
}

.transferCont .el-transfer__buttons .el-transfer__button:first-child {
  float: left;
}

.transferCont .el-transfer__buttons .el-transfer__button:last-child {
  float: right;
}

.transferCont .el-transfer-panel {
  width: 40%;
}

.formGroupCont .el-date-editor--datetimerange.el-input__inner {
  width: 100%;
}
</style>
