<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="padding-left: 0px">寝室考勤</h3>
      </div>
      <div class="card-body p-0" v-if="itemData != null && itemData.length != 0">
        <el-table :data="itemData" stripe class="elTableData">
          <el-table-column type="index" label="序号" width="80" min-width="100"></el-table-column>
          <el-table-column prop="text" label="楼名" class="elColumn" min-width="120" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="total" label="总人数" class="elColumn" min-width="120"></el-table-column>
          <el-table-column prop="should" label="应到人数" class="elColumn" min-width="120"></el-table-column>
          <el-table-column prop="actual" label="实到人数" class="elColumn" min-width="120"></el-table-column>
          <el-table-column prop="leave" label="请假人数" class="elColumn" min-width="120"></el-table-column>
          <el-table-column label="当前打卡率（%）" class="elColumn" min-width="140">
            <template slot-scope="scope">
              <span class="color-primary">
                {{ scope.row.scale }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="130" fixed="right">
            <template slot-scope="scope">
              <operationBtn :btnName="'查看'" ref="detail" @click.native="onDetail(scope.row)"></operationBtn>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="card-body" v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>
    <div class="row">
      <div class="col-12">
        <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import '@/assets/css/Admin.css';
import operationBtn from '@/components/operationBtn.vue';
export default {
  components: {
    operationBtn
  },
  data() {
    return {
      // 加载动画
      fullscreenLoading: false,
      // 列表数据
      itemData: null,
      isPower: {},
      attendanceId: null
    };
  },
  methods: {
    // 详情
    onDetail(_data) {
      let that = this;
      that.$router.push({
        path: '/Admin/DormPunchIn/Floor/' + _data.id,
        query: {
          attendanceId: that.attendanceId,
          name: _data.text,
          url: that.$router.history.current.fullPath
        }
      });
    },
    // 返回
    onBack() {
      let that = this;
      back(that);
    }
  },
  created() {
    let that = this;
    onGetData(that);
  }
};

function onGetData(that) {
  if (regNull(that.attendanceId)) {
    that.attendanceId = that.$route.query.memberId;
  }
  that.fullscreenLoading = true;
  ajaxGet(that, '/api/attendance/statisbybuilding', { attendanceId: that.attendanceId }, function (resData) {
    that.itemData = resData;
    that.fullscreenLoading = false;
  });
}
</script>

<style scoped="scoped"></style>
