<template>
  <div class="SubWebPageStudent" v-loading.fullscreen.lock="fullscreenLoading" element-loading-text="请稍等，正在生成综合报告" element-loading-background="rgba(0, 0, 0, 0.8)">
    <!--头部-->
    <div class="divPageHeaderBox">
      <!-- 学年 -->
      <ul class="ulTabsBox">
        <li class="liTab" :class="{ liAction: currsorIndex == index }" v-for="(item, index) in optYear" :key="index" @click="onChangeYear(item, index)">{{ item.name }}</li>
      </ul>
      <!-- 学期 -->
      <div class="divSemesterBox">
        <div class="divTitle">学期</div>
        <el-select v-model="nowSemester.semesterId" placeholder="请选择" class="selForm width_300" @change="onChangeSemester">
          <el-option v-for="item in optSemester" :key="item.semesterId" :label="item.name" :value="item.semesterId"></el-option>
        </el-select>
      </div>
    </div>

    <!--页头-->
    <div class="A4CardBox pageHeader PrintPage" id="pageCover">
      <div class="divLogo">
        <img src="../../../assets/image/logo.png" height="50" />
      </div>
      <div class="divImgBox">
        <img src="../../../assets/image/report/cover_1.png" />
        <div class="num1 VerticalMid">
          <div class="bgColor"></div>
          <div class="fontBox VerticalMid">
            校园
            <br />
            生活
          </div>
        </div>
        <div class="num2 VerticalMid">
          <div class="bgColor"></div>
          <div class="fontBox VerticalMid">
            成长
            <br />
            档案
          </div>
        </div>
        <div class="num3 VerticalMid">
          <div class="bgColor"></div>
          <div class="fontBox VerticalMid">
            综合
            <br />
            评价
          </div>
        </div>
      </div>
      <div class="divTitleBox">
        <div class="divTitle">
          <div class="divNameBox">{{ infoData.name }}</div>
          <div class="divSubName">{{ reportTitle }} &middot; 综合评价报告</div>
        </div>
      </div>
      <div class="divSchoolName">
        {{ onGetSchoolName() }}
        <br />
        {{ nowDate }}
      </div>
    </div>

    <!-- 基本信息/个人总评 -->
    <div class="A4CardBox pageCont pageBoxOne PrintPageTwo" ref="targetDom">
      <div class="InfoTitleCont oneTitle">
        <i class="fax icon">&#xe791;</i>
        基本信息
      </div>
      <div class="baseInfoCont">
        <div class="stuName">{{ infoData.name }}</div>
        <div class="stuLabelCont">
          <span class="spItem" v-if="!onGetIsNull(infoData.class)">
            {{ infoData.class }}
          </span>
          <span class="spItem" v-if="!onGetIsNull(infoData.studentNo)">
            {{ infoData.studentNo }}
          </span>
          <span class="spItem" v-if="!onGetIsNull(infoData.nation)">
            {{ infoData.nation }}
          </span>
        </div>
        <div class="stuLabelCont">
          <span class="spItem" v-if="!onGetIsNull(infoData.mobile)">
            {{ infoData.mobile }}
          </span>
          <span class="spItem" v-if="!onGetIsNull(infoData.political)">
            {{ infoData.political }}
          </span>
          <span class="spItem" v-if="!onGetIsNull(infoData.cadre)">
            {{ infoData.cadre }}
          </span>
        </div>
        <div class="stuLabelCont">
          <span class="spItem" v-if="!onGetIsNull(infoData.originalPlace)">
            {{ infoData.originalPlace }}
          </span>
        </div>
        <div class="stuAvatarCont">
          <span class="spGender bgBlue" v-if="onGetGender(infoData.gender)">
            <i class="fax icon">&#xe652;</i>
          </span>
          <span class="spGender bgRed" v-else>
            <i class="fax icon">&#xe655;</i>
          </span>
          <img :src="infoData.photo" :alt="infoData.name" class="imgAvatar" />
        </div>
      </div>
      <div class="InfoTitleCont twoTitle">
        <i class="fax icon">&#xe791;</i>
        {{ onGetTitle() }}
      </div>
      <div class="literacyFractionCont">
        <div class="fractionItem divTotalScore">
          {{ infoData.score }}
          <span class="spUnit">分</span>
        </div>
        <div class="fractionItem divEvaluation">
          <span class="spTopLabel">个人总评</span>
          {{ infoData.rating }}
        </div>
      </div>

      <div class="divFileRecords" v-if="infoData.Number != null">
        <el-row :gutter="20">
          <el-col :span="8">
            <div class="fileList VerticalMid">
              <div class="fileCont">
                <div class="topCont VerticalMid">{{ infoData.Number.Activity }}</div>
                <div class="bottomCont VerticalMid">参与活动</div>
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="fileList VerticalMid">
              <div class="fileCont">
                <div class="topCont VerticalMid">{{ infoData.Number.LiteracyCourse }}</div>
                <div class="bottomCont VerticalMid">综合课堂</div>
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="fileList VerticalMid">
              <div class="fileCont">
                <div class="topCont VerticalMid">{{ infoData.Number.Moral }}</div>
                <div class="bottomCont VerticalMid">行为规范</div>
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="fileList VerticalMid">
              <div class="fileCont">
                <div class="topCont VerticalMid">{{ infoData.Number.Prize }}</div>
                <div class="bottomCont VerticalMid">竞赛获奖</div>
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="fileList VerticalMid">
              <div class="fileCont">
                <div class="topCont VerticalMid">{{ infoData.Number.SelfReport }}</div>
                <div class="bottomCont VerticalMid">上报审核</div>
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="fileList VerticalMid">
              <div class="fileCont">
                <div class="topCont VerticalMid">{{ infoData.Number.ClassCadre + infoData.Number.Association }}</div>
                <div class="bottomCont VerticalMid">履职次数</div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>

    <!-- 成绩和评语，学期和学年展示不同 -->
    <div v-if="nowSemester.semesterId != null && allRecord != null && allRecord.length != 0">
      <div v-for="(item, index) in allRecord" :key="index">
        <!-- 成绩单 -->
        <div class="A4CardBox pageCont pageBoxScore PrintPage" v-if="item.score != null && item.score.length != 0">
          <div class="InfoTitleCont oneTitle titleBox">
            <i class="fax icon">&#xe791;</i>
            {{ item.name }} &middot; 成绩单
          </div>
          <div class="divDataListCont">
            <div class="divScoreCont">
              <el-table :data="item.score" border class="elTableData" cell-class-name="tdCont">
                <el-table-column prop="courseName" label="课程" min-width="180"></el-table-column>
                <el-table-column prop="peaceTime" label="平时" min-width="100"></el-table-column>
                <el-table-column prop="midTerm" label="期中" min-width="100"></el-table-column>
                <el-table-column prop="final" label="期末" min-width="100"></el-table-column>
                <el-table-column prop="final" label="总评" min-width="100"></el-table-column>
                <el-table-column prop="credit" label="学分" min-width="100"></el-table-column>
              </el-table>
            </div>
          </div>
        </div>

        <div>
          <div class="A4CardBox pageCont pageBoxComments PrintPage" v-if="item.comment != null && item.comment.length != 0">
            <div class="InfoTitleCont oneTitle titleBox">
              <i class="fax icon">&#xe791;</i>
              {{ item.name }} &middot; 班主任评语
            </div>
            <div class="divDataListCont" v-for="(list, indexTwo) in item.comment" :key="indexTwo">
              <div class="divContTitle">
                <span class="spTitleCont">
                  {{ list.commentsRank }}
                </span>
              </div>
              <div class="divCommentContent">
                <div class="divCommFont">
                  {{ onNoHtml(list.description) }}
                </div>
                <div class="divOhter">{{ list.teacher }}</div>
                <div class="divOhter">{{ list.recordDate | dateformatDay }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="allRecord != null && allRecord.length != 0">
      <div v-for="(item, index) in allRecord" :key="index">
        <div class="A4CardBox pageCont pageBoxScore PrintPage" v-if="item.score != null && item.score.length != 0">
          <div class="InfoTitleCont oneTitle titleBox">
            <i class="fax icon">&#xe791;</i>
            {{ item.name }} &middot; 成绩单
          </div>
          <div class="divDataListCont">
            <div class="divScoreContTwo">
              <div class="divItem" v-for="(list, indexTwo) in item.score" :key="indexTwo">
                <el-table :data="list.children" border class="elTableData" cell-class-name="tdCont" :header-row-class-name="'tdHeader_' + indexTwo">
                  <el-table-column :label="list.semester" align="center">
                    <el-table-column prop="courseName" label="课程" min-width="180"></el-table-column>
                    <el-table-column prop="final" label="总评" min-width="100"></el-table-column>
                    <el-table-column prop="credit" label="学分" min-width="100"></el-table-column>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </div>
        </div>
        <div class="A4CardBox pageCont pageBoxComments PrintPage" v-if="item.comment != null && item.comment.length != 0">
          <div class="InfoTitleCont oneTitle titleBox">
            <i class="fax icon">&#xe791;</i>
            {{ item.name }} &middot; 班主任评语
          </div>
          <div class="divDataListCont" v-for="(list, indexTwo) in item.comment" :key="indexTwo">
            <div class="divContTitle">
              <span class="spTitleCont">
                {{ list.semester }}
              </span>
              <span class="spTitleContTwo ml-3">
                {{ list.commentsRank }}
              </span>
            </div>
            <div class="divCommentContent">
              <div class="divCommFont">
                {{ onNoHtml(list.description) }}
              </div>
              <div class="divOhter">{{ list.teacher }}</div>
              <div class="divOhter">{{ list.recordDate | dateformatDay }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 综合分布情况 -->
    <div class="A4CardBox pageCont pageBoxTwo PrintPage">
      <!--副标题-->
      <div class="InfoSubTitle mt-9">
        综合评价报告
        <div class="divIconCont" v-if="titlePoint != null && titlePoint.length != 0">
          <span :style="{ color: item }" v-for="(item, index) in titlePoint" :key="index">&bull;</span>
        </div>
      </div>
      <!--综合分布情况统计图-->
      <div class="LiteracyDistribution">
        <div class="divLabelCont">
          <span class="spTotal">
            {{ infoData.score }}
            <span class="spUnit">分</span>
          </span>
          <span class="spFont">总计</span>
        </div>
        <div v-if="arrProportion != null && arrProportion.length > 0">
          <comProportion :comID="'echartProportion'" :comHeight="500" :comColor="optColor" :comData="arrProportion" ref="echartProportion"></comProportion>
        </div>
      </div>
    </div>

    <!-- 综合子项详情 -->
    <div v-if="reportData != null && reportData.length != 0">
      <div v-for="(item, index) in reportData" :key="index">
        <div class="A4CardBox pageCont pageBoxHeader PrintPage">
          <div class="InfoTitleCont oneTitle titleBox">
            综合评价 &middot;
            <span :style="{ color: item.colorVal }">{{ item.name }}</span>
          </div>
          <div class="chartBox">
            <div v-if="item.radar != null && item.radar.length != 0">
              <comChartRadar :comHeight="500" :comID="'chart_' + index" :comData="item.radar" :ref="'chart_' + index"></comChartRadar>
            </div>
          </div>
          <div class="literacyFractionCont">
            <div class="fractionItem divTotal" :style="{ color: item.colorVal }">
              <span class="spTopLabel">综合</span>
              {{ item.score }}
              <span class="spUnit">分</span>
            </div>
            <div class="fractionItem divTotal" :style="{ color: item.colorVal }">
              <span class="spTopLabel">记录</span>
              {{ item.quantity }}
              <span class="spUnit">条</span>
            </div>
          </div>
        </div>

        <!-- {{onGetInfoPage(item)}} -->
        <div class="A4CardBox pageCont pageBoxSub PrintPage" v-for="(list, indexTwo) in onGetInfoPage(item)" :key="indexTwo">
          <div class="InfoTitleCont oneTitle titleBox">
            综合评价 &middot;
            <span :style="{ color: item.colorVal }">{{ item.name }}</span>
          </div>
          <div class="divDataListCont">
            <div class="divContTitle">
              <span class="spTitleCont">
                {{ list.semester }}
              </span>
            </div>
            <div class="divRecordBox" v-for="(child, indexThr) in list.children" :key="indexThr" :class="onGetClass(child.attachment)">
              <div class="divContBox">
                <div class="divScore">
                  {{ child.score }}
                  <span class="spFont">分</span>
                </div>
                <div class="divHeader">
                  <div class="divSub">
                    <span class="spTime">{{ child.logDate | dateformatDay }}</span>
                    <span class="spType">{{ child.type }}</span>
                    <span class="spType">{{ child.configName }}</span>
                  </div>
                  <div class="divName">{{ child.name }}</div>
                </div>
              </div>
              <!-- {{onGetAttachment(child.attachment)}} -->
              <div class="divImgContBox" v-if="!regNullArray(child.attachment)">
                <el-row :gutter="8">
                  <el-col :span="8" v-for="(img, indexThs) in child.attachment" :key="indexThs">
                    <div class="">
                      <el-image :src="imageUrlToBase64(img)" class="imgList" fit="cover" :preview-src-list="onGetListAttachment(child.attachment)"></el-image>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--页尾-->
    <div class="A4CardBox pageFooter PrintPage">
      <img src="../../../assets/image/report/cover_2.png" class="bgImgBox" />
      <img src="../../../assets/image/report/cover_3.png" class="footerBox" />
      <div class="divFontBoxTwo">本报告由尧瑶科技综合评价系統自动生成</div>
      <div class="divFontBox">
        <el-row>
          <el-col :span="12">
            <div class="leftFont VerticalMid">校訓</div>
          </el-col>
          <el-col :span="12">
            <div class="rightFont VerticalMid">班訓</div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import '@/assets/css/Student.css';
import comChartRadar from '@/components/Report/echarts_radar.vue';
import comProportion from '@/components/Report/echarts_proportion.vue';
export default {
  components: {
    comChartRadar,
    comProportion
  },
  data() {
    return {
      urlPhoto: null,
      // 加载动画
      fullscreenLoading: false,
      // 个人信息
      infoData: {
        studentId: '',
        // 总分
        score: 0,
        // 总评
        rating: '',
        // 综合记录数
        record: null
      },
      // 综合报告标题
      reportTitle: '',
      // 报告数据
      reportData: [],
      // 展示类型 0：毕业所有学年 1：学年
      isType: 0,
      // 当前学年选项
      valYear: [],
      // 学年下拉选择数据
      optYear: [],
      currsorIndex: 0,

      // 当前学期选项
      nowSemester: {
        semesterId: null,
        name: null
      },
      // 学期下拉选择数据
      optSemester: [],

      // 颜色数组
      optColor: ['#7E66F6', '#3191D9', '#4FBD76', '#F9BB4A', '#F50737', '#8CB93D', '#3AAF9D', '#4261E4', '#A75CE7', '#6610f2', '#e83e8c', '#fd7e14', '#28a745', '#17a2b8'],
      // 页面显示颜色的数组
      titlePoint: [],
      // 综合分布情况统计图
      arrProportion: [],
      // 与全班对比统计图
      arrContrast: [],
      // 评语
      arrComments: [],
      // 成绩数组
      arrResults: [],
      // 荣誉
      arrHonor: [],
      // 权限管理
      isPower: {
        //增删改查
        student_report_download: false
      },
      arrAllSemester: [],
      // 时间
      nowDate: timeTransform(new Date(), 'YYYY年MM月'),
      CommentsNum: 0,
      RecordNum: 0,
      allRecord: [],
      allSemester: []
    };
  },
  watch: {
    arrProportion: {
      handler: function (newVar, oldVar) {
        let that = this;
        that.arrProportion = newVar;
        if (that.$refs.echartProportion != undefined) {
          that.$refs.echartProportion.onGetRadar(newVar);
        }
      },
      deep: true
    },
    arrContrast: {
      handler: function (newVar, oldVar) {
        let that = this;
        that.arrContrast = newVar;
        if (that.$refs.echartContrastBar != undefined) {
          that.$refs.echartContrastBar.onGetRadar(newVar);
        }
        //
      },
      deep: true
    },
    reportData: {
      handler: function (newVar, oldVar) {
        let that = this;
        that.reportData = newVar;
        if (newVar.radar != null && newVar.radar.length != 0) {
          for (const [index, item] of newVar.radar) {
            if (that.$refs['chart_' + index] != undefined) {
              that.$refs['chart_' + index].onGetRadar(newVar);
            }
          }
        }
      },
      deep: true
    },
    reportTitle: {
      handler: function (newVar, oldVar) {
        this.reportTitle = newVar;
      },
      deep: true
    },
    infoData: {
      handler: function (newVar, oldVar) {
        this.infoData = newVar;
      },
      deep: true
    }
  },
  methods: {
    onGetListAttachment(objItem) {
      let that = this;
      return objItem.map((item) => {
        return that.$ServerUrl() + item;
      });
    },
    //异步执行
    imageUrlToBase64(objItem) {
      let that = this;
      return that.$ServerUrl() + objItem;
    },

    /**
     * @param {Object} objData
     */
    onGetInfoPage(objData) {
      let that = this;
      let tempData = [];
      let tempDataTwo = [];
      if (!regNullArray(that.optSemester)) {
        that.optSemester.forEach((item, index) => {
          let iCount = 0;
          if (!regNull(objData.record.find((fd) => fd.semesterId == item.semesterId))) {
            tempData.push({
              semester: item.name,
              semesterId: item.semesterId,
              children: []
            });
            objData.record
              .filter((fl) => fl.semesterId == item.semesterId)
              .forEach((list) => {
                let tpImgList = regNull(list.attachment) ? null : list.attachment.split('|');
                let tpImgNum = regNullArray(tpImgList) ? 0 : regNullArray(tpImgList).length;
                if ((iCount >= 0 && iCount <= 4) || (iCount > 4 && iCount < 7 && tpImgNum < 4)) {
                  tempData[tempData.length - 1].children.push({
                    attachment: tpImgList,
                    configName: list.configName,
                    logDate: list.logDate,
                    name: list.name,
                    score: list.score,
                    type: list.type
                  });
                  if (regNullArray(tpImgList)) {
                    iCount += 1;
                  } else {
                    if (tpImgList.length > 0 && tpImgList.length < 4) {
                      iCount += 2;
                    } else if (tpImgList.length > 3 && tpImgList.length < 7) {
                      iCount += 3;
                    } else if (tpImgList.length > 6 && tpImgList.length < 10) {
                      iCount += 4;
                    }
                  }
                } else {
                  if (regNullArray(tpImgList)) {
                    iCount = 1;
                  } else {
                    if (tpImgList.length > 0 && tpImgList.length < 4) {
                      iCount = 2;
                    } else if (tpImgList.length > 3 && tpImgList.length < 7) {
                      iCount = 3;
                    } else if (tpImgList.length > 6 && tpImgList.length < 10) {
                      iCount = 4;
                    }
                  }
                  tempData.push({
                    semester: item.name,
                    semesterId: item.semesterId,
                    children: [
                      {
                        attachment: regNull(list.attachment) ? null : list.attachment.split('|'),
                        configName: list.configName,
                        logDate: list.logDate,
                        name: list.name,
                        score: list.score,
                        type: list.type
                      }
                    ]
                  });
                }
                //  tempData[tempData.length-1].children.push(...list);
              });
          }
        });
      }
      return tempData;
    },
    /**
     * @description 获取学校名称
     */
    onGetSchoolName() {
      return schoolName();
    },
    /**
     * @description 总评标题，根据学期，学年显示总评名称
     */
    onGetTitle() {
      let that = this;
      if (!regNull(that.nowSemester.semesterId)) {
        return '学期总评';
      } else if (!regNull(that.currsorIndex != 0)) {
        return '学年总评';
      } else {
        return '总评';
      }
    },
    /**
     * @description 对没有图片的记录进行添加样式名
     * @param {Object} objItem
     */
    onGetClass(objItem) {
      if (regNullArray(objItem)) {
        return 'noImage';
      }
      return '';
    },
    /**
     * @description 选择学年
     * @param {Object} objItem
     */
    onChangeYear(objItem, objIndex) {
      let that = this;
      that.currsorIndex = objIndex;
      that.optSemester = objItem.children;
      that.nowSemester.semesterId = null;
      that.nowSemester.name = null;
      onGetData(that);
    },
    /**
     * @description 选择学期
     * @param {Object} objVal
     */
    onChangeSemester(objVal) {
      let that = this;
      let temp = that.optSemester.find((item) => item.semesterId == objVal);
      if (!regNull(temp)) {
        that.nowSemester.name = temp.name;
        that.nowSemester.nowYear = temp.yearId;
        that.currsorIndex = [that.optYear.findIndex((item) => item.yearId == temp.yearId)];
      }
      onGetData(that);
    },
    /**
     * @description 去除HTML
     * @param {Object} objVal
     */
    onNoHtml(objVal) {
      return delHtml(objVal);
    },
    /**
     * @description 返回
     */
    onReturn() {
      let that = this;
      back(that);
    },
    /**
     * @description 是否为空值
     * @param {Object} _val
     */
    onGetIsNull(_val) {
      return regNull(_val); //true:是空值
    },
    /**
     * @description 根据性别返回 Boolen值 true:男，false：女
     * @param {Object} _val
     */
    onGetGender(_val) {
      return isGender(_val);
    },

    // 格式化时间
    onDateFormat(_val) {
      return dateFormat(_val, '.', 'YYYY-MM-DD');
    },
    // 图片处理
    onGetImg(that, _Value) {
      if (regNull(_Value)) {
        return [];
      } else {
        let arrImg = _Value.split('|');
        return arrImg.map((item) => that.$ServerUrl() + item);
      }
    },
    //去除数组内的重复
    onArrUnique(arr) {
      const res = new Map();
      return arr.filter((arr) => !res.has(arr.semesterId) && res.set(arr.semesterId, 1));
    }
  },
  created() {
    let that = this;
    // 判断传值
    that.optYear = [
      {
        yearId: null,
        name: '全部学年',
        children: []
      }
    ];
    that.fullscreenLoading = true;
    // 获取学生的学期信息
    ajaxGet(that, '/api/student/semester/student', null, function (resSemester) {
      if (!regNullArray(resSemester)) {
        // 全部学年 对应所有学期
        that.optYear[0].children = resSemester;
        // 默认所有学期
        that.optSemester = resSemester;
        // 生成各学年数据

        resSemester.forEach((item) => {
          if (regNull(that.optYear.find((list) => list.yearId == item.yearId))) {
            that.optYear.push({
              yearId: item.yearId,
              name: item.year,
              children: resSemester.filter((fl) => fl.yearId == item.yearId)
            });
          }

          if (item.isCurrent == true) {
            that.nowSemester.semesterId = item.semesterId;
            that.nowSemester.name = item.name;
            that.nowSemester.nowYear = item.yearId;
          }
        });
        if (!regNull(that.nowSemester.semesterId)) {
          that.currsorIndex = [that.optYear.findIndex((item) => item.yearId == that.nowSemester.nowYear)];
          that.optSemester = that.optYear.find((item) => item.yearId == that.nowSemester.nowYear).children;
        }

        for (let i = resSemester.length - 1; i >= 0; i--) {
          that.allSemester.push(resSemester[i]);
        }
      }
      // 获取学生基本信息
      ajaxGet(that, '/api/student/student', null, function (resData) {
        that.infoData = resData;
        that.infoData.photo = that.$ServerUrl() + resData.photo;
        that.fullscreenLoading = false;
        onGetData(that);
      });
    });
  }
};

/**
 * @description 获取数据
 * @param {Object} that
 */
function onGetData(that) {
  that.fullscreenLoading = true;
  // 设置标题
  if (!regNull(that.nowSemester.semesterId)) {
    that.reportTitle = that.nowSemester.name;
  } else if (!regNull(that.optYear[that.currsorIndex].yearId)) {
    that.reportTitle = that.optYear[that.currsorIndex].name;
  } else {
    that.reportTitle = that.infoData.entryYear + '届';
  }

  let _data = {
    StudentId: that.infoData.studentId,
    SemesterId: that.nowSemester.semesterId,
    YearId: that.optYear[that.currsorIndex].yearId
  };

  let apiUrl = '/api/student/growtharchives';
  let apiUrlTwo = '/api/student/growtharchives/classcadresearch';
  if (!regNull(that.nowSemester.semesterId)) {
    apiUrl += '?semesterId=' + that.nowSemester.semesterId;
    apiUrlTwo += '?semesterId=' + that.nowSemester.semesterId;
  } else if (!regNull(that.optYear[that.currsorIndex].yearId)) {
    apiUrl += '?yearId=' + that.optYear[that.currsorIndex].yearId;
    apiUrlTwo += '?yearId=' + that.optYear[that.currsorIndex].yearId;
  }

  // 学生职务
  ajaxGet(that, apiUrlTwo, null, function (resCadre) {
    that.infoData.cadre = '';
    if (!regNullArray(resCadre)) {
      if (!regNull(resCadre.find((item) => item.departureDate == '0001-01-01T00:00:00'))) {
        resCadre.forEach((item) => {
          if (item.departureDate == '0001-01-01T00:00:00') {
            that.infoData.cadre += item.position + '、';
          }
        });
      } else {
        let tpCadre = resCadre[0].departureDate;
        that.infoData.cadre = resCadre[0].position;
        resCadre.forEach((item) => {
          if (!compareDate(item.departureDate, tpCadre)) {
            tpCadre = item.departureDate;
            that.infoData.cadre = item.position;
          }
        });
      }
    }

    // 获取档案记录
    ajaxGet(that, apiUrl, null, function (resNumber) {
      that.infoData.Number = resNumber;
      // Activity 参与活动 | LiteracyCourse 综合课堂  | MoralityLog 行为规范 | Prize 竞赛获奖 | SelfReport 上报审核

      // 班主任评语
      ajaxGet(that, '/api/student/growth/studentcommensearch', _data, function (resComments) {
        // 学生成绩
        ajaxGet(that, '/api/student/growth/academicsearch', _data, function (resScore) {
          // 个人总评
          ajaxGet(that, '/api/student/growth/generalcomment', _data, function (resChartData) {
            // 综合分布情况
            ajaxGet(that, '/api/student/growth/chartdata', _data, function (resChart) {
              that.allRecord = [];
              // 判断是学期还是学年
              if (!regNull(that.nowSemester.semesterId)) {
                // 学期
                that.allRecord = [
                  {
                    name: that.nowSemester.name,
                    score: resScore.filter((fl) => fl.semester == that.nowSemester.name),
                    comment: resComments.filter((fl) => fl.semesterId == that.nowSemester.semesterId)
                  }
                ];
              } else if (!regNull(that.optYear[that.currsorIndex].yearId)) {
                // 学年

                that.arrResults = [];
                if (!regNullArray(resScore)) {
                  resScore.forEach((item) => {
                    if (regNull(that.arrResults.find((list) => list.semester == item.semester))) {
                      that.arrResults.push({
                        semester: item.semester,
                        children: resScore.filter((fl) => fl.semester == item.semester)
                      });
                    }
                  });
                }

                that.allRecord = [
                  {
                    name: that.optYear[that.currsorIndex].name,
                    score: that.arrResults,
                    comment: resComments
                  }
                ];
              } else {
                // 所有学年
                for (let i = that.optYear.length - 1; i >= 0; i--) {
                  if (that.optYear[i].yearId != null) {
                    let scoreData = resScore.filter((flTwo) => flTwo.yearId == that.optYear[i].yearId);
                    that.arrResults = [];
                    if (!regNullArray(scoreData)) {
                      scoreData.forEach((itemTwo) => {
                        if (regNull(that.arrResults.find((list) => list.semester == itemTwo.semester))) {
                          that.arrResults.push({
                            semester: itemTwo.semester,
                            children: scoreData.filter((fl) => fl.semester == itemTwo.semester)
                          });
                        }
                      });
                    }
                    that.allRecord.push({
                      name: that.optYear[i].name,
                      score: that.arrResults,
                      comment: resComments.filter((flTwo) => flTwo.yearId == that.optYear[i].yearId)
                    });
                  }
                }
              }

              // 个人总评
              that.infoData.score = resChartData.score; // 总分
              that.infoData.rating = regNull(resChartData.generalComment) ? '暂未评价' : resChartData.generalComment; // 总评
              that.infoData.record = resChartData.logQuantity; // 综合记录数

              // 处理综合评价数据
              if (!regNullArray(resChart)) {
                // 与全班中位分对比的统计图需要的数据
                that.arrContrast = resChart;
                // 设置需要统计图的颜色数量
                that.titlePoint = that.optColor.slice(0, resChart.length);
                // 生成综合分布情况统计图
                that.arrProportion = [];
                that.reportData = [];
                resChart.forEach((item, index) => {
                  // 综合分布
                  that.arrProportion.push({
                    name: item.name,
                    value: item.score + item.baseScore
                  });
                  _data.ConfigId = item.configId;

                  let tpUpData = {
                    StudentId: that.infoData.studentId,
                    SemesterId: that.nowSemester.semesterId,
                    YearId: that.optYear[that.currsorIndex].yearId,
                    ConfigId: item.configId
                  };
                  that.fullscreenLoading = true;
                  // 获取学生综合评价评价雷达图数据
                  ajaxGet(that, '/api/student/growth/chartdata', tpUpData, function (resSub) {
                    // 根据条件获取综合评价记录
                    ajaxGet(that, '/api/student/growth/searchnopage', tpUpData, function (resList) {
                      that.reportData.push({
                        configId: item.configId,
                        name: item.name,
                        median: item.median,
                        quantity: item.quantity,
                        score: item.score,
                        colorVal: that.optColor[index],
                        radar: resSub, // 雷达图
                        record: resList, //
                        arrSemester: onArrUnique(
                          resList.map((mp) => ({
                            value: mp.semesterId,
                            label: mp.semester
                          }))
                        )
                      });
                      that.fullscreenLoading = false;
                    });
                  });
                });
              } else {
                that.fullscreenLoading = false;
              }
            });
          });
        });
      });
    });
  });
}
</script>

<style scoped="scoped" lang="less">
.A4CardBox {
  background-color: #ffffff;
  width: 210mm;
  height: 297mm;
  margin: 50px auto;
  box-shadow: 0px 0px 20px 0px rgba(157, 157, 157, 0.75);
  -webkit-box-shadow: 0px 0px 20px 0px rgba(157, 157, 157, 0.75);
  -moz-box-shadow: 0px 0px 20px 0px rgba(157, 157, 157, 0.75);
  position: relative;
}

// 页头
.pageHeader {
  .divLogo {
    padding-left: 60px;
    padding-top: 50px;
  }

  .divImgBox {
    position: absolute;
    top: 0;
    right: 0;

    .num1 {
      position: absolute;
      top: 90px;
      left: 90px;
      width: 100px;
      height: 100px;

      .bgColor {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #eda317;
        border-radius: 50%;
        opacity: 0.3;
      }

      .fontBox {
        position: relative;
        z-index: 10;
        background-color: #eda317;
        border-radius: 50%;
        width: 85px;
        height: 85px;
        color: #ffffff;
        font-size: 24px;
        line-height: 26px;
      }
    }

    .num2 {
      position: absolute;
      top: 240px;
      left: 90px;
      width: 100px;
      height: 100px;

      .bgColor {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #37a0ee;
        border-radius: 50%;
        opacity: 0.3;
      }

      .fontBox {
        position: relative;
        z-index: 10;
        background-color: #37a0ee;
        border-radius: 50%;
        width: 85px;
        height: 85px;
        color: #ffffff;
        font-size: 24px;
        line-height: 26px;
      }
    }

    .num3 {
      position: absolute;
      top: 290px;
      left: 290px;
      width: 100px;
      height: 100px;

      .bgColor {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #da524d;
        border-radius: 50%;
        opacity: 0.3;
      }

      .fontBox {
        position: relative;
        z-index: 10;
        background-color: #da524d;
        border-radius: 50%;
        width: 85px;
        height: 85px;
        color: #ffffff;
        font-size: 24px;
        line-height: 26px;
      }
    }
  }

  .divTitleBox {
    position: absolute;
    bottom: 140px;
    left: 0;
    right: 0;
    background-color: #37a1ed;
    width: 100%;

    .divTitle {
      margin-top: 5px;
      border-top: 2px solid #ffffff;
    }

    .divNameBox {
      color: #ffffff;
      width: 100%;
      text-align: right;
      font-size: 52px;
      padding-right: 40px;
      padding-top: 20px;
      font-weight: bold;
    }

    .divSubName {
      color: #ffffff;
      width: 100%;
      text-align: right;
      padding-right: 40px;
      font-size: 32px;
      padding-bottom: 20px;
    }
  }

  .divSchoolName {
    position: absolute;
    bottom: 40px;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 18px;
  }
}

// 页尾
.pageFooter {
  background-color: #00a1e9;
  position: relative;

  .divFontBox {
    position: relative;
    color: #ffffff;
    z-index: 10;

    width: 100%;
    height: 100%;

    .leftFont {
      width: 150px;
      text-align: center;
      font-family: 'Mianfeiziti';
      font-size: 152px;
      margin: 0 auto;
      padding-top: 50%;
      padding-left: 25%;
    }

    .rightFont {
      padding-top: 100%;
      padding-right: 25%;
      margin: 0 auto;
      width: 150px;
      text-align: center;
      font-family: 'Mianfeiziti';
      font-size: 152px;
    }
  }

  .divFontBoxTwo {
    color: #ffffff;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 30px;
    z-index: 99;
    text-align: center;
    font-size: 12px;
  }

  .bgImgBox {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .footerBox {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
  }
}

// 通用页面
.pageCont {
  padding: 40px 30px;

  .InfoTitleCont {
    letter-spacing: 1px;
    color: #212529;
    position: relative;
    font-size: 24px;

    .icon {
      font-size: 28px;
    }

    .enBox {
      text-transform: uppercase;

      .spFont {
        font-size: 18px;
        color: #212529;

        &:first-child {
          padding-right: 10px;
          color: #212529;
        }
      }
    }
  }
}

// 第1页
.pageBoxOne {
  .baseInfoCont {
    position: relative;
    padding-top: 50px;
    padding-left: 30px;
    padding-bottom: 50px;

    .stuName {
      font-size: 36px;
      letter-spacing: 1px;
      color: #3698fc;
      margin-bottom: 15px;
    }

    .stuLabelCont {
      margin-bottom: 15px;

      .spItem {
        border-right: 2px solid #e3e3e3;
        color: #323e56;
        font-size: 18px;
        padding: 0px 20px;
        letter-spacing: 1px;
        color: #606266;

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          border-right: none;
        }
      }
    }

    .stuAvatarCont {
      position: absolute;
      top: 20px;
      bottom: 0;
      right: 30px;

      .imgAvatar {
        width: 120px;
        border-radius: 4px;
        border: 1px solid #eeeeee;
      }
    }

    .spGender {
      position: absolute;
      top: -15px;
      right: -15px;
      color: #ffffff;
      font-size: 18px;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      text-align: center;
      line-height: 30px;

      .icon {
        font-size: 22px;
      }
    }
  }

  .literacyFractionCont {
    text-align: center;
    margin-top: 40px;

    .fractionItem {
      display: inline-block;
      color: #256fbc;
      position: relative;
    }

    .divTotalScore {
      font-family: 'OswaldLight';
      font-size: 82px;
      letter-spacing: 2px;

      .spUnit {
        font-size: 32px;
        color: #8d8f91;
        margin-left: -15px;
      }
    }

    .divEvaluation {
      font-size: 62px;
      font-weight: bold;
      letter-spacing: 2px;
      margin-left: 50px;
      margin-right: 50px;

      .spTopLabel {
        color: #959595;
        letter-spacing: 1px;
        font-weight: normal;
        position: absolute;
        top: -20px;
        left: 0;
        font-size: 22px;
      }
    }

    .divTotal {
      font-family: 'OswaldLight';
      font-size: 62px;
      letter-spacing: 2px;
      min-width: 100px;
      text-align: center;

      .spTopLabel {
        color: #959595;
        letter-spacing: 1px;
        font-weight: normal;
        position: absolute;
        top: -15px;
        left: 0;
        font-size: 22px;
      }

      .spUnit {
        font-size: 26px;
        color: #8d8f91;
        margin-left: 5px;
      }
    }
  }

  .oneTitle {
    margin-top: 50px;
  }

  .twoTitle {
    margin-top: 70px;
  }

  .divFileRecords {
    padding-top: 35px;

    .fileList {
      width: 140px;
      height: 140px;
      margin: 25px auto;
      border-radius: 50%;
      background-image: linear-gradient(to right bottom, #256fbc, #39afd6);

      .fileCont {
        background-color: #ffffff;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.5);
        -webkit-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.5);
        -moz-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.5);

        .topCont {
          margin-top: 10px;
          height: 40px;
          font-family: 'OswaldLight';
          color: #256fbc;
          font-size: 18px;
          font-weight: bold;
        }

        .bottomCont {
          height: 40px;
          margin-top: 10px;
          width: 80px;
          padding-left: 5px;
          padding-right: 5px;
          border-top: 1px solid #eeeeee;
          margin: 0 auto;
          font-size: 12px;
          color: #606166;
        }
      }
    }
  }
}

// 第2页
.pageBoxTwo {
  .InfoSubTitle {
    text-align: center;
    font-size: 32px;
    letter-spacing: 1px;
    color: #323e56;

    .enBox {
      text-transform: uppercase;
    }

    .divIconCont {
      text-align: center;
    }
  }

  .InfoSubTitle span {
    font-size: 26px;
    padding: 0px 2px;
  }
}

.pageBoxHeader {
  padding-top: 100px;

  .titleBox {
    padding-bottom: 50px;
    text-align: center;
  }

  .chartBox {
    padding-top: 50px;
  }

  .literacyFractionCont {
    text-align: center;
    margin-top: 60px;
    display: flex;

    .fractionItem {
      color: #3698fc;
      position: relative;
      width: 100%;
    }

    .divTotal {
      font-family: 'OswaldLight';
      font-size: 82px;
      font-weight: bold;
      letter-spacing: 2px;
      min-width: 100px;
      text-align: center;

      .spTopLabel {
        color: #959595;
        letter-spacing: 1px;
        font-weight: normal;
        font-size: 22px;
      }

      .spUnit {
        font-size: 22px;
        color: #8d8f91;
        margin-left: 5px;
        font-weight: normal;
      }
    }
  }
}

// 综合之项
.pageBoxSub {
  padding-top: 50px;

  .titleBox {
    padding-bottom: 0px;
    text-align: center;
  }

  .divDataListCont {
    position: relative;
    padding-top: 20px;

    .divContTitle {
      display: flex;
      margin-bottom: 30px;

      .spTitleCont {
        background-color: #0065b3;
        color: #ffffff;
        padding: 10px 20px;
        letter-spacing: 1px;
        border-radius: 5px;
      }
    }

    .divRecordBox {
      padding-top: 20px;
      padding-bottom: 20px;
      position: relative;
      padding-left: 15px;
      padding-right: 15px;
      border-bottom: 1px dotted #eeeeee;

      &:last-child {
        border-bottom: none;
      }

      .divContBox {
        width: 100%;
        display: flex;

        .divScore {
          height: 65px;
          width: 120px;
          border-radius: 5px;
          font-family: 'OswaldLight';
          overflow: hidden;
          font-style: italic;
          font-size: 76px;
          font-weight: bolder;
          line-height: 60px;
          color: #909199;
          opacity: 0.8;
          position: relative;

          .spFont {
            font-size: 14px;
            bottom: 0;
            line-height: 18px;
            right: 10px;
          }
        }

        .divHeader {
          position: relative;

          .divSub {
            display: flex;

            .spTime {
              color: #606266;
              border: 1px dotted #606266;
              font-size: 12px;
              height: 28px;
              line-height: 28px;
              padding-left: 8px;
              padding-right: 8px;
              border-radius: 5px;
            }

            .spType {
              margin-left: 15px;
              background-color: #37a0ee;
              color: #ffffff;
              font-size: 12px;
              height: 28px;
              line-height: 28px;
              padding-left: 10px;
              padding-right: 10px;
              border-radius: 3px;
              opacity: 0.8;
            }
          }

          .divName {
            width: 100%;
            padding-top: 5px;
            color: #303133;
          }
        }
      }

      .divImgContBox {
        padding-top: 5px;
        padding-left: 120px;

        .imgList {
          height: 110px;
          width: 100%;
          border-radius: 5px;
          border: 1px solid #dddddd;
          padding: 5px;
        }
      }
    }

    .noImage {
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }
}

// 成绩单
.pageBoxScore {
  padding-top: 130px;

  .titleBox {
    padding-bottom: 20px;
  }

  .divDataListCont {
    position: relative;
    padding-bottom: 50px;
    padding-top: 50px;

    .divContTitle {
      display: flex;

      .spTitleCont {
        background-color: #0065b3;
        color: #ffffff;
        padding: 10px 20px;
        letter-spacing: 1px;
        border-radius: 5px;
      }
    }
  }

  .divScoreCont {
    padding-top: 15px;
    position: relative;
  }

  .divScoreContTwo {
    display: flex;

    .divItem {
      width: 50%;
      padding-right: 10px;
    }
  }
}

// 班主任评语
.pageBoxComments {
  padding-top: 140px;

  .titleBox {
    padding-bottom: 70px;
  }

  .divDataListCont {
    position: relative;
    padding-bottom: 50px;
    padding-top: 50px;

    .divContTitle {
      display: flex;

      .spTitleCont {
        background-color: #0065b3;
        color: #ffffff;
        padding: 10px 20px;
        letter-spacing: 1px;
        border-radius: 5px;
      }

      .spTitleContTwo {
        border: 2px solid #0065b3;
        color: #0065b3;
        font-weight: bold;
        padding: 10px 20px;
        letter-spacing: 1px;
        border-radius: 5px;
      }

      .spCommentsRank {
        padding: 5px 20px;
        letter-spacing: 1px;
        font-size: 22px;
        font-weight: bold;
      }
    }
  }

  .divCommentContent {
    padding-top: 15px;
    padding-left: 30px;
    padding-right: 30px;
    position: relative;

    .divRank {
      position: absolute;
      right: 100px;
      color: #2490ec;
      bottom: 40px;
      top: 0px;
      font-size: 82px;
      font-weight: bolder;
      transform: rotate(9deg);
      -ms-transform: rotate(9deg);
      /* Internet Explorer */
      -moz-transform: rotate(9deg);
      /* Firefox */
      -webkit-transform: rotate(9deg);
      /* Safari 和 Chrome */
      -o-transform: rotate(9deg);
      /* Opera */
      opacity: 0.3;
    }

    .divCommFont {
      position: relative;
      z-index: 10;
      text-indent: 2em;
      letter-spacing: 1px;
      color: #606266;
    }

    .divOhter {
      position: relative;
      z-index: 10;
      text-align: right;
      font-size: 14px;
      color: #6d7482;

      &:first-child {
        padding-top: 30px;
      }
    }
  }
}

.position-relative {
  position: relative;
}

.divPageHeaderBox {
  display: flex;
  padding-bottom: 5px;

  .ulTabsBox {
    flex: 1;
    display: flex;
    padding-top: 10px;
  }

  .liTab {
    padding-left: 15px;
    padding-right: 15px;
    font-size: 18px;
    border-right: 2px solid #cecece;
    color: #767676;
    cursor: pointer;

    &:hover {
      color: #3e3e3c;
    }

    &:last-child {
      border-right: none;
    }
  }

  .divSemesterBox {
    display: flex;

    .divTitle {
      height: 40px;
      line-height: 40px;
      letter-spacing: 2px;
      color: #323e56;
      padding-right: 10px;
    }
  }
}

.divPageHeaderBox .liAction:hover,
.divPageHeaderBox .liAction {
  color: #3364ff;
}

.elImageBox {
  border: 1px solid #dddddd;
  border-radius: 5px;
  width: 33px;
  height: 33px;
  margin: 0;
  position: relative;
}

.divNoImage {
  font-size: 12px;
  color: #767676;
}

.divYearTitle {
  font-size: 18px;
  margin-bottom: 30px;
}

.divYearTitle span {
  background-color: #c2e5fe;
  padding: 10px 30px;
}

.divSearchBox {
  position: absolute;
  top: -70px;
  left: 50px;
  z-index: 10;
  display: flex;
}

.divSearchBox .spSearch {
  padding-left: 10px;
  padding-right: 10px;
}

.DetailsCont {
  padding-right: 30px;
  padding-left: 30px;
  max-width: 1280px;
  margin: 5px auto;
}

.ReportName {
  padding-top: 15px;
  text-align: center;
  color: #323e56;
}

.ReportName .spMiddot {
  width: 30px;
  height: 30px;
  position: relative;
  display: inline-block;
}

.ReportName .spMiddot .spIcon {
  font-size: 5px;
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  text-align: center;
}

.ReportName .spNameCont {
  width: auto;
  font-size: 36px;
  letter-spacing: 1px;
  position: relative;
}

.ReportName .spNameCont .spTitleBg {
  position: absolute;
  height: 10px;
  left: 0;
  right: 0;
  bottom: -15px;
  /* background-image: url(../../../assets/image/line_bg.png); */
  background-repeat: repeat-x;
}

.ReportName .titleSubCont {
  padding-top: 20px;
}

.ReportName .titleSubCont span {
  font-size: 18px;
  padding: 0px 2px;
}

.LiteracyDistribution {
  margin: 30px auto;
  position: relative;
}

.LiteracyDistribution .labelCont {
  margin: 0px auto;
  color: #939393;
}

.LiteracyDistribution .labelCont .spIcon {
}

.LiteracyDistribution .divLabelCont {
  position: absolute;
  top: 197px;
  left: 0;
  right: 0;
  text-align: center;
}

.LiteracyDistribution .divLabelCont .spTotal {
  font-size: 52px;
  font-family: 'OswaldLight';
  display: block;
  letter-spacing: 1px;
  color: #323e56;
}

.LiteracyDistribution .divLabelCont .spUnit {
  font-size: 16px;
  color: #4b586f;
}

.LiteracyDistribution .divLabelCont .spFont {
  font-size: 18px;
  color: #4b586f;
}

.chartItemCont {
  position: relative;
}

.chartItemCont .divLabelCont {
  position: absolute;
  top: -68px;
  right: 0;
  color: #939393;
  font-size: 18px;
}

.chartItemCont .divItemInfo {
  position: absolute;
  left: 0;
  top: 50px;
}

.chartItemCont .divItemInfo .divInfoType {
  height: 80px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #efefef;
  border-radius: 5px;
  color: #ffffff;
  font-size: 42px;
}

.chartItemCont .divItemInfo .divItemNum {
  font-family: 'OswaldLight';
  font-size: 52px;
  letter-spacing: 2px;
  position: relative;
  margin-top: 50px;
}

.chartItemCont .divItemInfo .spUnit {
  color: #323e56;
  font-size: 26px;
  padding-left: 5px;
}

.divDataListCont .tbTitleCont {
  position: absolute;
  background-color: #c2e5fe;
  color: #323e56;
  padding: 10px 20px;
  letter-spacing: 1px;
  top: -44px;
  left: 0;
}

.divDataListCont .tbData {
}

.divDataListCont .tbData th {
  color: #797f8c;
}

.divDataListCont .tbData td {
  vertical-align: middle;
  color: #585858;
}

.divDataListCont .tbData td .tdImg {
  width: 80px;
  height: 80px;
  border-radius: 4px;
}

.divGradesCompared {
  width: 100%;
  margin: 30px 0px;
}

.divQRCodeCont {
  text-align: center;
}

.divQRCodeCont img {
  width: 150px;
}

.divQRCodeCont .spFont {
  display: block;
  color: #323e56;
  font-size: 18px;
  letter-spacing: 2px;
}

.pt-6 {
  padding-top: 6rem;
}

.mt-6 {
  margin-top: 6rem;
}

.mt-7 {
  margin-top: 7rem;
}

.mt-8 {
  margin-top: 8rem;
}

.mt-9 {
  margin-top: 9rem;
}

.mt-10 {
  margin-top: 10rem;
}

.mt-13 {
  margin-top: 13rem;
}

.cascaderCont {
  width: 250px;
}
</style>

<style>
.pageBoxScore .tdCont {
  padding-top: 15px;
  padding-bottom: 15px;
  font-family: 'OswaldLight';
}

.pageBoxScore .tdHeader_0 th {
  background-color: #f4f7fb !important;
}

.pageBoxScore .tdHeader_1 th {
  background-color: #fdf6f6 !important;
}

.elImageBox .image-slot {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
