<!--
  新增家校联系
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-form :model="ruleForm" ref="ruleForm" label-width="120px">
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            查看
          </h3>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-lg-3 colInfo">
              <label>学期</label>
              <div class="colFonts">{{ruleForm.semester | flNoValue}}</div>
            </div>
            <div class="col-lg-3 colInfo">
              <label>班级</label>
              <div class="colFonts">{{ruleForm.class | flNoValue}}</div>
            </div>
            <div class="col-lg-3 colInfo">
              <label>学生</label>
              <div class="colFonts">{{ruleForm.name | flNoValue}}</div>
            </div>
            <div class="col-lg-3 colInfo">
              <label>等级</label>
              <div class="colFonts">{{ruleForm.commentsRankString | flNoValue}}</div>
            </div>
            <div class="col-lg-3 colInfo">
              <label>登记时间</label>
              <div class="colFonts">{{ruleForm.recordDate | dateformat("YYYY-MM-DD hh:mm")}}</div>
            </div>
          </div>
        </div>
      </el-card>

      <div class="box-card">
        <el-tabs type="border-card">
          <el-tab-pane label="学生成绩">
            <el-table :data="itemData.academicRecords" stripe class="elTableData">
              <el-table-column prop="courseName" label="课程" class="elColumn"></el-table-column>
              <el-table-column prop="peaceTime" label="平时" class="elColumn"></el-table-column>
              <el-table-column prop="midTerm" label="期中" class="elColumn"></el-table-column>
              <el-table-column prop="final" label="期末" class="elColumn"></el-table-column>
              <el-table-column prop="total" label="总评" class="elColumn"></el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="处分记录">
            <el-table :data="itemData.punishes" stripe class="elTableData">
              <el-table-column prop="punishNo" label="处分号">
                <template slot-scope="scope">
                  <span>{{scope.row.punishNo}}</span>
                </template>
              </el-table-column>
              <el-table-column prop="reasonName" label="处分原因">
                <template slot-scope="scope">
                  <span>{{scope.row.reasonName}}</span>
                </template>
              </el-table-column>
              <el-table-column prop="level" label="处分类型">
                <template slot-scope="scope">
                  <span v-if="scope.row.level!=null">{{scope.row.level.name}}</span>
                </template>
              </el-table-column>
              <el-table-column label="处分时间">
                <template slot-scope="scope">
                  <span>{{scope.row.punishDate | dateformat("YYYY-MM-DD")}}</span>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="获奖情况">
            <el-table :data="itemData.prizes" stripe class="elTableData">
              <el-table-column prop="name" label="奖项">
                <template slot-scope="scope">
                  <span>{{scope.row.name}}</span>
                </template>
              </el-table-column>
              <el-table-column prop="level" label="奖励级别">
                <template slot-scope="scope">
                  <span v-if="scope.row.level!=null">{{scope.row.level.name}}</span>
                </template>
              </el-table-column>
              <el-table-column label="奖励时间">
                <template slot-scope="scope">
                  <span>{{scope.row.prizeDate | dateformat("YYYY-MM-DD")}}</span>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
      </div>


      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            评语
          </h3>
        </div>
        <div class="card-body">
          <div class="colRemarksCont" v-html="ruleForm.description"></div>
        </div>
      </el-card>

      <div class="row">
        <div class="col-12">
          <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
  import '@/assets/css/Admin.css';


  export default {
    components: {

    },
    data() {
      return {
        fullscreenLoading: false,
        itemData: {},
        ruleForm: {},
      };
    },
    created() {
      let that = this;
      let id = that.$route.params.Id;
      that.ruleForm.commentId = id;
      that.fullscreenLoading = true;
      ajaxGet(that, "/api/admin/studentcomment/" + id, null, function(res) {
        ajaxGet(that, "/api/admin/studentcomment/studentinfo?studentId=" + res.studentId + "&semesterId=" + res
          .semesterId, null,
          function(r1) {
            that.fullscreenLoading = false;
            that.itemData = r1;
            that.ruleForm = res;

            that.ruleForm.commentsRank = res.commentsRankString;
            that.ruleForm.recordDate = timeTransform(that.ruleForm.recordDate);
          })
      })
      that.fullscreenLoading = true;
    },

    mounted() {},
    methods: {
      onBack() {
        let that = this;
        back(that);
      },
    },
    watch: {

    },
    filters: {
      flNoValue(obj) {
        if (obj == null || typeof(obj) == "undefined" || obj == '') {
          return '暂无数据';
        }
        return obj;
      }
    },
  };
</script>

<style scoped="scoped">

</style>
