<!--行为规范-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <search :key="random" :SearchTitles="SearchTitles" :ColNumber="3" :SearchList="SearchList" ref="SearchList" @onSearch="onSearch" v-if="SearchList != null"></search>

    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title">
          评价中心
          <span class="titleErr" v-if="currentType == 1 || currentType == 2">（记录由个人关联而来，无法申诉处理、删除）</span>
        </h3>
        <div class="card-tools">
          <ul class="nav">
            <li class="nav-item" style="margin-right: 5px">
              <el-button
                v-if="
                  (isPower.school_record_classlog_create && currentType == 1) ||
                  (isPower.school_record_log_create && currentType == 3) ||
                  (isPower.school_record_dormitorylog_create && currentType == 2)
                "
                type="success"
                icon="el-icon-circle-plus-outline"
                @click="onAdd()"
              >
                新增
              </el-button>
            </li>
            <li class="nav-item" style="margin-right: 5px" v-if="currentType == 1 && isPower.school_record_classlog_export">
              <el-button @click="onGetExport()" icon="el-icon-download" style="background-color: #17a2b8; color: white">导出</el-button>
            </li>
            <li class="nav-item" style="margin-right: 5px" v-if="currentType == 2 && isPower.school_record_dormitorylog_export">
              <el-button @click="onGetExport()" icon="el-icon-download" style="background-color: #17a2b8; color: white">导出</el-button>
            </li>
            <li class="nav-item" style="margin-right: 5px" v-if="currentType == 3 && isPower.school_record_log_export">
              <el-button @click="onGetExport()" icon="el-icon-download" style="background-color: #17a2b8; color: white">导出</el-button>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body p-0" v-if="item != null && item.length != 0" :key="random">
        <el-table stripe :data="item" class="elTableData" border>
          <el-table-column type="index" label="序号" class="elColumn" width="80" :index="indexMethod" align="center"></el-table-column>

          <el-table-column prop="class" min-width="100" label="班级" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.related == true" style="color: #409eff; font-size: 16px">关联</span>
              <span v-if="scope.row.transformStatus == 2" style="color: #409eff">已落实</span>
              {{ scope.row.class ? scope.row.class : scope.row.className }}
            </template>
          </el-table-column>
          <el-table-column prop="room" label="寝室" min-width="85" align="center"></el-table-column>
          <el-table-column prop="student" label="责任人" min-width="80" align="center">
            <template slot-scope="scope">
              {{ scope.row.student ? scope.row.student : scope.row.responsible }}
            </template>
          </el-table-column>
          <el-table-column prop="reason" label="原因" min-width="200" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.appealStatus == 1" style="color: #409eff">申诉中</span>
              <span v-if="scope.row.appealStatus == 2" style="color: #67c23a">申诉成功</span>
              <span v-if="scope.row.appealStatus == 3" style="color: #f56c6c">申诉驳回</span>
              <span v-if="scope.row.appealStatus == 4" style="color: #909399">申诉过期</span>
              <span v-html="onGetReason(scope.row)"></span>
            </template>
          </el-table-column>
          <el-table-column prop="remark" min-width="120" label="备注" align="center"></el-table-column>
          <el-table-column prop="score" label="分值" min-width="60" align="center">
            <template slot-scope="scope">
              <div v-html="scope.row.score"></div>
            </template>
          </el-table-column>
          <el-table-column prop="logDate" label="检查时间" min-width="150" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.logDate | dateformatMinute }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="attachment" label="附件" min-width="90" align="center">
            <template slot-scope="scope">
              <span @click="onPreview(scope.row.attachment)" v-if="scope.row.attachment" class="color-primary">点击查看</span>

              <span v-else>无</span>
            </template>
          </el-table-column>
          <el-table-column prop="createdBy" label="记录人" min-width="80" align="center" v-if="isPower.school_recordperson_details">
            <template slot-scope="scope">
              <span>{{ scope.row.createdBy }}</span>
            </template>
          </el-table-column>
          <!-- 班级操作 -->
          <el-table-column label="操作" :width="200" fixed="right" align="center" v-if="currentType == 1">
            <template slot-scope="scope">
              <!-- <div class="t-r"> -->
              <operationBtn v-if="isPower.school_record_classlog_details" :btnName="'查看'" @click.native="onDetail(scope.row.logId)"></operationBtn>
              <el-dropdown v-if="scope.row.isApprove || scope.row.isDelete" trigger="click" @command="handleCommand($event, scope.row)" class="ml-1">
                <el-button size="mini">
                  更多操作
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-if="scope.row.isApprove" class="color-primary" command="onApprove">申诉处理</el-dropdown-item>
                  <el-dropdown-item v-if="scope.row.isDelete" class="color-danger" command="onDelete">删除</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <!-- </div> -->
            </template>
          </el-table-column>
          <!-- 寝室 -->
          <el-table-column label="操作" :width="200" fixed="right" align="center" v-if="currentType == 2">
            <template slot-scope="scope">
              <!-- <div class="t-r"> -->
              <operationBtn v-if="isPower.school_record_dormitorylog_details" :btnName="'查看'" @click.native="onDetail(scope.row.logId)"></operationBtn>
              <el-dropdown v-if="scope.row.isDelete" trigger="click" @command="handleCommand($event, scope.row)" class="ml-1">
                <el-button size="mini">
                  更多操作
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-if="scope.row.isDelete" class="color-danger" command="onDelete">删除</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <!-- </div> -->
            </template>
          </el-table-column>
          <!-- 班级个人操作 -->
          <el-table-column label="操作" :width="200" fixed="right" align="center" v-if="currentType == 3">
            <template slot-scope="scope">
              <!-- <div class="t-r"> -->
              <operationBtn v-if="isPower.school_record_log_details" :btnName="'查看'" @click.native="onDetail(scope.row.logId)"></operationBtn>
              <el-dropdown v-if="scope.row.isApprove || scope.row.isDelete" trigger="click" @command="handleCommand($event, scope.row)" class="ml-1">
                <el-button size="mini">
                  更多操作
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-if="scope.row.isApprove" class="color-primary" command="onApprove">申诉处理</el-dropdown-item>
                  <el-dropdown-item v-if="scope.row.isDelete" class="color-danger" command="onDelete">删除</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <!-- </div> -->
            </template>
          </el-table-column>
        </el-table>
        <comPage :paging="page" :pageBtnList="pageBtnList" @pagingClick="pagingClick" @pageBtnClick="pageBtnClick"></comPage>
      </div>

      <div class="card-body" v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>

    <!-- 申诉处理 -->
    <el-dialog
      :title="'申诉处理'"
      :visible.sync="dialogVisibleApprove"
      width="30%"
      :before-close="handleCloseApprove"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :append-to-body="true"
      :fullscreen="false"
    >
      <el-form ref="ruleFormApprove" :model="ruleFormApprove" :rules="rulesApprove">
        <el-row :gutter="20">
          <el-col :span="24" class="colInfo">
            <label>申诉理由</label>
            <div class="colFonts">{{ regNull(ruleFormApprove.appealReason) ? '暂无数据' : ruleFormApprove.appealReason }}</div>
          </el-col>
        </el-row>
        <el-form-item label="处理意见" prop="approveReason">
          <el-input type="textarea" v-model="ruleFormApprove.approveReason" :rows="5" placeholder="请输入处理意见"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleApprove = false">取 消</el-button>
        <el-button type="danger" @click="onUpdateApprove('Reject')">驳 回</el-button>
        <el-button type="primary" @click="onUpdateApprove('Pass')">通 过</el-button>
      </span>
    </el-dialog>
    <!--配合走马灯效果放大图片组件-->
    <el-image-viewer v-if="showViewer" :on-close="closeViewer" :url-list="bigImgList" />
  </div>
</template>

<script>
import '@/assets/css/Admin.css';
import comPage from '@/components/Page.vue';
import operationBtn from '@/components/operationBtn.vue';
import search from '@/components/search.vue';
import ElImageViewer from 'element-ui/packages/image/src/image-viewer';
export default {
  components: {
    comPage,
    operationBtn,
    search,
    ElImageViewer
  },
  data() {
    return {
      showViewer: false,
      bigImgList: [],
      random: 0, //刷新组件
      SearchTitles: [],
      SearchList: null, //搜索集合
      currentType: null, //当前类型
      fullscreenLoading: false,
      page: null, //分页查询接口返回的结果
      item: [], //列表数据
      pageBtnList: null, //分页左侧按钮集合
      paging: {
        //分页数据
        pageLength: 0, // 总共页数
        Size: 10, // 当前请求数量
        Index: 1 //当前在第几页
      },
      dialogVisibleApprove: false,

      // 申诉处理
      ruleFormApprove: {
        logId: null,
        memberId: null,
        approveStatus: null,
        approveReason: null,
        appealReason: null
      },
      rulesApprove: {
        approveReason: [{ max: 256, message: '处理意见不能超过256个字符', trigger: 'change' }]
      },
      ruleFormApproveResert: null,
      optAppealStatus: [],
      optAll: [
        {
          Value: null,
          Title: '全部'
        },
        {
          Value: 'true',
          Title: '已落实'
        },
        {
          Value: 'false',
          Title: '未落实'
        }
      ],
      isPower: {
        // 班级权限
        school_record_classlog: false,
        school_record_classlog_details: false,
        school_record_classlog_create: false,
        school_record_classlog_delete: false,
        school_record_classlog_approve: false,
        school_record_classlog_export: false,
        // 班级个人权限
        school_record_log: false,
        school_record_log_details: false,
        school_record_log_create: false,
        school_record_log_delete: false,
        school_record_log_approve: false,
        school_record_log_export: false,
        // 寝室权限
        school_record_dormitorylog: false,
        school_record_dormitorylog_details: false,
        school_record_dormitorylog_create: false,
        school_record_dormitorylog_delete: false,
        school_record_dormitorylog_approve: false,
        school_record_dormitorylog_export: false,

        school_recordperson_details: false,
        //删除所有记录
        school_all_delete: false,
        //处理所有申诉
        school_all_processing_complaints: false
      },
      LogDateStart: null,
      LogDateEnd: null,
      url: null,

      uploadSearch: {},
      uploadApi: null,
      uploadNmae: null,
      // 教师信息
      TeacherInfo: {},

      roleInfo: {}
    };
  },
  created() {
    let that = this;
    that.TeacherInfo = onGetNowUser(that);
    that.roleInfo = onGetNowRole(that);

    that.ruleFormApproveResert = JSON.parse(JSON.stringify(that.ruleFormApprove));

    isPower(that, 'school_record_classlog', 'school_record_classlog');
    isPower(that, 'school_record_classlog_details', 'school_record_classlog_details');
    isPower(that, 'school_record_classlog_create', 'school_record_classlog_create');
    isPower(that, 'school_record_classlog_delete', 'school_record_classlog_delete');
    isPower(that, 'school_record_classlog_approve', 'school_record_classlog_approve');
    isPower(that, 'school_record_classlog_export', 'school_record_classlog_export');

    isPower(that, 'school_record_log', 'school_record_log');
    isPower(that, 'school_record_log_details', 'school_record_log_details');
    isPower(that, 'school_record_log_create', 'school_record_log_create');
    isPower(that, 'school_record_log_delete', 'school_record_log_delete');
    isPower(that, 'school_record_log_approve', 'school_record_log_approve');
    isPower(that, 'school_record_log_export', 'school_record_log_export');
    isPower(that, 'school_record_dormitorylog', 'school_record_dormitorylog');
    isPower(that, 'school_record_dormitorylog_details', 'school_record_dormitorylog_details');
    isPower(that, 'school_record_dormitorylog_create', 'school_record_dormitorylog_create');
    isPower(that, 'school_record_dormitorylog_delete', 'school_record_dormitorylog_delete');
    isPower(that, 'school_record_dormitorylog_approve', 'school_record_dormitorylog_approve');
    isPower(that, 'school_record_dormitorylog_export', 'school_record_dormitorylog_export');
    isPower(that, 'school_recordperson_details', 'school_recordperson_details');
    isPower(that, 'school_all_delete', 'school_all_delete');
    isPower(that, 'school_all_processing_complaints', 'school_all_processing_complaints');

    ajaxGet(that, '/api/dict/appealstatus', null, (r) => {
      if (!regNullArray(r)) {
        r.forEach((element) => {
          that.optAppealStatus.push({
            Value: element.Value,
            Title: element.Text
          });
        });
      }
      Init(that);
    });
  },
  methods: {
    /**
     * @description 打开放大图片
     */
    onPreview(str) {
      if (typeof str == 'string') {
        var files = str.split('|');
        for (var i in files) {
          this.bigImgList.push(this.$ServerUrl() + files[i]);
        }
      }
      this.showViewer = true;
    },
    /**
     * @description 关闭图片预览图
     */
    closeViewer() {
      this.bigImgList = [];
      this.showViewer = false;
    },

    onGetExport() {
      let that = this;
      that.fullscreenLoading = true;
      ajaxExport(that, that.uploadApi, that.uploadSearch, that.uploadNmae, function (resData) {
        that.fullscreenLoading = false;
      });
    },
    /**
     * @description 展示关联的学生名单
     */
    onGetRelatedStudent(objItem) {
      if (!regNullArray(objItem)) {
        return (
          '关联学生：' +
          objItem
            .map((item) => {
              return item.name + '【' + item.studentNo + '】';
            })
            .join('、')
        );
      } else {
        return '暂无关联学生';
      }
    },
    /**
     * @description 申诉处理
     * @param {Object} typeString Pass通过；Reject驳回
     */
    onUpdateApprove(typeString) {
      let that = this;
      if (typeString == 'Pass') {
        that.ruleFormApprove.approveStatus = 2;
      } else if (typeString == 'Reject') {
        that.ruleFormApprove.approveStatus = 3;
      }
      that.$refs['ruleFormApprove'].validate((valid) => {
        if (valid) {
          let url = '';
          if (that.currentType == 1) {
            url = '/api/admin/moralclass/approve'; //班级
          } else if (that.currentType == 3) {
            url = '/api/admin/moralperson/approve';
          }
          that.fullscreenLoading = true;
          ajaxPut(that, url, that.ruleFormApprove, (r) => {
            that.fullscreenLoading = false;
            that.dialogVisibleApprove = false;
            Init(that);
          });
        } else {
          setTimeout(() => {
            var isError = document.getElementsByClassName('is-error');
            var el = isError[0].querySelector('input') || isError[0].querySelector('textarea') || isError[0].querySelector('select');
            if (el) {
              el.focus();
            }
          }, 1);
          return false;
        }
      });
    },
    /**
     * @description 关闭申诉处理弹窗
     */
    handleCloseApprove() {
      let that = this;
      that.dialogVisibleApprove = false;
    },
    /**
     * @description 打开申诉处理弹窗
     */
    onApprove(obj) {
      let that = this;
      that.ruleFormApprove = JSON.parse(JSON.stringify(that.ruleFormApproveResert));
      that.ruleFormApprove.appealReason = obj.appealReason;
      that.ruleFormApprove.memberId = obj.memberId;
      that.ruleFormApprove.logId = obj.logId;
      that.$nextTick(() => {
        that.$refs.ruleFormApprove.clearValidate();
      });
      that.dialogVisibleApprove = true;
    },
    /**
     * @description 更多操作
     * @param {Object} command
     * @param {Object} _valueID
     * @param {Object} objVal
     */
    handleCommand(objType, objVal) {
      let that = this;
      switch (objType) {
        case 'onDelete':
          that.onDelete(objVal);
          break;
        case 'onApprove':
          that.onApprove(objVal);
          break;
      }
    },
    /**
     * @description 显示原因
     * @param {Object} objItem
     */
    onGetReason(objItem) {
      return objItem.reason;
      // + (regNull(delHtml(objItem.remark)) ? '' : '<span class="color-gray">（' + delHtml(objItem
      //   .remark) + '）</span>');
    },
    /**
     * @description 列表序号索引
     * @param {Object} index
     */
    indexMethod(index) {
      return (this.paging.Index - 1) * this.paging.Size + index + 1;
    },
    onAdd() {
      let that = this;
      this.$router.push({
        path: '/Admin/Adviser/Morality/Create/' + that.currentType,
        query: {
          type: that.currentId
        }
      });
    },
    onDetail(Id) {
      let that = this;
      that.$router.push({
        path: '/Admin/Adviser/Morality/Detail/' + that.currentType + '/' + Id,
        query: {
          url: that.$router.history.current.fullPath
        }
      });
    },
    onDelete(objVal) {
      let that = this;
      var deleteUrl = '';
      if (that.currentType == 1) {
        deleteUrl = '/api/admin/moralclass/' + objVal.logId;
      } else if (that.currentType == 2) {
        deleteUrl = '/api/admin/moraldorm/' + objVal.logId;
      } else if (that.currentType == 3) {
        deleteUrl = '/api/admin/moralperson/' + objVal.logId;
      }
      if (deleteUrl != '') {
        confirmDelete(that, null, function (res) {
          if (res == true) {
            that.fullscreenLoading = true;
            ajaxDelete(that, deleteUrl, null, function (r) {
              that.fullscreenLoading = false;
              Init(that);
            });
          }
        });
      }
    },
    pageBtnClick(index) {
      //分页组件左侧的按钮事件，按顺序进行判断
    },
    onSearch(params) {
      let that = this;
      let data = {};
      let searchData = params;
      searchData.forEach((element) => {
        if (element.data) {
          data[element.zhi] = Join(element.data);
        }
      });
      data.PageNumer = 1;
      data['type'] = that.currentType;
      routerPath(that, '/Admin/Adviser/Morality/Record', data, null, 'PageSize');
    },
    pagingClick(type, val) {
      let that = this;
      let url = '/Admin/Adviser/Morality/Record?type=' + that.currentType;
      if (type == 1) {
        //更改每页条数触发的事件
        routerPath(that, url, 'PageSize', val, 'PageNumer', 'Reason', 'ClassId', 'Student', 'LogDate', 'LastUpdateDate', 'AppealStatus', 'All', 'Self');
      } else {
        //更改当前页时触发的事件
        routerPath(that, url, 'PageNumer', val, 'PageSize', 'Reason', 'ClassId', 'Student', 'LogDate', 'LastUpdateDate', 'AppealStatus', 'All', 'Self');
      }
    }
  },
  watch: {
    $route() {
      let that = this;
      Init(that);
    }
  }
};

function Init(that) {
  that.currentType = that.$route.query.type;

  if (regNull(that.currentType)) {
    if (that.isPower.school_record_classlog == true) {
      that.currentType = 1;
    } else if (that.isPower.school_record_log == true) {
      that.currentType = 2;
    } else {
      that.currentType = 1;
    }
  }
  that.SearchTitles = [];
  if (that.isPower.school_record_classlog == true) {
    that.SearchTitles.push({
      name: '班级',
      isCurrent: that.currentType == 1 ? true : false,
      url: '/Admin/Adviser/Morality/Record?type=1'
    });
  }
  if (that.isPower.school_record_dormitorylog == true) {
    that.SearchTitles.push({
      name: '寝室',
      isCurrent: that.currentType == 2 ? true : false,
      url: '/Admin/Adviser/Morality/Record?type=2'
    });
  }
  if (that.isPower.school_record_log == true) {
    that.SearchTitles.push({
      name: '个人',
      isCurrent: that.currentType == 3 ? true : false,
      url: '/Admin/Adviser/Morality/Record?type=3'
    });
  }
  pagingPage(that);
}

function pagingPage(that) {
  paging(
    that,
    function (r) {
      that.fullscreenLoading = true;
      let Myurl = null;
      that.uploadApi = null;
      if (that.currentType == 1) {
        Myurl = '/api/admin/moralclass/search';
        that.uploadApi = '/api/admin/moralclass/export';
        that.uploadNmae = '班级扣分.xlsx';
      } else if (that.currentType == 3) {
        Myurl = '/api/admin/moralperson/search';
        that.uploadApi = '/api/admin/moralperson/export';
        that.uploadNmae = '个人扣分.xlsx';
      } else if (that.currentType == 2) {
        Myurl = '/api/admin/moraldorm/search';
        that.uploadApi = '/api/admin/moraldorm/export';
        that.uploadNmae = '寝室扣分.xlsx';
      }
      let classList = [];
      ajaxGet(that, '/api/admin/college/normallinkage', null, function (resClass) {
        resClass.forEach((element) => {
          var it = {
            Value: element.collegeId,
            Title: element.name,
            Childrens: []
          };

          if (!regNullArray(element.classes)) {
            element.classes.forEach((chil) => {
              it.Childrens.push({
                Value: chil.classId,
                Title: chil.alias
              });
            });
          }
          classList.push(it);
        });
        that.SearchList = [
          {
            type: 'cascader',
            Name: '班级',
            data: r.ClassId,
            select: classList,
            zhi: 'ClassId'
          },
          {
            type: 'input',
            Name: '管理原因',
            data: r.Reason,
            holder: '请输入管理原因',
            zhi: 'Reason'
          },
          {
            type: 'time',
            Name: '检查时间',
            data: r.LogDate,
            zhi: 'LogDate'
          },
          // {
          //   type: "time",
          //   Name: "操作时间",
          //   data: r.LastUpdateDate,
          //   zhi: "LastUpdateDate"
          // },
          // 申诉状态
          {
            type: 'select',
            Name: '申诉状态',
            data: r.AppealStatus,
            select: that.optAppealStatus, //选择的数据
            zhi: 'AppealStatus', // 传值
            isClear: true //select是否可以清除
          }
        ];
        if (that.currentType == 1) {
          that.SearchList.push({
            type: 'select',
            Name: '是否落实',
            data: r.All,
            select: that.optAll, //选择的数据
            zhi: 'All', // 传值
            isClear: true //select是否可以清除
          });
        }
        if (that.currentType == 3) {
          that.SearchList.push({
            type: 'input',
            Name: '学生',
            data: r.Student,
            holder: '请输入学生姓名/学号',
            zhi: 'Student'
          });
        }
        if (!that.TeacherInfo.isTeacher) {
          that.SearchList.push({
            type: 'select',
            Name: '是否本人创建',
            data: r.Self,
            select: [
              {
                Value: 'true',
                Title: '是'
              },
              {
                Value: 'false',
                Title: '否'
              }
            ], //选择的数据
            zhi: 'Self' // 传值
          });
        }

        var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
        that.random += random;

        var LogDateList = [];
        var LastUpdateDateList = [];
        var ClassId = null;
        let data = {};
        if (!regNull(r.ClassId)) {
          ClassId = JSON.parse(JSON.stringify(r.ClassId)).split(',')[1];
        }
        if (r.LogDate != null && r.LastUpdateDate != null) {
          LogDateList = JSON.parse(JSON.stringify(r.LogDate)).split(',');
          LastUpdateDateList = JSON.parse(JSON.stringify(r.LastUpdateDate)).split(',');
          data = {
            type: that.currentType,
            PageNumer: r.PageNumer,
            PageSize: r.PageSize,
            Reason: r.Reason,
            ClassId: ClassId,
            Student: r.Student,
            LogDateStart: LogDateList[0],
            LogDateEnd: LogDateList[1],
            LastUpdateDateStart: LastUpdateDateList[0],
            LastUpdateDateEnd: LastUpdateDateList[1],
            AppealStatus: r.AppealStatus,
            All: r.All,
            RoleId: that.roleInfo.roleId
          };
        } else if (r.LogDate != null) {
          LogDateList = JSON.parse(JSON.stringify(r.LogDate)).split(',');
          data = {
            type: that.currentType,
            PageNumer: r.PageNumer,
            PageSize: r.PageSize,
            Reason: r.Reason,
            ClassId: ClassId,
            Student: r.Student,
            LogDateStart: LogDateList[0],
            LogDateEnd: LogDateList[1],
            AppealStatus: r.AppealStatus,
            All: r.All,
            RoleId: that.roleInfo.roleId
          };
        } else if (r.LastUpdateDate != null) {
          LastUpdateDateList = JSON.parse(JSON.stringify(r.LastUpdateDate)).split(',');
          data = {
            type: that.currentType,
            PageNumer: r.PageNumer,
            PageSize: r.PageSize,
            Reason: r.Reason,
            ClassId: ClassId,
            Student: r.Student,
            LastUpdateDateStart: LastUpdateDateList[0],
            LastUpdateDateEnd: LastUpdateDateList[1],
            AppealStatus: r.AppealStatus,
            All: r.All,
            RoleId: that.roleInfo.roleId
          };
        } else {
          data = {
            type: that.currentType,
            PageNumer: r.PageNumer,
            PageSize: r.PageSize,
            Reason: r.Reason,
            ClassId: ClassId,
            Student: r.Student,
            AppealStatus: r.AppealStatus,
            All: r.All,
            RoleId: that.roleInfo.roleId
          };
        }
        if (!that.TeacherInfo.isTeacher) {
          data.Self = r.Self;
        } else {
          data.Self = true;
        }
        that.paging.Index = r.PageNumer;
        that.paging.Size = r.PageSize;
        that.uploadSearch = data;
        if (that.$route.query.url == '/admin/Preparations/index') {
          for (let key in that.SearchList) {
            if (that.SearchList[key].zhi == 'Student') {
              that.SearchList[key].data = that.$route.query.Student;
            }
          }
          data.Student = that.$route.query.Student;
        }
        ajaxGet(that, Myurl, data, function (r1) {
          that.fullscreenLoading = false;
          that.page = r1;
          that.item = r1.items;
          for (var i in that.item) {
            var it = that.item[i];
            it.isApprove = true;
            it.isDelete = true;

            if (!that.isPower.school_all_delete) {
              // 用户自己新建的行为规范扣分可以删除，其他的无法删除
              if (that.TeacherInfo.userId != it.createdByTeacherId) {
                it.isDelete = false;
              }
              if (
                (that.currentType == 1 && !that.isPower.school_record_classlog_delete) ||
                (that.currentType == 2 && !that.isPower.school_record_dormitorylog_delete) ||
                (that.currentType == 3 && !that.isPower.school_record_log_delete)
              ) {
                it.isDelete = false;
              }
            }

            if (!that.isPower.school_all_processing_complaints) {
              if (that.TeacherInfo.userId != it.createdByTeacherId) {
                it.isApprove = false;
              }
              if ((that.currentType == 1 && !that.isPower.school_record_classlog_approve) || (that.currentType == 3 && !that.isPower.school_record_log_approve)) {
                it.isApprove = false;
              }
            }

            if (it.appealStatus != 1) {
              it.isApprove = false;
            }
            // 由班级个人关联过来的数据，不需要申诉处理
            if (!regNull(it.related)) {
              it.isApprove = false;
              //  it.isDelete = false;
            }
          }
        });
      });
    },
    'Reason',
    'ClassId',
    'Student',
    'LogDate',
    'LastUpdateDate',
    'AppealStatus',
    'All',
    'Self'
  );
}
</script>

<style scoped="scoped">
.spToolTip {
  cursor: pointer;
}

.navTabsCont .nav-link {
  font-size: 12px;
}

.navToolsCont {
  margin-top: 1px;
}

.btn-mini {
  margin-left: 15px;
  margin-right: 5px;
  height: 40px;
  line-height: 25px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 14px;
}

.spIcon {
  background-color: #f8f9fa;
  font-size: 14px;
  padding: 2px 5px;
}

.spBan {
  color: #409eff;
}

.spBu {
  color: #fac10b;
}

.spXiao {
  color: #f6402c;
}

.titleErr {
  font-size: 14px;
  color: #f6402c;
}

.paperclip {
  margin-right: 5px;
  color: #409eff;
}
</style>
