<!--
  学生成绩
-->
<template>
  <div class="SubWebPage page" v-loading.fullscreen.lock="fullscreenLoading">
    <el-card class="card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="width: 100%; padding-left: 0px" v-if="SearchTitles != null && SearchTitles.length > 0">
          <ul class="nav nav-pills navTabsCont">
            <li class="nav-item" v-for="(p, index) in SearchTitles" :key="index">
              <router-link :to="p.url" :class="p.isCurrent == true ? 'nav-link active' : 'nav-link'">
                {{ p.name }}
              </router-link>
            </li>
          </ul>
        </h3>
      </div>
      <div class="card-body">
        <el-row :gutter="24">
          <el-col :span="8" class="mb-3">
            <div class="selTitleBox">
              <span class="spTitle">学期</span>
            </div>
            <div class="selBodyBox">
              <el-select v-model="arrSearch.strSemester" style="width: 100%" placeholder="请选择" @change="onChangeSemester">
                <el-option v-for="(item, index) in arrSearch.optSemester" :key="index" :label="item.name" :value="item.semesterId"></el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="8" class="mb-3">
            <div class="selTitleBox">
              <span class="spTitle">班级</span>
            </div>
            <div class="selBodyBox">
              <el-select v-model="arrSearch.strClass" style="width: 100%" placeholder="请选择" @change="handleChangeClass" filterable>
                <el-option v-for="(item, index) in arrSearch.optClass" :key="index" :label="item.alias" :value="item.classId"></el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="8" class="mb-3">
            <div class="selTitleBox">
              <span class="spTitle">课程</span>
            </div>
            <div class="selBodyBox">
              <el-select v-model="arrSearch.strCourse" clearable style="width: 100%" placeholder="请选择" filterable>
                <el-option v-for="(item, index) in arrSearch.optCourse" :key="index" :label="item.name" :value="item.courseId"></el-option>
              </el-select>
            </div>
          </el-col>

          <el-col :span="8" class="mb-3">
            <div class="selTitleBox">
              <span class="spTitle">学生</span>
            </div>
            <div class="selBodyBox">
              <el-input v-model="arrSearch.Student" placeholder="请输入学号/姓名"></el-input>
            </div>
          </el-col>
          <el-col :span="8" class="mb-3">
            <div class="selTitleBox">
              <span class="spTitle">成绩类型</span>
            </div>
            <div class="selBodyBox">
              <el-select v-model="arrSearch.courseType" clearable style="width: 100%" placeholder="请选择" filterable>
                <el-option v-for="(item, index) in arrSearch.courseTypes" :key="index" :label="item.Text" :value="item.Value"></el-option>
              </el-select>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="card-footer text-center">
        <el-button type="primary" icon="el-icon-search" @click.native="onSearch()">查询</el-button>
        <el-button icon="el-icon-refresh" @click.native="onResetSearch()">重置</el-button>
      </div>
    </el-card>

    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title">课程成绩</h3>
        <div class="card-tools">
          <ul class="nav">
            <li class="nav-item" style="marginright: 5px" v-if="isPower.academicrecord_import">
              <el-button type="info" icon="el-icon-upload2" @click="onOpenDialog()">导入学生成绩</el-button>
            </li>
            <li class="nav-item" style="margin-right: 5px" v-if="isPower.academicrecord_export">
              <el-button @click="onOpenExport()" icon="el-icon-download" style="background-color: #17a2b8; color: white">导出</el-button>
            </li>
            <li class="nav-item">
              <el-button v-if="isPower.academicrecord_create == true" type="success" icon="el-icon-edit-outline" @click="onInput()">录入成绩</el-button>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body p-0" v-if="itemData != null && itemData.length != 0">
        <el-table :data="itemData" stripe class="elTableData">
          <el-table-column type="index" label="序号" class="elColumn" width="70"></el-table-column>
          <el-table-column prop="studentNo" label="学号" class="elColumn" min-width="120" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="studentName" label="姓名" class="elColumn" min-width="120" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="className" label="班级" class="elColumn" min-width="120" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="courseName" label="课程" class="elColumn" min-width="160" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="peaceTime" label="平时" class="elColumn" v-if="showScore['1']" min-width="100">
            <template slot-scope="scope">
              <span v-if="scope.row.peaceTime">{{ scope.row.peaceTime }}</span>
              <span class="color-gray" v-else>暂无成绩</span>
            </template>
          </el-table-column>
          <el-table-column prop="midTerm" label="期中" class="elColumn" v-if="showScore['2']" min-width="100">
            <template slot-scope="scope">
              <span v-if="scope.row.midTerm">{{ scope.row.midTerm }}</span>
              <span class="color-gray" v-else>暂无成绩</span>
            </template>
          </el-table-column>
          <el-table-column prop="final" label="期末" class="elColumn" v-if="showScore['3']" min-width="100">
            <template slot-scope="scope">
              <span v-if="scope.row.final">{{ scope.row.final }}</span>
              <span class="color-gray" v-else>暂无成绩</span>
            </template>
          </el-table-column>
          <el-table-column prop="total" label="总评" class="elColumn" v-if="showScore['4']" min-width="100">
            <template slot-scope="scope">
              <span v-if="scope.row.total">{{ scope.row.total }}</span>
              <span class="color-gray" v-else>暂无成绩</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="200px" fixed="right">
            <template slot-scope="scope">
              <operationBtn v-if="isPower.academicrecord_details == true" :btnName="'查看'" @click.native="onDetail(scope.row.recordId)"></operationBtn>
              &nbsp;
              <el-dropdown v-if="isPower.academicrecord_edit || isPower.academicrecord_delete" trigger="click" @command="handleCommand($event, scope.row.recordId)">
                <el-button size="mini">
                  更多操作
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-if="isPower.academicrecord_edit" class="color-warning" command="onEdit">编辑</el-dropdown-item>
                  <el-dropdown-item divided v-if="isPower.academicrecord_delete" class="color-danger" command="onDelete">删除</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <comPage :isPage="false" :paging="paging"></comPage>
      </div>
      <div class="card-body" v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>

    <!-- 导入课程成绩 -->
    <el-dialog
      title="导入课程成绩"
      :visible.sync="dialogVisible"
      width="60%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :append-to-body="true"
      :fullscreen="false"
    >
      <div class="divdvContBox">
        <div class="divSelect">
          <span class="select-text">学期:</span>
          <el-select v-model="downParams.semesterId" placeholder="请选择学期" class="width_100Pie" @change="handleDownSemest">
            <el-option v-for="item in arrSearch.optSemester" :key="item.semesterId" :label="item.name" :value="item.semesterId"></el-option>
          </el-select>
        </div>
        <div class="divSelect">
          <span class="select-text">班级:</span>
          <el-select v-model="downParams.classId" placeholder="请选择学期" class="width_100Pie" @change="handleDownClass">
            <el-option v-for="item in arrSearch.optClass" :key="item.classId" :label="item.alias" :value="item.classId"></el-option>
          </el-select>
        </div>
        <div class="divSelect">
          <span class="select-text">课程:</span>
          <el-select v-model="downParams.courseId" placeholder="请选择课程" class="width_100Pie">
            <el-option v-for="item in downCourseOpt" :key="item.courseId" :label="item.name" :value="item.courseId"></el-option>
          </el-select>
        </div>
      </div>

      <div class="divUploadBox">
        <el-upload
          ref="import"
          class="upload-demo"
          :action="getUploadApi"
          :limit="1"
          :before-upload="beforeUpload"
          :file-list="fileList"
          :auto-upload="false"
          :http-request="uploadFile"
        >
          <el-button size="small" type="primary">点击上传文件</el-button>
        </el-upload>
        <div class="divTipFont">只允许上传一个xls或xlsx格式的文件，且不超过{{ tipSize }}M</div>
      </div>

      <!--弹出框内容-->
      <div slot="footer" class="dialog-footer">
        <div style="float: left; font-size: 13px; line-height: 40px">
          <el-button type="text" @click="handleDownTemplate">下载模版</el-button>
        </div>
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitUpload()">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 查看学生成绩详情 -->
    <el-dialog :title="dialogName" :visible.sync="dialogVisibleStudent" width="40%" :close-on-press-escape="false" :append-to-body="true" :fullscreen="false">
      <div class="row">
        <div class="col-lg-12 colInfo">
          <label>课程</label>
          <div class="colFonts">{{ dialogItem.courseName }}</div>
        </div>
        <div class="col-lg-6 colInfo">
          <label>平时</label>
          <div class="colFonts">{{ onGetNullFont(dialogItem.peaceTime) }}</div>
        </div>
        <div class="col-lg-6 colInfo">
          <label>期中</label>
          <div class="colFonts">{{ onGetNullFont(dialogItem.midTerm) }}</div>
        </div>
        <div class="col-lg-6 colInfo">
          <label>期末</label>
          <div class="colFonts">{{ onGetNullFont(dialogItem.final) }}</div>
        </div>
        <div class="col-lg-6 colInfo">
          <label>总评</label>
          <div class="colFonts">{{ onGetNullFont(dialogItem.total) }}</div>
        </div>
      </div>
    </el-dialog>

    <!-- 编辑学生个人成绩 -->
    <el-dialog
      :title="dialogName"
      :visible.sync="dialogVisibleStudentEdit"
      width="40%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :append-to-body="true"
      :fullscreen="false"
    >
      <div class="row">
        <div class="col-lg-6 colInfo">
          <label>平时</label>
          <div class="colFonts">
            <el-input v-model="dialogItem.peaceTime" placeholder="请输入平时成绩"></el-input>
          </div>
        </div>
        <div class="col-lg-6 colInfo">
          <label>期中</label>
          <div class="colFonts">
            <el-input v-model="dialogItem.midTerm" placeholder="请输入期中成绩"></el-input>
          </div>
        </div>
        <div class="col-lg-6 colInfo">
          <label>期末</label>
          <div class="colFonts">
            <el-input v-model="dialogItem.final" placeholder="请输入期末成绩"></el-input>
          </div>
        </div>
        <div class="col-lg-6 colInfo">
          <label>总评</label>
          <div class="colFonts">
            <el-input v-model="dialogItem.total" placeholder="请输入总评成绩"></el-input>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="onCloseDialog">取 消</el-button>
        <el-button type="primary" @click="onSubmitEdit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import '@/assets/css/Admin.css';
import comPage from '@/components/Page.vue';
import operationBtn from '@/components/operationBtn.vue';
export default {
  components: {
    comPage,
    operationBtn
  },
  data() {
    return {
      downCourseOpt: [], //模版下载课程列表
      downDialogVisible: false, //是否显示模版下载弹窗
      downParams: {
        //模版下载收集的数据
        semesterId: null,
        classId: null,
        courseId: null
      },
      impotParams: {
        //导入成绩收集的数据
        semesterId: null
      },
      isTeacher: false, //是否是老师
      uploadInfoApi: '',
      // 成绩弹出框查看
      dialogName: '',
      // 弹出框显示
      dialogVisibleStudent: false,
      dialogVisibleStudentEdit: false,
      // 成绩查看详情数据
      dialogItem: {
        courseName: null
      },
      showScore: {
        //类型搜索对应table选项
        1: true,
        2: true,
        3: true,
        4: true,
        5: true,
        6: true
      },
      SearchTitles: [],
      dialogTitle: null,
      dialogVisible: false,
      fullscreenLoading: false,
      // 搜索数据集
      SearchList: null,
      // 列表数据
      itemData: null,
      itemDataError: null,
      // 上传AApi接口
      paging: {
        totalCount: 0
      },
      // 搜索数据集
      arrSearch: {
        Student: null,
        // 学期
        strSemester: '',
        semesterId: '',
        optSemester: [],
        // 班级
        strClass: '',
        classId: '',
        optClass: [],
        // 课程
        strCourse: null,
        courseId: null,
        optCourse: [],
        //学生学号或姓名

        //课程类型
        courseType: '',
        courseTypes: []
      },
      arrSearchReset: null,
      isPower: {
        //增删改查 权限管理
        academicrecord_index: false,
        academicrecord_class_index: false,
        academicrecord_details: false,
        academicrecord_edit: false,
        academicrecord_create: false,
        academicrecord_delete: false,
        academicrecord_import: false,
        academicrecord_export: false
      },
      dataEcport: {},

      //上传组件地址
      fileList: [],

      tipSize: webUploadFileSize()
    };
  },
  computed: {
    /**
     * @description  设置导入地址
     */
    getUploadApi() {
      return `/api/admin/academicrecord/import/${this.downParams.semesterId}`;
    }
  },
  methods: {
    /**
     * @description 更多操作
     * @param {Object} command
     * @param {Object} objValID
     */
    handleCommand(command, objValID) {
      let that = this;
      switch (command) {
        case 'onEdit':
          that.onEdit(objValID);
          break;
        case 'onDelete':
          that.onDelete(objValID);
          break;
      }
    },
    /**
     * @description 确认导入按钮事件
     */
    submitUpload() {
      let that = this;
      that.$refs.import.submit();
    },
    /**
     * @description 开始上传文件
     * @param {Object} content
     */
    uploadFile(content) {
      let that = this;
      if (!regNull(that.downParams.semesterId)) {
        // 接收上传文件的后台地址
        var url = `/api/admin/academicrecord/import/${that.downParams.semesterId}`;
        let files = new FormData();
        files.append('file', content.file);

        that.fullscreenLoading = true;
        ajaxImport(that, url, files, function (r) {
          that.fullscreenLoading = false;
          if (r.success >= 0 || r.success == true) {
            if (r.result == true) {
              var it = {
                result: r.result, //导入结果
                total: r.total, //导入记录总数
                success: r.success //导入成功记录数
              };
              that.$refs.import.abort(content.file);
              that.fileList.splice(that.fileList.indexOf(content.file), 1);
              that.dialogVisible = false;
              that.onImportSuccess(it);
            } else {
              var it = {
                result: r.result, //导入结果
                total: r.total, //导入记录总数
                error: r.error, //导入错误原因
                message: r.message
              };
              that.$refs.import.abort(content.file);
              that.fileList.splice(that.fileList.indexOf(content.file), 1);
              that.dialogVisible = false;
              that.onImportSuccess(it);
            }
          } else {
            var it = {
              result: false,
              message: r.message
            };
            that.$refs.import.abort(content.file);
            that.fileList.splice(that.fileList.indexOf(content.file), 1);
            that.dialogVisible = false;
            that.onImportSuccess(it);
          }
        });
      } else {
        warning(that, '导入课程成绩，至少需要选择对应的学期');
      }
    },
    /**
     * @description 上传文件之前的钩子，参数为上传的文件，若返回 false 或者返回 Promise 且被 reject，则停止上传。
     * @param {Object} file
     */
    beforeUpload(file) {
      let that = this;
      const isXls = file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      if (!isXls) {
        warning(that, '仅支持xls和xlsx格式的文件上传!');
      }
      const isLt10M = file.size / 1024 / 1024 < webUploadFileSize();
      if (!isLt10M) {
        warning(that, '上传文件大小不能超过 ' + webUploadFileSize() + 'M!');
      }
      return isXls && isLt10M;
    },
    /**
     * @description 下载模版班级选择
     */
    handleDownClass() {
      //
      let that = this;
      that.downParams.courseId = null;
      let url = `/api/admin/academicrecord/course/${that.downParams.semesterId}/${that.downParams.classId}`;
      that.fullscreenLoading = true;
      ajaxGet(that, url, null, function (resData) {
        that.downCourseOpt = resData;
        that.fullscreenLoading = false;
      });
    },
    /**
     * @description 下载模版学期选择
     * @param {Object} val
     */
    handleDownSemest(objVal) {
      //
      let that = this;
      that.downParams.courseId = null;
      that.downCourseOpt = [];
      if (!regNull(that.downParams.classId)) {
        that.handleDownClass();
      } else {
        that.fullscreenLoading = true;
        ajaxGet(that, `/api/admin/academiccourse/course/${objVal}`, null, function (res) {
          that.fullscreenLoading = false;
          that.downCourseOpt = res;
        });
      }
    },
    /**
     * @description 开始下载模版
     */
    handleDownTemplate() {
      //
      let that = this;
      let { semesterId, classId, courseId } = that.downParams;
      if (!classId) {
        warning(that, '请选择班级');
        return;
      }
      if (!courseId) {
        warning(that, '请选择课程');
        return;
      }
      that.fullscreenLoading = true;
      let url = `/api/admin/academicrecord/exporttemplate/${semesterId}/${classId}/${courseId}`;
      ajaxExport(that, url, null, '学生成绩导入模版.xls', function (res) {
        that.downDialogVisible = false;
        that.fullscreenLoading = false;
      });
    },
    /**
     * @description 点击下载模版
     */
    downTemplate() {
      let that = this;
      that.downParams.semesterId = that.arrSearch.optSemester.find((item) => item.isCurrent == true).semesterId;
      if (that.isTeacher) {
        that.downParams.classId = that.arrSearch.optClass[0].classId;
        that.handleDownClass();
      } else {
        that.handleDownSemest(that.downParams.semesterId);
      }
      that.downDialogVisible = true;
    },

    /**
     * @description 点击导入按钮
     */
    onCloseDown() {
      //
      this.dialogVisible = true;
    },

    /**
     * @description 点击导入学生成绩
     */
    onOpenDialog() {
      //
      let that = this;
      if (!regNull(that.arrSearch.strSemester)) {
        that.downParams.semesterId = that.arrSearch.strSemester;
      }

      if (!regNull(that.arrSearch.strClass)) {
        that.downParams.classId = that.arrSearch.strClass;
      }

      if (!regNull(that.arrSearch.strSemester) && !regNull(that.arrSearch.strClass)) {
        that.handleDownClass();
      }

      that.dialogVisible = true;
    },
    /**
     * @description 重置搜索
     */
    onResetSearch() {
      let that = this;
      that.arrSearch.strSemester = that.arrSearch.optSemester.find((item) => item.isCurrent == true).semesterId;
      if (that.arrSearch.optClass && that.arrSearch.optClass.length > 0) {
        that.arrSearch.strClass = that.arrSearch.optClass[0].classId;
      }
      that.arrSearch.strCourse = null;
      that.arrSearch.Student = null;
      that.arrSearch.courseType = null;
      that.handleChangeClass(that.arrSearch.strClass);
      that.onSearch();
    },

    /**
     * @description 编辑成绩
     */
    onCloseDialog() {
      //
      let that = this;
      that.onSearch();
      that.dialogVisibleStudentEdit = false;
    },

    /**
     * @description 暂无数据
     * @param {Object} val
     */
    onGetNullFont(val) {
      if (regNull(val)) {
        return '暂无成绩';
      }
      return val;
    },

    /**
     * @description 学期改变
     */
    onChangeSemester() {
      //
      let that = this;
      that.handleChangeClass(that.arrSearch.strClass);
    },

    /**
     * @description 班级选择事件
     * @param {Object} _valueID
     */
    handleChangeClass(_valueID) {
      //班级改变
      let that = this;
      let url = `/api/admin/academicrecord/course/${that.arrSearch.strSemester}/${_valueID}`;
      if (regNull(_valueID)) {
        url = `/api/admin/academiccourse/course/${that.arrSearch.strSemester}`;
      }
      that.arrSearch.strCourse = null;
      ajaxGet(that, url, null, function (resData) {
        that.arrSearch.optCourse = resData;
      });
    },

    /**
     * @description 搜索数据
     */
    onSearch() {
      let that = this;
      if (!regNull(that.arrSearch.strSemester) && regNull(that.arrSearch.strClass)) {
        warning(that, '请选择班级');
        return false;
      } else if (regNull(that.arrSearch.strClass) && regNull()) {
      } else {
        that.itemData = [];
      }

      let _data = {
        SemesterId: that.arrSearch.strSemester,
        ClassId: that.arrSearch.strClass,
        CourseId: that.arrSearch.strCourse ? that.arrSearch.strCourse : null,
        Student: that.arrSearch.Student
      };
      that.dataEcport = _data;
      that.fullscreenLoading = true;
      if (that.arrSearch.courseType) {
        for (let key in that.showScore) {
          if (key == that.arrSearch.courseType) {
            that.showScore[key] = true;
          } else {
            that.showScore[key] = false;
          }
        }
      } else {
        for (let key in that.showScore) {
          that.showScore[key] = true;
        }
      }
      ajaxGet(that, '/api/admin/academicrecord/search', _data, function (resData) {
        that.itemData = resData;
        that.paging.totalCount = resData.length;
        that.fullscreenLoading = false;
      });
    },
    onOpenExport() {
      //导出
      let that = this;
      that.fullscreenLoading = true;
      ajaxExport(that, '/api/admin/academicrecord/export', that.dataEcport, '学生成绩.xls', function (res) {
        that.fullscreenLoading = false;
      });
    },
    /**
     * @description 删除
     * @param {Object} Id
     */
    onDelete(Id) {
      let that = this;
      confirmDelete(that, null, function (res) {
        if (res == true) {
          that.fullscreenLoading = true;
          ajaxDelete(that, '/api/admin/academicrecord/' + Id, null, function (r) {
            that.fullscreenLoading = false;
            that.onSearch();
          });
        }
      });
    },
    /**
     * @description 编辑
     * @param {Object} Id
     */
    onEdit(Id) {
      let that = this;
      let tempRecord = that.itemData.find((val) => val.recordId == Id);
      that.dialogName = tempRecord.studentNo + ' | ' + tempRecord.studentName + '【' + tempRecord.courseName + '】';
      that.dialogItem = tempRecord;
      that.dialogVisibleStudentEdit = true;
    },
    // 编辑个人成绩
    onSubmitEdit() {
      let that = this;
      that.fullscreenLoading = true;
      ajaxPut(that, '/api/admin/academicrecord/' + that.dialogItem.recordId, that.dialogItem, function (res) {
        that.fullscreenLoading = false;
        that.dialogVisibleStudentEdit = false;
      });
    },
    /**
     * @description 查看
     * @param {Object} Id
     */
    onDetail(Id) {
      let that = this;
      let tempRecord = that.itemData.find((val) => val.recordId == Id);
      that.dialogName = tempRecord.studentNo + ' | ' + tempRecord.studentName;
      that.dialogItem = tempRecord;
      that.dialogVisibleStudent = true;
    },
    onInput() {
      let that = this;
      that.$router.push({
        path: '/Admin/Score/AdviserScoreRecord',
        query: {
          arrSearch: that.arrSearch
        }
      });
    },
    /**
     * @description 学生成绩导入
     * @param {Object} param
     */
    onImportSuccess(param) {
      let that = this;
      onGetData(that);
    }
  },
  created() {
    let that = this;
    isPower(that, 'academicrecord_index', 'academicrecord_index');
    isPower(that, 'academicrecord_class_index', 'academicrecord_class_index');
    isPower(that, 'academicrecord_details', 'academicrecord_details');
    isPower(that, 'academicrecord_edit', 'academicrecord_edit');
    isPower(that, 'academicrecord_create', 'academicrecord_create');
    isPower(that, 'academicrecord_delete', 'academicrecord_delete');
    isPower(that, 'academicrecord_import', 'academicrecord_import');
    isPower(that, 'academicrecord_export', 'academicrecord_export');

    that.SearchTitles = [];
    if (that.isPower.academicrecord_class_index == true) {
      that.SearchTitles.push({
        name: '课程成绩',
        isCurrent: true,
        url: '/Admin/Score/Adviser'
      });
    }
    if (that.isPower.academicrecord_index == true) {
      that.SearchTitles.push({
        name: '学期成绩汇总',
        isCurrent: false,
        url: '/Admin/Score/Course'
      });
    }
    that.fullscreenLoading = true;
    ajaxGet(that, '/api/admin/academicrecord/type', null, function (res) {
      //获取成绩类型
      that.arrSearch.courseTypes = res;
      that.fullscreenLoading = false;
      onGetData(that);
    });
  },
  watch: {
    $route() {
      let that = this;
      onGetData(that);
    }
  }
};

///加载列表数据
// 获取数据
function onGetData(that) {
  // 开启加载动画
  that.fullscreenLoading = true;
  // 搜索 下拉学期
  ajaxGet(that, '/api/admin/semester', null, function (resSemester) {
    if (!regNullArray(resSemester)) {
      that.arrSearch.optSemester = resSemester;
      let tpSemester = resSemester.find((item) => item.isCurrent == true);
      // 判断是否有当前学期，没有则默认显示第一个学期
      if (!regNull(tpSemester)) {
        that.arrSearch.strSemester = tpSemester.semesterId;
      } else {
        that.arrSearch.strSemester = resSemester[0].semesterId;
      }

      // 搜索下拉班级
      that.fullscreenLoading = false;

      let tempUserInfo = onGetNowUser(that);
      that.isTeacher = tempUserInfo.isTeacher;
      that.arrSearch.optClass = that.isTeacher ? tempUserInfo.manageClass : tempUserInfo.allClass;

      if (!regNullArray(that.arrSearch.optClass)) {
        that.arrSearch.strClass = that.arrSearch.optClass[0].classId;
      }

      that.handleChangeClass(that.arrSearch.strClass);
      that.onSearch();
    } else {
      that.arrSearch.optSemester = [];
      that.fullscreenLoading = false;
    }
  });
}
</script>

<style scoped="scoped" lang="less">
.divUploadBox {
  margin-top: 25px;
  padding-left: 15px;
  position: relative;

  .divTipFont {
    position: absolute;
    top: 8px;
    left: 150px;
    font-size: 12px;
  }
}

.select-text {
  display: block;
  box-sizing: border-box;
  font-size: 14px;
  margin-bottom: 5px;
}

.divImpBtn {
  margin-top: 5px;
}

.selTitleBox {
  font-size: 14px;
}

.divdvContBox {
  padding-left: 15px;
  margin-top: -15px;
  display: flex;
}

.divdvContBox .divSelect {
  padding-right: 15px;
  width: 70%;
}

.selTitleBox .spTitle {
  color: #303133;
}

.selBodyBox {
  padding-top: 5px;
}

.navTabsCont .nav-link {
  font-size: 12px;
}
</style>
<style>
.importStyle {
  font-size: 14px;
  padding: 8px 15px;
}
</style>
