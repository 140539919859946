import Vue from 'vue';
import App from './App.vue';
import router from './router';
import './plugins/element.js';

import ElementUI from 'element-ui'; //装入Element
import 'element-ui/lib/theme-chalk/index.css';
// AdminLTE-3.0.5 模板样式
import './assets/css/adminlte.css';
import './assets/css/bootstrap.css';
// 全局通用样式
import './assets/css/tools.css';
import './assets/css/style.css';
// 字体
import './assets/font/font.css';
// 阿里图标库
import './assets/font/iconfont.css';
import './assets/font/iconfont.js';

//API
import axios from 'axios';
import qs from 'qs';


import Print from 'vue-print-nb'
Vue.use(Print)

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

// 表单验证组件
import verify from "vue-verify-plugin";

// jquery
import $ from 'jquery';
import md5 from 'js-md5';
// 全局方法调用
import imCommon from './plugins/Common.js';

import imMethod from './plugins/method.js';
import preventReClick from './plugins/preventReClick'
// 网站标题
import VueWechatTitle from 'vue-wechat-title';
import dataV from '@jiaminghi/data-view';




import moment from 'moment'; //moment.js
Vue.prototype.$moment = moment;

//定义一个全局过滤器实现日期格式化
Vue.filter('dateformat', function(dataStr, pattern = 'YYYY-MM-DD HH:mm:ss') {
  return moment(dataStr).format(pattern)
});
Vue.filter('dateformatMinute', function(dataStr, pattern = 'YYYY-MM-DD HH:mm') {
  return moment(dataStr).format(pattern)
});

Vue.filter('dateformatDay', function(dataStr, pattern = 'YYYY-MM-DD') {
  return moment(dataStr).format(pattern)
});
// 拼接系里所有图片
Vue.prototype.onImgUrl = function(obj) {
  if (obj != null && typeof(obj) != "undefined" && obj != '') {
    return serverUrl() + obj + '?v=' + Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
  } else {
    return null;
  }
};
// 判断是否有数据
Vue.filter('nullData', function(objData) {
  if (objData != null && typeof(objData) != "undefined" && objData != "") {
    return objData;
  } else {
    return '暂无数据';
  }
});



Vue.prototype.regNullArray = function(obj) {
  if (obj != null && obj.length > 0) {
    return false;
  } else {
    return true;
  }
};
Vue.prototype.regNull = function(obj) {
  if (obj != null && typeof(obj) != "undefined" && obj != '') {
    return false;
  } else {
    return true;
  }
};


// 拼接系里所有图片
Vue.prototype.onImgSpliceUrl = function(obj) {
  if (obj != null && typeof(obj) != "undefined" && obj != '') {
    return Vue.prototype.$ServerUrl() + obj + '?v=' + Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
  } else {
    return null;
  }
};

Vue.prototype.onImgSpliceUrlNoV = function(obj) {
  if (obj != null && typeof(obj) != "undefined" && obj != '') {
    return Vue.prototype.$ServerUrl() + obj;
  } else {
    return null;
  }
};









































// router.beforeEach((to, from, next) => {
//   versionTood.isNewVersion();
//   next();
// });

// 响应拦截器
axios.interceptors.response.use(response => {
  // 对响应数据做一些事情
  return response
}, error => {
  // 请求错误时做些事
  let status = ''
  if (error.request) {
    status = error.request
  } else if (error.response) {
    status = error.response
  }
  if (status) {
    switch (status.status) {
      case 400:
        error.message = '请求错误(400)';
        break;
      case 401:
        error.message = '未授权，请重新登录(401)';
        // router.push('/401')
        break;
      case 403:
        error.message = '拒绝访问(403)';
        break;
      case 404:
        error.message = '请求出错(404)';
        // router.push('/404')
        break;
      case 408:
        error.message = '请求超时(408)';
        break;
      case 500:
        error.message = '服务器错误(500)';
        // router.push('/500')
        break;
      case 501:
        error.message = '服务未实现(501)';
        break;
      case 502:
        error.message = '网络错误(502)';
        break;
      case 503:
        error.message = '服务不可用(503)';
        break;
      case 504:
        error.message = '网络超时(504)';
        break;
      case 505:
        error.message = 'HTTP版本不受支持(505)';
        break;
      case 0:
        error.message = '网络接口请求出错!';
        break;
      default:
        error.message = '网络请求出错!';
    }
  } else {
    error.message = '连接服务器失败!';
  }
  error.type = 'api';
  return Promise.reject(error);
});

// 大屏展示
Vue.use(dataV);

// 全局方法调用
Vue.use(imCommon);
// 网站标题
Vue.use(VueWechatTitle);
// 表单验证组件
Vue.use(verify);

// jquery
Vue.prototype.$ = $;

//全局注册，使用方法为:this.$axios
Vue.prototype.$axios = axios;
//全局注册，使用方法为:this.qs
Vue.prototype.$qs = qs;
Vue.config.productionTip = false;
Vue.prototype.$md5 = md5;




// 校验登录
// router.beforeEach(function(to, from, next) {
//   let that = this;
//   //页面是否登录，本地存储中是否有token(uid)数据，否：跳转登录页面
//   let item = localStorage.getItem("accessToken");
//   try {
//     item = JSON.parse(item);
//   } catch (error) {
//     item = item;
//   }
//   if (item != null) {
//     // 获取时效，先进行转换，然后再进行折算
//     let tempExpiresIn =(setInt(localStorage.getItem("expiresIn"))*1000)*0.8;
//     let date = new Date().getTime();
//     // 如果大于就是过期了，如果小于或等于就还没过期
//     if (date - item.startTime > tempExpiresIn) {
//       let refreshToken = localStorage.getItem("refreshToken");
//       if (regNull(refreshToken)) {
//         if (to.path == '/login') { //如果是登录页面路径，就直接next()
//           next();
//         } else { //不然就跳转到登录；
//           next('/login');
//         }
//       } else {
//         let _data = {
//           token: item.value,
//           refreshToken: refreshToken
//         }
//         // 判断当前是在管理端 还是 学生端
//         let apiUrl = to.path.toUpperCase().includes('/ADMIN/') ? '/api/auth/refreshtoken' : '/api/studentauth/refreshtoken';
//         // 重置Token
//         axios.post(
//           Vue.prototype.$ServerUrl() + apiUrl,
//           _data,
//         ).then(res => {
//           if (res.data.success) {
//             localStorage.removeItem('accessToken');
//             localStorage.removeItem('refreshToken');
//             Vue.prototype.$SaveKey(res.data.data.accessToken);
//             localStorage.setItem('expiresIn',res.data.data.expiresIn);
//             localStorage.setItem('refreshToken',res.data.data.refreshToken);
//             next();
//           } else {
//             localStorage.removeItem('accessToken');
//             localStorage.removeItem('refreshToken');
//             next('/login');
//           }
//         }).catch(err => {});
//       }
//     } else {
//       next();
//     }
//   } else {
//     localStorage.removeItem('accessToken');
//     localStorage.removeItem('refreshToken');
//     next();
//   }
// });


// 缓存
// 用于记录班主任所管辖的班级数据
Vue.prototype.$cacheTeacher = "teacher";
// 记录教师姓名
Vue.prototype.$cacheTeacherName = "userName";
// 记录学生的学号和姓名
Vue.prototype.$cacheStudent = "Student";




new Vue({
  router,
  render: h => h(App)
}).$mount('#app');
