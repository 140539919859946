<template>
  <div
    class="SubWebPage literacyreport"
    v-loading.fullscreen.lock="fullscreenLoading"
    element-loading-text="请稍等，正在生成综合报告"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <!--头部-->
    <div class="divPageHeaderBox">
      <!-- 学年 -->
      <ul class="ulTabsBox">
        <li class="liTab" :class="{ liAction: currsorIndex == index }" v-for="(item, index) in optYear" :key="index" @click="onChangeYear(item, index)">{{ item.name }}</li>
      </ul>
      <!-- 学期 -->
      <div class="divSemesterBox">
        <div class="divTitle">学期</div>
        <el-select v-model="nowSemester.semesterId" placeholder="请选择" class="selForm width_300" @change="onChangeSemester">
          <el-option v-for="item in optSemester" :key="item.semesterId" :label="item.name" :value="item.semesterId"></el-option>
        </el-select>
      </div>
    </div>

    <div class="ReportBody">
      <div id="printTest" style="-webkit-print-color-adjust: exact">
        <!--页头 id="pageCover"-->
        <div class="A4CardBox pageHeader PrintPage" id="pageCover">
          <div class="divLogo">
            <img src="../../../assets/image/logodarkcolor.png" height="55" />
          </div>
          <div class="divImgBox">
            <img src="../../../assets/image/report/cover_1.png" height="650" />
            <div class="num1 VerticalMid">
              <div class="bgColor"></div>
              <div class="fontBox VerticalMid">
                校园
                <br />
                生活
              </div>
            </div>
            <div class="num2 VerticalMid">
              <div class="bgColor"></div>
              <div class="fontBox VerticalMid">
                成长
                <br />
                档案
              </div>
            </div>
            <div class="num3 VerticalMid">
              <div class="bgColor"></div>
              <div class="fontBox VerticalMid">
                综合
                <br />
                评价
              </div>
            </div>
          </div>
          <div class="divTitleBox">
            <div class="divTitle">
              <div class="divNameBox">{{ infoData.name }}</div>
              <div class="divSubName">{{ reportTitle }} &middot; 综合评价报告</div>
            </div>
          </div>
          <div class="divSchoolName">
            {{ onGetSchoolName() }}
            <br />
            {{ nowDate }}
          </div>
        </div>

        <!-- 基本信息/个人总评 -->
        <div class="A4CardBox pageCont pageBoxOne PrintPageTwo" ref="targetDom">
          <div class="InfoTitleCont oneTitle">
            <i class="fax icon">&#xe791;</i>
            基本信息
          </div>
          <div class="baseInfoCont">
            <div class="stuName">
              {{ infoData.name }}
              <el-tag style="font-size: 18px" type="danger" v-if="!regNull(infoData.punishLevel) && infoData.Number && infoData.Number.Punish > 0">
                {{ infoData.punishLevel }}
              </el-tag>
            </div>
            <div class="stuLabelCont">
              <span class="spItem" v-if="!onGetIsNull(infoData.class)">
                {{ infoData.class }}
              </span>
              <span class="spItem" v-if="!onGetIsNull(infoData.studentNo)">
                {{ infoData.studentNo }}
              </span>
              <span class="spItem" v-if="!onGetIsNull(infoData.nation)">
                {{ infoData.nation }}
              </span>
            </div>
            <div class="stuLabelCont">
              <span class="spItem" v-if="!onGetIsNull(infoData.mobile)">
                {{ infoData.mobile }}
              </span>
              <span class="spItem" v-if="!onGetIsNull(infoData.political)">
                {{ infoData.political }}
              </span>
              <span class="spItem" v-if="!onGetIsNull(infoData.cadre)">
                {{ infoData.cadre }}
              </span>
            </div>
            <div class="stuLabelCont">
              <span class="spItem" v-if="!onGetIsNull(infoData.originalPlace)">
                {{ infoData.originalPlace }}
              </span>
            </div>

            <div class="stuAvatarCont">
              <span class="spGender VerticalMid bgBlue" v-if="onGetGender(infoData.gender)">
                <i class="fax icon">&#xe652;</i>
              </span>
              <span class="spGender VerticalMid bgRed" v-else>
                <i class="fax icon">&#xe655;</i>
              </span>
              <img :src="infoData.photo" :alt="infoData.name" class="imgAvatar" />
            </div>
          </div>
          <div class="InfoTitleCont twoTitle">
            <i class="fax icon">&#xe791;</i>
            {{ onGetTitle() }}
          </div>
          <div class="literacyFractionCont">
            <div class="fractionItem divTotalScore">
              {{ infoData.score }}
              <span class="spUnit">分</span>
            </div>
            <div class="fractionItem divEvaluation">
              <span class="spTopLabel">个人总评</span>
              {{ infoData.rating }}
            </div>
          </div>

          <div class="LiteracyDistribution" style="padding-top: 5px">
            <div class="divLabelCont">
              <span class="spTotal">
                {{ infoData.score }}
                <span class="spUnit">分</span>
              </span>
              <span class="spFont">总计</span>
            </div>
            <div v-if="arrProportion != null && arrProportion.length > 0" class="echartsBody">
              <comProportion :comID="'echartProportion'" :comHeight="550" :comColor="optColor" :comData="arrProportion" ref="echartProportion"></comProportion>
            </div>
          </div>
        </div>
        <!-- 评语 -->
        <div ref="commentpage" class="A4CardBox pageCont pageBoxComments PrintPage">
          <div>
            <PerformAncesummary style="padding-bottom: 40px" :nums="infoData.Number"></PerformAncesummary>
          </div>
          <template v-for="(item, index) in allRecord">
            <div :key="item.name + index">
              <div class="InfoTitleCont oneTitle titleBox" style="padding-bottom: 30px">
                <i class="fax icon">&#xe791;</i>
                {{ item.name }} &middot; 班主任评语
              </div>
            </div>

            <div v-for="(list, indexTwo) in item.comment" :key="list.description + indexTwo">
              <div class="divDataListCont">
                <div class="divContTitle">
                  <span class="spTitleCont">
                    {{ list.semester }}
                  </span>
                  <span class="spTitleContTwo ml-3">
                    {{ list.commentsRank }}
                  </span>
                </div>
                <div class="divCommentContent">
                  <div class="divCommFont">
                    {{ onNoHtml(list.description) }}
                  </div>
                  <div class="divOhter">班主任：{{ list.teacher }}</div>
                  <!-- <div class="divOhter">{{list.recordDate | dateformatDay}}</div> -->
                </div>
              </div>
            </div>
          </template>
        </div>
        <!-- 成绩 -->
        <template v-if="transcriptsData.length > 0">
          <div class="A4CardBox pageCont pageBoxScore PrintPage" v-for="(item, index) in transcriptsData" :key="index">
            <template v-for="(v, i) in item">
              <div class="InfoTitleCont oneTitle titleBox" style="padding-bottom: 0" :key="v.semester">
                <i class="fax icon">&#xe791;</i>
                {{ v.semester }} &middot; 成绩单
              </div>
              <div class="divDataListCont" :key="'table' + v.semester + i">
                <div class="divScoreContTwo">
                  <el-table :data="v.list" border class="elTableData" cell-class-name="tdCont2">
                    <el-table-column prop="courseName" label="课程" min-width="180"></el-table-column>
                    <el-table-column prop="peaceTime" label="平时" min-width="100"></el-table-column>
                    <el-table-column prop="midTerm" label="期中" min-width="100"></el-table-column>
                    <el-table-column prop="final" label="期末" min-width="100"></el-table-column>
                    <el-table-column prop="final" label="总评" min-width="100"></el-table-column>
                  </el-table>
                </div>
              </div>
            </template>
          </div>
        </template>

        <!-- 综合子项详情 -->

        <template v-for="(item, index) in reportData">
          <div class="A4CardBox pageCont pageBoxHeader PrintPage" :key="index + 'e'">
            <div class="InfoTitleCont oneTitle titleBox">
              综合评价 &middot;
              <span :style="{ color: item.colorVal }">{{ item.name }}</span>
            </div>
            <div class="chartBox">
              <div v-if="item.radar != null && item.radar.length != 0" class="echartsBody">
                <comChartRadar :comHeight="650" :comID="'chart_' + index" :comData="item.radar" :ref="'chart_' + index"></comChartRadar>
              </div>
            </div>
            <div class="literacyFractionCont">
              <div class="fractionItem divTotal" :style="{ color: item.colorVal }">
                <span class="spTopLabel">综合</span>
                {{ item.score }}
                <span class="spUnit">分</span>
              </div>
              <div class="fractionItem divTotal" :style="{ color: item.colorVal }">
                <span class="spTopLabel">记录</span>
                {{ item.quantity }}
                <span class="spUnit">条</span>
              </div>
            </div>
          </div>

          <div
            v-setHtml="{
              index
            }"
            class="A4CardBox pageCont pageBoxSub PrintPage divDataListCont"
            :key="index + 'd'"
          >
            <template v-for="(list, indexTwo) in onGetInfoPage(item)">
              <div v-if="indexTwo === 0" class="InfoTitleCont oneTitle titleBox" :key="list.semester + indexTwo">
                综合评价 &middot;
                <span :style="{ color: item.colorVal }">{{ item.name }}</span>
              </div>
              <div :key="indexTwo + index + item.name">
                <div class="divDataListCont">
                  <div class="divContTitle">
                    <span class="spTitleCont">
                      {{ list.semester }}
                    </span>
                  </div>
                  <div class="divRecordBox" :class="onGetClass(child.attachment)" v-for="(child, indexThr) in list.children" :key="indexThr">
                    <div class="divContBox">
                      <div class="divScore">
                        {{ child.score }}
                        <span class="spFont">分</span>
                      </div>
                      <div class="divHeader">
                        <div class="divSub">
                          <span class="spTime">{{ child.logDate | dateformatDay }}</span>
                          <span class="spType">{{ child.type }}</span>
                          <span class="spType">{{ child.configName }}</span>
                        </div>
                        <div class="divName">{{ child.name }}</div>
                      </div>
                    </div>
                    <div class="divImgContBox" v-if="!regNullArray(child.attachment)">
                      <el-row :gutter="8">
                        <el-col :span="8" v-for="(img, indexThs) in child.attachment" :key="indexThs">
                          <div class="">
                            <!-- <img style="width:210px;height:98px;object-fit: cover;margin-bottom:20px" :src="imageUrlToBase64(img)" alt=""> -->
                            <!-- -->
                            <el-image :src="imageUrlToBase64(img)" class="imgList" fit="cover" :preview-src-list="onGetListAttachment(child.attachment)"></el-image>
                          </div>
                        </el-col>
                      </el-row>
                    </div>
                  </div>
                </div>
              </div>

              <!-- </div>  -->
            </template>
          </div>
        </template>
        <!-- <div v-if="reportData!=null && reportData.length!=0">
          <div v>




          </div>
        </div> -->
        <!--页尾-->
        <div class="A4CardBox pageFooter pageCont">
          <img src="../../../assets/image/report/cover_2.png" class="bgImgBox" />
          <img src="../../../assets/image/report/cover_3.png" class="footerBox" height="650" />
          <div class="divFontBoxTwo">本报告由尧瑶科技综合评价系統自动生成</div>
          <div class="divFontBox">
            <el-row>
              <el-col :span="12">
                <div class="leftFont VerticalMid">校訓</div>
              </el-col>
              <el-col :span="12">
                <div class="rightFont VerticalMid">班訓</div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <span>建议使用Chrome浏览器进行PDF的生成操作！</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" v-print="'#printTest'" @click="onPrintReport">继 续</el-button>
      </span>
    </el-dialog>
    <div class="row">
      <div class="col-12">
        <el-button icon="el-icon-back" @click="onReturn()">返回</el-button>
        <!-- <el-button type="primary" icon="el-icon-download" v-print="'#printTest'" v-if="isPower.student_report_download"
          @click="onPrintReport">
          在线生成PDF</el-button> -->
        <el-button v-if="isChromeShow && isPower.student_report_download" type="primary" icon="el-icon-download" v-print="'#printTest'" @click="onPrintReport()">
          在线生成PDF
        </el-button>
        <el-button v-else-if="!isChromeShow && isPower.student_report_download" type="primary" @click="onDialog" icon="el-icon-download">在线生成PDF</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import '@/assets/css/Admin.css';
import comChartRadar from '@/components/Report/echarts_radar_admin.vue';
import comProportion from '@/components/Report/echarts_proportion_admin.vue';
import PerformAncesummary from '@/components/Admin/PerformAncesummary.vue';

let prevDom = [];
function inserted(target) {
  let nextDom = null;
  const height = parseFloat(getComputedStyle(target).height) - 50;
  let elementArr = Array.from(target.children);
  let elArr = [];
  let H = 0;
  try {
    elementArr.forEach((el) => {
      const elRect = el.getBoundingClientRect();
      H += elRect.height || 0;
      if (H > height) {
        elArr.push(el);
      }
    });
  } catch (error) {}

  if (elArr.length > 0) {
    if (!nextDom) {
      nextDom = target.cloneNode(false);

      // nextDom.classList.add(...['A4CardBox','pageCont','pageBoxSub','PrintPage'])
    }
    elArr.forEach((el) => {
      if (el instanceof HTMLDivElement) {
        nextDom.appendChild(el);
      }
    });
    target.insertAdjacentElement('afterend', nextDom);
    inserted(nextDom);
    if (nextDom) {
      prevDom.push(nextDom);
    }
  }
}
export default {
  components: {
    comChartRadar,
    comProportion,
    PerformAncesummary
  },
  directives: {
    setHtml: {
      inserted
    }
  },
  data() {
    return {
      urlPhoto: null,
      // 加载动画
      fullscreenLoading: false,
      // 个人信息
      infoData: {
        studentId: '',
        // 总分
        score: 0,
        // 总评
        rating: '',
        // 综合记录数
        record: null
      },
      // 综合报告标题
      reportTitle: '',
      // 报告数据
      reportData: [],
      // 展示类型 0：毕业所有学年 1：学年
      isType: 0,
      // 当前学年选项
      valYear: [],
      // 学年下拉选择数据
      optYear: [],
      currsorIndex: 0,
      isChromeShow: false, // 判断当前浏览器是否为Chrome浏览器
      dialogVisible: false,
      // 当前学期选项
      nowSemester: {
        semesterId: null,
        name: null
      },
      // 学期下拉选择数据
      optSemester: [],

      // 颜色数组
      optColor: ['#7E66F6', '#3191D9', '#4FBD76', '#F9BB4A', '#F50737', '#8CB93D', '#3AAF9D', '#4261E4', '#A75CE7', '#6610f2', '#e83e8c', '#fd7e14', '#28a745', '#17a2b8'],
      // 页面显示颜色的数组
      titlePoint: [],
      // 综合分布情况统计图
      arrProportion: [],
      // 与全班对比统计图
      arrContrast: [],
      // 评语
      arrComments: [],
      // 成绩数组
      arrResults: [],
      // 荣誉
      arrHonor: [],
      // 权限管理
      isPower: {
        //增删改查
        student_report_download: false
      },
      arrAllSemester: [],
      // 时间
      nowDate: timeTransform(new Date(), 'YYYY年MM月'),
      CommentsNum: 0,
      RecordNum: 0,
      allRecord: [],
      allSemester: [],

      //成绩单数据
      transcriptsData: []
    };
  },
  watch: {
    arrProportion: {
      handler: function (newVar, oldVar) {
        let that = this;
        that.arrProportion = newVar;
        if (that.$refs.echartProportion != undefined) {
          that.$refs.echartProportion.onGetRadar(newVar);
        }
      },
      deep: true
    },
    arrContrast: {
      handler: function (newVar, oldVar) {
        let that = this;
        that.arrContrast = newVar;
        if (that.$refs.echartContrastBar != undefined) {
          that.$refs.echartContrastBar.onGetRadar(newVar);
        }
        //
      },
      deep: true
    },
    reportData: {
      handler: function (newVar, oldVar) {
        let that = this;
        that.reportData = newVar;
        if (newVar.radar != null && newVar.radar.length != 0) {
          for (const [index, item] of newVar.radar) {
            if (that.$refs['chart_' + index] != undefined) {
              that.$refs['chart_' + index].onGetRadar(newVar);
            }
          }
        }
      },
      deep: true
    },
    reportTitle: {
      handler: function (newVar, oldVar) {
        this.reportTitle = newVar;
      },
      deep: true
    },
    infoData: {
      handler: function (newVar, oldVar) {
        this.infoData = newVar;
      },
      deep: true
    }
  },
  mounted() {
    let newDom = null;
    let prevDoms = [];
    const dom = this.$refs.commentpage;
    const Mo = new MutationObserver((mutationsList) => {
      const len = mutationsList.length;
      let target = mutationsList[len - 1].target;
      const height = parseFloat(getComputedStyle(target).height);
      let elementArr = Array.from(target.children);
      let elArr = [];
      let H = 0;
      try {
        elementArr.forEach((el) => {
          const elRect = el.getBoundingClientRect();
          H += elRect.height || 0;
          if (H > height) {
            elArr.push(el);
          }
        });
      } catch (error) {}
      if (elArr.length > 0) {
        if (!newDom) {
          newDom = target.cloneNode(false);
        } else {
          try {
            Array.from(newDom.children).forEach((el) => {
              newDom.removeChild(el);
            });
          } catch (err) {}
        }
        elArr.forEach((el) => {
          target.removeChild(el);
          if (el instanceof HTMLDivElement) {
            newDom.appendChild(el.cloneNode(true));
          }
        });
        target.insertAdjacentElement('afterend', newDom);
      } else {
        if (newDom) {
          if (Array.from(newDom.children).length < prevDoms.length) {
          } else {
            try {
              // Array.from(newDom.children).forEach(el=>{
              //   newDom.removeChild(el)
              // })
              newDom.remove();
              console.log(newDom, 'newDom');
              newDom = null;
            } catch (err) {}
          }
        }
      }
      prevDoms = elementArr;
    });
    Mo.observe(dom, { childList: true });
  },
  methods: {
    /**
     * @description 点击打印触发事件
     */
    onPrintReport() {
      let that = this;
      that.dialogVisible = false;
      if (!regNull(that.infoData.name) && !regNull(that.reportTitle)) {
        document.title = that.infoData.name + '_' + that.reportTitle + '综合评价报告';
      } else {
        document.title = '综合报告 - ' + schoolName();
      }
    },
    handleClose() {
      let that = this;
      that.dialogVisible = false;
    },
    /**
     * @description 非Chrome浏览器事件
     */
    onDialog() {
      let that = this;
      that.dialogVisible = true;
    },
    onGetListAttachment(objItem) {
      let that = this;
      return objItem.map((item) => {
        return that.$ServerUrl() + item;
      });
    },
    //异步执行
    imageUrlToBase64(objItem) {
      let that = this;
      return that.$ServerUrl() + objItem;
    },

    /**
     * @param {Object} objData
     */
    onGetInfoPage(objData) {
      let that = this;
      let tempData = [];
      let tempDataTwo = [];
      if (!regNullArray(that.optSemester)) {
        that.optSemester.forEach((item, index) => {
          let iCount = 0;
          if (!regNull(objData.record.find((fd) => fd.semesterId == item.semesterId))) {
            tempData.push({
              semester: item.name,
              semesterId: item.semesterId,
              children: []
            });
            objData.record
              .filter((fl) => fl.semesterId == item.semesterId)
              .forEach((list) => {
                let tpImgList = regNull(list.attachment) ? null : list.attachment.split('|');
                let tpImgNum = regNullArray(tpImgList) ? 0 : regNullArray(tpImgList).length;
                if ((iCount >= 0 && iCount <= 4) || (iCount > 4 && iCount < 7 && tpImgNum < 4)) {
                  tempData[tempData.length - 1].children.push({
                    attachment: tpImgList,
                    configName: list.configName,
                    logDate: list.logDate,
                    name: list.name,
                    score: list.score,
                    type: list.type
                  });
                  if (regNullArray(tpImgList)) {
                    iCount += 1;
                  } else {
                    if (tpImgList.length > 0 && tpImgList.length < 4) {
                      iCount += 2;
                    } else if (tpImgList.length > 3 && tpImgList.length < 7) {
                      iCount += 3;
                    } else if (tpImgList.length > 6 && tpImgList.length < 10) {
                      iCount += 4;
                    }
                  }
                } else {
                  if (regNullArray(tpImgList)) {
                    iCount = 1;
                  } else {
                    if (tpImgList.length > 0 && tpImgList.length < 4) {
                      iCount = 2;
                    } else if (tpImgList.length > 3 && tpImgList.length < 7) {
                      iCount = 3;
                    } else if (tpImgList.length > 6 && tpImgList.length < 10) {
                      iCount = 4;
                    }
                  }
                  tempData.push({
                    semester: item.name,
                    semesterId: item.semesterId,
                    children: [
                      {
                        attachment: regNull(list.attachment) ? null : list.attachment.split('|'),
                        configName: list.configName,
                        logDate: list.logDate,
                        name: list.name,
                        score: list.score,
                        type: list.type
                      }
                    ]
                  });
                }
              });
          }
        });
      }

      tempData.reverse();

      return tempData;
    },
    /**
     * @description 获取学校名称
     */
    onGetSchoolName() {
      return schoolName();
    },

    /**
     * @description 总评标题，根据学期，学年显示总评名称
     */
    onGetTitle() {
      let that = this;
      if (!regNull(that.nowSemester.semesterId)) {
        return '学期总评';
      } else if (!regNull(that.currsorIndex != 0)) {
        return '学年总评';
      } else {
        return '总评';
      }
    },
    /**
     * @description 对没有图片的记录进行添加样式名
     * @param {Object} objItem
     */
    onGetClass(objItem) {
      if (regNullArray(objItem)) {
        return 'noImage';
      }
      return '';
    },
    /**
     * @description 选择学年
     * @param {Object} objItem
     */
    onChangeYear(objItem, objIndex) {
      let that = this;
      that.currsorIndex = objIndex;
      that.optSemester = objItem.children;
      that.nowSemester.semesterId = null;
      that.nowSemester.name = null;

      prevDom.forEach((el) => {
        if (el instanceof HTMLDivElement) {
          el.remove();
        }
      });
      prevDom = [];
      onGetData(that);
    },
    /**
     * @description 选择学期
     * @param {Object} objVal
     */
    onChangeSemester(objVal) {
      let that = this;
      let temp = that.optSemester.find((item) => item.semesterId == objVal);
      if (!regNull(temp)) {
        that.nowSemester.name = temp.name;
        that.nowSemester.nowYear = temp.yearId;
        that.currsorIndex = [that.optYear.findIndex((item) => item.yearId == temp.yearId)];
      }

      prevDom.forEach((el) => {
        if (el instanceof HTMLDivElement) {
          el.remove();
        }
      });
      prevDom = [];
      onGetData(that);
    },
    /**
     * @description 去除HTML
     * @param {Object} objVal
     */
    onNoHtml(objVal) {
      return delHtml(objVal);
    },
    /**
     * @description 返回
     */
    onReturn() {
      let that = this;
      back(that);
    },
    /**
     * @description 是否为空值
     * @param {Object} _val
     */
    onGetIsNull(_val) {
      return regNull(_val); //true:是空值
    },
    /**
     * @description 根据性别返回 Boolen值 true:男，false：女
     * @param {Object} _val
     */
    onGetGender(_val) {
      return isGender(_val);
    },

    // 格式化时间
    onDateFormat(_val) {
      return dateFormat(_val, '.', 'YYYY-MM-DD');
    },
    // 图片处理
    onGetImg(that, _Value) {
      if (regNull(_Value)) {
        return [];
      } else {
        let arrImg = _Value.split('|');
        return arrImg.map((item) => that.$ServerUrl() + item);
      }
    },
    //去除数组内的重复
    onArrUnique(arr) {
      const res = new Map();
      return arr.filter((arr) => !res.has(arr.semesterId) && res.set(arr.semesterId, 1));
    }
  },
  created() {
    let that = this;
    document.title = '综合报告 - ' + schoolName();
    isPower(that, 'student_report_download', 'student_report_download');
    that.isChromeShow = window.navigator.userAgent.indexOf('Chrome') !== -1;
    // 判断传值
    that.optYear = [
      {
        yearId: null,
        name: '全部学年',
        children: []
      }
    ];
    that.fullscreenLoading = true;
    if (!regNull(that.$route.params.id)) {
      // 获取学生的学期信息
      ajaxGet(that, '/api/admin/semester/student/' + that.$route.params.id, null, function (resSemester) {
        if (!regNullArray(resSemester)) {
          // 全部学年 对应所有学期
          that.optYear[0].children = resSemester;
          // 默认所有学期
          that.optSemester = resSemester;
          // 生成各学年数据

          resSemester.forEach((item) => {
            if (regNull(that.optYear.find((list) => list.yearId == item.yearId))) {
              that.optYear.push({
                yearId: item.yearId,
                name: item.year,
                children: resSemester.filter((fl) => fl.yearId == item.yearId)
              });
            }

            if (item.isCurrent == true) {
              that.nowSemester.semesterId = item.semesterId;
              that.nowSemester.name = item.name;
              that.nowSemester.nowYear = item.yearId;
            }
          });
          if (!regNull(that.nowSemester.semesterId)) {
            that.currsorIndex = [that.optYear.findIndex((item) => item.yearId == that.nowSemester.nowYear)];
            that.optSemester = that.optYear.find((item) => item.yearId == that.nowSemester.nowYear).children;
          }

          for (let i = resSemester.length - 1; i >= 0; i--) {
            that.allSemester.push(resSemester[i]);
          }
        }
        // 获取学生基本信息
        ajaxGet(that, '/api/admin/student/' + that.$route.params.id, null, function (resData) {
          that.infoData = resData || {};

          if (that.infoData.photo) {
            that.infoData.photo = that.$ServerUrl() + resData.photo;
          } else {
            that.infoData.photo;
            if (that.infoData.gender == '男') {
              that.infoData.photo = require('@/assets/image/defaultAvatar_Male.jpg');
            }

            if (that.infoData.gender == '女') {
              that.infoData.photo = require('@/assets/image/defaultAvatar_Female.jpg');
            }
          }

          that.fullscreenLoading = false;
          onGetData(that);
        });
      });
    }
  }
};

/**
 * @description 获取数据
 * @param {Object} that
 */
function onGetData(that) {
  that.allRecord = [];

  that.fullscreenLoading = true;
  // 设置标题
  if (!regNull(that.nowSemester.semesterId)) {
    that.reportTitle = that.nowSemester.name;
  } else if (!regNull(that.optYear[that.currsorIndex].yearId)) {
    that.reportTitle = that.optYear[that.currsorIndex].name;
  } else {
    that.reportTitle = that.infoData.entryYear + '届';
  }

  let _data = {
    StudentId: that.infoData.studentId,
    SemesterId: that.nowSemester.semesterId,
    YearId: that.optYear[that.currsorIndex].yearId
  };

  let apiUrl = '/api/admin/growtharchives/' + that.infoData.studentId;
  let apiUrlTwo = '/api/admin/growtharchives/classcadresearch/' + that.infoData.studentId;
  if (!regNull(that.nowSemester.semesterId)) {
    apiUrl += '?semesterId=' + that.nowSemester.semesterId;
    apiUrlTwo += '?semesterId=' + that.nowSemester.semesterId;
  } else if (!regNull(that.optYear[that.currsorIndex].yearId)) {
    apiUrl += '?yearId=' + that.optYear[that.currsorIndex].yearId;
    apiUrlTwo += '?yearId=' + that.optYear[that.currsorIndex].yearId;
  }

  // 学生职务
  ajaxGet(that, apiUrlTwo, null, function (resCadre) {
    that.infoData.cadre = '';
    if (!regNullArray(resCadre)) {
      if (!regNull(resCadre.find((item) => item.departureDate == '0001-01-01T00:00:00'))) {
        resCadre.forEach((item) => {
          if (item.departureDate == '0001-01-01T00:00:00') {
            that.infoData.cadre += item.position + '、';
          }
        });
      } else {
        let tpCadre = resCadre[0].departureDate;
        that.infoData.cadre = resCadre[0].position;
        resCadre.forEach((item) => {
          if (!compareDate(item.departureDate, tpCadre)) {
            tpCadre = item.departureDate;
            that.infoData.cadre = item.position;
          }
        });
      }
    }
    // 获取档案记录
    ajaxGet(that, apiUrl, null, function (resNumber) {
      that.infoData.Number = resNumber;
      // Activity 参与活动 | LiteracyCourse 综合课堂  | MoralityLog 行为规范 | Prize 竞赛获奖 | SelfReport 上报审核

      // ajaxGet(that, '/api/admin/growtharchives/activitysearch', _data, function(resData) {
      //   setTimeout(()=>{
      //       if(Array.isArray(resData.items)){
      //         that.infoData.Number.Activity = resData.items.length || 0
      //       }else{
      //         that.infoData.Number.Activity = 0
      //       }
      //   })

      // })

      // 班主任评语
      ajaxGet(that, '/api/admin/growth/studentcommensearch', _data, function (resComments) {
        // 学生成绩
        ajaxGet(that, '/api/admin/growth/academicsearch', _data, function (resScore) {
          // 个人总评
          ajaxGet(that, '/api/admin/growth/generalcomment', _data, function (resChartData) {
            // 综合分布情况
            ajaxGet(that, '/api/admin/growth/chartdata', _data, function (resChart) {
              that.allRecord = [];
              // 判断是学期还是学年
              if (!regNull(that.nowSemester.semesterId)) {
                // 学期
                that.allRecord = [
                  {
                    name: that.nowSemester.name,
                    score: resScore.filter((fl) => fl.semester == that.nowSemester.name),
                    comment: resComments.filter((fl) => fl.semesterId == that.nowSemester.semesterId)
                  }
                ];
              } else if (!regNull(that.optYear[that.currsorIndex].yearId)) {
                // 学年
                that.arrResults = [];
                if (!regNullArray(resScore)) {
                  resScore.forEach((item) => {
                    if (regNull(that.arrResults.find((list) => list.semester == item.semester))) {
                      that.arrResults.push({
                        semester: item.semester,
                        children: resScore.filter((fl) => fl.semester == item.semester)
                      });
                    }
                  });
                }

                that.allRecord = [
                  {
                    name: that.optYear[that.currsorIndex].name,
                    score: that.arrResults,
                    comment: resComments
                  }
                ];
              } else {
                // 所有学年
                for (let i = that.optYear.length - 1; i >= 0; i--) {
                  if (that.optYear[i].yearId != null) {
                    let scoreData = resScore.filter((flTwo) => flTwo.yearId == that.optYear[i].yearId);
                    that.arrResults = [];
                    if (!regNullArray(scoreData)) {
                      scoreData.forEach((itemTwo) => {
                        if (regNull(that.arrResults.find((list) => list.semester == itemTwo.semester))) {
                          that.arrResults.push({
                            semester: itemTwo.semester,
                            children: scoreData.filter((fl) => fl.semester == itemTwo.semester)
                          });
                        }
                      });
                    }
                    that.allRecord.push({
                      name: that.optYear[i].name,
                      score: that.arrResults,
                      comment: resComments.filter((flTwo) => flTwo.yearId == that.optYear[i].yearId)
                    });
                  }
                }
              }

              let tempArr = [];
              if (!regNullArray(resScore)) {
                resScore.forEach((v, i) => {
                  const o = tempArr.find((vv) => vv.semesterId === v.semesterId);

                  if (o) {
                    o.list.push(v);
                  } else {
                    tempArr.push({
                      semester: v.semester,
                      semesterId: v.semesterId,
                      list: [v]
                    });
                  }
                });
              }

              let tempArr2 = [];
              tempArr.forEach((v, i) => {
                if (i % 2 == 0) {
                  let a = [];
                  let t1 = tempArr[i];
                  let t2 = tempArr[i + 1];
                  if (t1) {
                    a.push(t1);
                  }
                  if (t2) {
                    a.push(t2);
                  }
                  tempArr2.push(a);
                }
              });
              that.transcriptsData = tempArr2;
              // 个人总评
              that.infoData.score = resChartData.score; // 总分
              that.infoData.rating = regNull(resChartData.generalComment) ? '暂未评价' : resChartData.generalComment; // 总评
              that.infoData.record = resChartData.logQuantity; // 综合记录数

              // 处理综合评价数据
              if (!regNullArray(resChart)) {
                // 与全班中位分对比的统计图需要的数据
                that.arrContrast = resChart;
                // 设置需要统计图的颜色数量
                that.titlePoint = that.optColor.slice(0, resChart.length);
                // 生成综合分布情况统计图
                that.arrProportion = [];
                that.reportData = [];
                resChart.forEach((item, index) => {
                  // 综合分布
                  that.arrProportion.push({
                    name: item.name,
                    value: item.score + item.baseScore
                  });
                  _data.ConfigId = item.configId;

                  let tpUpData = {
                    StudentId: that.infoData.studentId,
                    SemesterId: that.nowSemester.semesterId,
                    YearId: that.optYear[that.currsorIndex].yearId,
                    ConfigId: item.configId
                  };
                  that.fullscreenLoading = true;
                  // 获取学生综合评价评价雷达图数据
                  ajaxGet(that, '/api/admin/growth/chartdata', tpUpData, function (resSub) {
                    // 根据条件获取综合评价记录
                    ajaxGet(that, '/api/admin/growth/searchnopage', tpUpData, function (resList) {
                      that.reportData.push({
                        configId: item.configId,
                        name: item.name,
                        median: item.median,
                        quantity: item.quantity,
                        score: item.score,
                        colorVal: that.optColor[index],
                        radar: resSub, // 雷达图
                        record: resList, //
                        arrSemester: onArrUnique(
                          resList.map((mp) => ({
                            value: mp.semesterId,
                            label: mp.semester
                          }))
                        )
                      });
                      that.fullscreenLoading = false;
                    });
                  });
                });
              } else {
                that.fullscreenLoading = false;
              }
            });
          });
        });
      });
    });
  });
}
</script>
<style lang="less"></style>

<style scoped="scoped" lang="less">
.A4CardBox {
  // border-bottom: 1px solid;
  background-color: #ffffff;
  width: 100%;
  height: 1410px;
  margin: 50px auto;
  overflow: hidden;
  box-shadow: 0px 0px 20px 0px rgba(157, 157, 157, 0.75);
  -webkit-box-shadow: 0px 0px 20px 0px rgba(157, 157, 157, 0.75);
  -moz-box-shadow: 0px 0px 20px 0px rgba(157, 157, 157, 0.75);
  position: relative;
  // border:2px solid #000;
}

// 通用页面
.pageCont {
  padding: 0px 30px;

  .InfoTitleCont {
    letter-spacing: 1px;
    color: #212529;
    position: relative;
    font-size: 32px;

    .icon {
      font-size: 34px;
    }

    .enBox {
      text-transform: uppercase;

      .spFont {
        font-size: 26px;
        color: #212529;

        &:first-child {
          padding-right: 10px;
          color: #212529;
        }
      }
    }
  }
}
.ReportBody {
  height: 100%;
  width: 910px;
  margin: 50px auto;
}

// 页头
.pageHeader {
  .divLogo {
    padding-left: 70px;
    padding-top: 60px;
  }

  .divImgBox {
    position: absolute;
    top: 0;
    right: 0;

    .num1 {
      position: absolute;
      top: 105px;
      left: 120px;
      width: 120px;
      height: 120px;

      .bgColor {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #eda317;
        border-radius: 50%;
        opacity: 0.3;
      }

      .fontBox {
        position: relative;
        z-index: 10;
        background-color: #eda317;
        border-radius: 50%;
        width: 105px;
        height: 105px;
        color: #ffffff;
        font-size: 26px;
        line-height: 28px;
        letter-spacing: 2px;
      }
    }

    .num2 {
      position: absolute;
      top: 280px;
      left: 120px;
      width: 120px;
      height: 120px;

      .bgColor {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #37a0ee;
        border-radius: 50%;
        opacity: 0.3;
      }

      .fontBox {
        position: relative;
        z-index: 10;
        background-color: #37a0ee;
        border-radius: 50%;
        width: 105px;
        height: 105px;
        color: #ffffff;
        font-size: 26px;
        line-height: 28px;
        letter-spacing: 2px;
      }
    }

    .num3 {
      position: absolute;
      bottom: 190px;
      right: 10px;
      width: 120px;
      height: 120px;

      .bgColor {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #da524d;
        border-radius: 50%;
        opacity: 0.3;
      }

      .fontBox {
        position: relative;
        z-index: 10;
        background-color: #da524d;
        border-radius: 50%;
        width: 105px;
        height: 105px;
        color: #ffffff;
        font-size: 26px;
        line-height: 28px;
        letter-spacing: 2px;
      }
    }
  }

  .divTitleBox {
    position: absolute;
    bottom: 170px;
    left: 0;
    right: 0;
    background-color: #37a1ed;
    width: 100%;

    .divTitle {
      margin-top: 5px;
      border-top: 4px solid #ffffff;
    }

    .divNameBox {
      color: #ffffff;
      width: 100%;
      text-align: right;
      font-size: 66px;
      padding-right: 40px;
      padding-top: 10px;
      font-weight: bold;
    }

    .divSubName {
      color: #ffffff;
      width: 100%;
      text-align: right;
      padding-right: 40px;
      font-size: 42px;
      padding-bottom: 20px;
    }
  }

  .divSchoolName {
    position: absolute;
    bottom: 40px;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 18px;
  }
}

// 页尾
.pageFooter {
  background-color: #00a1e9;
  position: relative;

  .divFontBox {
    position: relative;
    color: #ffffff;
    z-index: 10;

    width: 100%;
    height: 100%;

    .leftFont {
      width: 150px;
      text-align: center;
      font-family: 'Mianfeiziti';
      font-size: 200px;
      margin: 0 auto;
      padding-top: 10%;
      padding-left: 15%;
    }

    .rightFont {
      padding-top: 100%;
      padding-right: 15%;
      margin: 0 auto;
      width: 150px;
      text-align: center;
      font-family: 'Mianfeiziti';
      font-size: 200px;
    }
  }

  .divFontBoxTwo {
    color: #ffffff;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 30px;
    z-index: 99;
    text-align: center;
    font-size: 16px;
  }

  .bgImgBox {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .footerBox {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
  }
}

// 第1页
.pageBoxOne {
  .baseInfoCont {
    position: relative;
    padding-top: 50px;
    padding-left: 30px;
    padding-bottom: 50px;

    .stuName {
      font-size: 46px;
      letter-spacing: 1px;
      color: #3698fc;
      margin-bottom: 15px;
    }

    .stuLabelCont {
      margin-bottom: 15px;

      .spItem {
        border-right: 2px solid #e3e3e3;
        color: #323e56;
        font-size: 22px;
        padding: 0px 20px;
        letter-spacing: 1px;
        color: #606266;

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          border-right: none;
        }
      }
    }

    .stuAvatarCont {
      position: absolute;
      top: 20px;
      bottom: 0;
      right: 30px;

      .imgAvatar {
        width: 200px;
        border-radius: 4px;
        border: 1px solid #eeeeee;
      }
    }

    .spGender {
      position: absolute;
      top: -18px;
      right: -18px;
      color: #ffffff;
      font-size: 18px;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      line-height: 50px;

      .icon {
        font-size: 42px;
      }
    }
  }

  .literacyFractionCont {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 20px;

    .fractionItem {
      display: inline-block;
      color: #256fbc;
      position: relative;
    }

    .divTotalScore {
      font-family: 'OswaldLight';
      font-size: 100px;
      letter-spacing: 2px;

      .spUnit {
        font-size: 22px;
        color: #8d8f91;
        margin-left: -15px;
      }
    }

    .divEvaluation {
      font-size: 72px;
      font-weight: bold;
      letter-spacing: 2px;
      margin-left: 50px;
      margin-right: 50px;

      .spTopLabel {
        color: #959595;
        letter-spacing: 1px;
        font-weight: normal;
        position: absolute;
        top: -20px;
        left: 0;
        font-size: 22px;
      }
    }

    .divTotal {
      font-family: 'OswaldLight';
      font-size: 62px;
      letter-spacing: 2px;
      min-width: 100px;
      text-align: center;

      .spTopLabel {
        color: #959595;
        letter-spacing: 1px;
        font-weight: normal;
        position: absolute;
        top: -15px;
        left: 0;
        font-size: 22px;
      }

      .spUnit {
        font-size: 26px;
        color: #8d8f91;
        margin-left: 5px;
      }
    }
  }

  .oneTitle {
    margin-top: 50px;
  }

  .twoTitle {
    margin-top: 70px;
  }

  .divFileRecords {
    .fileList {
      width: 200px;
      height: 200px;
      margin: 25px auto;
      border-radius: 50%;
      background-image: linear-gradient(to right bottom, #256fbc, #39afd6);

      .fileCont {
        background-color: #ffffff;
        width: 160px;
        height: 160px;
        border-radius: 50%;
        box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.5);
        -webkit-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.5);
        -moz-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.5);

        .topCont {
          margin-top: 10px;
          height: 70px;
          font-family: 'OswaldLight';
          color: #256fbc;
          font-size: 28px;
          font-weight: bold;
        }

        .bottomCont {
          height: 70px;
          margin-top: 10px;
          width: 160px;
          padding-left: 5px;
          padding-right: 5px;
          border-top: 3px solid #eeeeee;
          margin: 0 auto;
          font-size: 18px;
          color: #606166;
        }
      }
    }
  }
}

// 第2页
.pageBoxTwo {
  .InfoSubTitle {
    text-align: center;
    font-size: 48px;
    letter-spacing: 1px;
    color: #323e56;
    margin-bottom: 10px;

    .enBox {
      text-transform: uppercase;
    }

    .divIconCont {
      text-align: center;
    }
  }

  .InfoSubTitle span {
    font-size: 32px;
    padding: 0px 2px;
  }
}

.pageBoxHeader {
  padding-top: 100px;

  .titleBox {
    padding-bottom: 50px;
    text-align: center;
  }

  .chartBox {
    padding-top: 50px;
  }

  .literacyFractionCont {
    text-align: center;
    margin-top: 60px;
    display: flex;

    .fractionItem {
      color: #3698fc;
      position: relative;
      width: 100%;
    }

    .divTotal {
      font-family: 'OswaldLight';
      font-size: 122px;
      font-weight: bold;
      letter-spacing: 2px;
      min-width: 100px;
      text-align: center;

      .spTopLabel {
        color: #959595;
        letter-spacing: 1px;
        font-weight: normal;
        font-size: 26px;
      }

      .spUnit {
        font-size: 26px;
        color: #8d8f91;
        margin-left: 5px;
        font-weight: normal;
      }
    }
  }
}

// 综合之项
.pageBoxSub {
  padding-top: 50px;

  .titleBox {
    padding-bottom: 0px;
    text-align: center;
  }

  .divDataListCont {
    position: relative;
    padding-top: 10px;

    .divContTitle {
      display: flex;
      margin-bottom: 20px;
      margin-top: 20px;
      margin-left: 20px;

      .spTitleCont {
        background-color: #0065b3;
        color: #ffffff;
        padding: 10px 20px;
        letter-spacing: 1px;
        border-radius: 5px;
        font-size: 22px;
      }
    }

    .divRecordBox {
      padding-top: 30px;
      padding-bottom: 40px;
      position: relative;
      padding-left: 15px;
      padding-right: 15px;
      border-bottom: 1px dotted #eeeeee;
      margin-bottom: 10px;

      &:last-child {
        border-bottom: none;
        margin-bottom: 0px;
      }

      .divContBox {
        width: 100%;
        display: flex;

        .divScore {
          height: 85px;
          width: 140px;
          border-radius: 5px;
          font-family: 'OswaldLight';
          overflow: hidden;
          font-style: italic;
          font-size: 72px;
          font-weight: bolder;
          line-height: 80px;
          color: #909199;
          opacity: 0.8;
          position: relative;

          .spFont {
            font-size: 24px;
            bottom: 0;
            line-height: 18px;
            right: 10px;
          }
        }

        .divHeader {
          position: relative;

          .divSub {
            display: flex;

            .spTime {
              color: #606266;
              border: 1px dotted #606266;
              font-size: 22px;
              height: 48px;
              line-height: 48px;
              padding-left: 18px;
              padding-right: 18px;
              border-radius: 5px;
            }

            .spType {
              margin-left: 15px;
              background-color: #37a0ee;
              color: #ffffff;
              font-size: 22px;
              height: 48px;
              line-height: 48px;
              padding-left: 15px;
              padding-right: 15px;
              border-radius: 3px;
              opacity: 0.8;
            }
          }

          .divName {
            width: 100%;
            padding-top: 15px;
            color: #303133;
            font-size: 22px;
          }
        }
      }

      .divImgContBox {
        padding-top: 15px;
        padding-left: 140px;

        .imgList {
          height: 110px;
          width: 100%;
          border-radius: 5px;
          border: 1px solid #dddddd;
          padding: 5px;
        }
      }
    }

    .noImage {
      padding-top: 25px;
      padding-bottom: 20px;
    }
  }
}

// 成绩单
.pageBoxScore {
  padding-top: 90px;

  .titleBox {
    padding-bottom: 20px;
  }

  .divDataListCont {
    position: relative;
    padding-bottom: 50px;
    padding-top: 50px;

    .divContTitle {
      display: flex;

      .spTitleCont {
        background-color: #0065b3;
        color: #ffffff;
        padding: 10px 20px;
        letter-spacing: 1px;
        border-radius: 5px;
      }
    }
  }

  .divScoreCont {
    padding-top: 15px;
    position: relative;
  }

  .divScoreContTwo {
    display: flex;

    .divItem {
      width: 100%;
      padding-right: 10px;
    }
  }
}

// 班主任评语
.pageBoxComments {
  padding-top: 100px;

  .titleBox {
    padding-bottom: 40px;
  }

  .divDataListCont {
    position: relative;
    padding-bottom: 50px;
    padding-top: 50px;

    .divContTitle {
      display: flex;

      .spTitleCont {
        background-color: #0065b3;
        color: #ffffff;
        padding: 10px 20px;
        letter-spacing: 1px;
        border-radius: 5px;
        font-size: 26px;
      }

      .spTitleContTwo {
        border: 2px solid #0065b3;
        color: #0065b3;
        font-weight: bold;
        padding: 10px 20px;
        letter-spacing: 1px;
        border-radius: 5px;
        font-size: 26px;
      }

      .spCommentsRank {
        padding: 5px 20px;
        letter-spacing: 1px;
        font-size: 18px;
        font-weight: bold;
      }
    }
  }

  .divCommentContent {
    padding-top: 15px;
    padding-left: 30px;
    padding-right: 30px;
    position: relative;

    .divRank {
      position: absolute;
      right: 100px;
      color: #2490ec;
      bottom: 40px;
      top: 0px;
      font-size: 82px;
      font-weight: bolder;
      transform: rotate(9deg);
      -ms-transform: rotate(9deg);
      /* Internet Explorer */
      -moz-transform: rotate(9deg);
      /* Firefox */
      -webkit-transform: rotate(9deg);
      /* Safari 和 Chrome */
      -o-transform: rotate(9deg);
      /* Opera */
      opacity: 0.3;
    }

    .divCommFont {
      position: relative;
      z-index: 10;
      text-indent: 2em;
      letter-spacing: 1px;
      color: #606266;
      font-size: 18px;
      margin-bottom: 20px;
      margin-top: 20px;
    }

    .divOhter {
      position: relative;
      z-index: 10;
      text-align: right;
      font-size: 22px;
      color: #3e3e3e;

      &:first-child {
        padding-top: 30px;
      }
    }
  }
}

.position-relative {
  position: relative;
}

.divPageHeaderBox {
  display: flex;
  padding-bottom: 5px;

  .ulTabsBox {
    flex: 1;
    display: flex;
    padding-top: 10px;
  }

  .liTab {
    padding-left: 15px;
    padding-right: 15px;
    font-size: 18px;
    border-right: 2px solid #cecece;
    color: #767676;
    cursor: pointer;

    &:hover {
      color: #3e3e3c;
    }

    &:last-child {
      border-right: none;
    }
  }

  .divSemesterBox {
    display: flex;

    .divTitle {
      height: 40px;
      line-height: 40px;
      letter-spacing: 2px;
      color: #323e56;
      padding-right: 10px;
    }
  }
}

.divPageHeaderBox .liAction:hover,
.divPageHeaderBox .liAction {
  color: #3364ff;
}

.elImageBox {
  border: 1px solid #dddddd;
  border-radius: 5px;
  width: 33px;
  height: 33px;
  margin: 0;
  position: relative;
}

.divNoImage {
  font-size: 12px;
  color: #767676;
}

.divYearTitle {
  font-size: 18px;
  margin-bottom: 30px;
}

.divYearTitle span {
  background-color: #c2e5fe;
  padding: 10px 30px;
}

.divSearchBox {
  position: absolute;
  top: -70px;
  left: 50px;
  z-index: 10;
  display: flex;
}

.divSearchBox .spSearch {
  padding-left: 10px;
  padding-right: 10px;
}

.DetailsCont {
  padding-right: 30px;
  padding-left: 30px;
  max-width: 1280px;
  margin: 5px auto;
}

.ReportName {
  padding-top: 15px;
  text-align: center;
  color: #323e56;
}

.ReportName .spMiddot {
  width: 30px;
  height: 30px;
  position: relative;
  display: inline-block;
}

.ReportName .spMiddot .spIcon {
  font-size: 5px;
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  text-align: center;
}

.ReportName .spNameCont {
  width: auto;
  font-size: 36px;
  letter-spacing: 1px;
  position: relative;
}

.ReportName .spNameCont .spTitleBg {
  position: absolute;
  height: 10px;
  left: 0;
  right: 0;
  bottom: -15px;
  /* background-image: url(../../../assets/image/line_bg.png); */
  background-repeat: repeat-x;
}

.ReportName .titleSubCont {
  padding-top: 20px;
}

.ReportName .titleSubCont span {
  font-size: 18px;
  padding: 0px 2px;
}

.LiteracyDistribution {
  margin: 30px auto;
  position: relative;
  padding-top: 120px;

  .labelCont {
    margin: 0px auto;
    color: #939393;
  }

  .divLabelCont {
    position: absolute;
    top: 240px;
    left: 0;
    right: 0;
    text-align: center;

    .spTotal {
      font-size: 48px;
      font-family: 'OswaldLight';
      display: block;
      letter-spacing: 1px;
      color: #323e56;
    }

    .spUnit {
      font-size: 18px;
      color: #4b586f;
    }

    .spFont {
      font-size: 18px;
      color: #4b586f;
    }
  }
}

.chartItemCont {
  position: relative;
}

.chartItemCont .divLabelCont {
  position: absolute;
  top: -68px;
  right: 0;
  color: #939393;
  font-size: 18px;
}

.chartItemCont .divItemInfo {
  position: absolute;
  left: 0;
  top: 50px;
}

.chartItemCont .divItemInfo .divInfoType {
  height: 80px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #efefef;
  border-radius: 5px;
  color: #ffffff;
  font-size: 42px;
}

.chartItemCont .divItemInfo .divItemNum {
  font-family: 'OswaldLight';
  font-size: 52px;
  letter-spacing: 2px;
  position: relative;
  margin-top: 50px;
}

.chartItemCont .divItemInfo .spUnit {
  color: #323e56;
  font-size: 26px;
  padding-left: 5px;
}

.divDataListCont .tbTitleCont {
  position: absolute;
  background-color: #c2e5fe;
  color: #323e56;
  padding: 10px 20px;
  letter-spacing: 1px;
  top: -44px;
  left: 0;
}

.divDataListCont .tbData {
}

.divDataListCont .tbData th {
  color: #797f8c;
}

.divDataListCont .tbData td {
  vertical-align: middle;
  color: #585858;
}

.divDataListCont .tbData td .tdImg {
  width: 80px;
  height: 80px;
  border-radius: 4px;
}

.divGradesCompared {
  width: 100%;
  margin: 30px 0px;
}

.divQRCodeCont {
  text-align: center;
}

.divQRCodeCont img {
  width: 150px;
}

.divQRCodeCont .spFont {
  display: block;
  color: #323e56;
  font-size: 18px;
  letter-spacing: 2px;
}

.pt-6 {
  padding-top: 6rem;
}

.mt-6 {
  margin-top: 6rem;
}

.mt-7 {
  margin-top: 7rem;
}

.mt-8 {
  margin-top: 8rem;
}

.mt-9 {
  margin-top: 9rem;
}

.mt-10 {
  margin-top: 10rem;
}

.mt-13 {
  margin-top: 13rem;
}

.cascaderCont {
  width: 250px;
}

.elTableData {
  .cell {
    font-size: 24px !important;
  }
}
</style>

<style>
.printBodyDiv {
  margin: 50px auto !important;
}
.pageBoxScore .elTableData .cell {
  font-size: 18px !important;
}

.pageBoxScore .tdCont2 {
  padding-top: 12px;
  padding-bottom: 12px;
  font-family: 'OswaldLight';
}

.pageBoxScore .tdHeader_0 th {
  background-color: #f4f7fb !important;
}

.pageBoxScore .tdHeader_1 th {
  background-color: #fdf6f6 !important;
}

.elImageBox .image-slot {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
<style scoped="scoped">
@media print {
  @page {
    size: portrait;
    margin: 0;
  }
  .fractionBody,
  .chartTitle,
  .chartBody {
    margin-left: 40px;
  }

  .LiteracyDistribution {
    margin-left: 60px;
  }
  .A4CardBox {
    margin: 0px auto;
  }
  * {
    -webkit-print-color-adjust: exact !important;
    -moz-print-color-adjust: exact !important;
    -ms-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }

  .modelBody .cardTypeLable {
    width: 175px !important;
  }
  .A4CardBox {
    height: 1400px !important;
  }
}
</style>
