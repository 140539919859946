<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <comSearch :ColNumber="2" :SearchList="SearchList" ref="SearchList" @onSearch="onSearch" v-if="SearchList != null" :key="random"></comSearch>
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="padding-left: 0px">
          考勤管理
          <small class="color-danger">（每{{ onGetRefreshTime() }}分钟自动刷新考勤数据）</small>
        </h3>
      </div>
      <div class="card-body p-0" v-if="itemData != null && itemData.length != 0">
        <el-table :data="itemData" stripe class="elTableData">
          <!-- <el-table-column type="index" label="序号" width="80" min-width="100"></el-table-column> -->
          <el-table-column prop="date" label="考勤日期" class="elColumn" min-width="120" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              {{ scope.row.date | dateformat('YYYY-MM-DD') }}
            </template>
          </el-table-column>
          <el-table-column prop="attendanceTypeStr" label="类型" class="elColumn"></el-table-column>
          <el-table-column prop="total" label="总人数" class="elColumn"></el-table-column>
          <el-table-column prop="leave" label="请假人数" class="elColumn"></el-table-column>
          <el-table-column prop="should" label="应到人数" class="elColumn"></el-table-column>
          <el-table-column prop="actual" label="实到人数" class="elColumn"></el-table-column>
          <el-table-column prop="absence" label="未到人数" class="elColumn"></el-table-column>
          <el-table-column prop="scale" label="打卡率" class="elColumn"></el-table-column>

          <el-table-column label="操作" width="300" fixed="right">
            <template slot-scope="scope">
              <operationBtn :btnName="'查看名单'" ref="detail" @click.native="onDetail(scope.row.attendanceId)" v-if="isPower.admin_notclockedin_memberdetail"></operationBtn>
              <operationBtn
                :btnName="'寝室考勤'"
                :btnType="'success'"
                ref="detail"
                @click.native="onDetailBuilding(scope.row.attendanceId)"
                v-if="isPower.admin_notclockedin_details"
              ></operationBtn>
              <operationBtn :btnName="'统计'" @click.native="onRate(scope.row.attendanceId)" v-if="isPower.admin_notclockedin_rate"></operationBtn>
              <operationBtn :btnName="'删除'" ref="delete" @click.native="onDelete(scope.row.attendanceId)" v-if="isPower.admin_notclockedin_delete"></operationBtn>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="card-body" v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>
  </div>
</template>

<script>
import '@/assets/css/Admin.css';
import operationBtn from '@/components/operationBtn.vue';
import comSearch from '@/components/search.vue';
var webRefresh;
export default {
  components: {
    operationBtn,
    comSearch
  },
  data() {
    return {
      // 加载动画
      fullscreenLoading: false,
      // 列表数据
      itemData: null,
      paging: {
        totalCount: 0
      },
      SearchList: null,
      isPower: {
        admin_notclockedin_rate: false,
        admin_notclockedin_delete: false,
        admin_notclockedin_details: false,
        admin_notclockedin_memberdetail: false
      },
      optAttendanceType: [],
      random: null
    };
  },
  methods: {
    onGetRefreshTime() {
      return onGetRegularRefresh();
    },
    // 统计
    onRate(_dataID) {
      let that = this;
      that.$router.push({
        path: '/Admin/DormPunchIn/SignInRate',
        query: {
          memberId: _dataID,
          url: that.$router.history.current.fullPath
        }
      });
    },
    // 删除
    onDelete(_dataID) {
      let that = this;
      confirmDelete(that, null, function (res) {
        if (res == true) {
          that.fullscreenLoading = true;
          ajaxDelete(that, '/api/attendance/' + _dataID, null, function (r) {
            that.fullscreenLoading = false;
            pagingPage(that);
          });
        }
      });
    },
    onDetailBuilding(_dataID) {
      let that = this;
      that.$router.push({
        path: '/Admin/DormPunchIn/Index',
        query: {
          memberId: _dataID,
          url: that.$router.history.current.fullPath
        }
      });
    },
    // 详情
    onDetail(_dataID) {
      let that = this;
      that.$router.push({
        path: '/Admin/DormPunchIn/Member',
        query: {
          memberId: _dataID,
          url: that.$router.history.current.fullPath
        }
      });
    },
    onSearch(params) {
      let that = this;
      let data = {};
      let searchData = params;
      searchData.forEach((element) => {
        if (element.data) {
          data[element.zhi] = Join(element.data);
        }
      });
      data.PageNumer = 1;
      routerPath(that, '/Admin/DormPunchIn/NotClockedIn', data, null, 'PageSize');
    }
  },
  watch: {
    $route() {
      let that = this;
      pagingPage(that);
    }
  },
  created() {
    let that = this;
    isPower(that, 'admin_notclockedin_rate', 'admin_notclockedin_rate');
    isPower(that, 'admin_notclockedin_delete', 'admin_notclockedin_delete');
    isPower(that, 'admin_notclockedin_details', 'admin_notclockedin_details');
    isPower(that, 'admin_notclockedin_memberdetail', 'admin_notclockedin_memberdetail');
    pagingPage(that);
  },
  mounted() {
    webRefresh = setInterval(() => {
      // 定时循环加载数据
      pagingPage(this);
    }, onGetRegularRefresh() * 60 * 1000);
  },
  beforeDestroy() {
    // 销毁定时循环
    clearInterval(webRefresh);
  }
};

function pagingPage(that) {
  paging(
    that,
    function (r) {
      let optAttendanceType = [];

      ajaxGet(that, '/api/attendance/attendancetype', null, (resType) => {
        if (!regNullArray(resType)) {
          optAttendanceType = resType.map((it) => {
            return {
              Title: it.Text,
              Value: it.Value
            };
          });
        }
        let Time = r.Time;
        let StartDate = null;
        let EndDate = null;
        if (regNull(Time)) {
          Time = [dateFormat(new Date(), '-', 'YYYY-MM-DD'), dateFormat(new Date(), '-', 'YYYY-MM-DD')];

          StartDate = dateFormat(new Date(), '-', 'YYYY-MM-DD');
          EndDate = dateFormat(new Date(), '-', 'YYYY-MM-DD');
        } else {
          StartDate = !regNullArray(Time.split(',')) ? dateFormat(Time.split(',')[0], '-', 'YYYY-MM-DD') : null;
          EndDate = !regNullArray(Time.split(',')) ? dateFormat(Time.split(',')[1], '-', 'YYYY-MM-DD') : null;
          Time = [StartDate, EndDate];
        }

        that.SearchList = [
          {
            type: 'time',
            Name: '考勤日期',
            data: Time,
            zhi: 'Time',
            isClear: false, //select是否可以清除
            resetData: [dateFormat(new Date(), '-', 'YYYY-MM-DD'), dateFormat(new Date(), '-', 'YYYY-MM-DD')]
          },
          {
            type: 'select',
            Name: '考勤类型',
            select: optAttendanceType,
            data: r.AttendanceType,
            zhi: 'AttendanceType'
          }
        ];

        let data = {
          PageNumer: r.PageNumer,
          PageSize: r.PageSize,
          AttendanceType: r.AttendanceType,
          EndDate: EndDate,
          StartDate: StartDate
        };
        that.paging.Index = r.PageNumer;
        that.paging.Size = r.PageSize;
        that.fullscreenLoading = true;
        ajaxGet(that, '/api/attendance/search', data, function (r) {
          that.page = r;
          that.itemData = r.items;
          that.fullscreenLoading = false;
        });
      });
    },
    'Time',
    'AttendanceType'
  );
}
</script>

<style scoped="scoped">
.navTabsCont .nav-link {
  font-size: 12px;
}

.navToolsCont {
  margin-top: 1px;
}
</style>
