<!--
  学生成绩录入
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">

    <el-form label-width="60px">
      <el-card class="card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            编辑
          </h3>
        </div>
        <div class="card-body">
          <el-row :gutter="24">
            <el-col :span="6">
              <el-form-item label="学期">
                <el-select v-model="arrSearch.strSemester" style="width: 80%;" disabled>
                  <el-option v-for="(item,index) in arrSearch.optSemester" :key="index" :label="item.Text"
                    :value="item.Value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="班级">
                <el-select v-model="arrSearch.strClass" style="width: 80%;" disabled>
                  <el-option v-for="(item,index) in arrSearch.optClass" :key="index" :label="item.Text"
                    :value="item.Value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="课程">
                <el-select v-model="arrSearch.strCourse" style="width: 80%;" disabled>
                  <el-option v-for="(item,index) in arrSearch.optCourse" :key="index" :label="item.Text"
                    :value="item.Value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="学生">
                <el-select v-model="arrSearch.strStudent" style="width: 80%;" disabled>
                  <el-option v-for="(item,index) in arrSearch.optStudent" :key="index" :label="item.Text"
                    :value="item.Value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-card>
    </el-form>

    <div v-if="isCourseType==1">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="60px">
        <el-card class="box-card">
          <div slot="header" class="card-header">
            <h3 class="card-title titleCont">
              <span class="spIcon"></span>
              {{ruleForm.studentNo}}&nbsp;&nbsp;{{ruleForm.studentName}}
            </h3>
          </div>
          <div class="card-body">
            <el-row :gutter="24">
              <el-col :span="12">
                <el-form-item label="平时" prop="peaceTime">
                  <el-input v-model="ruleForm.peaceTime" placeholder="请输入" class="formControl"
                    @change="handleChange($event,'peaceTime')"></el-input>
                  <!-- @change="handleChange($event,'peaceTime')" -->
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="期中" prop="midTerm">
                  <el-input v-model="ruleForm.midTerm" placeholder="请输入" class="formControl"
                    @change="handleChange($event,'midTerm')"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="期末" prop="final">
                  <el-input v-model="ruleForm.final" placeholder="请输入" class="formControl"
                    @change="handleChange($event,'final')"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="总评" prop="total">
                  <el-input v-model="ruleForm.total" placeholder="请输入" class="formControl"
                    @change="handleChange($event,'total')"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </el-card>
      </el-form>
    </div>
    <div v-if="isCourseType==2">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="60px">
        <el-card class="box-card">
          <div slot="header" class="card-header">
            <h3 class="card-title titleCont">
              <span class="spIcon"></span>
              {{ruleForm.studentNo}}&nbsp;&nbsp;{{ruleForm.studentName}}
            </h3>
          </div>
          <div class="card-body">
            <el-row :gutter="24">
              <el-col :span="12">
                <el-form-item label="平时" prop="peaceTime">
                  <el-select v-model="ruleForm.peaceTime" placeholder="请选择" style="width: 100%;">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select> <!-- @change="handleChange($event,'peaceTime')" -->
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="期中" prop="midTerm">
                  <el-select v-model="ruleForm.midTerm" placeholder="请选择" style="width: 100%;">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="期末" prop="final">
                  <el-select v-model="ruleForm.final" placeholder="请选择" style="width: 100%;">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="总评" prop="total">
                  <el-select v-model="ruleForm.total" placeholder="请选择" style="width: 100%;">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </el-card>
      </el-form>
    </div>


    <div class="row" style="margin-top: 10px;">
      <div class="col-12">
        <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
        <el-button icon="el-icon-refresh" @click="onReset()">重 置</el-button>
        <el-button type="primary" icon="el-icon-circle-check" @click="onVerification('ruleForm')">提交</el-button>
      </div>
    </div>




  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  //import qs from 'qs';
  export default {
    name: '',
    components: {

    },
    data() {
      return {
        fullscreenLoading: false,
        //考试课分数配置范围
        courseConfigRange: [],
        options: [],
        // 搜索数据集
        arrSearch: {
          // 学期
          strSemester: '',
          optSemester: [],
          // 班级
          strClass: '',
          optClass: [],
          // 课程
          strCourse: '',
          optCourse: [],
          // 学生
          strStudent: '',
          optStudent: [],
        },
        // 课程类型
        isCourseType: 1,
        //提交数据
        ruleForm: {
          semesterId: '', //学期
          classId: '', //班级
          courseId: '', //课程
          studentId: '', // 学生
          studentNo: '', //学号
          studentName: '', //学生姓名
          peaceTime: '', //平时
          midTerm: '', //期中
          final: null, //期末
          total: null, //总评
          isType: 1, // 课程类型
          recordId: null,
        },



        rules: {
          peaceTime: [{
            required: true,
            message: '平时不能为空',
            trigger: 'blur'
          }],
          midTerm: [{
            required: true,
            message: '期中不能为空',
            trigger: 'blur'
          }],
          final: [{
            required: true,
            message: '期末不能为空',
            trigger: 'blur'
          }],
          total: [{
            required: true,
            message: '总评不能为空',
            trigger: 'blur'
          }]
        },

        ruleFormReset: null,

        optStudentInit: [],

      };
    },
    methods: {
      // 返回
      onBack() {
        let _this = this;
        back(_this);
      },
      // 重置
      onReset() {
        let _this = this;
        _this.ruleForm = JSON.parse(JSON.stringify(_this.ruleFormReset));
        try {
          _this.$refs["ruleForm"].resetFields();
        } catch {}
      },
      //验证表单
      onVerification(objForm) {
        let _this = this;
        // 验证
        _this.$refs[objForm].validate((valid) => {
          if (valid) {
            _this.onPostData(_this);
          } else {
            return false;
          }
        });
      },

      // 输入框值转换
      handleChange(_VALUE, _TYPE) {
        let _this = this;
        // 考试课

        if (_this.isCourseType == 1) {
          let temp = 0;
          switch (_TYPE) {
            case 'peaceTime':
              temp = setTwoDecimal(setTwoFloat(_VALUE), 1);
              _this.ruleForm.peaceTime = (temp != '') ? temp : 0;
              return;
            case 'midTerm':
              temp = setTwoDecimal(setTwoFloat(_VALUE), 1);
              _this.ruleForm.midTerm = (temp != '') ? temp : 0;
              return;
            case 'final':
              temp = setTwoDecimal(setTwoFloat(_VALUE), 1);
              _this.ruleForm.final = (temp != '') ? temp : 0;
              return;
            case 'total':
              temp = setTwoDecimal(setTwoFloat(_VALUE), 1);
              // _this.ruleForm.total = (temp != '') ? temp : 0;
              _this.ruleForm.total = '';
              for (let item of _this.courseConfigRange) {
                // _this.ruleForm.total = (temp >=item.floor&&temp<item.ceiling) ? temp : '';
                if (temp >= item.floor && temp < item.ceiling) {

                  _this.ruleForm.total = temp;
                  break;
                }
              }
              return;
            case 'gpa':
              temp = setTwoDecimal(setTwoFloat(_VALUE), 1);
              _this.ruleForm.gpa = (temp != '') ? temp : 0;
              return;
            case 'credit':
              temp = setTwoDecimal(setTwoFloat(_VALUE), 1);
              _this.ruleForm.credit = (temp != '') ? temp : 0;
              return;
          }
        }
      },
      // 提交数据
      onPostData() {
        let _this = this;
        _this.fullscreenLoading = true;
        ajaxPut(_this, '/api/admin/academicrecord/' + _this.ruleForm.recordId, _this.ruleForm, function(resultMessage) {
          _this.fullscreenLoading = false;
          back(_this);
        });
      },

    },
    created() {
      let _this = this;
      GetData(_this);
    },
  };


  // 获取数据
  function GetData(_this) {
    let id = _this.$route.params.id;
    // 开启加载动画
    _this.fullscreenLoading = true;
    ajaxGet(_this, "/api/admin/academicrecord/" + id, null, function(r) {
      _this.ruleForm = r;
      // 学期
      ajaxGet(_this, '/api/admin/semester', null, function(resSemester) {
        // 班级
        ajaxGet(_this, '/api/admin/class', null, function(resClass) {
          // 课程
          ajaxGet(_this, '/api/admin/course', null, function(resCourse) {
            // 处理学期数据
            for (let item of resSemester) {
              let arr = {
                Text: item.name,
                Value: item.semesterId
              };
              _this.arrSearch.optSemester.push(arr);
              if (item.name == r.semester) {
                _this.arrSearch.strSemester = item.name;
                _this.ruleForm.semesterId = item.semesterId;
              }
            }
            // 处理班级数据
            for (let item of resClass) {
              let arr = {
                Text: item.name,
                Value: item.classId
              };
              _this.arrSearch.optClass.push(arr);
              if (item.name == r.className) {
                _this.arrSearch.strClass = item.name;
                _this.ruleForm.classId = item.classId;
              }
            }
            //处理课程数据
            for (let item of resCourse) {
              let arr = {
                Text: '[' + item.code + ']' + item.name,
                Value: item.courseId,
              };
              _this.arrSearch.optCourse.push(arr);
              if (item.name == r.courseName) {
                _this.arrSearch.strCourse = '[' + item.code + ']' + item.name;
                _this.ruleForm.courseId = item.courseId;
                _this.isCourseType = item.courseType
              }
            }
            if (_this.isCourseType == 1) {
              //考试课配置
              ajaxGet(_this, "/api/admin/course/configrange/" + _this.ruleForm.courseId, null, function(
                resCourseConfigRange) {
                for (var i in resCourseConfigRange) {
                  _this.courseConfigRange.push(resCourseConfigRange[i]);
                }
              })
            } else if (_this.isCourseType == 2) {
              //考查课配置
              ajaxGet(_this, "/api/admin/course/configname/" + _this.ruleForm.courseId, null, function(
                resCourseConfigName) {
                var resCourseConfigNameList = [];
                for (var i in resCourseConfigName) {
                  if (resCourseConfigNameList.indexOf(resCourseConfigName[i]) < 0) {
                    resCourseConfigNameList.push(resCourseConfigName[i]);
                  }
                }
                if (resCourseConfigNameList != '') {
                  for (var i in resCourseConfigNameList) {
                    var newOption = {
                      lable: resCourseConfigNameList[i],
                      value: resCourseConfigNameList[i],
                    }
                    _this.options.push(newOption);
                  }
                }
              })
            }

            //学生
            ajaxGet(_this, '/api/admin/student/class/' + _this.ruleForm.classId, null, function(
            resData) {
              _this.fullscreenLoading = false;
              //处理学生数据
              for (let item of resData) {
                let arr = {
                  Text: item.name,
                  Value: item.studentId,
                };
                _this.arrSearch.optCourse.push(arr);
                if (item.name == r.studentName) {
                  _this.arrSearch.strStudent = item.name;
                  _this.ruleForm.studentId = item.studentId;
                }
                _this.ruleFormReset = JSON.parse(JSON.stringify(_this.ruleForm));
              }
            })
          })
        })
      })
    })
  };
</script>

<style scoped="scoped">
  .inGradesCont .itemList {
    padding-bottom: 15px;
  }

  .elTableData .el-form-item {
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
  }
</style>
<style>
  .inGradesCont .el-input-group__prepend {
    font-size: 14px;
  }
</style>
