<!--
  日常事务 - 日常动态管理 - 自我记录 - 详情
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          上报审核详情
        </h3>
      </div>
      <div class="card-body pb-5">
        <el-row :gutter="24">
          <el-col :span="24" class="colInfo">
            <label>获奖名称</label>
            <div class="colFonts">{{ itemData.name | flNoValue }}</div>
          </el-col>
          <el-col :span="8" class="colInfo">
            <label>获奖时间</label>
            <div class="colFonts">{{ itemData.recordDate | dateformatDay }}</div>
          </el-col>
          <el-col :span="8" class="colInfo">
            <label>颁奖单位</label>
            <div class="colFonts">{{ itemData.place | flNoValue }}</div>
          </el-col>
          <el-col :span="8" class="colInfo">
            <label>审核状态</label>
            <div class="colFonts">
              <span class="color-warning" v-if="itemData.auditState == 1">{{ itemData.auditStateString }}</span>
              <span class="color-success" v-else-if="itemData.auditState == 2">{{ itemData.auditStateString }}</span>
              <span class="color-danger" v-else-if="itemData.auditState == 3">{{ itemData.auditStateString }}</span>
              <span v-else>{{ itemData.auditStateString }}</span>
            </div>
          </el-col>
          <el-col :span="8" class="colInfo">
            <label>班级</label>
            <div class="colFonts">{{ itemData.class | flNoValue }}</div>
          </el-col>
          <el-col :span="8" class="colInfo">
            <label>学号</label>
            <div class="colFonts">{{ itemData.studentNo | flNoValue }}</div>
          </el-col>
          <el-col :span="8" class="colInfo">
            <label>学生</label>
            <div class="colFonts">{{ itemData.studentName | flNoValue }}</div>
          </el-col>
        </el-row>
      </div>
    </el-card>

    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          备注
        </h3>
      </div>
      <div class="card-body">
        <div class="colRemarksCont" v-if="itemData.description != null" v-html="itemData.description"></div>
        <div class="NoDataTipCont" style="text-align: center" v-else>
          <svg aria-hidden="true" class="icon">
            <use xlink:href="#fax-exclamation-triangle-o"></use>
          </svg>
          暂无备注
        </div>
      </div>
    </el-card>

    <comShowImage :uploadFiles="imgArr"></comShowImage>

    <!-- 班主任加的是班级德育分，审核时，有选择班级德育分的原因 -->
    <div v-if="TeacherInfo.isTeacher">
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            班级德育分原因
          </h3>
          <div class="card-tools">
            <ul class="nav nav-pills ml-auto navToolsCont dormTools">
              <li class="nav-item">
                <el-button type="success" icon="el-icon-circle-plus-outline" @click="onAdd()">新增</el-button>
              </li>
            </ul>
          </div>
        </div>

        <div class="card-body">
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="ruleForm">
            <el-table :data="ruleForm.configs" stripe class="elTableData" border>
              <el-table-column label="原因" class="elColumn">
                <template slot-scope="scope">
                  <el-form-item :prop="'configs.' + scope.$index + '.configIds'" :rules="rules.configIds">
                    <el-cascader
                      v-model="scope.row.configIds"
                      :options="optReason"
                      @change="handleChange(scope.row.configIds, scope.$index)"
                      style="width: 100% !important"
                      :props="{ label: 'name', value: 'reasonId', children: 'children' }"
                      ref="Cascader"
                    ></el-cascader>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="分数" class="elColumn">
                <template slot-scope="scope">
                  <!-- 按选择模式录入 -->
                  <el-form-item :prop="'configs.' + scope.$index + '.scoreId'" :rules="rules.scoreId" v-if="scope.row.inputScoreMode == 1">
                    <el-select v-model="scope.row.scoreId" class="width_100Pie" filterable placeholder="请选择" @change="onChangeScoreId(scope.row.scoreId, scope.$index)">
                      <el-option v-for="(item, i) in scope.row.points" :key="i" :label="item.score" :value="item.pointId"></el-option>
                    </el-select>
                  </el-form-item>
                  <!-- 自定义录入 -->

                  <el-form-item :prop="'configs.' + scope.$index + '.score'" :rules="rules.score" v-if="scope.row.inputScoreMode == 4">
                    <el-input v-model="scope.row.score" placeholder="请输入内容" class="width_100Pie"></el-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="操作" class="elColumn" width="120">
                <template slot-scope="scope">
                  <el-button type="danger" icon="el-icon-delete" @click="onDelete(scope.$index)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-form>
        </div>
      </el-card>
    </div>

    <!-- 管理员审核就是原审核功能，关联个人综合积分 -->
    <div v-if="configs.length > 0 && !TeacherInfo.isTeacher">
      <comLiteracyConfig :configList="configs" :defaultConfig="defaultConfigs" ref="comLiteracyConfig" @change="handleTypesChange" :key="random"></comLiteracyConfig>
    </div>

    <div class="row" style="margin-top: 10px">
      <div class="col-12">
        <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
        <el-button type="danger" icon="el-icon-error" @click="onFailed()">审核不通过</el-button>
        <el-button type="success" icon="el-icon-success" @click="onAdopt()">审核通过</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import '@/assets/css/Admin.css';
import comShowImage from '@/components/showImage.vue';
import comLiteracyConfig from '@/components/Admin/LiteracyConfig.vue';
import { element } from 'screenfull';
export default {
  components: {
    comShowImage,
    comLiteracyConfig
  },
  data() {
    return {
      fullscreenLoading: false,
      //列表数据
      itemData: [],
      configs: [],
      defaultConfigs: [],
      radio: '',
      imgArr: [], //存放图片
      videoArr: [], //存放视频
      random: 0,
      ruleForm: {
        configs: []
      },
      rules: {
        configIds: [{ required: true, message: '请选择原因', trigger: 'change' }],
        scoreId: [{ required: true, message: '请选择分数', trigger: 'change' }],
        score: [{ required: true, message: '请输入分数', trigger: 'change' }]
      },
      TeacherInfo: {},
      optReason: []
    };
  },
  filters: {
    flNoValue(obj) {
      if (obj == null || typeof obj == 'undefined' || obj == '') {
        return '暂无数据';
      }
      return obj;
    }
  },
  methods: {
    onDelete(index) {
      let that = this;
      that.ruleForm.configs.splice(index, 1);
    },
    onAdd() {
      let that = this;
      that.ruleForm.configs.push({
        configId: null,
        configIds: [],
        points: [],
        inputScoreMode: null,
        score: null,
        scoreId: null
      });
    },
    onChangeScoreId(val, index) {
      let that = this;
      let item = that.ruleForm.configs[index].points.find((x) => x.pointId == val);
      if (!regNull(item)) {
        that.ruleForm.configs[index].score = item.score;
      }
    },
    handleChange(val, index) {
      let that = this;
      that.ruleForm.configs[index].score = null;
      that.ruleForm.configs[index].scoreId = null;
      that.ruleForm.configs[index].configId = val[val.length - 1];
      ajaxGet(that, '/api/admin/classcreditreason/' + that.ruleForm.configs[index].configId, null, function (res) {
        that.ruleForm.configs[index].points = res.points.sort((a, b) => {
          return b.score - a.score;
        });
        that.ruleForm.configs[index].inputScoreMode = res.inputScoreMode;
        // 选择模式录入，有默认值则给默认值
        if (res.inputScoreMode == 1) {
          var isDefault = res.points.find((x) => x.isDefault);
          if (!regNull(isDefault)) {
            that.ruleForm.configs[index].score = isDefault.score;
            that.ruleForm.configs[index].scoreId = isDefault.pointId;
          }
        }
      });
    },
    handleTypesChange(objItem, objValID) {
      let that = this;
      if (!regNull(objItem)) {
        if (regNull(that.ruleForm.configs)) {
          that.ruleForm.configs.push(objItem);
        } else {
          let tpInfo = that.ruleForm.configs.find((item) => item.configId == objItem.configId);
          if (regNull(tpInfo)) {
            that.ruleForm.configs.push(objItem);
          } else {
            that.ruleForm.configs.forEach((item) => {
              if (item.configId == objItem.configId) {
                item.score = objItem.score;
              }
            });
          }
        }
      } else if (!regNull(objValID)) {
        that.ruleForm.configs = that.ruleForm.configs.filter((item) => item.configId != objValID);
      }
    },
    onAdopt() {
      //审核通过
      let that = this;
      let data = {
        reportId: that.$route.params.id,
        auditState: that.TeacherInfo.isTeacher ? 2 : 4,
        configs: that.ruleForm.configs
      };
      if (that.TeacherInfo.isTeacher) {
        that.$refs['ruleForm'].validate((valid) => {
          if (valid) {
            that.fullscreenLoading = true;
            ajaxPost(that, '/api/admin/selfreport/audit', data, function (r) {
              that.fullscreenLoading = false;
              that.onBack();
            });
          } else {
            setTimeout(() => {
              var isError = document.getElementsByClassName('is-error');
              isError[0].querySelector('input').focus();
            }, 1);
            return false;
          }
        });
      } else {
        that.fullscreenLoading = true;
        ajaxPost(that, '/api/admin/selfreport/audit', data, function (r) {
          that.fullscreenLoading = false;
          that.onBack();
        });
      }
    },
    onFailed() {
      //审核不通过
      let that = this;
      let data = {
        reportId: that.$route.params.id,
        auditState: that.TeacherInfo.isTeacher ? 3 : 5
      };
      if (that.TeacherInfo.isTeacher) {
        that.$refs['ruleForm'].validate((valid) => {
          if (valid) {
            that.fullscreenLoading = true;
            ajaxPost(that, '/api/admin/selfreport/audit', data, function (r) {
              that.fullscreenLoading = false;
              that.onBack();
            });
          } else {
            setTimeout(() => {
              var isError = document.getElementsByClassName('is-error');
              isError[0].querySelector('input').focus();
            }, 1);
            return false;
          }
        });
      } else {
        that.fullscreenLoading = true;
        ajaxPost(that, '/api/admin/selfreport/audit', data, function (r) {
          that.fullscreenLoading = false;
          that.onBack();
        });
      }
    },
    onBack() {
      let that = this;
      back(that);
    }
  },
  mounted() {},
  created() {
    let that = this;
    that.TeacherInfo = onGetNowUser(that);

    ajaxGet(that, '/api/admin/growthconfig', null, function (resConfig) {
      that.configs = JSON.parse(
        JSON.stringify(
          resConfig.filter((val) => {
            return val.configType == 1;
          })
        )
      );
    });

    Init(that);
  }
};

function Init(that) {
  let Id = that.$route.params.id;
  that.fullscreenLoading = true;
  ajaxGet(that, '/api/admin/selfreport/' + Id, null, function (r) {
    that.itemData = r;
    if (r.attachment != null) {
      var attachments = r.attachment.split('|');
      for (var i in attachments) {
        var suffix = attachments[i].split('.')[1].toLowerCase(); //截取后缀
        if (suffix == 'jpg' || suffix == 'png' || suffix == 'jpeg' || suffix == 'bmp' || suffix == 'gif') {
          //判断是否为图片格式
          that.imgArr.push(that.$ServerUrl() + attachments[i]);
        } else {
          that.videoArr.push(that.$ServerUrl() + attachments[i]);
        }
      }
    }
    ajaxGet(that, '/api/admin/classcreditlog/reason?classId=' + r.classId, null, function (resReason) {
      that.optReason = resReason;
      that.optReason.forEach((item) => {
        removeChildren(item);
      });
    });
    that.fullscreenLoading = false;
  });
}

function removeChildren(tree) {
  if (tree.children && tree.children.length > 0) {
    tree.children.forEach((child) => {
      removeChildren(child);
    });
  } else {
    tree.children = undefined; // 或者 tree.children = undefined;
  }
}
</script>

<style scoped="scoped">
.el-form-item {
  padding-bottom: 18px;
}

.divReviewRadio {
  padding: 30px 0px 30px 30px;
}

.infoList {
  padding-bottom: 17px;
  padding-left: 30px;
}

.infoList .spName {
  font-size: 32px;
  color: #212e48;
  padding-right: 30px;
  height: 40px;
}

.infoList .spFonts {
  color: #3b475d;
  padding-right: 30px;
  padding-left: 30px;
  border-right: 1px solid #d6d6d6;
}

.infoList .spFonts:first-child {
  padding-left: 0;
}

.infoList .spFonts:last-child {
  border-right: none;
}

.infoList .spMedal {
  padding-right: 15px;
}

.infoList .spFonts .faxSize {
  font-size: 18px;
}

.infoList .spFonts .icon {
  color: #ffffff;
}

.infoList .spStatus {
  border: 1px solid #007bff;
  background-color: #007bff;
  color: #ffffff;
  padding: 5px 20px;
  border-radius: 5px;
}

.infoList .spNStatus {
  border: 1px solid #007bff;
  background-color: #007bff;
  color: #ffffff;
  padding: 5px 20px;
  border-radius: 5px;
}

.infoList .spFonts .spLabel {
  font-size: 14px;
  padding: 2px 5px;
  border-radius: 5px;
  background-color: #b1b1b1;
  color: #ffffff;
  margin-right: 10px;
}

.infoList .spFonts .spNum {
  padding-left: 10px;
  padding-right: 5px;
  font-family: 'OswaldLight';
  font-size: 16px;
  font-weight: bold;
  color: #dc3545;
}

.cardTabs .abtnTabs {
  font-size: 16px;
  color: #b1b1b1;
  margin-left: 15px;
  position: relative;
  display: block;

  float: left;
  text-align: center;
}

.cardTabs .spDivide {
  float: left;
  padding-left: 15px;
}

.cardTabs .abtnTabs:hover {
  color: #323e56;
}

.cardTabs .abtnTabs .spFaxIcon {
  display: none;
}

.cardTabs .abtnTabs:first-child {
  margin-left: 0;
}

.cardTabs .abtnTabs .spNumber {
  background-color: #dee2e6;
  font-size: 12px;
  color: #ffffff;
  font-family: 'OswaldLight';

  padding: 1px 5px;
  border-radius: 5px;
}

.cardTabs .abtnTabAct {
  font-size: 1.1rem;
  color: #323e56;
}

.cardTabs .abtnTabAct .spFaxIcon {
  display: block;
  position: absolute;
  top: 22px;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 12px;
}

.cardTabs .abtnTabAct .spNumber {
  background-color: #1666b3;
  color: #ffffff;
  font-size: 14px;
}

.bodyImgCont .mailbox-attachment-icon {
  min-height: 165px;
}

.bodyFileCont .mailbox-attachments li,
.bodyImgCont .mailbox-attachments li {
  width: 250px;
}

.bodyImgCont .mailbox-attachment-icon {
  height: 198px;
}

.bodyFileCont .mailbox-attachment-size .spFont,
.bodyImgCont .mailbox-attachment-size .spFont {
  font-size: 12px;
}

.divVideoCont {
  overflow-y: auto;
  width: 100%;
  height: 500px;
  padding-right: 10px;
}

.divVideoCont ul li .aItem {
  display: block;
  padding: 7px 0px;
  padding-left: 30px;
  border-bottom: 1px dotted #dddddd;
  position: relative;
}

.divVideoCont ul li:last-child .aItem {
  border-bottom: none;
}

.divVideoCont ul li:hover {
  background-color: #5aaaff;
  color: #ffffff;
}

.divVideoCont ul li .aItem span {
  font-family: 'OswaldLight';
  font-size: 14px;
  margin-left: 10px;
}

.divVideoCont ul li .aItem .faxIcon {
  position: absolute;
  top: 11px;
  left: 5px;
  display: none;
}

.divVideoCont ul li .aItemAct {
  background-color: #007bff;
  color: #ffffff;
  border-bottom: none;
}

.divVideoCont ul li .aItemAct .faxIcon {
  display: block;
}
</style>

<style>
.divReviewRadio .el-radio__inner,
.divReviewRadio .el-radio__label {
  font-size: 16px;
}
</style>
