<!--
  行为规范
-->
<template>
  <div class="SubWebPageStudent" v-loading.fullscreen.lock="fullscreenLoading">
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          {{ Title }}
        </h3>
      </div>
      <div class="card-body">
        <el-row :gutter="24">
          <el-col :span="12" class="colInfo">
            <label>
              行为原因
              <el-tag type="warning" size="mini" effect="dark" v-if="itemData.transform">落实</el-tag>
            </label>
            <div class="colFonts">{{ flNoValue(itemData.reason) }}</div>
          </el-col>
          <el-col :span="12" class="colInfo">
            <label>分数</label>
            <div class="colFonts color-danger" v-if="itemData.score >= 0">{{ itemData.score }}</div>
            <div class="colFonts color-success" v-else-if="itemData.score < 0">{{ itemData.score }}</div>
          </el-col>
          <el-col :span="12" class="colInfo">
            <label>责任人</label>
            <div class="colFonts">{{ flNoValue(itemData.student ? itemData.student : itemData.responsible) }}</div>
          </el-col>
          <el-col :span="12" class="colInfo">
            <label>班级</label>
            <div class="colFonts">{{ flNoValue(itemData.class) }}</div>
          </el-col>
          <el-col :span="12" class="colInfo" v-if="currentType != 1">
            <label>寝室</label>
            <div class="colFonts">{{ flNoValue(itemData.room) }}</div>
          </el-col>
          <el-col :span="12" class="colInfo">
            <label>行为时间</label>
            <div class="colFonts">{{ itemData.logDate | dateformatMinute }}</div>
          </el-col>
          <el-col :span="24" class="colInfo">
            <label>备注</label>
            <div class="colFonts">{{ flNoValue(itemData.remark) }}</div>
          </el-col>
        </el-row>
      </div>
    </el-card>
    <el-card class="box-card" v-if="itemData.appealStatus">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          申诉信息
        </h3>
      </div>
      <div class="card-body">
        <el-row :gutter="24">
          <el-col :span="12" class="colInfo">
            <label>申诉人</label>
            <div class="colFonts">{{ itemData.appealBy }}</div>
          </el-col>
          <el-col :span="12" class="colInfo">
            <label>申诉状态</label>
            <div v-if="itemData.appealStatus == 1" class="colFonts" style="color: #409eff">{{ itemData.appealStatusName }}</div>
            <div v-if="itemData.appealStatus == 2" class="colFonts" style="color: #67c23a">{{ itemData.appealStatusName }}</div>
            <div v-if="itemData.appealStatus == 3" class="colFonts" style="color: #f56c6c">{{ itemData.appealStatusName }}</div>
            <div v-if="itemData.appealStatus == 4" class="colFonts" style="color: #909399">{{ itemData.appealStatusName }}</div>
          </el-col>
          <el-col :span="12" class="colInfo">
            <label>申诉时间</label>
            <div class="colFonts">{{ itemData.appealDate | dateformatMinute }}</div>
          </el-col>
          <el-col :span="12" class="colInfo">
            <label>申诉理由</label>
            <div class="colFonts">{{ flNoValue(itemData.appealReason) }}</div>
          </el-col>
        </el-row>

        <el-row :gutter="24" v-if="itemData.appealStatus == 2 || itemData.appealStatus == 3">
          <el-col :span="12" class="colInfo">
            <label>申诉处理人</label>
            <div class="colFonts">{{ itemData.approveBy }}</div>
          </el-col>
          <el-col :span="12" class="colInfo">
            <label>申诉处理时间</label>
            <div class="colFonts">{{ itemData.approveDate | dateformatMinute }}</div>
          </el-col>
          <el-col :span="12" class="colInfo">
            <label>申诉处理意见</label>
            <div class="colFonts">{{ flNoValue(itemData.approveReason) }}</div>
          </el-col>
        </el-row>
      </div>
    </el-card>
    <comShowImage :uploadFiles="imgArr"></comShowImage>

    <div class="row">
      <div class="col-12 mt-1">
        <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import '@/assets/css/Student.css';
import comShowImage from '@/components/showImage.vue';
export default {
  components: {
    comShowImage
  },
  data() {
    return {
      // 动画
      fullscreenLoading: false,
      dialogVisible: false,
      //列表数据
      itemData: [],
      Title: null,
      imgArr: [],
      currentType: null
    };
  },
  methods: {
    onBack() {
      let that = this;
      back(that);
    },
    flNoValue(obj) {
      if (obj == null || typeof obj == 'undefined' || obj == '') {
        return '暂无数据';
      }
      return obj;
    }
  },
  created() {
    let that = this;
    let id = that.$route.params.id;
    let type = that.$route.params.type;
    let url = '';
    that.currentType = type;
    if (type == 1) {
      that.Title = '查看班级行为规范';
      url = '/api/student/studentunionmoral/classlog/' + id;
    } else if (type == 3) {
      that.Title = '查看个人行为规范';
      url = '/api/student/studentunionmoral/person/' + id;
    } else if (type == 2) {
      that.Title = '查看寝室行为规范';
      url = '/api/student/studentunionmoral/dormlog/' + id;
    }
    that.fullscreenLoading = true;
    ajaxGet(that, url, null, function (r) {
      that.fullscreenLoading = false;
      that.itemData = r;
      if (r.attachment != null) {
        var attachments = r.attachment.split('|');
        for (var i in attachments) {
          var files = attachments[i].split('.');
          if (files != null && files.length > 0) {
            var suffix = files[files.length - 1].toLowerCase(); //截取后缀
            if (suffix == 'jpg' || suffix == 'png' || suffix == 'jpeg' || suffix == 'bmp' || suffix == 'gif') {
              //判断是否为图片格式
              that.imgArr.push(that.$ServerUrl() + attachments[i]);
            }
          }
        }
      }
    });
  }
};
</script>

<style scoped="scoped">
.imgBox {
  width: 100%;
  height: 200px;
  border: 1px solid #dddddd;
  border-radius: 5px;
}

.stuInfo {
  position: relative;
  height: 100%;
  min-height: 80px;
  padding-left: 70px;
  margin: 15px;
}

.stuInfo .stuFraction {
  position: absolute;
  top: -2px;
  bottom: 0;
  right: 5px;
  font-family: 'OswaldLight';
  font-size: 46px;
  color: #28a745;
}

.stuInfo .PlusBx {
  color: #f56c6c;
}

.stuInfo .stuImgCont {
  position: absolute;
  top: 0;
  left: 0;
  width: 60px;
  height: 60px;
  border-radius: 4px;
  border: 1px solid #dddddd;
  overflow: hidden;
  border-radius: 50%;
}

.stuInfo .stuImgCont img {
  width: 100%;
}

.stuInfo .stuName {
  color: #212e48;
  font-size: 18px;
  font-weight: bold;
}

.stuInfo .stuOther {
  color: #767676;
  font-size: 12px;
}
</style>
<style>
.imgBox .el-image__inner {
  height: auto;
}
</style>
