<!--
-->
<template>
  <div class="SubWebPage DetailsPro" v-loading.fullscreen.lock="fullscreenLoading">
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          【{{ name }}】成员
        </h3>
        <div class="card-tools">
          <ul class="nav">
            <li class="nav-item">
              <!--导入-->
              <comImport
                @onImportSuccess="onImportSuccess"
                :template="'/templates/学生会名单导入模版.xlsx'"
                :path="uploadInfoApi"
                :btnIcon="'el-icon-upload2'"
                :title="'批量导入学生会名单 '"
                :btnName="'导入学生会名单'"
                ref="conImportInfoFile"
                v-if="isPower.admin_studentunion_import"
              ></comImport>
            </li>
            <li class="nav-item">
              <el-button type="success" v-if="isPower.admin_studentunion_newlist" icon="el-icon-circle-plus-outline" @click="onCreate()">新增</el-button>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body p-0" v-if="itemData != null && itemData.length">
        <el-table :data="itemData" stripe class="elTableData">
          <el-table-column type="index" label="序号" width="100"></el-table-column>
          <el-table-column prop="studentName" label="姓名" min-width="140"></el-table-column>
          <el-table-column prop="studentNo" label="学号" min-width="140"></el-table-column>
          <el-table-column prop="className" label="班级" min-width="140"></el-table-column>
          <el-table-column label="职位" min-width="140">
            <template slot-scope="scope">
              <span class="spPosition" :class="{ 'color-danger': scope.row.isManager }">
                {{ scope.row.position }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="160" fixed="right">
            <template slot-scope="scope">
              <!--              <operationBtn :btnName="'调职'" v-if="isPower.admin_studentunion_transfer" :btnType="'warning'"
                @click.native="onTransfer(scope.row)">
              </operationBtn> -->
              <operationBtn :btnName="'编辑'" v-if="isPower.admin_studentunion_edit" @click.native="onDetail(scope.row)"></operationBtn>
              <operationBtn :btnName="'撤职'" v-if="isPower.admin_studentunion_dismissal" :btnType="'danger'" @click.native="onDelete(scope.row.memberId)"></operationBtn>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="card-body" v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>
    <el-dialog :title="isEdit ? '编辑学生名单' : '添加学生名单'" :visible.sync="dialogVisible1" width="30%" :before-close="handleCalce">
      <div class="divDialogBox">
        <el-form class="ruleForm" :model="studentForm" :rules="studentRules" ref="studentFormRef">
          <el-form-item v-if="!isEdit" label="班级" prop="classId">
            <el-select v-model="studentForm.classId" class="width_100Pie" filterable placeholder="请选择班级" @change="onChangeClass">
              <el-option v-for="item in classItem" :key="item.classId" :label="item.alias" :value="item.classId"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="!isEdit" label="学生" prop="studentId">
            <el-select v-model="studentForm.studentId" class="width_100Pie" filterable placeholder="请选择学生">
              <el-option v-for="item in studentItem" :key="item.studentId" :label="item.name" :value="item.studentId"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="班级" v-if="isEdit">
            <el-input v-model="currentDetail.className" disabled></el-input>
          </el-form-item>
          <el-form-item label="学生" v-if="isEdit">
            <el-input v-model="currentDetail.studentName" disabled></el-input>
          </el-form-item>
          <el-form-item label="职位名称" prop="position">
            <el-input v-model="studentForm.position" class="width_100Pie" placeholder="请输入职位名称"></el-input>
          </el-form-item>
          <el-form-item label="权限">
            <div class="width_100Pie mt-5">
              <el-checkbox-group v-model="auditList">
                <el-checkbox label="班级"></el-checkbox>
                <el-checkbox label="个人"></el-checkbox>
                <el-checkbox label="寝室"></el-checkbox>
                <!-- <el-checkbox label="寝室个人"></el-checkbox> -->
              </el-checkbox-group>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCalce">取 消</el-button>
        <el-button type="primary" @click="onChangeStudent">确 定</el-button>
      </span>
    </el-dialog>

    <div class="row mt-10">
      <div class="col-12">
        <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import '@/assets/css/Admin.css';
import operationBtn from '@/components/operationBtn.vue';
import comPage from '@/components/Page.vue';
import comImport from '@/components/Import.vue';
export default {
  components: {
    operationBtn,
    comImport
  },
  data() {
    return {
      // 加载动画
      fullscreenLoading: false,
      dialogVisible: false,
      dialogVisible1: false,
      name: null,
      roleKey: {
        班级: 'isMoralityClass',
        个人: 'isMorality',
        寝室: 'isMoralityDormitory'
        // "寝室个人": "isMoralityDp"
      },
      isEdit: false,
      currentDetail: {},
      studentForm: {
        studentId: null,
        position: null,
        classId: null
      },
      collegeId: null,
      auditList: [],
      // 社团信息对象
      itemData: [],
      ruleForm: {},
      classItem: null,
      currentClassId: null,
      currentStudentId: null,
      studentItem: null,
      position: null,
      Currentposition: null,
      associationId: null,
      uploadInfoApi: null,
      studentRules: {
        classId: [
          {
            required: true,
            message: '请选择班级',
            trigger: 'change'
          }
        ],
        studentId: [
          {
            required: true,
            message: '请选择学生',
            trigger: 'change'
          }
        ],
        position: [
          {
            required: true,
            message: '请输入职位名称',
            trigger: 'change'
          }
        ]
      },
      isPower: {
        admin_studentunion_transfer: false,
        admin_studentunion_dismissal: false,
        admin_studentunion_import: false,
        admin_studentunion_newlist: false,
        admin_studentunion_edit: false
      }
    };
  },
  methods: {
    onBack() {
      back(this);
    },
    //取消
    handleCalce() {
      if (!this.isEdit) {
        this.studentForm.position = null;
        this.$refs.studentFormRef.resetFields();
      }
      this.dialogVisible1 = false;
    },
    /**
     * @description 调职
     * @param {Object} objVal
     */
    onTransfer(objVal) {
      let that = this;
      that.dialogVisible = true;
      that.Currentposition = objVal.position;
      that.ruleForm = objVal;
    },
    /**
     * @description 撤职
     * @param {Object} _clubId
     * @param {Object} _studentId
     */
    onDelete(memberId) {
      let that = this;
      confirmDelete(that, '您确定要撤销该学生的职位吗？ 撤销后信息将不可恢复，请知悉', function (res) {
        if (res == true) {
          that.fullscreenLoading = true;
          let data = [memberId];
          ajaxPut(that, '/api/admin/association/quitassociation/' + that.associationId, data, function (r) {
            that.fullscreenLoading = false;
            onGetData(that);
          });
        }
      });
    },
    //导入成功后
    onImportSuccess(param) {
      let that = this;
      onGetData(that);
    },
    /**
     * @description 新增
     */
    onCreate() {
      let that = this;
      that.auditList = [];
      that.isEdit = false;
      that.dialogVisible1 = true;
    },
    //调职
    onSaveTransfer() {
      let that = this;
      let data = {
        memberId: that.ruleForm.memberId,
        studentId: that.ruleForm.studentId,
        associationId: that.associationId,
        position: that.ruleForm.newposition
      };
      that.fullscreenLoading = true;
      ajaxPut(that, '/api/admin/association/member/' + that.ruleForm.memberId, data, function (resdata) {
        that.fullscreenLoading = false;
        that.dialogVisible = false;
        onGetData(that);
      });
    },
    /**
     * @description 选择班级事件
     * @return {void}
     */
    onChangeClass(_dataID) {
      let _this = this;
      _this.studentItem = [];
      _this.studentForm.studentId = null;
      ajaxGet(_this, '/api/admin/student/class/' + _dataID, null, function (resData) {
        if (_this.ruleForm.members != null && _this.ruleForm.members.length != 0) {
          _this.studentItem = resData.filter(function (obj) {
            return _this.ruleForm.members.find((item) => item.studentId == obj.studentId) == undefined;
          });
        } else {
          _this.studentItem = resData;
        }
      });
    },
    onDetail(row) {
      //编辑
      let that = this;
      that.isEdit = true;
      that.currentDetail = row;
      that.auditList = [];
      for (let key in that.roleKey) {
        if (row[that.roleKey[key]] === true) {
          that.auditList.push(key);
        }
      }
      that.studentForm.position = row.position;
      that.dialogVisible1 = true;
    },
    onChangeStudent() {
      let that = this;
      let data = JSON.parse(JSON.stringify(that.studentForm));
      data.associationId = that.associationId;
      if (that.auditList.length > 0) {
        that.auditList.forEach((item) => {
          data[that.roleKey[item]] = true;
        });
      }
      if (that.isEdit) {
        data.studentId = that.currentDetail.studentId;
        data.memberId = that.currentDetail.memberId;
        that.$refs.studentFormRef.validate((valid) => {
          if (valid) {
            that.fullscreenLoading = true;
            ajaxPut(that, `/api/admin/association/member/${that.currentDetail.memberId}`, data, function (res) {
              that.dialogVisible1 = false;
              that.fullscreenLoading = false;
              onGetData(that);
            });
          } else {
            return false;
          }
        });
      } else {
        that.$refs.studentFormRef.validate((valid) => {
          if (valid) {
            that.fullscreenLoading = true;
            ajaxPost(that, '/api/admin/association/member', data, function (resdata) {
              that.handleCalce();
              that.fullscreenLoading = false;
              onGetData(that);
            });
          } else {
            return false;
          }
        });
      }
    }
  },
  created() {
    let that = this;
    isPower(that, 'admin_studentunion_import', 'admin_studentunion_import');
    isPower(that, 'admin_studentunion_dismissal', 'admin_studentunion_dismissal');
    isPower(that, 'admin_studentunion_transfer', 'admin_studentunion_transfer');
    isPower(that, 'admin_studentunion_newlist', 'admin_studentunion_newlist');
    isPower(that, 'admin_studentunion_edit', 'admin_studentunion_edit');
    that.collegeId = that.$route.query.collegeId && that.$route.query.collegeId.toUpperCase();
    that.name = that.$route.query.name;
    if (!regNull(that.$route.params.Id)) {
      onGetData(that);
    }
  }
};

function onGetData(that) {
  that.fullscreenLoading = true;
  ajaxGet(that, '/api/admin/association/' + that.$route.params.Id, null, function (resdata) {
    that.itemData = resdata.members;
    that.fullscreenLoading = false;
    that.associationId = resdata.associationId;
    that.uploadInfoApi = '/api/admin/association/associationmember/' + that.associationId;

    let tpClassApiUrl = regNull(resdata.collegeId) ? '/api/admin/sysuser/userfilternograduate' : '/api/admin/class/classbycollegeid?collegeId=' + resdata.collegeId;
    ajaxGet(that, tpClassApiUrl, null, function (resClass) {
      that.classItem = resClass;
      // if (that.collegeId) {
      //   that.classItem = resClass.filter(item => item.collegeId === that.collegeId)
      // } else {
      //   that.classItem = []
      // }
    });
  });
}
</script>

<style scoped="scoped">
.infoFontBox {
  display: block;
  margin-top: 40px;
  padding-left: 15px;
}

.elTableData .spPosition {
  color: #767676;
}

.elCarouselBox {
  border: 1px solid #efefef;
}

.dialogBox {
  padding: 10px 5px;
  height: 140px;
  overflow: hidden;
}

.dialogVideoBox {
  padding: 10px 5px;
}

.dialogBox .mailbox-attachment-icon {
  font-size: 32px;
  padding: 15px 10px;
}

.dialogBox .mailbox-attachment-size > span {
  font-size: 12px;
}

.dialogBox .mailbox-attachment-info {
  padding: 7px 10px;
}

.bodyAwardsCont .stuAwardsCont {
  position: relative;
  padding: 0px 15px 45px 15px;
  height: 260px;
}

.bodyAwardsCont .stuAwardsCont:hover {
  cursor: pointer;
}

.bodyAwardsCont .stuAwardsCont:hover .awardsName {
  color: #000;
}

.bodyAwardsCont .stuAwardsCont:hover .stuFileCont {
  border-color: #d9ecff;
}

.bodyAwardsCont .stuAwardsCont .awardFooter {
  text-align: center;
  padding-top: 5px;
}

.bodyAwardsCont .stuAwardsCont .awardFooter .divBtnBox {
}

.bodyAwardsCont .awardsName {
  padding-top: 10px;
  color: #666;
}

.bodyAwardsCont .stuFileCont {
  padding-top: 5px;
  border: 1px solid #eeeeee;
  height: 150px;
  overflow: hidden;
  width: 100%;
  margin-top: 5px;
  position: relative;
}

.bodyRecordCont .stuRecordCont {
  position: relative;
  padding: 0px 15px 45px 15px;
  padding-left: 75px;
}

.bodyRecordCont .stuRecordCont .stuAvatar {
  position: absolute;
  left: 15px;
  top: 0px;
  border: 1px solid #eee;
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 5px;
}

.bodyRecordCont .stuRecordCont .stuName {
  font-size: 16px;
}

.bodyRecordCont .stuRecordFont {
  font-size: 12px;
  padding-top: 5px;
  color: #767676;
}

.bodyRecordCont .stuRecordLabel {
  font-size: 12px;
  padding-top: 5px;
  color: #b1b1b1;
}

.bodyRecordCont .stuFileCont {
  padding-top: 10px;
}

.bodyRecordCont .stuFileCont .divFileIcon {
  border: 1px solid #eee;
  width: 100%;
  height: 80px;
  overflow: hidden;
  border-radius: 4px;
}

.bodyRecordCont .stuFileCont .divFileIcon .elIcon {
  font-size: 32px;
}

.colRecordOther {
}

.colRecordOther .divList {
  float: left;
  width: 33.3%;
}

.colRecordCont {
  padding: 5px;
  font-size: 12px;
  color: #767676;
}

.divVideoCont {
  overflow-y: auto;
  width: 100%;
  height: 500px;
  padding-right: 10px;
}

.divVideoCont ul li .aItem {
  display: block;
  padding: 7px 0px;
  padding-left: 30px;
  border-bottom: 1px dotted #dddddd;
  position: relative;
}

.divVideoCont ul li:last-child .aItem {
  border-bottom: none;
}

.divVideoCont ul li:hover {
  background-color: #5aaaff;
  color: #ffffff;
}

.divVideoCont ul li .aItem span {
  font-family: 'OswaldLight';
  font-size: 14px;
  margin-left: 10px;
}

.divVideoCont ul li .aItem .faxIcon {
  position: absolute;
  top: 11px;
  left: 5px;
  display: none;
}

.divVideoCont ul li .aItemAct {
  background-color: #007bff;
  color: #ffffff;
  border-bottom: none;
}

.divVideoCont ul li .aItemAct .faxIcon {
  display: block;
}

.bodyImgCont .mailbox-attachment-icon {
  min-height: 165px;
}

.bodyFileCont .mailbox-attachments li,
.bodyImgCont .mailbox-attachments li {
  width: 250px;
}

.bodyImgCont .mailbox-attachment-icon {
  height: 198px;
}

.bodyFileCont .mailbox-attachment-size .spFont,
.bodyImgCont .mailbox-attachment-size .spFont {
  font-size: 12px;
}

.tabListCont .divNavTitle {
}

.tabListCont .divNavTitle {
}

.tabListCont .divNavTitle .divTitleCont {
  padding: 10px 0px 10px 10px;
  color: #dee2e6;
  cursor: pointer;
  font-size: 18px;
}

.tabListCont .divNavTitle .divTitleCont .spNum {
  background-color: #dee2e6;
  font-family: 'OswaldLight';
  padding: 2px 5px;
  border-radius: 4px;
  margin-left: 5px;
  font-size: 14px;
  color: #ffffff;
}

.tabListCont .divNavTitle .divTitleAct {
  color: #3e3e3e;
}

.tabListCont .divNavTitle .divTitleAct .spNum {
  background-color: #17a2b8;
  color: #ffffff;
}

.tabListCont .divNavTitle .nav-link {
  font-size: 14px;
  color: #b1b1b1;
}

.tabListCont .divNavTitle .nav-link .spNum {
  font-family: 'OswaldLight';
  font-weight: normal;
  font-size: 14px;
  padding-left: 10px;
}

.tabListCont .divNavTitle .active {
  color: #007bff;
}

.DetailsPro .divRoleTitle {
  font-size: 18px;
  color: #323e56;
  padding-top: 20px;
}

.DetailsPro .divRoleTitle .spItem {
  float: right;
  margin-left: 5px;
  font-size: 14px;
  color: #28a745;
}

.DetailsPro .divRoleTitle .spItem em {
  font-style: normal;
  background-color: #28a745;
  color: #ffffff;
  margin-left: 5px;
  margin-right: 5px;
  padding: 2px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-family: 'OswaldLight';
}

.DetailsPro .divRoleTitle .el-button {
  padding: 0;
  border: none;
}

.DetailsPro .divRoleTitle .el-button:hover {
  background-color: transparent;
}

.stuInfo {
  position: relative;
  height: 100%;
  min-height: 80px;
  padding-left: 70px;
  margin: 15px;
}

.stuInfo .stuFraction {
  position: absolute;
  top: -5px;
  bottom: 0;
  right: -5px;
  font-family: 'OswaldLight';
  font-size: 26px;
  color: #28a745;
}

.stuInfo .stuBtnDown {
  position: absolute;
  bottom: 20px;
  right: 0;
}

.stuInfo .stuBtnDown .aView {
  margin-right: 5px;
  margin-left: 5px;
}

.stuInfo .stuBtnDown .aView .faxSize {
  font-size: 12px;
  color: #343a40;
}

.stuInfo .stuBtnDown .aView:hover .faxSize {
  color: #1666b3;
}

.stuInfo .stuImgCont {
  position: absolute;
  top: 0;
  left: 0;
  width: 60px;
  height: 80px;
  border-radius: 4px;
  overflow: hidden;
}

.stuInfo .stuImgCont img {
  width: 100%;
}

.stuInfo .stuName {
  color: #212e48;
  font-size: 18px;
  font-weight: bold;
}

.stuInfo .stuRole {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 12px;
}

.stuRole .spStatus {
  font-size: 12px;
  color: #b1b1b1;
}

.stuInfo .stuOther {
  color: #767676;
  font-size: 12px;
}

.cardTabs .abtnTabs {
  font-size: 16px;
  color: #b1b1b1;
  margin-left: 15px;
  position: relative;
  display: block;

  float: left;
  text-align: center;
}

.cardTabs .spDivide {
  float: left;
  padding-left: 15px;
}

.cardTabs .abtnTabs:hover {
  color: #323e56;
}

.cardTabs .abtnTabs .spFaxIcon {
  display: none;
}

.cardTabs .abtnTabs:first-child {
  margin-left: 0;
}

.cardTabs .abtnTabs .spNumber {
  background-color: #dee2e6;
  font-size: 12px;
  color: #ffffff;
  font-family: 'OswaldLight';

  padding: 1px 5px;
  border-radius: 5px;
}

.cardTabs .abtnTabAct {
  font-size: 1.1rem;
  color: #323e56;
}

.cardTabs .abtnTabAct .spFaxIcon {
  display: block;
  position: absolute;
  top: 22px;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 12px;
}

.cardTabs .abtnTabAct .spNumber {
  background-color: #1666b3;
  color: #ffffff;
  font-size: 14px;
}

.infoList {
  padding-bottom: 17px;
  padding-left: 30px;
}

.infoList .spName {
  font-size: 32px;
  color: #212e48;
  padding-right: 30px;
  height: 40px;
}

.infoList .spFonts {
  color: #3b475d;
  padding-right: 30px;
  padding-left: 30px;
  border-right: 1px solid #d6d6d6;
}

.infoList .spFonts:first-child {
  padding-left: 0;
}

.infoList .spFonts:last-child {
  border-right: none;
}

.infoList .spMedal {
  padding-right: 15px;
}

.infoList .spFonts .faxSize {
  font-size: 18px;
}

.infoList .spFonts .icon {
  color: #ffffff;
}

.infoList .spStatus {
  border: 1px solid #007bff;
  background-color: #007bff;
  color: #ffffff;
  padding: 5px 20px;
  border-radius: 5px;
}

.infoList .spNStatus {
  border: 1px solid #007bff;
  background-color: #007bff;
  color: #ffffff;
  padding: 5px 20px;
  border-radius: 5px;
}

.infoList .spFonts .spLabel {
  font-size: 14px;
  padding: 2px 5px;
  border-radius: 5px;
  background-color: #b1b1b1;
  color: #ffffff;
  margin-right: 10px;
}

.infoList .spFonts .spNum {
  padding-left: 10px;
  padding-right: 5px;
  font-family: 'OswaldLight';
  font-size: 16px;
  font-weight: bold;
  color: #dc3545;
}

.DetailsPro .colRemarksCont {
  font-size: 14px;
  letter-spacing: 1px;
}
</style>
