<!--社会实践-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <search :key="random" :SearchTitles="SearchTitles" :ColNumber="3" :SearchList="SearchList" ref="SearchList" @onSearch="onSearch" v-if="SearchList != null"></search>
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title">
          寝室得分
          <span class="titleErr" v-if="currentType == 1">
            （
            <i class="el-icon-paperclip"></i>
            表示该记录由寝室个人关联而来，无法申诉、落实、删除）
          </span>
        </h3>
        <div class="card-tools">
          <ul class="nav nav-pills ml-auto navToolsCont dormTools">
            <li class="nav-item">
              <el-button v-if="isPower.moralitydormitorylog_create == true || isPower.moralitydplog_create" type="success" icon="el-icon-circle-plus-outline" @click="onAdd()">
                新增
              </el-button>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body p-0" v-if="item != null && item.length != 0" :key="random">
        <el-table stripe :data="item" class="elTableData" row-key="id" :tree-props="{ children: 'children', hasChildren: 'hasChildren' }">
          <el-table-column type="index" label="序号" class="elColumn" width="80" :index="indexMethod"></el-table-column>
          <el-table-column prop="reason" label="管理原因">
            <template slot-scope="scope">
              <el-tag type="warning" size="mini" effect="plain" class="mr-1" v-if="scope.row.transform">{{ currentType == 1 ? '已落实' : '落实' }}</el-tag>
              <!-- <el-tag type="danger" size="mini" class="mr-1" v-if="scope.row.appeal != null">存在反馈信息</el-tag> -->
              <span v-if="scope.row.relatedDpLogId" class="paperclip">
                <i class="el-icon-paperclip"></i>
              </span>
              <span v-html="onGetReason(scope.row)"></span>
            </template>
          </el-table-column>
          <el-table-column prop="studentName" width="120" label="学生" v-if="currentType == 2"></el-table-column>
          <el-table-column prop="class" label="班级" width="120">
            <template slot-scope="scope">
              {{ scope.row.class ? scope.row.class : scope.row.className }}
            </template>
          </el-table-column>
          <el-table-column prop="room" label="寝室" width="120"></el-table-column>
          <el-table-column label="申诉状态" width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.appealStatus == 1" style="color: #409eff">{{ scope.row.appealStatusName }}</span>
              <span v-if="scope.row.appealStatus == 2" style="color: #67c23a">{{ scope.row.appealStatusName }}</span>
              <span v-if="scope.row.appealStatus == 3" style="color: #f56c6c">{{ scope.row.appealStatusName }}</span>
              <span v-if="scope.row.appealStatus == 4" style="color: #909399">{{ scope.row.appealStatusName }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="score" label="分数" width="80">
            <template slot-scope="scope">
              <div v-html="scope.row.score"></div>
            </template>
          </el-table-column>
          <el-table-column prop="lastUpdateDate" label="记录人" width="80">
            <template slot-scope="scope">
              {{ scope.row.createdBy }}
            </template>
          </el-table-column>
          <el-table-column prop="logDate" label="行为时间" width="150">
            <template slot-scope="scope">
              <span>{{ scope.row.logDate | dateformatMinute }}</span>
            </template>
          </el-table-column>

          <!-- 寝室 -->
          <el-table-column label="操作" :width="200" fixed="right" align="center" v-if="currentType == 1">
            <template slot-scope="scope">
              <div class="t-r">
                <operationBtn v-if="isPower.moralitydormitorylog_details" :btnName="'查看'" @click.native="onDetail(scope.row.memberId)"></operationBtn>
                <el-dropdown
                  v-if="
                    (isPower.moralitydormitorylog_appeal && scope.row.isAppeal) ||
                    (isPower.moralitydormitorylog_approve && scope.row.appealStatus == 1) ||
                    (isPower.moralitydormitorylog_tea_create && scope.row.isImplement) ||
                    (isPower.moralitydormitorylog_delete && scope.row.isDelete)
                  "
                  trigger="click"
                  @command="handleCommand($event, scope.row.logId, scope.row)"
                  class="ml-1"
                >
                  <el-button size="mini">
                    更多操作
                    <i class="el-icon-arrow-down el-icon--right"></i>
                  </el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item v-if="isPower.moralitydormitorylog_appeal && scope.row.isAppeal" class="color-primary" command="onAppeal">申诉</el-dropdown-item>
                    <el-dropdown-item v-if="isPower.moralitydormitorylog_approve && scope.row.appealStatus == 1" class="color-primary" command="onApprove">
                      申诉处理
                    </el-dropdown-item>
                    <el-dropdown-item v-if="isPower.moralitydormitorylog_tea_create && scope.row.isImplement" class="color-warning" command="onImplement">落实</el-dropdown-item>
                    <el-dropdown-item v-if="isPower.moralitydormitorylog_delete && scope.row.isDelete" class="color-danger" command="onDelete">删除</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </template>
          </el-table-column>

          <!-- 寝室个人 -->
          <el-table-column label="操作" :width="200" fixed="right" align="center" v-if="currentType == 2">
            <template slot-scope="scope">
              <div class="t-r">
                <operationBtn v-if="isPower.moralitydplog_details" :btnName="'查看'" @click.native="onDetail(scope.row.memberId)"></operationBtn>
                <el-dropdown
                  v-if="
                    (isPower.moralitydplog_appeal && scope.row.isAppeal) ||
                    (isPower.moralitydplog_approve && scope.row.appealStatus == 1) ||
                    (isPower.moralitydplog_delete && scope.row.isDelete)
                  "
                  trigger="click"
                  @command="handleCommand($event, scope.row.logId, scope.row)"
                  class="ml-1"
                >
                  <el-button size="mini">
                    更多操作
                    <i class="el-icon-arrow-down el-icon--right"></i>
                  </el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item v-if="isPower.moralitydplog_appeal && scope.row.isAppeal" class="color-primary" command="onAppeal">申诉</el-dropdown-item>
                    <el-dropdown-item v-if="isPower.moralitydplog_approve && scope.row.appealStatus == 1" class="color-primary" command="onApprove">申诉处理</el-dropdown-item>
                    <el-dropdown-item v-if="isPower.moralitydplog_delete && scope.row.isDelete" class="color-danger" command="onDelete">删除</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </template>
          </el-table-column>
        </el-table>

        <comPage :paging="page" :pageBtnList="pageBtnList" @pagingClick="pagingClick" @pageBtnClick="pageBtnClick"></comPage>
      </div>
      <div class="card-body" v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>

    <!-- 申诉 -->
    <el-dialog
      :title="'申诉'"
      :visible.sync="dialogVisibleAppeal"
      width="30%"
      :before-close="handleCloseAppeal"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :append-to-body="true"
      :fullscreen="false"
    >
      <el-form ref="ruleFormAppeal" :model="ruleFormAppeal" :rules="rulesAppeal">
        <el-form-item label="申诉理由" prop="appealReason">
          <el-input type="textarea" v-model="ruleFormAppeal.appealReason" :rows="5" placeholder="请输入申诉理由"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleAppeal = false">取 消</el-button>
        <el-button type="primary" @click="onUpdateAppeal">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 申诉处理 -->
    <el-dialog
      :title="'申诉处理'"
      :visible.sync="dialogVisibleApprove"
      width="30%"
      :before-close="handleCloseApprove"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :append-to-body="true"
      :fullscreen="false"
    >
      <el-form ref="ruleFormApprove" :model="ruleFormApprove" :rules="rulesApprove">
        <el-form-item label="处理意见" prop="approveReason">
          <el-input type="textarea" v-model="ruleFormApprove.approveReason" :rows="5" placeholder="请输入处理意见"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleApprove = false">取 消</el-button>
        <el-button type="danger" @click="onUpdateApprove('Reject')">驳 回</el-button>
        <el-button type="primary" @click="onUpdateApprove('Pass')">通 过</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import '@/assets/css/Admin.css';
import comPage from '@/components/Page.vue';
import operationBtn from '@/components/operationBtn.vue';
import search from '@/components/search.vue';
export default {
  components: {
    comPage,
    operationBtn,
    search
  },
  data() {
    return {
      random: 0, //刷新组件
      SearchTitles: [],
      SearchList: null, //搜索集合
      currentType: null, //当前类型
      fullscreenLoading: false,
      page: null, //分页查询接口返回的结果
      item: [], //列表数据
      pageBtnList: null, //分页左侧按钮集合
      paging: {
        //分页数据
        pageLength: 0, // 总共页数
        Size: 10, // 当前请求数量
        Index: 1 //当前在第几页
      },
      dialogVisible: false,
      dialogVisibleAppeal: false,
      dialogVisibleApprove: false,
      ruleForm: {
        logId: null,
        appeal: ''
      },
      isPower: {
        // 寝室权限
        moralitydormitorylog_index: false,
        moralitydormitorylog_details: false,
        moralitydormitorylog_create: false,
        moralitydormitorylog_delete: false,
        moralitydormitorylog_tea_create: false,
        moralitydormitorylog_appeal: false,
        moralitydormitorylog_approve: false,
        // 寝室个人权限
        moralitydplog_index: false,
        moralitydplog_details: false,
        moralitydplog_create: false,
        moralitydplog_delete: false,
        moralitydplog_appeal: false,
        moralitydplog_approve: false
      },
      // 申诉
      ruleFormAppeal: {
        memberId: null,
        appealReason: null
      },
      ruleFormAppealReset: null,
      rulesAppeal: {
        appealReason: [{ max: 256, message: '申诉理由不能超过256个字符', trigger: 'change' }]
      },
      // 申诉处理
      ruleFormApprove: {
        memberId: null,
        approveStatus: null,
        approveReason: null
      },
      rulesApprove: {
        approveReason: [{ max: 256, message: '处理意见不能超过256个字符', trigger: 'change' }]
      },
      ruleFormApproveResert: null,
      optAppealStatus: [],
      optAll: [
        {
          Value: 'true',
          Title: '未申诉'
        },
        {
          Value: 'false',
          Title: '已申诉'
        }
      ],
      url: null, //当前页面的路径
      TeacherInfo: {} // 教师信息
    };
  },
  created() {
    let that = this;
    that.TeacherInfo = onGetNowUser(that);

    that.ruleFormAppealReset = JSON.parse(JSON.stringify(that.ruleFormAppeal));
    that.ruleFormApproveResert = JSON.parse(JSON.stringify(that.ruleFormApprove));

    isPower(that, 'moralitydormitorylog_index', 'moralitydormitorylog_index');
    isPower(that, 'moralitydormitorylog_details', 'moralitydormitorylog_details');
    isPower(that, 'moralitydormitorylog_create', 'moralitydormitorylog_create');
    isPower(that, 'moralitydormitorylog_delete', 'moralitydormitorylog_delete');
    isPower(that, 'moralitydormitorylog_tea_create', 'moralitydormitorylog_tea_create');
    isPower(that, 'moralitydormitorylog_appeal', 'moralitydormitorylog_appeal');
    isPower(that, 'moralitydormitorylog_approve', 'moralitydormitorylog_approve');

    isPower(that, 'moralitydplog_index', 'moralitydplog_index');
    isPower(that, 'moralitydplog_details', 'moralitydplog_details');
    isPower(that, 'moralitydplog_create', 'moralitydplog_create');
    isPower(that, 'moralitydplog_delete', 'moralitydplog_delete');
    isPower(that, 'moralitydplog_appeal', 'moralitydplog_appeal');
    isPower(that, 'moralitydplog_approve', 'moralitydplog_approve');

    ajaxGet(that, '/api/dict/appealstatus', null, (r) => {
      if (!regNullArray(r)) {
        r.forEach((element) => {
          that.optAppealStatus.push({
            Value: element.Value,
            Title: element.Text
          });
        });
      }
      Init(that);
    });
  },
  methods: {
    /**
     * @description 申述处理
     * @param {Object} typeString Pass通过；Reject驳回
     */
    onUpdateApprove(typeString) {
      let that = this;
      if (typeString == 'Pass') {
        that.ruleFormApprove.approveStatus = 2;
      } else if (typeString == 'Reject') {
        that.ruleFormApprove.approveStatus = 3;
      }
      that.$refs['ruleFormApprove'].validate((valid) => {
        if (valid) {
          let url = '';
          if (that.currentType == 1) {
            url = '/api/admin/moralitydormitorylog/approve';
          } else if (that.currentType == 2) {
            url = '/api/admin/moralitydplog/approve';
          }
          that.fullscreenLoading = true;
          ajaxPut(that, url, that.ruleFormApprove, (r) => {
            that.fullscreenLoading = false;
            that.dialogVisibleApprove = false;
            Init(that);
          });
        } else {
          setTimeout(() => {
            var isError = document.getElementsByClassName('is-error');
            var el = isError[0].querySelector('input') || isError[0].querySelector('textarea') || isError[0].querySelector('select');
            if (el) {
              el.focus();
            }
          }, 1);
          return false;
        }
      });
    },
    handleCloseApprove() {
      let that = this;
      that.dialogVisibleApprove = false;
    },
    /**
     * @description 提交申诉
     */
    onUpdateAppeal() {
      let that = this;
      that.$refs['ruleFormAppeal'].validate((valid) => {
        if (valid) {
          let url = '';
          if (that.currentType == 1) {
            url = '/api/admin/moralitydormitorylog/appeal';
          } else {
            url = '/api/admin/moralitydplog/appeal';
          }
          that.fullscreenLoading = true;
          ajaxPut(that, url, that.ruleFormAppeal, (r) => {
            that.fullscreenLoading = false;
            that.dialogVisibleAppeal = false;
            Init(that);
          });
        } else {
          setTimeout(() => {
            var isError = document.getElementsByClassName('is-error');
            var el = isError[0].querySelector('input') || isError[0].querySelector('textarea') || isError[0].querySelector('select');
            if (el) {
              el.focus();
            }
          }, 1);
          return false;
        }
      });
    },
    handleCloseAppeal() {
      let that = this;
      that.dialogVisibleAppeal = false;
    },
    onAppeal(obj) {
      let that = this;
      that.ruleFormAppeal = JSON.parse(JSON.stringify(that.ruleFormAppealReset));
      that.ruleFormAppeal.memberId = obj.memberId;
      that.$nextTick(() => {
        that.$refs.ruleFormAppeal.clearValidate();
      });
      that.dialogVisibleAppeal = true;
    },
    onApprove(obj) {
      let that = this;
      that.ruleFormApprove = JSON.parse(JSON.stringify(that.ruleFormApproveResert));
      that.ruleFormApprove.memberId = obj.memberId;
      that.$nextTick(() => {
        that.$refs.ruleFormApprove.clearValidate();
      });
      that.dialogVisibleApprove = true;
    },
    /**
     * @description 更多操作
     * @param {Object} command
     * @param {Object} _valueID
     * @param {Object} objVal
     */
    handleCommand(objType, objValID, objVal) {
      let that = this;
      switch (objType) {
        case 'onImplement':
          if (objVal.appealStatus == 1) {
            warning(that, '待处理数据无法落实，请先取消申诉');
            return false;
          }
          that.onImplement(objVal.memberId);
          break;
        case 'onDelete':
          that.onDelete(objVal);
          break;
        case 'onAppeal':
          that.onAppeal(objVal);
          break;
        case 'onApprove':
          that.onApprove(objVal);
          break;
      }
    },
    /**
     * @description 显示原因
     * @param {Object} objItem
     */
    onGetReason(objItem) {
      return objItem.reason + (regNull(delHtml(objItem.remark)) ? '' : '<span class="color-gray">（' + delHtml(objItem.remark) + '）</span>');
    },
    /**
     * @description 落实
     * @param {Object} Id
     */
    onImplement(Id) {
      let that = this;
      that.$router.push({
        path: '/Admin/Adviser/Dormitory/Implement/' + Id,
        query: {
          url: that.$router.history.current.fullPath
        }
      });
    },
    onGetUser(val) {
      if (regNull(val.user)) {
        return val.student;
      } else {
        return val.user;
      }
    },

    /**
     * @description 列表序号索引
     * @param {Object} index
     */
    indexMethod(index) {
      return (this.paging.Index - 1) * this.paging.Size + index + 1;
    },
    onAdd() {
      let that = this;
      let type = parseInt(that.currentType) + 2;
      that.$router.push({
        path: '/Admin/Morality/Create/' + type,
        query: {
          type: that.currentId
        }
      });
    },
    onDetail(Id) {
      let that = this;
      that.$router.push({
        path: '/Admin/Dormitory/Detail/' + that.currentType + '/' + Id,
        query: {
          url: that.$router.history.current.fullPath
        }
      });
    },
    onDelete(objVal) {
      let that = this;
      var deleteUrl = '';
      if (that.currentType == 1) {
        deleteUrl = '/api/admin/moralitydormitorylog/' + objVal.memberId;
      } else if (that.currentType == 2) {
        deleteUrl = '/api/admin/moralitydplog/' + objVal.memberId;
      }
      if (deleteUrl != '') {
        confirmDelete(that, null, function (res) {
          if (res == true) {
            that.fullscreenLoading = true;
            ajaxDelete(that, deleteUrl, null, function (r) {
              that.fullscreenLoading = false;
              Init(that);
            });
          }
        });
      }
    },
    pageBtnClick(index) {
      //分页组件左侧的按钮事件，按顺序进行判断
    },
    onSearch(params) {
      let that = this;
      let data = {};
      let searchData = params;
      searchData.forEach((element) => {
        if (element.data) {
          data[element.zhi] = Join(element.data);
        }
      });
      data['type'] = that.currentType;
      let url = '/Admin/Dormitory/Index';
      data.PageNumer = 1;
      routerPath(that, url, data, null, 'PageSize');
    },
    pagingClick(type, val) {
      let that = this;
      let url = '/Admin/Dormitory/Index?type=' + that.currentType;
      if (type == 1) {
        //更改每页条数触发的事件
        routerPath(that, url, 'PageSize', val, 'PageNumer', 'Reason', 'ClassId', 'StudentInfo', 'LogDate', 'LastUpdateDate', 'AppealStatus', 'All');
      } else {
        //更改当前页时触发的事件
        routerPath(that, url, 'PageNumer', val, 'PageSize', 'Reason', 'ClassId', 'StudentInfo', 'LogDate', 'LastUpdateDate', 'AppealStatus', 'All');
      }
    }
  },
  watch: {
    $route() {
      let that = this;
      Init(that);
    }
  }
};

function Init(that) {
  that.currentType = that.$route.query.type;
  if (that.currentType == null || typeof that.currentType == 'undefined' || that.currentType == '') {
    that.currentType = 1;
    if (that.isPower.moralitydormitorylog_index == true) {
      that.currentType = 1;
    } else if (that.isPower.moralitydplog_index == true) {
      that.currentType = 2;
    } else {
      that.currentType = 1;
    }
  }
  that.SearchTitles = [];
  if (that.isPower.moralitydormitorylog_index == true) {
    that.SearchTitles.push({
      name: '寝室',
      isCurrent: that.currentType == 1 ? true : false,
      url: '/Admin/Dormitory/Index?type=1'
    });
  }
  if (that.isPower.moralitydplog_index == true) {
    that.SearchTitles.push({
      name: '寝室个人',
      isCurrent: that.currentType == 2 ? true : false,
      url: '/Admin/Dormitory/Index?type=2'
    });
  }

  pagingPage(that);
}

function pagingPage(that) {
  paging(
    that,
    function (r) {
      let Myurl = null;
      if (that.currentType == 1) {
        Myurl = '/api/admin/moralitydormitorylog/search';
      } else if (that.currentType == 2) {
        Myurl = '/api/admin/moralitydplog/search';
      }
      that.fullscreenLoading = true;

      let classList = [];
      ajaxGet(that, '/api/admin/college/normallinkage', null, function (resClass) {
        resClass.forEach((element) => {
          var it = {
            Value: element.collegeId,
            Title: element.name,
            Childrens: []
          };

          if (!regNullArray(element.classes)) {
            element.classes.forEach((chil) => {
              it.Childrens.push({
                Value: chil.classId,
                Title: chil.alias
              });
            });
          }
          classList.push(it);
        });

        that.SearchList = [
          {
            type: 'cascader',
            Name: '班级',
            data: r.ClassId,
            select: classList,
            zhi: 'ClassId'
          },
          {
            type: 'input',
            Name: '管理原因',
            data: r.Reason,
            holder: '请输入管理原因',
            zhi: 'Reason'
          },
          {
            type: 'time',
            Name: '行为时间',
            data: r.LogDate,
            zhi: 'LogDate'
          },
          {
            type: 'time',
            Name: '操作时间',
            data: r.LastUpdateDate,
            zhi: 'LastUpdateDate'
          },
          // 申诉状态
          {
            type: 'select',
            Name: '申诉状态',
            data: r.AppealStatus,
            select: that.optAppealStatus, //选择的数据
            zhi: 'AppealStatus', // 传值
            isClear: true //select是否可以清除
          },
          {
            type: 'select',
            Name: '是否申诉',
            data: r.All,
            select: that.optAll, //选择的数据
            zhi: 'All', // 传值
            isClear: true //select是否可以清除
          }
        ];

        if (that.currentType == 2) {
          that.SearchList.push({
            type: 'input',
            Name: '学生',
            data: r.StudentInfo,
            holder: '请输入学生姓名/学号',
            zhi: 'StudentInfo'
          });
        }

        var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
        that.random += random;

        var LogDateList = [];
        var LastUpdateDateList = [];
        var ClassId = null;
        let data = {};
        if (!regNull(r.ClassId)) {
          ClassId = JSON.parse(JSON.stringify(r.ClassId)).split(',')[1];
        }
        if (r.LogDate != null && r.LastUpdateDate != null) {
          LogDateList = JSON.parse(JSON.stringify(r.LogDate)).split(',');
          LastUpdateDateList = JSON.parse(JSON.stringify(r.LastUpdateDate)).split(',');
          data = {
            type: that.currentType,
            PageNumer: r.PageNumer,
            PageSize: r.PageSize,
            Reason: r.Reason,
            ClassId: ClassId,
            StudentInfo: r.StudentInfo,
            LogDateStart: LogDateList[0],
            LogDateEnd: LogDateList[1],
            LastUpdateDateStart: LastUpdateDateList[0],
            LastUpdateDateEnd: LastUpdateDateList[1],
            AppealStatus: r.AppealStatus,
            All: r.All
          };
        } else if (r.LogDate != null) {
          LogDateList = JSON.parse(JSON.stringify(r.LogDate)).split(',');
          data = {
            type: that.currentType,
            PageNumer: r.PageNumer,
            PageSize: r.PageSize,
            Reason: r.Reason,
            ClassId: ClassId,
            StudentInfo: r.StudentInfo,
            LogDateStart: LogDateList[0],
            LogDateEnd: LogDateList[1],
            AppealStatus: r.AppealStatus,
            All: r.All
          };
        } else if (r.LastUpdateDate != null) {
          LastUpdateDateList = JSON.parse(JSON.stringify(r.LastUpdateDate)).split(',');
          data = {
            type: that.currentType,
            PageNumer: r.PageNumer,
            PageSize: r.PageSize,
            Reason: r.Reason,
            ClassId: ClassId,
            StudentInfo: r.StudentInfo,
            LastUpdateDateStart: LastUpdateDateList[0],
            LastUpdateDateEnd: LastUpdateDateList[1],
            AppealStatus: r.AppealStatus,
            All: r.All
          };
        } else {
          data = {
            type: that.currentType,
            PageNumer: r.PageNumer,
            PageSize: r.PageSize,
            Reason: r.Reason,
            ClassId: ClassId,
            StudentInfo: r.StudentInfo,
            AppealStatus: r.AppealStatus,
            All: r.All
          };
        }
        that.paging.Index = r.PageNumer;
        that.paging.Size = r.PageSize;
        ajaxGet(that, Myurl, data, function (r1) {
          that.page = r1;

          that.item = r1.items;

          for (var i in that.item) {
            var it = that.item[i];

            // 判断是否可以删除按钮isDelte，true可以删除，false不能删除
            it.isDelete = !that.TeacherInfo.isTeacher;
            // 判断是否可以申诉按钮isAppeal，true可以申诉，false不能申诉
            it.isAppeal = true;
            // 判断是否可以落实，true可以落实，false不能落实
            it.isImplement = true;
            // 有申诉状态说明已发起过申诉，无法再次申诉（申诉只能一次）
            if (!regNull(it.appealStatus)) {
              it.isAppeal = false;
            }
            // 是否转化，转化则已落实，未转化则未落实
            if (it.transform) {
              it.isImplement = false;
            }
            // 由班级个人关联过来的数据，不需要申诉、落实、删除
            if (!regNull(it.relatedDpLogId)) {
              it.isDelete = false;
              it.isAppeal = false;
              it.isImplement = false;
            }

            it.id = i + 1;
          }
          that.fullscreenLoading = false;
        });
      });
    },
    'Reason',
    'ClassId',
    'StudentInfo',
    'LogDate',
    'LastUpdateDate',
    'AppealStatus',
    'All'
  );
}
</script>

<style scoped="scoped">
.navTabsCont .nav-link {
  font-size: 12px;
}

.navToolsCont {
  margin-top: 1px;
}

.btn-mini {
  margin-left: 15px;
  margin-right: 5px;
  height: 40px;
  line-height: 25px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 14px;
}

.spIcon {
  background-color: #f8f9fa;
  font-size: 14px;
  padding: 2px 5px;
}

.spBan {
  color: #409eff;
}

.spBu {
  color: #fac10b;
}

.spXiao {
  color: #f6402c;
}

.titleErr {
  font-size: 14px;
  color: #f6402c;
}

.paperclip {
  margin-right: 5px;
  color: #409eff;
}
</style>
