<!--
 ECHARTS 的 雷达图
-->
<template>
  <!-- 250px -->
  <div :id="comID" :style="pieStyle"></div>
</template>

<script>
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/legend';

import * as echarts from 'echarts/core';
import { TooltipComponent } from 'echarts/components';
import { TreeChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';

echarts.use([TooltipComponent, TreeChart, CanvasRenderer]);

export default {
  props: {
    /**
     * @description 控件ID
     */
    comID: {
      type: String,
      default: 'myChartTree'
    },
    /**
     * @description 高度
     */
    comHeight: {
      type: Number,
      default: 250
    },
    /**
     * @description 数据
     */
    comChartData: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      pieStyle: '',
      myChart: {},
      itemData: []
    };
  },
  created() {
    let that = this;
    that.onGetRadar(that.comChartData, that.comHeight);
  },
  mounted() {
    let that = this;
    window.onresize = function () {
      that.myChart.resize();
      that.pieStyle = 'width: 100%; height:' + that.comHeight * 0.0625 + 'rem;';
    };
  },
  methods: {
    /**
     * @description 调用方法
     * @param {Object} objData
     */
    onGetRadar(objData, objheight) {
      let that = this;
      if (!regNullArray(objData)) {
        that.itemData = JSON.parse(JSON.stringify(objData));
        that.$nextTick(() => {
          that.drawLine();
        });
      }
      if (objheight < 500) {
        that.pieStyle = 'width: 100%; height:' + 500 * 0.0625 + 'rem;';
      } else {
        that.pieStyle = 'width: 100%; height:' + objheight * 0.0625 + 'rem;';
      }
      window.onresize = function () {
        that.myChart.resize();
      };
    },
    /**
     * @description 生成雷达图
     */
    drawLine() {
      let that = this;
      // 基于准备好的dom，初始化echarts实例

      /*
        var data = {
          name: '扣分原因TOP 5',
          children: [{
              name: '行为规范【班级】',
              children: [{
                  name: '卫生',
                  children: [{
                      name: '窗帘问题',
                      value: 3938
                    },
                    {
                      name: '辱骂，态度差不配合',
                      value: 3812
                    },
                    {
                      name: '成袋垃圾乱放',
                      value: 6714
                    },
                    {
                      name: '边走边吃，乱丢、乱到',
                      value: 743
                    },
                    {
                      name: '整齐度（桌椅、书柜、饮水机、卫生工具摆放等）',
                      value: 743
                    }
                  ]
                },
                {
                  name: '两操',
                  children: [{
                      name: '眼操期间窗帘问题',
                      value: 3534
                    },
                    {
                      name: '课间操早退、迟到',
                      value: 5731
                    },
                    {
                      name: '拒缴违规物品',
                      value: 7840
                    },
                    {
                      name: '课间操整齐度（讲话、打闹等）',
                      value: 5914
                    },
                    {
                      name: '课间操教室留人（无证明）',
                      value: 3416
                    },
                    {
                      name: '课间操教室多媒体，电灯未关等',
                      value: 5914
                    }
                  ]
                },
                {
                  name: '文护（护督）',
                  children: [{
                      name: '窜班、窜部',
                      value: 7074
                    },
                    {
                      name: '男女生交往过密',
                      value: 7074
                    },
                    {
                      name: '牌类（打牌）',
                      value: 7074
                    },
                    {
                      name: '手机、充电宝 使用或充电等（已缴)',
                      value: 7074
                    },
                    {
                      name: '通风报信、紧闭窗门不配合',
                      value: 7074
                    }
                  ]
                },
                {
                  name: '纪检',
                  children: [{
                      name: '仪容仪表、校服校卡',
                      value: 7074
                    },
                    {
                      name: '黑板记录班级人数不规范',
                      value: 7074
                    },
                    {
                      name: '抽烟',
                      value: 7074
                    },
                    {
                      name: '自修课剪指甲、头发、照镜子等',
                      value: 7074
                    },
                    {
                      name: '牌类（打牌）',
                      value: 7074
                    }
                  ]
                }
              ]
            },
            {
              name: '行为规范【个人】',
              children: [{
                  name: '卫生',
                  children: [{
                      name: '关门拒检',
                      value: 3938
                    },
                    {
                      name: '辱骂，态度差不配合',
                      value: 3812
                    },
                    {
                      name: '整齐度（桌椅、书柜、饮水机、卫生工具摆放等）',
                      value: 6714
                    },
                    {
                      name: '检查未留人',
                      value: 743
                    },
                    {
                      name: '垃圾分类错误',
                      value: 743
                    }
                  ]
                },
                {
                  name: '两操',
                  children: [{
                      name: '旷操（个人）',
                      value: 3534
                    },
                    {
                      name: '课间操整齐度（讲话、打闹等）',
                      value: 5731
                    },
                    {
                      name: '校服规范，仪容仪表',
                      value: 7840
                    },
                    {
                      name: '大课间其他（电子设备、外卖、烟等）',
                      value: 5914
                    },
                    {
                      name: '请假单及程序不规范',
                      value: 3416
                    },
                    {
                      name: '整体差',
                      value: 5914
                    }
                  ]
                },
                {
                  name: '文护（护督）',
                  children: [{
                      name: '仪容仪表、校服校卡',
                      value: 7074
                    },
                    {
                      name: '违规使用多媒体',
                      value: 7074
                    },
                    {
                      name: '刀具、棍棒等危险物品',
                      value: 7074
                    },
                    {
                      name: '牌类（打牌）',
                      value: 7074
                    },
                    {
                      name: '热食、外卖等禁食（包括包装袋）',
                      value: 7074
                    }
                  ]
                },
                {
                  name: '纪检',
                  children: [{
                      name: '自修课抬水、灌水、洗东西',
                      value: 7074
                    },
                    {
                      name: '热食、外卖等禁食（包括包装袋）',
                      value: 7074
                    },
                    {
                      name: '仪容仪表、校服校卡',
                      value: 7074
                    },
                    {
                      name: '手机、充电宝 使用或充电等（已缴)',
                      value: 7074
                    },
                    {
                      name: '自修课讲话',
                      value: 7074
                    }
                  ]
                }
              ]
            },
            {
              name: '行为规范【寝室】',
              children: [{
                  name: '纪律',
                  children: [{
                      name: '与其他寝室楼对喊',
                      value: 721
                    },
                    {
                      name: '寝室内和走廊上泼水，往楼下扔垃圾或倒水',
                      value: 4294
                    },
                    {
                      name: '破坏公共财物',
                      value: 9800
                    },
                    {
                      name: '明火、蚊香',
                      value: 1314
                    },
                    {
                      name: '门后抵物、门把手挂物、门上无钥匙',
                      value: 2220
                    }
                  ]
                },
                {
                  name: '内务',
                  children: [{
                      name: '大扫除时洗漱（做无关事）',
                      value: 721
                    },
                    {
                      name: '未关（水龙头、电灯、风扇、柜门）',
                      value: 4294
                    },
                    {
                      name: '垃圾分类错误',
                      value: 9800
                    },
                    {
                      name: '不按规定乱挂（放）物品',
                      value: 1314
                    },
                    {
                      name: '大扫除未留人',
                      value: 2220
                    }
                  ]
                }
              ]
            },
            {
              name: '行为规范【寝室个人】',
              children: [{
                  name: '纪律',
                  children: [{
                      name: '并铺睡',
                      value: 721
                    },
                    {
                      name: '晚归',
                      value: 4294
                    },
                    {
                      name: '热食，奶茶、坚果类外卖，爆米花等禁食',
                      value: 9800
                    },
                    {
                      name: '态度差（不服从管理，不礼貌、辱骂，殴打、喊叫)',
                      value: 1314
                    },
                    {
                      name: '寝室内和走廊上泼水，往楼下扔垃圾或倒水',
                      value: 2220
                    }
                  ]
                },
                {
                  name: '内务',
                  children: [{
                      name: '大扫除未打扫',
                      value: 721
                    },
                    {
                      name: '大扫除时洗漱（做无关事）',
                      value: 4294
                    },
                    {
                      name: '污垢不整洁（地面、床下、洗脸台、蹲坑等）',
                      value: 9800
                    },
                    {
                      name: '未关（水龙头、电灯、风扇、柜门）',
                      value: 1314
                    },
                    {
                      name: '物品摆放不整齐（脸盆、毛巾、牙杯、鞋子等）',
                      value: 2220
                    }
                  ]
                }
              ]
            }
          ]
        };
*/
      let myChart = echarts.init(document.getElementById(that.comID));
      // 绘制图表
      if (!regNullArray(that.itemData)) {
        console.log(that.itemData, 'this.itemData');

        myChart.setOption({
          tooltip: {
            trigger: 'item',
            triggerOn: 'mousemove',
            extraCssText: 'max-width:850px;',
            formatter: function (params) {
              var tpTipCont = '';
              if (params != null) {
                tpTipCont += params.marker + '名称：' + params.data.name + '<br/>';
                // tpTipCont += params.marker + DN + params.data.neDn + '<br/>'
                // tpTipCont += params.marker + neType + params.data.neType + '<br/>'
                if (params.data.type == 1) {
                  tpTipCont += params.marker + '基础分：' + params.data.baseScore + '<br/>';
                  if (!regNull(params.data.ceiling) && params.data.ceiling != 0) {
                    tpTipCont += params.marker + '上限分：' + params.data.ceiling + '<br/>';
                  }
                  if (!regNull(params.data.remark)) {
                    tpTipCont += params.marker + '说明：' + params.data.ceiling + '<br/>';
                  }
                } else if (params.data.type == 2) {
                  if (!regNull(params.data.remark)) {
                    tpTipCont += params.marker + '说明：' + params.data.remark + '<br/>';
                  }
                } else if (params.data.type == 3) {
                  if (!regNull(params.data.role)) {
                    tpTipCont += params.marker + '角色：' + params.data.role + '<br/>';
                  }
                  if (!regNull(params.data.score)) {
                    tpTipCont += params.marker + (regNull(params.data.title) ? '分数' : params.data.title) + '：' + params.data.score + '<br/>';
                  }
                }
              }
              return tpTipCont;
            }
          },
          series: [
            {
              type: 'tree',
              data: that.itemData,
              top: '0%',
              bottom: '0%',
              left: '100px',
              right: '20%',
              symbolSize: 7,
              label: {
                position: 'right',
                verticalAlign: 'middle',
                align: 'left',
                fontSize: 16,
                color: 'rgba(48,51,51,1)',
                formatter: function (params) {
                  // params为x轴文字内容
                  // if (params.type == true) {
                  //   return params.name + '（' + params.value + '）';
                  // } else {
                  //   return params.name;
                  // }
                }
              },
              itemStyle: {
                color: 'rgba(64, 158, 255, 1)'
              },
              lineStyle: {
                color: 'rgba(221,221,221,1)'
              },
              leaves: {
                label: {
                  position: 'right',
                  verticalAlign: 'middle',
                  align: 'left'
                }
              },
              emphasis: {
                focus: 'descendant'
              },
              expandAndCollapse: true,
              animationDuration: 550,
              initialTreeDepth: 5,
              animationDurationUpdate: 750
            }
          ]
        });
      }
    }
  }
};
</script>

<style></style>
