<!--
  留宿名单 —— 查看
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="ruleForm">
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            查看{{ Title }}名单
          </h3>
        </div>
        <div class="card-body tabListCont">
          <div class="row">
            <div class="col-lg-12 colInfo">
              <label>{{ Title }}时间</label>
              <div class="colFonts">{{ ruleForm.startDate | dateformatMinute }} 至 {{ ruleForm.endDate | dateformatMinute }}</div>
            </div>
            <div class="col-lg-12 colInfo">
              <label>{{ Title }}原因</label>
              <div class="colFonts" v-if="ruleForm.reason" v-html="ruleForm.reason"></div>
            </div>
          </div>
        </div>
      </el-card>
      <!-- 学生名单 -->
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont" style="float: left">
            <span class="spIcon"></span>
            学生名单
          </h3>
        </div>
        <div class="card-body tabListCont">
          <el-row :gutter="20">
            <el-col :lg="8" :md="8" :sm="12" v-for="(p, index) in ruleForm.members" :key="index">
              <div class="stuInfo">
                <div class="stuInfoBox">
                  <div class="stuImgCont VerticalMid">
                    <el-image :src="onImgSpliceUrlNoV(p.photo)">
                      <div slot="error" class="image-slot">
                        <svg class="icon" aria-hidden="true">
                          <use xlink:href="#fax-use-circle-alt"></use>
                        </svg>
                      </div>
                    </el-image>
                  </div>
                  <div class="divInfoBox">
                    <div class="stuName">{{ p.name }}</div>
                    <div class="stuOther">{{ p.studentNo }}</div>
                    <div class="stuRole">{{ p.className }}</div>
                  </div>
                </div>
                <div class="stuRoom">{{ p.buildingName }}（楼）{{ p.floorName }}（层）{{ p.roomName }}（室）{{ p.bedName }}（床位）</div>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-card>
    </el-form>

    <div class="row" style="margin-top: 10px">
      <div class="col-12">
        <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import '@/assets/css/Admin.css';

export default {
  components: {},
  data() {
    return {
      // 加载动画
      fullscreenLoading: false,
      //添加学生信息弹出框
      dialogVisibleForBed: false,
      //刷新组件
      random: 0,
      // 表单对象
      ruleForm: {
        id: null,
        date: [],
        startDate: null,
        endDate: null,
        bedId: null,
        studentId: null,
        reason: null,
        members: []
      },
      // 学生数组
      studentItem: [],
      InitStudentItem: [],
      // 选择学生的ID
      currentStudentId: null,
      currentBedId: null,
      // 验证
      rules: {
        reason: [{ required: true, message: '请输入留宿原因', trigger: 'change' }],
        date: [{ required: true, message: '请输入留宿时间', trigger: 'change' }]
      },
      // 寝室数组
      optRooms: [],
      InitRooms: [],
      roomsReset: [],
      currentType: null,
      Title: null
    };
  },
  created() {
    let that = this;
    that.fullscreenLoading = true;
    that.currentType = that.$route.params.type ? that.$route.params.type : 1;
    that.ruleForm.id = that.$route.params.id;
    that.Title = that.currentType == 1 ? '留宿' : '停宿';

    if (that.ruleForm.id) {
      let url = '';
      if (that.currentType == 1) {
        url = '/api/admin/dormstay/' + that.ruleForm.id;
      } else {
        url = '/api/admin/dormsuspend/' + that.ruleForm.id;
      }
      ajaxGet(that, url, null, function (r) {
        that.fullscreenLoading = false;
        that.ruleForm.startDate = r.startDate;
        that.ruleForm.endDate = r.endDate;
        that.ruleForm.reason = r.reason;
        that.ruleForm.members.push({
          bedId: r.bedId,
          bedName: r.bed,
          buildingName: r.building,
          classId: r.classId,
          className: null,
          floorName: r.floor,
          id: null,
          photo: r.photo,
          roomId: null,
          roomName: r.floor,
          score: null,
          name: r.name,
          studentId: r.studentId,
          studentNo: r.studentNo
        });
      });
    }
  },
  methods: {
    /**
     * @description 返回
     * @return {void}
     */
    onBack() {
      let that = this;
      back(that);
    }
  }
};
</script>

<style scoped="scoped">
.nav-item {
  padding: 0 10px;
}

.divDialogBox {
  margin: 0 auto;
  width: 80%;
}

.scoreBox .spLabel {
  padding-left: 2px;
  padding-right: 2px;
  font-weight: bolder;
}

.scoreBox .itemList {
  padding-top: 5px;
  padding-bottom: 5px;
}

.stuInfo {
  position: relative;
  height: 100%;
  min-height: 80px;
  margin: 15px;
}

.stuInfo .stuInfoBox {
  display: flex;
}

.stuInfo .divInfoBox {
  padding-left: 10px;
}

.stuInfo .stuImgCont {
  width: 70px;
  height: 70px;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #dddddd;
}

.stuInfo .stuName {
  color: #212e48;
  font-size: 18px;
  font-weight: bold;
}

.stuInfo .stuRole {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 12px;
}

.stuInfo .stuRoom {
  font-size: 12px;
  color: #767676;
}

.stuInfo .stuOther {
  color: #767676;
  font-size: 12px;
}

.stuInfo .stuRemove {
  display: none;
}

.stuInfo:hover .stuRemove {
  display: block;
  position: absolute;
  top: -0.9375rem;
  left: -0.625rem;
  font-size: 22px;
  cursor: pointer;
}

.transferBox {
  margin: 0 auto;
}

label {
  margin-bottom: 0;
}

.inputGroupCont {
  padding-top: 15px;
}

.inputGroupCont .inputList {
  padding-bottom: 10px;
}

.tabListCont .divNavTitle .divTitleCont {
  padding: 10px 0px 10px 10px;
  color: #dee2e6;
  cursor: pointer;
  font-size: 18px;
}

.tabListCont .divNavTitle .divTitleCont .spNum {
  background-color: #dee2e6;
  font-family: 'OswaldLight';
  padding: 2px 5px;
  border-radius: 4px;
  margin-left: 5px;
  font-size: 14px;
  color: #ffffff;
}

.tabListCont .divNavTitle .divTitleAct {
  color: #3e3e3e;
}

.tabListCont .divNavTitle .divTitleAct .spNum {
  background-color: #17a2b8;
  color: #ffffff;
}

.tabListCont .divNavTitle .nav-link {
  font-size: 14px;
  color: #b1b1b1;
}

.tabListCont .divNavTitle .nav-link .spNum {
  font-family: 'OswaldLight';
  font-weight: normal;
  font-size: 14px;
  padding-left: 10px;
}

.tabListCont .divNavTitle .active {
  color: #007bff;
}

.divAlertCont {
  padding-left: 30px;
  padding-top: 10px;
  color: #9595a0;
}
</style>

<style>
.transferCont .el-transfer__buttons {
  width: 20%;
}

.transferCont .el-transfer__buttons .el-transfer__button:first-child {
  float: left;
}

.transferCont .el-transfer__buttons .el-transfer__button:last-child {
  float: right;
}

.transferCont .el-transfer-panel {
  width: 40%;
}

.formGroupCont .el-date-editor--datetimerange.el-input__inner {
  width: 100%;
}

.stuInfo .stuImgCont .image-slot {
  font-size: 28px;
  color: #dddddd;
}
</style>
