<!--上传图片-->
<template>
  <div v-loading.fullscreen.lock="fullscreenLoading">
    <div v-if="isPicture == true">
      <div v-if="isUpload == true">
        <el-alert style="margin-top: -30px; margin-bottom: 10px" :title="uploadTitle" type="info" show-icon :closable="false"></el-alert>
        <el-upload
          action=""
          :file-list="fileList"
          :http-request="uploadSectionFile"
          :auto-upload="true"
          :multiple="true"
          :before-upload="beforeUpload"
          :limit="pictureMaxCount"
          list-type="picture-card"
        >
          <i slot="default" class="el-icon-plus"></i>
          <div slot="file" slot-scope="{ file }">
            <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
            <span class="el-upload-list__item-actions">
              <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                <i class="el-icon-zoom-in"></i>
              </span>
              <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleDownload(file)">
                <i class="el-icon-download"></i>
              </span>
              <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)">
                <i class="el-icon-delete"></i>
              </span>
            </span>
          </div>
        </el-upload>
      </div>
      <div v-else>
        <div v-if="files == null || files.length <= 0" style="font-size: 14px; color: #ccc">暂无附件</div>
        <el-upload :disabled="true" v-else action="" class="el-upload-view" :file-list="fileList" list-type="picture-card">
          <i slot="default" class="el-icon-plus"></i>
          <div slot="file" slot-scope="{ file }">
            <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
            <span class="el-upload-list__item-actions">
              <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                <i class="el-icon-zoom-in"></i>
              </span>
              <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleDownload(file)">
                <i class="el-icon-download"></i>
              </span>
            </span>
          </div>
        </el-upload>
      </div>
    </div>

    <!-- 上传视频和PDF ↓↓↓↓↓↓-->
    <div v-else-if="isVideoOrPdf">
      <el-row :gutter="24" v-if="isUpload == true">
        <el-col :span="14">
          <el-upload
            class="upload-demo"
            action=""
            :on-preview="handlePreview"
            :file-list="fileList"
            :http-request="uploadSectionFile"
            :auto-upload="true"
            :multiple="true"
            :before-upload="beforeUpload"
            :limit="pictureMaxCount"
            :on-remove="handleRemove"
            ref="upload"
          >
            <div class="text-left pl-2">
              <el-button slot="trigger" size="small" type="primary" icon="el-icon-plus">上传文件</el-button>
            </div>
            <div class="text-left">
              <div slot="tip" class="el-upload__tip">{{ upLoadTipPdf }}</div>
            </div>
          </el-upload>
        </el-col>
      </el-row>
      <div v-else style="margin-top: -20px">
        <div v-for="(p, index) in fileList" :key="index">
          <span style="font-size: 13px">{{ p.name }}</span>
          <a :download="p.name" style="color: #409eff; font-size: 13px" target="_blank" :href="p.url">下载</a>
        </div>
      </div>
    </div>
    <!-- 上传视频和PDF ↑↑↑↑↑↑-->

    <div v-else>
      <el-row :gutter="24" v-if="isUpload == true">
        <el-col :span="14">
          <el-upload
            class="upload-demo"
            action=""
            :on-preview="handlePreview"
            :file-list="fileList"
            :http-request="uploadSectionFile"
            :auto-upload="true"
            :multiple="true"
            :before-upload="beforeUpload"
            :limit="pictureMaxCount"
            :on-remove="handleRemove"
          >
            <div style="float: left">
              <el-button slot="trigger" size="small" type="primary" icon="el-icon-plus">上传文件</el-button>
            </div>
            <div style="float: left; line-height: 30px">
              <div slot="tip" class="el-upload__tip">{{ upLoadTipFile }}</div>
            </div>
          </el-upload>
        </el-col>
      </el-row>

      <div v-else style="margin-top: -20px">
        <div v-for="(p, index) in fileList" :key="index">
          <span style="font-size: 13px">{{ p.name }}</span>
          <a :download="p.name" style="color: #409eff; font-size: 13px" target="_blank" :href="p.url">下载</a>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import qs from 'qs';
export default {
  props: {
    isUpload: {
      //是否允许上传
      type: Boolean,
      default: true
    },
    files: Array, //上传后的图片集合
    isPicture: {
      //是否只允许上传图片
      type: Boolean,
      default: true
    },
    isVideoOrPdf: {
      // 上传视频和PDF
      type: Boolean,
      default: false
    },
    //上传文件个数
    pictureMaxCount: {
      type: Number,
      default: 8
    }
  },
  data() {
    return {
      fileList: [],
      // 上传图片
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      fullscreenLoading: false,

      uploadTitle: '',

      // 是否为视频文件
      isVideo: false,
      videoDuration: 0,
      upLoadTipPdf: '',
      upLoadTipFile: '',
      isIMGFile: false
    };
  },
  watch: {
    videoDuration: function (newVar, oldVar) {
      this.videoDuration = newVar;
    },
    files: function (newVal, oldVal) {
      this.files = newVal;
    }
  },
  created() {
    let that = this;

    if (that.files != null && that.files.length > 0) {
      that.fileList = JSON.parse(JSON.stringify(that.files));
      for (var i in that.fileList) {
        that.fileList[i].url = that.$ServerUrl() + that.fileList[i].path;
        if (that.fileList[i].name == null || typeof that.fileList[i].name == 'undefined' || that.fileList[i].name == '') {
          var urls = that.fileList[i].path.split('/');
          that.fileList[i].name = urls[urls.length - 1];
        }
      }
    } else {
      that.fileList = [];
    }
    that.uploadTitle = '最多允许上传 ' + that.pictureMaxCount + ' 张图片，格式为：jpg，每张图片不能超过 ' + webUploadImgSize() + 'M。';
    that.upLoadTipPdf = '（格式为：MP4和PDF，文件最大不能超过 ' + webUploadPDFSize() + 'M。）';
    that.upLoadTipFile = '（不能上传js和exe文件，不超过' + webUploadFileSize() + 'M）';
  },

  methods: {
    uploadImage(_valImage) {
      let that = this;
      if (_valImage != null && _valImage.length > 0) {
        that.fileList = JSON.parse(JSON.stringify(_valImage));
        for (var i in that.fileList) {
          that.fileList[i].url = that.$ServerUrl() + that.fileList[i].path;
          if (that.fileList[i].name == null || typeof that.fileList[i].name == 'undefined' || that.fileList[i].name == '') {
            var urls = that.fileList[i].path.split('/');
            that.fileList[i].name = urls[urls.length - 1];
          }
        }
      } else {
        that.fileList = [];
      }
    },

    uploadSectionFile(param) {
      let that = this;
      let tag = 'Files';
      let list = that.$route.fullPath.split('/');
      if (list.find((item) => item == 'Morality')) {
        tag = 'Morality'; //五项常规
      }
      if (list.find((item) => item == 'Innovation')) {
        tag = 'Innovation'; //创新创业
      }
      if (list.find((item) => item == 'StudentPunishRevocation')) {
        tag = 'StudentPunishRevocation'; //处分撤销
      }
      if (list.find((item) => item == 'StudentPunish')) {
        tag = 'StudentPunish'; //处分
      }
      if (list.find((item) => item == 'StudentPrize')) {
        tag = 'StudentPrize'; //个人奖励
      }
      if (list.find((item) => item == 'ClassPrize')) {
        tag = 'ClassPrize'; //班级奖励
      }
      if (list.find((item) => item == 'activity')) {
        tag = 'activity'; //活动
      }
      if (list.find((item) => item == 'ClubMGMT')) {
        tag = 'ClubMGMT'; //社团创建
      }
      if (list.find((item) => item == 'Attendance')) {
        tag = 'Attendance'; //晚自修考勤
      }
      if (list.find((item) => item == 'SelfReport')) {
        tag = 'SelfReport'; //上报审核
      }
      if (list.find((item) => item == 'BehaviorRecord')) {
        tag = 'Morality'; //学生端行为规范
      }
      if (list.find((item) => item == 'LiteracyActivities')) {
        tag = 'LiteracyActivities'; //学生端活动上传附件
      }
      if (list.find((item) => item == 'ClubActivities')) {
        tag = 'ClubActivities'; //学生端社团活动
      }
      // tag=list[2];
      for (var it of list) {
        if (it == 1) {
          tag = tag + '1';
        } else if (it == 2) {
          tag = tag + '2';
        } else if (it == 3) {
          tag = tag + '3';
        } else if (it == 4) {
          tag = tag + '4';
        }
      }
      // 如果上传的是视频文件，则获取时长；
      if (that.isVideo) {
        that.getVideoDuration(that, param.file); // 获取视频时间
      } else {
        that.videoDuration = -1;
      }

      var fileObj = param.file;
      // 接收上传文件的后台地址
      var url = that.isIMGFile ? '/api/admin/file/avatarupload' : '/api/admin/file/upload';
      let files = new FormData();
      files.append('file', fileObj);
      files.append('tag', tag);
      that.fullscreenLoading = true;

      ajaxUpload(that, url, files, function (r) {
        that.fullscreenLoading = false;
        if (!regNull(r)) {
          if (r.result == true) {
            var file = {
              uid: param.file.uid,
              url: that.$ServerUrl() + r.path,
              path: r.path,
              name: param.file.name,
              size: param.file.size,
              duration: that.videoDuration
            };
            that.fileList.push(file);
            that.$emit('onPictureHandleSuccess', that.fileList);
          } else {
            // 上传失败清除列表
            warning(that, r.message);
            that.$refs['upload'].clearFiles();
          }
        } else {
          // 上传失败清除列表
          that.$refs['upload'].clearFiles();
          that.$emit('onPictureHandleSuccess', null);
        }
      });
    },
    // 获取视频的时间长
    getVideoDuration(that, file) {
      var url = URL.createObjectURL(file);
      var audioElement = new Audio(url);
      var result;
      audioElement.addEventListener('loadedmetadata', function () {
        // 视频时长值的获取要等到这个匿名函数执行完毕才产生
        result = audioElement.duration; //得到时长为秒，小数，182.36
        that.videoDuration = parseInt(result); //转为int值
      });
    },

    beforeUpload(file) {
      let that = this;
      // 图片大小
      let isImgSize = webUploadImgSize();
      if (that.isPicture == true) {
        const isJPG = file.type === 'image/jpeg';
        // || file.type === 'image/gif' || file.type === 'image/bmp' || file
        //   .type === 'image/png'
        const isLt2M = file.size / 1024 / 1024 < isImgSize;

        if (!isJPG) {
          warning(that, '仅支持jpg格式的图片上传!');
        }
        if (!isLt2M) {
          warning(that, '上传图片大小不能超过 ' + isImgSize + 'MB!');
        }
        that.isIMGFile = true;
        // return isJPG;
        return isJPG && isLt2M;
      } else if (that.isVideoOrPdf) {
        // 上传视频和PDF

        const isFormat = file.type === 'video/mp4' || file.type === 'application/pdf';
        that.isVideo = file.type === 'video/mp4';
        const isLt1000M = file.size / 1024 / 1024 < webUploadPDFSize();
        if (!isLt1000M) {
          warning(that, '上传文件最大不能超过 ' + webUploadPDFSize() + 'MB!');
          return false;
        }
        if (!isFormat) {
          warning(that, '仅支持mp4或pdf格式的文件上传!');
          return false;
        }
        that.isIMGFile = false;
        return isLt1000M && isFormat;
      } else {
        if (file.type == 'application/x-msdownload' || file.type == 'text/javascript') {
          warning(that, '不支持js和exe格式的文件上传!');
          return false;
        }
        const isLt50M = file.size / 1024 / 1024 < webUploadFileSize();
        if (!isLt50M) {
          warning(that, '上传文件大小不能超过 ' + webUploadFileSize() + 'MB!');
          return false;
        }
        that.isIMGFile = false;
        return isLt50M;
      }
    },
    // 删除上传的图片
    handleRemove(file) {
      let that = this;
      var number = -1;
      for (var i in that.fileList) {
        var it = that.fileList[i];
        if (it.uid == file.uid) {
          number = i;
          break;
        }
      }
      if (number >= 0) {
        that.fileList.splice(i, 1);
        that.$emit('onPictureHandleSuccess', that.fileList);
      }
    },
    handlePreview(file) {
      window.open(file.url);
    },
    // 预览图片
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // 下载上传的图片
    handleDownload(file) {
      let that = this;
      var number = -1;
      for (var i in that.fileList) {
        var it = that.fileList[i];
        if (it.uid == file.uid) {
          number = i;
          break;
        }
      }
      if (number >= 0) {
        window.open(that.fileList[number].url);
      }
    }
  }
};
</script>

<style>
.el-upload-view .el-upload--picture-card {
  display: none;
}
</style>
