<!--行为规范-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <search :key="random" :SearchTitles="SearchTitles" :ColNumber="3" :SearchList="SearchList" ref="SearchList" @onSearch="onSearch" v-if="SearchList != null"></search>

    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title">
          评价中心
          <span class="titleErr" v-if="currentType == 1 || currentType == 2">（记录由个人关联而来，无法申诉、落实、删除）</span>
        </h3>
        <div class="card-tools">
          <ul class="nav">
            <li
              class="nav-item"
              style="margin-right: 5px"
              v-if="isPower.class_record_classlog_export || isPower.class_record_log_export || isPower.class_record_dormitorylog_export"
            >
              <el-button @click="onGetExport()" icon="el-icon-download" style="background-color: #17a2b8; color: white">导出</el-button>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body p-0" v-if="item != null && item.length != 0" :key="random">
        <el-table stripe :data="item" class="elTableData" border>
          <el-table-column type="index" label="序号" class="elColumn" width="80" :index="indexMethod" align="center"></el-table-column>
          <el-table-column prop="class" min-width="100" label="班级" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.transformStatus == 2" style="color: #409eff">已落实</span>
              <span v-if="scope.row.transformStatus == 3" style="color: #409eff">无法落实</span>
              <span v-if="scope.row.related == true" style="color: #409eff; font-size: 16px">关联</span>
              {{ scope.row.class ? scope.row.class : scope.row.className }}
            </template>
          </el-table-column>
          <el-table-column prop="room" min-width="85" label="寝室" align="center"></el-table-column>
          <el-table-column prop="student" label="责任人" min-width="80" align="center">
            <template slot-scope="scope">
              <div
                v-if="regNull(scope.row.student) && regNull(scope.row.responsible) && scope.row.isImplement"
                style="font-size: 24px; color: #60c560; cursor: pointer"
                @click="onImplement(scope.row)"
              >
                落实
              </div>
              <div v-else>
                {{ scope.row.student ? scope.row.student : scope.row.responsible }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="reason" label="原因" align="center" min-width="200">
            <template slot-scope="scope">
              <span v-if="scope.row.appealStatus == 1" style="color: #409eff">申诉中</span>
              <span v-if="scope.row.appealStatus == 2" style="color: #67c23a">申诉成功</span>
              <span v-if="scope.row.appealStatus == 3" style="color: #f56c6c">申诉驳回</span>
              <span v-if="scope.row.appealStatus == 4" style="color: #909399">申诉过期</span>
              <span v-html="onGetReason(scope.row)"></span>
            </template>
          </el-table-column>
          <el-table-column prop="remark" min-width="120" label="备注" align="center"></el-table-column>
          <el-table-column prop="score" label="分数" min-width="60" align="center">
            <template slot-scope="scope">
              <div v-html="scope.row.score"></div>
            </template>
          </el-table-column>
          <el-table-column prop="logDate" label="检查时间" min-width="150" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.logDate | dateformatMinute }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="attachment" label="附件" min-width="90" align="center">
            <template slot-scope="scope">
              <span @click="onPreview(scope.row.attachment)" v-if="scope.row.attachment" class="color-primary cursor-pointer">点击查看</span>

              <span v-else>无</span>
            </template>
          </el-table-column>
          <el-table-column prop="createdBy" label="记录人" min-width="80" align="center" v-if="isPower.class_recordperson_details">
            <template slot-scope="scope">
              <span>{{ scope.row.createdBy }}</span>
            </template>
          </el-table-column>
          <!-- 班级操作 -->
          <el-table-column label="操作" :width="200" fixed="right" align="center" v-if="currentType == 1">
            <template slot-scope="scope">
              <!-- <div class="t-r"> -->
              <operationBtn v-if="isPower.class_record_classlog_details" :btnName="'查看'" @click.native="onDetail(scope.row.logId)"></operationBtn>
              <el-dropdown
                v-if="scope.row.isAppeal || scope.row.isCancel || scope.row.isImplement || scope.row.cancelImplement || scope.row.isDelete"
                trigger="click"
                @command="handleCommand($event, scope.row.logId, scope.row)"
                class="ml-1"
              >
                <el-button size="mini">
                  更多操作
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-if="scope.row.isAppeal" class="color-primary" command="onAppeal">申诉</el-dropdown-item>
                  <el-dropdown-item v-if="scope.row.isCancel" class="color-primary" command="onCancel">取消申诉</el-dropdown-item>
                  <el-dropdown-item v-if="scope.row.isImplement" class="color-warning" command="onImplement">落实</el-dropdown-item>
                  <el-dropdown-item v-if="scope.row.cancelImplement" class="color-warning" command="onCancelImplement">取消落实</el-dropdown-item>
                  <el-dropdown-item v-if="scope.row.isDelete" class="color-danger" command="onDelete">删除</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <!-- </div> -->
            </template>
          </el-table-column>
          <!-- 寝室 -->
          <el-table-column label="操作" :width="200" fixed="right" align="center" v-if="currentType == 2">
            <template slot-scope="scope">
              <!-- <div class="t-r"> -->
              <operationBtn v-if="isPower.class_record_dormitorylog_details" :btnName="'查看'" @click.native="onDetail(scope.row.logId)"></operationBtn>
              <el-dropdown v-if="scope.row.isDelete" trigger="click" @command="handleCommand($event, scope.row.logId, scope.row)" class="ml-1">
                <el-button size="mini">
                  更多操作
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown" v-if="scope.row.isDelete">
                  <el-dropdown-item v-if="scope.row.isDelete" class="color-danger" command="onDelete">删除</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <!-- </div> -->
            </template>
          </el-table-column>
          <!-- 班级个人操作 -->
          <el-table-column label="操作" :width="200" fixed="right" align="center" v-if="currentType == 3">
            <template slot-scope="scope">
              <!-- <div class="t-r"> -->
              <operationBtn v-if="isPower.class_record_log_details" :btnName="'查看'" @click.native="onDetail(scope.row.logId)"></operationBtn>
              <el-dropdown
                v-if="scope.row.isAppeal || scope.row.isCancel || scope.row.isDelete"
                trigger="click"
                @command="handleCommand($event, scope.row.logId, scope.row)"
                class="ml-1"
              >
                <el-button size="mini">
                  更多操作
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-if="scope.row.isAppeal" class="color-primary" command="onAppeal">申诉</el-dropdown-item>
                  <el-dropdown-item v-if="scope.row.isCancel" class="color-primary" command="onCancel">取消申诉</el-dropdown-item>
                  <el-dropdown-item v-if="scope.row.isDelete" class="color-danger" command="onDelete">删除</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <!-- </div> -->
            </template>
          </el-table-column>
        </el-table>
        <comPage :paging="page" :pageBtnList="pageBtnList" @pagingClick="pagingClick" @pageBtnClick="pageBtnClick"></comPage>
      </div>

      <div class="card-body" v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>

    <!-- 申诉 -->
    <el-dialog
      :title="'申诉'"
      :visible.sync="dialogVisibleAppeal"
      width="30%"
      :before-close="handleCloseAppeal"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :append-to-body="true"
      :fullscreen="false"
    >
      <el-form ref="ruleFormAppeal" :model="ruleFormAppeal" :rules="rulesAppeal">
        <el-form-item label="申诉理由" prop="appealReason">
          <el-input type="textarea" v-model="ruleFormAppeal.appealReason" :rows="5" placeholder="请输入申诉理由"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleAppeal = false">取 消</el-button>
        <el-button type="primary" @click="onUpdateAppeal">确 定</el-button>
      </span>
    </el-dialog>

    <!--配合走马灯效果放大图片组件-->
    <el-image-viewer v-if="showViewer" :on-close="closeViewer" :url-list="bigImgList" />
  </div>
</template>

<script>
import '@/assets/css/Admin.css';
import comPage from '@/components/Page.vue';
import operationBtn from '@/components/operationBtn.vue';
import search from '@/components/search.vue';
import ElImageViewer from 'element-ui/packages/image/src/image-viewer';
export default {
  components: {
    comPage,
    operationBtn,
    search,
    ElImageViewer
  },
  data() {
    return {
      random: 0, //刷新组件
      SearchTitles: [],
      SearchList: null, //搜索集合
      currentType: null, //当前类型
      fullscreenLoading: false,
      page: null, //分页查询接口返回的结果
      item: [], //列表数据
      pageBtnList: null, //分页左侧按钮集合
      paging: {
        //分页数据
        pageLength: 0, // 总共页数
        Size: 10, // 当前请求数量
        Index: 1 //当前在第几页
      },
      dialogVisible: false,
      dialogVisibleAppeal: false,
      showViewer: false,
      ruleForm: {
        logId: null,
        appeal: ''
      },
      // 申诉
      ruleFormAppeal: {
        logId: null,
        memberId: null,
        appealReason: null
      },
      ruleFormAppealReset: null,
      rulesAppeal: {
        appealReason: [
          {
            max: 256,
            message: '申诉理由不能超过256个字符',
            trigger: 'change'
          }
        ]
      },
      optAppealStatus: [],
      optAll: [
        {
          Value: null,
          Title: '全部'
        },
        {
          Value: 'true',
          Title: '已落实'
        },
        {
          Value: 'false',
          Title: '未落实'
        }
      ],
      isPower: {
        // 班级权限
        class_record_classlog: false,
        class_record_classlog_details: false,
        class_record_classlog_delete: false,
        class_record_classlog_appeal: false,
        class_record_classlog_implement: false,
        class_record_classlog_cancel_implement: false,
        class_record_classlog_export: false,
        // 班级个人权限
        class_record_log: false,
        class_record_log_details: false,
        class_record_log_delete: false,
        class_record_log_appeal: false,
        class_record_log_export: false,
        // 寝室权限
        class_record_dormitorylog: false,
        class_record_dormitorylog_details: false,
        class_record_dormitorylog_delete: false,
        class_record_dormitorylog_appeal: false,
        class_record_dormitorylog_implement: false,
        class_record_dormitorylog_export: false,
        class_recordperson_details: false,
        //删除所有记录
        class_all_delete: false
      },
      LogDateStart: null,
      LogDateEnd: null,
      url: null,

      uploadSearch: {},
      uploadApi: null,
      uploadNmae: null,
      // 教师信息
      TeacherInfo: {},
      // 放大图片
      bigImgList: [],

      roleInfo: {}
    };
  },
  created() {
    let that = this;

    if (regNull(that.$GetKey('accessToken'))) {
      that.$router.push({
        path: '/Login',
        query: {
          url: that.$router.history.current.fullPath
        }
      });
      return false;
    }

    that.TeacherInfo = onGetNowUser(that);
    that.roleInfo = onGetNowRole(that);

    that.ruleFormAppealReset = JSON.parse(JSON.stringify(that.ruleFormAppeal));

    isPower(that, 'class_record_classlog', 'class_record_classlog');
    isPower(that, 'class_record_classlog_details', 'class_record_classlog_details');
    isPower(that, 'class_record_classlog_delete', 'class_record_classlog_delete');
    isPower(that, 'class_record_classlog_appeal', 'class_record_classlog_appeal');

    isPower(that, 'class_record_classlog_implement', 'class_record_classlog_implement');
    isPower(that, 'class_record_classlog_cancel_implement', 'class_record_classlog_cancel_implement');
    isPower(that, 'class_record_classlog_export', 'class_record_classlog_export');

    isPower(that, 'class_record_log', 'class_record_log');
    isPower(that, 'class_record_log_details', 'class_record_log_details');
    isPower(that, 'class_record_log_delete', 'class_record_log_delete');
    isPower(that, 'class_record_log_appeal', 'class_record_log_appeal');
    isPower(that, 'class_record_log_export', 'class_record_log_export');

    isPower(that, 'class_record_dormitorylog', 'class_record_dormitorylog');
    isPower(that, 'class_record_dormitorylog_details', 'class_record_dormitorylog_details');
    isPower(that, 'class_record_dormitorylog_delete', 'class_record_dormitorylog_delete');
    isPower(that, 'class_record_dormitorylog_implement', 'class_record_dormitorylog_implement');
    isPower(that, 'class_record_dormitorylog_appeal', 'class_record_dormitorylog_appeal');
    isPower(that, 'class_record_dormitorylog_export', 'class_record_dormitorylog_export');
    isPower(that, 'class_recordperson_details', 'class_recordperson_details');
    isPower(that, 'class_all_delete', 'class_all_delete');
    ajaxGet(that, '/api/dict/appealstatus', null, (r) => {
      if (!regNullArray(r)) {
        r.forEach((element) => {
          that.optAppealStatus.push({
            Value: element.Value,
            Title: element.Text
          });
        });
      }
      Init(that);
    });
  },
  methods: {
    onGetExport() {
      let that = this;
      that.fullscreenLoading = true;
      ajaxExport(that, that.uploadApi, that.uploadSearch, that.uploadNmae, function (resData) {
        that.fullscreenLoading = false;
      });
    },
    /**
     * @description 展示关联的学生名单
     */
    onGetRelatedStudent(objItem) {
      if (!regNullArray(objItem)) {
        return (
          '关联学生：' +
          objItem
            .map((item) => {
              return item.name + '【' + item.studentNo + '】';
            })
            .join('、')
        );
      } else {
        return '暂无关联学生';
      }
    },
    /**
     * @description 提交申诉
     */
    onUpdateAppeal() {
      let that = this;
      that.$refs['ruleFormAppeal'].validate((valid) => {
        if (valid) {
          let url = '';
          if (that.currentType == 1) {
            url = '/api/admin/moralclass/appeal';
          } else if (that.currentType == 3) {
            url = '/api/admin/moralperson/appeal';
          }
          that.fullscreenLoading = true;
          ajaxPut(that, url, that.ruleFormAppeal, (r) => {
            that.fullscreenLoading = false;
            that.dialogVisibleAppeal = false;
            Init(that);
          });
        } else {
          setTimeout(() => {
            var isError = document.getElementsByClassName('is-error');
            var el = isError[0].querySelector('input') || isError[0].querySelector('textarea') || isError[0].querySelector('select');
            if (el) {
              el.focus();
            }
          }, 1);
          return false;
        }
      });
    },
    /**
     * @description 关闭申诉弹窗
     */
    handleCloseAppeal() {
      let that = this;
      that.dialogVisibleAppeal = false;
    },
    /**
     * @description 取消申诉
     */
    onCancel(obj) {
      let that = this;
      let url = '';
      if (that.currentType == 1) {
        url = '/api/admin/moralclass/unappeal?id=' + obj.logId;
      } else if (that.currentType == 3) {
        url = '/api/admin/moralperson/unappeal?id=' + obj.logId;
      }
      that.fullscreenLoading = true;
      ajaxPut(that, url, null, (r) => {
        that.fullscreenLoading = false;
        Init(that);
      });
    },
    /**
     * @description 打开申诉弹窗
     */
    onAppeal(obj) {
      let that = this;

      let show = compareDate(new Date(), new Date(obj.appealDeadline));
      if (show == true) {
        that.ruleFormAppeal = JSON.parse(JSON.stringify(that.ruleFormAppealReset));
        that.ruleFormAppeal.logId = obj.logId;
        that.$nextTick(() => {
          that.$refs.ruleFormAppeal.clearValidate();
        });
        that.dialogVisibleAppeal = true;
      } else {
        warning(that, '申诉过期');
      }
    },

    /**
     *
     *
     */
    onCancelImplement(obj) {
      let that = this;
      ajaxPut(that, '/api/admin/moralclass/canceltransform?id=' + obj.logId, null, (r) => {
        that.fullscreenLoading = false;
        Init(that);
      });
    },
    /**
     * @description 更多操作
     * @param {Object} command
     * @param {Object} _valueID
     * @param {Object} objVal
     */
    handleCommand(objType, objValID, objVal) {
      let that = this;
      switch (objType) {
        case 'onImplement':
          if (objVal.appealStatus == 1) {
            warning(that, '待处理数据无法落实，请先取消申诉');
            return false;
          }
          that.onImplement(objVal);
          break;
        case 'onDelete':
          that.onDelete(objVal);
          break;
        case 'onAppeal':
          that.onAppeal(objVal);
          break;
        case 'onCancel':
          that.onCancel(objVal);
          break;
        case 'onCancelImplement':
          that.onCancelImplement(objVal);
          break;
      }
    },
    /**
     * @description 显示原因
     * @param {Object} objItem
     */
    onGetReason(objItem) {
      return objItem.reason;
      // return objItem.reason + (regNull(delHtml(objItem.remark)) ? '' : '<span class="color-gray">（' + delHtml(objItem
      //   .remark) + '）</span>');
    },
    /**
     * @description 列表序号索引
     * @param {Object} index
     */
    indexMethod(index) {
      return (this.paging.Index - 1) * this.paging.Size + index + 1;
    },
    /**
     * @description 落实
     * @param {Object} objItem
     */
    onImplement(objItem) {
      let that = this;

      let url = '';
      if (that.currentType == 1) {
        url = '/Admin/Adviser/Morality/ClassImplement/' + objItem.logId;
      }
      that.$router.push({
        path: url,
        query: {
          url: that.$router.history.current.fullPath
        }
      });
    },
    onDetail(Id) {
      let that = this;
      that.$router.push({
        path: '/Admin/Adviser/Morality/Detail/' + that.currentType + '/' + Id,
        query: {
          url: that.$router.history.current.fullPath
        }
      });
    },
    onDelete(objVal) {
      let that = this;
      var deleteUrl = '';
      if (that.currentType == 1) {
        deleteUrl = '/api/admin/moralclass/' + objVal.logId;
      } else if (that.currentType == 2) {
        deleteUrl = '/api/admin/moraldorm/' + objVal.logId;
      } else if (that.currentType == 3) {
        deleteUrl = '/api/admin/moralperson/' + objVal.logId;
      }
      if (deleteUrl != '') {
        confirmDelete(that, null, function (res) {
          if (res == true) {
            that.fullscreenLoading = true;
            ajaxDelete(that, deleteUrl, null, function (r) {
              that.fullscreenLoading = false;
              Init(that);
            });
          }
        });
      }
    },
    pageBtnClick(index) {
      //分页组件左侧的按钮事件，按顺序进行判断
    },
    onSearch(params) {
      let that = this;
      let data = {};
      let searchData = params;
      searchData.forEach((element) => {
        if (element.data) {
          data[element.zhi] = Join(element.data);
        }
      });
      data.PageNumer = 1;
      data['type'] = that.currentType;
      routerPath(that, '/Admin/Adviser/Morality/Index', data, null, 'PageSize');
    },
    pagingClick(type, val) {
      let that = this;
      let url = '/Admin/Adviser/Morality/Index?type=' + that.currentType;
      if (type == 1) {
        //更改每页条数触发的事件
        routerPath(that, url, 'PageSize', val, 'PageNumer', 'Reason', 'ClassId', 'Student', 'LogDate', 'LastUpdateDate', 'AppealStatus', 'All');
      } else {
        //更改当前页时触发的事件
        routerPath(that, url, 'PageNumer', val, 'PageSize', 'Reason', 'ClassId', 'Student', 'LogDate', 'LastUpdateDate', 'AppealStatus', 'All');
      }
    },

    /**
     * @description 打开放大图片
     */
    onPreview(str) {
      if (typeof str == 'string') {
        var files = str.split('|');
        for (var i in files) {
          this.bigImgList.push(this.$ServerUrl() + files[i]);
        }
      }
      this.showViewer = true;
    },
    /**
     * @description 关闭图片预览图
     */
    closeViewer() {
      this.bigImgList = [];
      this.showViewer = false;
    }
  },
  watch: {
    $route() {
      let that = this;
      Init(that);
    }
  }
};

function Init(that) {
  that.currentType = that.$route.query.type;

  if (regNull(that.currentType)) {
    if (that.isPower.class_record_classlog == true) {
      that.currentType = 1;
    } else if (that.isPower.class_record_log == true) {
      that.currentType = 2;
    } else {
      that.currentType = 1;
    }
  }
  that.SearchTitles = [];
  if (that.isPower.class_record_classlog == true) {
    that.SearchTitles.push({
      name: '班级',
      isCurrent: that.currentType == 1 ? true : false,
      url: '/Admin/Adviser/Morality/Index?type=1'
    });
  }
  if (that.isPower.class_record_dormitorylog == true) {
    that.SearchTitles.push({
      name: '寝室',
      isCurrent: that.currentType == 2 ? true : false,
      url: '/Admin/Adviser/Morality/Index?type=2'
    });
  }
  if (that.isPower.class_record_log == true) {
    that.SearchTitles.push({
      name: '个人',
      isCurrent: that.currentType == 3 ? true : false,
      url: '/Admin/Adviser/Morality/Index?type=3'
    });
  }

  pagingPage(that);
}

function isMoreThanSevenDays(targetDate) {
  const now = Date.now(); // 当前时间的时间戳
  const targetTimestamp = new Date(targetDate).getTime(); // 目标日期的时间戳
  const difference = Math.abs(now - targetTimestamp); // 两个时间戳的差值
  const sevenDaysInMilliseconds = 7 * 24 * 60 * 60 * 1000; // 七天的毫秒数

  return difference > sevenDaysInMilliseconds; // 判断是否超过七天
}

function pagingPage(that) {
  paging(
    that,
    function (r) {
      that.fullscreenLoading = true;
      let Myurl = null;
      if (that.currentType == 1) {
        Myurl = '/api/admin/moralclass/search';
        that.uploadApi = '/api/admin/moralclass/export';
        that.uploadNmae = '班级扣分.xlsx';
      } else if (that.currentType == 2) {
        Myurl = '/api/admin/moraldorm/search';
        that.uploadApi = '/api/admin/moraldorm/export';
        that.uploadNmae = '寝室扣分.xlsx';
      } else if (that.currentType == 3) {
        Myurl = '/api/admin/moralperson/search';
        that.uploadApi = '/api/admin/moralperson/export';
        that.uploadNmae = '个人扣分.xlsx';
      }

      let resultClass = that.TeacherInfo.isTeacher ? that.TeacherInfo.manageClass : that.TeacherInfo.allClass;

      var ClassId = r.ClassId;
      if (!regNullArray(resultClass)) {
        var classList = [];

        for (var i in resultClass) {
          classList.push({
            Title: resultClass[i].alias,
            Value: resultClass[i].classId
          });
        }

        if (that.TeacherInfo.isTeacher) {
          if (regNull(ClassId) && !regNullArray(resultClass)) {
            ClassId = resultClass[0].classId;
          }
        }
      }

      that.SearchList = [
        {
          type: 'select',
          Name: '班级',
          data: ClassId,
          select: classList,
          zhi: 'ClassId',
          isClear: !that.TeacherInfo.isTeacher //select是否可以清除
        },
        {
          type: 'input',
          Name: '管理原因',
          data: r.Reason,
          holder: '请输入管理原因',
          zhi: 'Reason'
        },
        {
          type: 'time',
          Name: '检查时间',
          data: r.LogDate,
          zhi: 'LogDate'
        },
        // {
        //   type: "time",
        //   Name: "操作时间",
        //   data: r.LastUpdateDate,
        //   zhi: "LastUpdateDate"
        // },
        // 申诉状态
        {
          type: 'select',
          Name: '申诉状态',
          data: r.AppealStatus,
          select: that.optAppealStatus, //选择的数据
          zhi: 'AppealStatus', // 传值
          isClear: true //select是否可以清除
        }
      ];
      if (that.currentType == 1) {
        that.SearchList.push({
          type: 'select',
          Name: '是否落实',
          data: r.All,
          select: that.optAll, //选择的数据
          zhi: 'All', // 传值
          isClear: true //select是否可以清除
        });
      }
      if (that.currentType == 3) {
        that.SearchList.push({
          type: 'input',
          Name: '学生',
          data: r.Student,
          holder: '请输入学生姓名/学号',
          zhi: 'Student'
        });
      }

      var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
      that.random += random;

      var LogDateList = [];
      var LastUpdateDateList = [];
      let data = {};
      if (r.LogDate != null && r.LastUpdateDate != null) {
        LogDateList = JSON.parse(JSON.stringify(r.LogDate)).split(',');
        LastUpdateDateList = JSON.parse(JSON.stringify(r.LastUpdateDate)).split(',');
        data = {
          type: that.currentType,
          PageNumer: r.PageNumer,
          PageSize: r.PageSize,
          Reason: r.Reason,
          ClassId: ClassId,
          Student: r.Student,
          LogDateStart: LogDateList[0],
          LogDateEnd: LogDateList[1],
          LastUpdateDateStart: LastUpdateDateList[0],
          LastUpdateDateEnd: LastUpdateDateList[1],
          AppealStatus: r.AppealStatus,
          All: r.All,
          RoleId: that.roleInfo.roleId
        };
      } else if (r.LogDate != null) {
        LogDateList = JSON.parse(JSON.stringify(r.LogDate)).split(',');
        data = {
          type: that.currentType,
          PageNumer: r.PageNumer,
          PageSize: r.PageSize,
          Reason: r.Reason,
          ClassId: ClassId,
          Student: r.Student,
          LogDateStart: LogDateList[0],
          LogDateEnd: LogDateList[1],
          AppealStatus: r.AppealStatus,
          All: r.All,
          RoleId: that.roleInfo.roleId
        };
      } else if (r.LastUpdateDate != null) {
        LastUpdateDateList = JSON.parse(JSON.stringify(r.LastUpdateDate)).split(',');
        data = {
          type: that.currentType,
          PageNumer: r.PageNumer,
          PageSize: r.PageSize,
          Reason: r.Reason,
          ClassId: ClassId,
          Student: r.Student,
          LastUpdateDateStart: LastUpdateDateList[0],
          LastUpdateDateEnd: LastUpdateDateList[1],
          AppealStatus: r.AppealStatus,
          All: r.All,
          RoleId: that.roleInfo.roleId
        };
      } else {
        data = {
          type: that.currentType,
          PageNumer: r.PageNumer,
          PageSize: r.PageSize,
          Reason: r.Reason,
          ClassId: ClassId,
          Student: r.Student,
          AppealStatus: r.AppealStatus,
          All: r.All,
          RoleId: that.roleInfo.roleId
        };
      }
      that.paging.Index = r.PageNumer;
      that.paging.Size = r.PageSize;

      if (that.$route.query.url == '/admin/Preparations/index') {
        for (let key in that.SearchList) {
          if (that.SearchList[key].zhi == 'Student') {
            that.SearchList[key].data = that.$route.query.Student;
          }
        }
        data.Student = that.$route.query.Student;
      }
      that.uploadSearch = data;
      ajaxGet(that, Myurl, data, function (r1) {
        that.fullscreenLoading = false;
        that.page = r1;
        that.item = r1.items;
        for (var i in that.item) {
          var it = that.item[i];
          // 判断是否可以删除按钮isDelte，true可以删除，false不能删除
          it.isDelete = true;
          // 判断是否可以申诉按钮isAppeal，true可以申诉，false不能申诉
          it.isAppeal = true;
          // 判断是否可以取消申诉按钮isCancel，true可以取消申诉，false不能取消申诉
          it.isCancel = true;
          // 判断是否可以落实，true可以落实，false不能落实
          it.isImplement = true;
          // 判断是否可以取消落实
          it.cancelImplement = true;
          //不是已经落实的无法取消落实
          if (it.transformStatus != 2) {
            it.cancelImplement = false;
          } else {
            it.isAppeal = false;
            it.isImplement = false;
          }
          //不是已申诉的无法取消申诉
          if (it.appealStatus != 1) {
            it.isCancel = false;
          } else {
            it.isAppeal = false;
            it.isImplement = false;
          }

          // 没申诉权限无法申诉和取消申诉
          if ((!that.isPower.class_record_classlog_appeal && that.currentType == 1) || (!that.isPower.class_record_log_appeal && that.currentType == 3)) {
            it.isAppeal = false;
            it.isCancel = false;
          }

          // 没落实权限无法落实和取消落实
          if (!that.isPower.class_record_classlog_implement || that.currentType != 1) {
            it.isImplement = false;
            it.cancelImplement = false;
          }

          // 有申诉状态说明已发起过申诉，无法再次申诉（申诉只能一次）
          if (it.appealStatus) {
            it.isAppeal = false;
          }

          if (!that.isPower.class_all_delete) {
            //只能删除自己的扣分
            if (that.TeacherInfo.userId !== it.createdByTeacherId) {
              it.isDelete = false;
            }
            // 没删除权限无法删除
            if (
              (that.currentType == 1 && !that.isPower.class_record_classlog_delete) ||
              (that.currentType == 2 && !that.isPower.class_record_dormitorylog_delete) ||
              (that.currentType == 3 && !that.isPower.class_record_log_delete)
            ) {
              it.isDelete = false;
            }
          }

          // 无法落实和申诉成功 不能落实、取消落实、申诉、取消申诉
          if (it.transformStatus == 3 || it.appealStatus == 2) {
            it.isAppeal = false;
            it.isCancel = false;
            it.isImplement = false;
            it.cancelImplement = false;
          }
          // 由班级个人关联过来的数据，不需要申诉、落实、取消落实、取消申诉
          if (it.related) {
            // it.isDelete = false;
            it.isAppeal = false;
            it.isCancel = false;
            it.isImplement = false;
            it.cancelImplement = false;
          }
        }
      });
    },
    'Reason',
    'ClassId',
    'Student',
    'LogDate',
    'LastUpdateDate',
    'AppealStatus',
    'All'
  );
}
</script>

<style scoped="scoped">
.navTabsCont .nav-link {
  font-size: 12px;
}

.navToolsCont {
  margin-top: 1px;
}

.btn-mini {
  margin-left: 15px;
  margin-right: 5px;
  height: 40px;
  line-height: 25px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 14px;
}

.spIcon {
  background-color: #f8f9fa;
  font-size: 14px;
  padding: 2px 5px;
}

.spBan {
  color: #409eff;
}

.spBu {
  color: #fac10b;
}

.spXiao {
  color: #f6402c;
}

.titleErr {
  font-size: 14px;
  color: #f6402c;
}

.paperclip {
  margin-right: 5px;
  color: #409eff;
}
</style>
